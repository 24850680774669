<script>
  import { _ } from "svelte-i18n";
  import { writable } from "svelte/store";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { getData, putData, deleteData } from "utils/ApiUtils.svelte";
  import { CSVToArray, arrayIsEmpty } from "utils/FormatUtils.svelte";
  import StudentEnrollmentDropdown from "./StudentEnrollmentDropdown.svelte";
  import { dynamicSort } from "utils/SortingUtils.svelte";
  import { trimText, formatNumberWith4Colors } from "utils/FormatUtils.svelte";
  import {
    currentCourseId,
    isOpenSendFeedbackModal,
    userConfig,
    userDetails,
  } from "../../../components/DataStore";
  import StudentAccountAddFromRoster from "./StudentAccountAddFromRoster.svelte";
  import Tooltip from "components/Tooltip.svelte";
  import PieIcon from "components/visual/PieIcon.svelte";

  export let courseId;
  export let courseName;

  let studentData = [];
  let studentDataStatus = "loading";
  let addStudentFlag = false;
  let uploadStudentCsvFlag = false;
  let addStudentRosterFlag = false;
  let newStudent = { studentEmail: "" };
  let isValidEmail = true;
  let dropArea = undefined;
  let dropAreaHighlighted = false;
  let importStatus = undefined;
  let importStatusMessage = undefined;

  let studentNameFilter = "";
  const studentNameFilter2 = writable("");
  let afterDate = "";
  let beforeDate = "";

  let csvTable = [];

  let sortColumn = "idStudent";
  let sortDirection = 1;

  let showDetails = false;

  function changeSort(c, d) {
    sortColumn = c;
    sortDirection = d;

    studentDataStatus = "loading";

    studentData = studentData.sort(dynamicSort(c, d));

    studentDataStatus = "ok";
  }

  onMount(async () => {
    console.log("studentData", studentData);
    dataStatus = "loading";

    // getStudentAccountReport();

    studentNameFilter2.subscribe((x) => {
      getStudentAccountReport();
    });
  });

  let sMap = new Map();
  let sMapSorted = [];

  let sMap2 = new Map();

  let lMap = new Map();
  let eMap = new Map();
  let cGroups = {};
  let eGroups = {};

  let dataStatus = "new";

  function getStudentAccountReport() {
    sMap = new Map();
    sMapSorted = [];
    sMap2 = new Map();

    dataStatus = "loading";
    getData(
      `api/courses/${courseId}/report?afterDate=${afterDate}&beforeDate=${beforeDate}`,
    )
      .then((data) => {
        // console.log('getStudentDetails successful', data)

        data.forEach((e) => {
          sMap.set(e.studentAccountId, e.studentAccountName);

          lMap.set(e.idLesson, e.lessonName);
          eMap.set(e.idExercise, e.title);
        });

        [...sMap].forEach((s) => {
          sMapSorted.push({ studentAccountId: s[0], studentAccountName: s[1] });
        });

        if ($studentNameFilter2) {
          sMapSorted = sMapSorted.filter((s) =>
            s?.studentAccountName
              ?.toLowerCase()
              ?.includes($studentNameFilter2?.toLowerCase()),
          );
        }

        sMapSorted = sMapSorted.sort(dynamicSort("studentAccountName", 1));

        [...sMap.keys()].forEach((k) => {
          // let obj = data
          //   .filter((v) => v.studentAccountId === k)
          //   .reduce((r, a) => {
          //     const k = a.idLesson; //{ idCourse: a.idCourse, courseName: a.courseName };
          //     r[k] = r[k] || [];
          //     r[k].push(a);
          //     return r;
          //   }, Object.create(null));

          // let counterTotal = 0;
          // let counterWithRec = 0;
          // let sumScore = 0;

          // let arr = Array.from(lMap.entries());

          // let sLMap = new Map();

          // Array.from(lMap.keys()).forEach((lessonId) => {
          //   //console.log(lessonId, obj[Number(lessonId)])
          //   sLMap.set(lessonId, obj[Number(lessonId)])
          // })

          // console.log('y', sLMap)

          // Object.entries(obj).forEach((i) => {
          //   counterTotal++;
          //   if (i?.recordingCount > 0) {
          //     counterWithRec++;
          //     sumScore += i.maxScore;
          //   }
          // });

          let sLMap = new Map();
          let progress = 0;
          let avgScore = 0;

          let counterTotal = 0;
          let counterWithRec = 0;
          let sumScore = 0;

          Array.from(lMap.keys()).forEach((lessonId) => {
            //console.log(lessonId, obj[Number(lessonId)])

            let x = data.filter(
              (v) => v.studentAccountId === k && v.idLesson === lessonId,
            );

            let LEmap = new Map();

            let lessonCounterTotal = 0;
            let lessonCounterWithRec = 0;
            let lessonSumScore = 0;

            x.forEach((d) => {
              counterTotal++;
              lessonCounterTotal++;
              if (d?.recordingCount > 0) {
                counterWithRec++;
                lessonCounterWithRec++;
                sumScore += d.maxScore;
                lessonSumScore += d.maxScore;
              }
            });

            let lessonProgress = 0;
            let lessonAvgScore = 0;

            if (lessonCounterWithRec > 0) {
              lessonProgress = lessonCounterWithRec / lessonCounterTotal;
              lessonAvgScore = lessonSumScore / lessonCounterWithRec;
            }

            sLMap.set(lessonId, {
              leMap: LEmap,
              lessonProgress: lessonProgress,
              lessonAvgScore: lessonAvgScore,
            });
          });

          if (counterWithRec > 0) {
            progress = counterWithRec / counterTotal;
            avgScore = sumScore / counterWithRec;
          }

          sMap2.set(k, {
            studentAccountName: sMap.get(k),
            lesson: sLMap,
            progress: progress,
            avgScore: avgScore,
          });
        });

        cGroups = data.reduce((r, a) => {
          const k = a.idCourse; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        eGroups = data.reduce((r, a) => {
          const k = a.idExercise; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        // sMapSorted = new Map([...sMap2.entries()].sort((a,b) => b['studentAccountName'] - a['studentAccountName']))

        console.log("x", sMap, sMapSorted, sMap2, lMap, eMap, cGroups, eGroups);

        dataStatus = "ok";
      })
      .catch((error) => {
        dataStatus = "error";
        // console.log('error lessons', error)
      });
  }

  
</script>

<div class="card shadow">
  <div class="card-header">
    <div>
      <div style="display: flex;gap: 2em;">
        <div class="form-check">
          <label for="studentNameFilterInput" class="form-check-label"
            >{$_("STUDENT_NAME")}</label
          >
          <input
            id="studentNameFilterInput"
            class="form-control s-form-input"
            type="text"
            style="min-width: 20em;"
            bind:value={$studentNameFilter2}
          />
        </div>
        <div>
          <label for="inputAfterDate" class="mr-3">{$_("AFTER_DATE")}</label>
          <input
            id="inputAfterDate"
            type="date"
            class="form-control"
            bind:value={afterDate}
            on:blur={getStudentAccountReport}
          />
        </div>
        <div>
          <label for="inputBeforeDate" class="mr-3">{$_("BEFORE_DATE")}</label>
          <input
            id="inputBeforeDate"
            type="date"
            class="form-control"
            bind:value={beforeDate}
            on:blur={getStudentAccountReport}
          />
        </div>
        <div class="mt-4 form-check">
          <label id="showDetailsCheckbox" class="mr-3 form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              bind:checked={showDetails}
              for="showDetailsCheckbox"
            />{$_("SHOW_DETAILS")}</label
          >
        </div>
      </div>
    </div>
  </div>
  {#if dataStatus === "loading"}
    <Loader />
  {:else if dataStatus === "error"}
    {$_("ERROR_LOADING_STUDENT_ACCOUNT_LIST")}
  {:else}
    <div>
      <div class="card-body">
        <div>
          <!-- <div>
                {$_("STUDENT_COUNT")}:{studentData?.length} / 30
              </div> -->
          <div
            class="table-responsive table mt-2"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info"
          >
            <table class="table table-hover" id="dataTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{$_("PROGRESS")}</th>
                  <th>
                    <Tooltip title={$_("AVG_BEST_SCORE_TOOLTIP")}>
                      <i class="fa-solid fa-circle-info" />
                    </Tooltip>{$_("AVG_BEST_SCORE")}
                  </th>
                  <th>{$_("STUDENT_NAME")}</th>
                  {#each [...lMap] as [idLesson, l]}
                    {#if !!idLesson}
                      <th
                        ><a
                          href="#/courses/{courseId}/lesson-detail/{idLesson}/all-students-progress"
                        >
                          {#if showDetails}{l}{:else}{trimText(l, 20)}{/if}</a
                        ></th
                      >
                    {/if}
                  {/each}
                </tr>
              </thead>
              <tbody>
                {#each sMapSorted as x, i}
                  {@const s = sMap2.get(x.studentAccountId)}

                  <!-- {#each [...sMap2] as [studentAccountId, s], i} -->
                  <tr>
                    <th scope="row">
                      {i + 1}
                    </th>
                    <td>
                      {#if s.progress === 1}
                        ✅
                      {:else if s.progress > 0}
                        {#if showDetails}
                          {formatNumberWith4Colors(s.progress * 100)}%
                        {:else}
                          ❓
                        {/if}
                      {:else}❌
                      {/if}
                    </td>

                    <td
                      class:good={s.avgScore >= 60}
                      class:bad={s.avgScore > 0 && s.avgScore < 60}
                    >
                      {#if s.avgScore > 0}{formatNumberWith4Colors(
                          s.avgScore,
                        )}{:else}0{/if}
                    </td>
                    <td>
                      {#if x.studentAccountId}<strong
                          ><a
                            href="/#/student-account-detail/{x.studentAccountId}"
                            >{s?.studentAccountName}</a
                          ></strong
                        >{/if}
                    </td>

                    {#each [...s?.lesson] as [idLesson, l]}
                      {#if !!idLesson}
                        <td>
                          {#if l.lessonProgress === 1}
                            {#if l.lessonAvgScore > 0}{formatNumberWith4Colors(
                                l.lessonAvgScore,
                              )}{:else}0{/if}
                          {:else if l.lessonProgress > 0}
                            {#if showDetails}
                              {#if l.lessonAvgScore > 0}{formatNumberWith4Colors(
                                  l.lessonAvgScore,
                                )}{:else}0{/if}
                              ({formatNumberWith4Colors(
                                l.lessonProgress * 100,
                              )}%)
                            {:else}
                              ❓
                            {/if}

                            <!-- <PieIcon value={l.lessonProgress * 100}></PieIcon> -->
                          {:else}❌
                          {/if}
                        </td>
                      {/if}
                    {/each}
                  </tr>
                {:else}NO_DATA
                {/each}
              </tbody>
              <tfoot>
                <tr />
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .card {
    overflow-x: auto;
  }

  .done {
    background-color: green;
    color: aliceblue;
  }

  .good {
    color: darkgreen;
    font-weight: 700;
  }

  .bad {
    color: red;
    font-weight: 700;
  }
</style>
