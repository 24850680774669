<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from 'components/Loader.svelte'
  import { onMount } from 'svelte'
  import { getData, postData, deleteData, putData } from 'utils/ApiUtils.svelte'
  import Breadcrumb from 'components/Breadcrumb.svelte'
  import * as yup from 'yup'

  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    userDetails,
    userConfig,
  } from '../../../components/DataStore'
  import { writable } from 'svelte/store'
  import TextAreaAutoresize from 'components/TextAreaAutoresize.svelte'

  // Form Validation
  const schema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(50, 'COURSE_VALIDATION_TOO_LONG')
      .required('COURSE_VALIDATION_ERROR_NO_NAME'),
    description: yup.string(),
    scoringType: yup.string(),
    reportType: yup.string(),
  })
  let errors = {}

  let courseDetailStatus = 'loading'
  let courseData = writable({
    name: '',
    description: '',
    color: '#BCD2F0',
    language: 'en-US',
    scoringType: 'SCORING_0_TO_100',
    reportType: 'REPORT_V2',
    language: 'en-US',
    privacyType: 'PUBLIC',
    marketplaceType: null,
  })

  let enabledLanguages = ['en-US']
  if ($userDetails?.userRole === 'ADMIN') {
    enabledLanguages = ['en-US', 'en-GB']
  }

  courseData.subscribe((x) => {
    courseDetailStatus = 'ok'
  })

  onMount(async () => {
    if ($currentCourseId) {
      getCourses($currentCourseId)
    } else {
      courseDetailStatus = 'new'
    }
    if ($userConfig?.serverFlags?.enableOtherTTSLanguages === true) {
      enabledLanguages = ['en-US', 'en-GB', 'en-AU', 'en-IN']
    }
  })

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    $currentPage = e
    router.goto('/' + e)
  }

  function getCourses(id) {
    getData('api/courses-2/' + id)
      .then((data) => {
        console.log('courses successful', data)
        courseData.update((x) => data)
        currentCourseName.update((x) => $courseData?.name)
        courseDetailStatus = 'loaded'
      })
      .catch((error) => {
        console.log('error courses', error)
        courseDetailStatus = 'error'
      })
  }

  async function handleSubmit(moveOut) {
    try {
      await schema.validate($courseData, { abortEarly: false })
      // for debugging validation result
      // alert(JSON.stringify($courseData, null, 2));
      errors = {}
      saveCourse(moveOut)
    } catch (err) {
      errors = err.inner.reduce((acc, err) => {
        return { ...acc, [err.path]: err.message }
      }, {})
    }
  }

  function saveCourse(moveOut = false) {
    courseDetailStatus = 'saving'

    let payloadData = {
      name: $courseData.name,
      schoolName: '',
      description: $courseData.description,
      color: $courseData.color,
      language: $courseData.language,
      scoringType: $courseData.scoringType,
      reportType: $courseData.reportType,
      privacyType: $courseData.privacyType,
      marketplaceType: $courseData.marketplaceType,
    }

    console.log('save course', payloadData)

    if (!$courseData?.id) {
      postData('api/courses', payloadData)
        .then((data) => {
          console.log('courses successful', data)

          courseData.update((x) => data)
          currentCourseId.update((x) => data?.id)

          courseDetailStatus = 'saved'
          mixpanel.track('Teacher Save Class Button Clicked', {
            New: true,
            'Course ID': $currentCourseId,
            'Course Name': $courseData.name,
            'Course Description': $courseData.description,
            'Scoring Type': $courseData.scoringType,
            'Report Type': $courseData.reportType,
          })

          if (moveOut) {
            clickChangePage('courses-detail/' + $currentCourseId)
          }
        })
        .catch((error) => {
          console.log('error courses', error)
          courseDetailStatus = 'error'
        })
    } else {
      mixpanel.track('Teacher Save Class Button Clicked', {
        New: false,
        'Course ID': $courseData.id,
        'Course Name': $courseData.name,
        'Course Description': $courseData.description,
        'Scoring Type': $courseData.scoringType,
        'Report Type': $courseData.reportType,
      })
      putData('api/courses/' + $courseData?.id, payloadData)
        .then((data) => {
          console.log('courses successful', data)

          courseData.update((x) => data)
          courseDetailStatus = 'saved'

          if (moveOut) {
            clickChangePage('courses-detail/' + $courseData?.id)
          }
        })
        .catch((error) => {
          console.log('error courses', error)
          courseDetailStatus = 'error'
        })
    }
  }

  function cancelCourseEdit() {
    if (courseDetailStatus === 'saved' || courseDetailStatus === 'loaded') {
      if ($courseData?.id) {
        clickChangePage('courses-detail/' + $courseData?.id)
      } else {
        clickChangePage('courses')
      }
    } else {
      if (confirm($_('COURSE_CANCEL_EDIT'))) {
        if ($courseData?.id) {
          clickChangePage('courses-detail/' + $courseData?.id)
        } else {
          clickChangePage('courses')
        }
      } else {
        // Do nothing!
        // console.log('Cancel')
      }
    }
  }

  function deleteCourse() {
    console.log('delete course')
    mixpanel.track('Teacher Delete Button Clicked', {
      'Button Location': 'Course Edit Page',
    })
    if (
      confirm(
        $_('COURSE_DELETE_CONFIRM_DIALOG', {
          values: { courseName: $courseData?.name },
        })
      )
    ) {
      // Save it!
      console.log('Thing was saved to the database.')
      deleteData('api/courses/' + $currentCourseId)
        .then((data) => {
          console.log('courses successful', data)

          //moveToPage('course-table.html');
          currentCourseId.update((x) => undefined)
          currentCourseName.update((x) => undefined)
          clickChangePage('courses')
        })
        .catch((error) => {
          console.log('error courses', error)
        })
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.')
    }
  }

  function handleCtrlEnter(event) {
    console.log('Save')
    handleSubmit(true)
  }

  function skipEnterOnInput(event) {
    console.log(event)
    if (event.key === 'Enter' && !event.ctrlKey) {
      event.preventDefault()
    } else if (event.key === 'Enter' && event.ctrlKey) {
      console.log('Save')
      handleSubmit(true)
    }
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <Breadcrumb />
    </div>
  </div>
  {#if courseDetailStatus === 'loading'}
    <Loader />
  {:else}
    <div class="s-content-spacing">
      <div class="card shadow">
        <form
          on:submit|preventDefault|stopPropagation={() => {
            handleSubmit(true)
          }}
        >
          <div class="card-body">
            <div>
              <div class="form-row">
                <div class="col offset-lg-0">
                  <label
                    for="courseEditName"
                    style="padding-right: 12px; padding-top: 6px;"
                    >{$_('COURSE_NAME')}</label
                  >
                  <input
                    id="courseEditName"
                    class="form-control"
                    class:validation-input-box-error={errors.name}
                    style="margin-bottom: 0;"
                    type="text"
                    placeholder={$_('COURSE_NAME')}
                    bind:value={$courseData.name}
                    on:keydown={skipEnterOnInput}
                  />
                  <div class="validation-text-error">
                    {#if errors.name}
                      {$_(errors.name)}
                    {/if}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-12 offset-lg-0">
                  <label for="courseEditDescription"
                    >{$_('COURSE_DESCRIPTION')}</label
                  >
                  <TextAreaAutoresize
                    bind:value={$courseData.description}
                    on:ctrlEnter={handleCtrlEnter}
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="col offset-lg-0">
                  <label for="reportType">{$_('REPORT_TYPE')}</label>
                  <select
                    class="custom-select"
                    id="reportType"
                    bind:value={$courseData.reportType}
                    on:keydown={skipEnterOnInput}
                  >
                    <option value="REPORT_V1">{$_('REPORT_V1')}</option>
                    <option value="REPORT_V2">{$_('REPORT_V2')}</option>
                  </select>
                </div>
              </div>

              {#if $courseData.reportType === 'REPORT_V1'}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="scoringType">{$_('SCORING_TYPE')}</label>

                    <select
                      class="custom-select"
                      id="scoringType"
                      bind:value={$courseData.scoringType}
                      on:keydown={skipEnterOnInput}
                    >
                      <option value="SCORING_0_TO_100"
                        >{$_('SCORING_0_TO_100')}</option
                      >
                      <option value="SCORING_5_STARS"
                        >{$_('SCORING_5_STARS')}</option
                      >
                      <option value="SCORING_NO_SCORE"
                        >{$_('SCORING_NO_SCORE')}</option
                      >
                    </select>
                  </div>
                </div>
              {/if}

              {#if $userConfig?.serverFlags?.enableOtherTTSLanguages === true}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="languageSelect"
                      >{$_('EVALUATION_LANGUAGE')}</label
                    >
                    <select
                      class="custom-select"
                      id="languageSelect"
                      bind:value={$courseData.language}
                      on:keydown={skipEnterOnInput}
                    >
                      {#each enabledLanguages as lang}
                        <option value={lang}>{$_(lang)}</option>
                      {/each}
                    </select>
                  </div>
                </div>
              {/if}

              {#if $userDetails?.userRole === 'ADMIN'}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="reportType"
                      >{$_('ADMIN_ONLY')} {$_('PRIVACY_TYPE')}</label
                    >
                    <select
                      class="custom-select"
                      id="reportType"
                      bind:value={$courseData.privacyType}
                      on:keydown={skipEnterOnInput}
                    >
                      <option value="PUBLIC">{$_('PUBLIC')}</option>
                      <option value="PRIVATE">{$_('PRIVATE')}</option>
                      <option value="MARKETPLACE">{$_('MARKETPLACE')}</option>
                    </select>
                  </div>
                </div>
              {/if}

              {#if $userDetails?.userRole === 'ADMIN'}
                <div class="form-row">
                  <div class="col offset-lg-0">
                    <label for="marketplaceType"
                      >{$_('ADMIN_ONLY')} {$_('MARKETPLACE_TYPE')}</label
                    >
                    <select
                      class="custom-select"
                      id="marketplaceType"
                      bind:value={$courseData.marketplaceType}
                      on:keydown={skipEnterOnInput}
                    >
                      <option value="">{$_('DISABLED')}</option>
                      <option value="MARKETPLACE">{$_('MARKETPLACE')}</option>
                    </select>
                  </div>
                </div>
              {/if}

              {#if courseDetailStatus === 'loading'}
                <div class="alert alert-success with-top-margin" role="alert">
                  {$_('COURSE_SAVED')}
                </div>
              {/if}
            </div>
          </div>

          <div class="card-header py-3">
            <div class="clearfix">
              {#if courseDetailStatus != 'new'}
                <div class="btn-group" role="group">
                  <button
                    class="btn btn-danger s-button-danger"
                    on:click|preventDefault={() => deleteCourse()}
                  >
                    <i
                      class="far fa-window-close"
                      style="padding-right: 8px;"
                    />{$_('DELETE')}
                  </button>
                </div>
              {/if}

              <div class="btn-group float-end" role="group">
                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  on:click={cancelCourseEdit}
                >
                  <i
                    class="far fa-window-close"
                    style="padding-right: 8px;"
                  />{$_('CANCEL')}
                </button>

                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  type="button"
                  style="display: none;"
                  on:click={() => {
                    handleSubmit(true)
                  }}
                >
                  <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                    'SAVE_DRAFT'
                  )}
                  <br />
                </button>

                <button
                  class="btn btn-success s-button-success"
                  disabled={courseDetailStatus === 'saving'}
                  on:click|preventDefault={() => {
                    handleSubmit(true)
                  }}
                >
                  <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                    'SAVE'
                  )}
                  <br />
                </button>
              </div>
            </div>

            {#if courseDetailStatus === 'error'}
              <div class="alert alert-danger" role="alert">
                {$_('COURSE_SAVING_ERROR')}
              </div>
            {:else if courseDetailStatus === 'saving'}
              <div class="alert alert-info" role="alert">
                {$_('PROCESSING')}
              </div>
            {:else if courseDetailStatus === 'saved'}
              <div class="alert alert-success" role="alert">{$_('SAVED')}</div>
            {/if}
          </div>
        </form>
      </div>
    </div>
  {/if}
</main>

<style>
  .validation-text-error {
    color: red;
    font-size: 0.75em;
    height: 3vh;
  }

  .validation-input-box-error {
    border-color: red;
  }
</style>
