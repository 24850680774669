<script>
  import { router } from 'tinro'
  import { currentPage, searchTerm } from '../../components/DataStore'
  import { getData, postData } from 'utils/ApiUtils.svelte'
  import Loader from 'components/Loader.svelte'
  import { onMount } from 'svelte'
  import { _ } from 'svelte-i18n'

  let searchResultList = []

  let searchResultTableStatus = 'loading'

  searchTerm.subscribe((x) => {
    getSearchResult($searchTerm)
  })

  function getSearchResult(searchTerm) {
    if (searchTerm) {
      getData('api/search?searchTerm=' + searchTerm)
        .then((data) => {
          console.log('searchResultList successful', data)

          searchResultList = data
          searchResultTableStatus = 'ok'
        })
        .catch((error) => {
          searchResultTableStatus = 'error'
          console.log('error searchResultList', error)
        })
    }
  }

  function navigateToLesson(id) {
    getData('api/lessons/' + id)
      .then((data) => {
        console.log('lessons successful', data)
        router.goto('/courses/' + data?.courseId + '/lesson-detail/' + data?.id)
      })
      .catch((error) => {
        console.log('error lessons', error)
      })
  }

  function navigateToExercise(id) {
    getData('api/exercises/' + id)
      .then((data) => {
        console.log('exercise successful', data)
        router.goto(
          '/courses/' +
            data?.courseId +
            '/lessons/' +
            data?.lessonId +
            '/exercise-detail/' +
            data?.id
        )
      })
      .catch((error) => {
        console.log('error exercise', error)
      })
  }

  function openSearchResult(type, id, name) {
    console.log('openSearchResult', type, id)

    switch (type) {
      case 'student':
        router.goto('/student-detail/' + name)
        break
      case 'registeredStudent':
        router.goto('/student-detail/' + name)
        break
      case 'course':
        router.goto('/courses-detail/' + id)
        break
      case 'lesson':
        navigateToLesson(id)
        break
      case 'exercise':
        navigateToExercise(id)
        break
    }
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    {#if !$searchTerm}
      <h3 class="text-dark mb-4">{$_('INPUT_SEARCH_TERM')}</h3>
    {:else}
      <h3 class="text-dark mb-4">Search Result: {$searchTerm}</h3>
      <div class="card shadow">
        {#if searchResultTableStatus === 'loading'}
          <Loader />
        {:else if searchResultTableStatus === 'error'}
          Error loading search result
        {:else}
          <div class="card-body">
            <div>
              <div
                class="table-responsive table mt-2"
                id="dataTable"
                role="grid"
                aria-describedby="dataTable_info"
              >
                <table class="table table-hover" id="dataTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {#each searchResultList as l, i}
                      <tr
                        class="lessonRow"
                        on:click={(x) => {
                          openSearchResult(
                            l?.searchResultType,
                            l?.id,
                            l?.resultName
                          )
                        }}
                      >
                        <th scope="row">{i + 1}</th>
                        <td>{l?.searchResultType}</td>
                        <td>{l?.resultName}</td>
                        <td>
                          {#if l?.resultDescription}
                            {l?.resultDescription}
                          {/if}
                        </td>
                      </tr>
                    {:else}
                      No result
                    {/each}
                  </tbody>
                  <tfoot>
                    <tr />
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</main>

<style>
  .lessonRow {
    cursor: pointer;
  }
</style>
