<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { getData, postData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { onMount } from "svelte";
  import SvelteHeatmap from "svelte-heatmap";
  import StudentRecordingsDropDown from "../../components/StudentRecordingsDropDown.svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
    userConfig,
  } from "../../components/DataStore";
  import printJS from "print-js";
  import PremiumIcon from "../../components/visual/PremiumIcon.svelte";


  export let studentAccountId
  export let studentName;

  let recordingList = [];
  let recordingListPage = {};

  let courseList = [];
  let courseListStatus = "loading";

  let studentRecordingListStatus = "loading";

  let selection = {};
  let filterSelections = {
    studentName: studentName,
    afterDate: "",
    beforeDate: "",
    includeQr: true,
    courseId: "",
  };

  let heatMap;
  let heatMapData = {};

  let page = 0;

  let sortColumn = "createdAt";
  let sortDirection = -1;

  userConfig.subscribe((v) => {
    if (v?.serverFlags?.qrHidden === true) {
      filterSelections["includeQr"] = false;
    }
  });

  onMount(async () => {
    getStudentList();
    getCourseList();
  });

  function refreshList() {
    console.log("refresh");
    studentRecordingListStatus = "loading";
    getStudentList();
  }
 

  function getStudentList(downloadAll = false) {
    let size = 100;

    if (downloadAll && studentListPage?.totalElements) {
      size = studentListPage?.totalElements;
    }

    let sort = (sortDirection < 0 ? "-" : "") + sortColumn;
    console.log(222, sort);

    studentRecordingListStatus = "loading";
    getData(
      `api/studentRecordingListPageQueryForOrg?page=${page}&size=${size}&sortBy=${sort}&studentName=${filterSelections.studentName}&afterDate=${filterSelections.afterDate}&beforeDate=${filterSelections.beforeDate}&includeQr=${filterSelections.includeQr}&courseId=${filterSelections.courseId}`,
    )
      .then((data) => {
        console.log("studentList successful", data);

        recordingList = data?.content;
        recordingListPage = data;
        studentRecordingListStatus = "ok";
        try {
          //initHeatMap();
        } catch (error) {
          console.log("error initializing heatmap", error);
        }
      })
      .catch((error) => {
        studentRecordingListStatus = "error";
        console.log("error lessons", error);
      });
  }

  function getCourseList() {
    getData("api/courses-coop")
      .then((data) => {
        console.log("studentList successful", data);

        courseList = data;

        courseListStatus = "ok";
        console.log("courseListStatus", courseListStatus);
      })
      .catch((error) => {
        courseListStatus = "error";
        console.log("error lessons", error);
      });
  }

  function viewRecordingDetail(r) {
    console.log(
      "viewRecordingDetail",
      r?.courseId,
      r?.lessonId,
      r?.exerciseId,
      r?.recordingId,
    );

    currentCourseId.update((x) => r?.courseId);
    currentCourseName.update((x) => r?.courseName);
    currentLessonId.update((x) => r?.lessonId);
    currentLessonName.update((x) => r?.lessonName);
    currentExerciseId.update((x) => r?.exerciseId);
    currentExerciseName.update((x) => r?.exerciseTitle);
    currentRecordingId.update((x) => r?.recordingId);

    mixpanel.track("Teacher View Recording Detail Clicked", {
      "Course ID": r?.courseId,
      "Lesson ID": r?.lessonId,
      "Exercise ID": r?.exerciseId,
      "Recording ID": r?.recordingId,
      "Button Location": "Student Recording Page",
    });

    clickChangePage("recording-detail/" + $currentRecordingId);
  }
  

  function openInNewTab(href) {
    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: href,
    }).click();
  }

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    $currentPage = e;
    router.goto("/" + e);
  }

  function updatePage(newPage) {
    page = newPage;
    getStudentList();
  }

  function changeOrder(column) {
    if (sortColumn === column) {
      sortDirection = -1 * sortDirection;
    }
    sortColumn = column;

    getStudentList();
  }
</script>

<div class="s-content-spacing" style="margin-top: 0 !important;">
  <div class="card shadow">
    <div class="card-body">
      <div style="display: flex;gap: 2em;">
        <div>
          <label class="mr-3">{$_("AFTER_DATE")}</label>
          <input
            type="date"
            class="form-control"
            bind:value={filterSelections.afterDate}
            on:input={refreshList}
          />
        </div>
        <div>
          <label class="mr-3">{$_("BEFORE_DATE")}</label>
          <input
            type="date"
            class="form-control"
            bind:value={filterSelections.beforeDate}
            on:input={refreshList}
          />
        </div>
      </div>
      <div style="display: flex;gap: 2em;">
        <div class="col">
          <label for="studentNameSearch" class="mr-3"
            >{$_("SELECT_COURSE")}</label
          >
          <select
            class="form-select"
            bind:value={filterSelections.courseId}
            on:change={() => {
              refreshList();
            }}
            aria-label="multiple select example"
          >
            <option value="" />
            {#each courseList as c}
              <option value={c.id}>{c.name}</option>
            {/each}
          </select>
        </div>
      </div>
      {#if studentRecordingListStatus != "ok"}
        <Loader />
      {:else}
        <div
          class="table-responsive table mt-2"
          style="overflow-y: hidden"
          id="dataTable"
          role="grid"
          aria-describedby="dataTable_info"
        >
          <table class="table my-0 table-hover" id="dataTable">
            <thead>
              <tr>
                <th>#</th>
                <th
                  class="sorting"
                  on:click={() => {
                    changeOrder("courseName");
                  }}
                >
                  {#if sortColumn === "courseName"}
                    {#if sortDirection < 0}
                      <i class="fas fa-caret-down mr-1" />
                    {:else}
                      <i class="fas fa-caret-up mr-1" />
                    {/if}

                    <strong>{$_("COURSE_NAME")}</strong>
                  {:else}
                    {$_("COURSE_NAME")}
                  {/if}
                </th>

                <th
                  class="sorting"
                  on:click={() => {
                    changeOrder("lessonName");
                  }}
                >
                  {#if sortColumn === "lessonName"}
                    {#if sortDirection < 0}
                      <i class="fas fa-caret-down mr-1" />
                    {:else}
                      <i class="fas fa-caret-up mr-1" />
                    {/if}

                    <strong>{$_("LESSON_NAME")}</strong>
                  {:else}
                    {$_("LESSON_NAME")}
                  {/if}
                </th>

                <th
                  class="sorting"
                  on:click={() => {
                    changeOrder("exerciseTitle");
                  }}
                >
                  {#if sortColumn === "exerciseTitle"}
                    {#if sortDirection < 0}
                      <i class="fas fa-caret-down mr-1" />
                    {:else}
                      <i class="fas fa-caret-up mr-1" />
                    {/if}

                    <strong>{$_("EXERCISE_TITLE")}</strong>
                  {:else}
                    {$_("EXERCISE_TITLE")}
                  {/if}
                </th>

                <th
                  class="sorting"
                  on:click={() => {
                    changeOrder("exerciseType");
                  }}
                >
                  {#if sortColumn === "exerciseType"}
                    {#if sortDirection < 0}
                      <i class="fas fa-caret-down mr-1" />
                    {:else}
                      <i class="fas fa-caret-up mr-1" />
                    {/if}

                    <strong>{$_("EXERCISE_TYPE")}</strong>
                  {:else}
                    {$_("EXERCISE_TYPE")}
                  {/if}
                </th>

                <th
                  class="sorting"
                  on:click={() => {
                    changeOrder("overallScore");
                  }}
                >
                  {#if sortColumn === "overallScore"}
                    {#if sortDirection < 0}
                      <i class="fas fa-caret-down mr-1" />
                    {:else}
                      <i class="fas fa-caret-up mr-1" />
                    {/if}

                    <strong>{$_("SCORE")}</strong>
                  {:else}
                    {$_("SCORE")}
                  {/if}
                </th>
                <!-- <th
                  on:click={() => {
                    changeOrder("teacherScore");
                  }}
                >
                  {#if sortColumn === "teacherScore"}
                    {#if sortDirection < 0}
                      <i class="fas fa-caret-down mr-1" />
                    {:else}
                      <i class="fas fa-caret-up mr-1" />
                    {/if}

                    <strong>{$_("TEACHER_SCORE")}</strong>
                  {:else}
                    {$_("TEACHER_SCORE")}
                  {/if}
                </th> -->
                <th
                  class="sorting"
                  on:click={() => {
                    changeOrder("createdAt");
                  }}
                >
                  {#if sortColumn === "createdAt"}
                    {#if sortDirection < 0}
                      <i class="fas fa-caret-down mr-1" />
                    {:else}
                      <i class="fas fa-caret-up mr-1" />
                    {/if}

                    <strong>{$_("DATE_TIME_RECORDED")}</strong>
                  {:else}
                    {$_("DATE_TIME_RECORDED")}
                  {/if}
                </th>
              </tr>
            </thead>
            <tbody>
              {#each recordingList as l, i}
                <tr
                  class="lessonRow"
                  on:click={(x) => {
                    viewRecordingDetail(l);
                  }}
                >
                  <th scope="row"
                    >{recordingListPage?.pageable.offset + i + 1}<span
                      class="ms-1"
                      ><a
                        on:click|stopPropagation={(e) => {
                          console.log(888, e);
                        }}
                        href={`#/recording-detail/${l?.recordingId}`}
                        target="_blank"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 30 30"
                          width="1em"
                          height="1em"
                          ><path
                            d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"
                          /></svg
                        ></a
                      ></span
                    ></th
                  >
                  <td>{l?.courseName}</td>
                  <td>{l?.lessonName}</td>
                  <td>{l?.exerciseTitle}</td>
                  <td>{l?.exerciseType}</td>
                  <td
                    >{#if l?.overallScore}{Number.parseInt(
                        l?.overallScore,
                      )}{/if}</td
                  >
                  <!-- <td
                    >{#if l?.teacherScore}{Number.parseInt(
                        l?.teacherScore
                      )}{/if}</td
                  > -->
                  <td
                    >{new Date(
                      Date.parse(l?.createdAt + "Z"),
                    ).toLocaleString()}</td
                  >
                </tr>
              {/each}
            </tbody>
            <tfoot />
          </table>
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination flex-wrap justify-content-center">
            <li class="page-item" class:disabled={page === 0}>
              <button
                class="page-link"
                on:click={() => updatePage(0)}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>
            <li class="page-item" class:disabled={page === 0}>
              <button
                class="page-link"
                on:click={() => updatePage(page - 1)}
                aria-label="Previous"
              >
                <span aria-hidden="true">&lsaquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>

            {#if recordingListPage?.totalPages > 8}
              {#if page < 3}
                {#each Array(3) as _, i}
                  <li class="page-item" class:active={page === i}>
                    <button class="page-link" on:click={() => updatePage(i)}
                      >{i + 1}</button
                    >
                  </li>
                {/each}
                <li class="page-item">
                  <button class="page-link" disabled>...</button>
                </li>
              {:else if page > recordingListPage?.totalPages - 3}
                <li class="page-item">
                  <button class="page-link" disabled>...</button>
                </li>
                {#each Array(3) as _, i}
                  <li
                    class="page-item"
                    class:active={page ===
                      recordingListPage?.totalPages - 3 + i}
                  >
                    <button class="page-link" on:click={() => updatePage(i)}
                      >{recordingListPage?.totalPages - 3 + i + 1}</button
                    >
                  </li>
                {/each}
              {:else}
                <li class="page-item">
                  <button class="page-link" disabled>...</button>
                </li>
                <li class="page-item" class:active={false}>
                  <button class="page-link" on:click={() => updatePage(page)}
                    >{page}</button
                  >
                </li>
                <li class="page-item" class:active={true}>
                  <button
                    class="page-link"
                    on:click={() => updatePage(page + 1)}>{page + 1}</button
                  >
                </li>
                <li class="page-item" class:active={false}>
                  <button
                    class="page-link"
                    on:click={() => updatePage(page + 2)}>{page + 2}</button
                  >
                </li>
                <li class="page-item">
                  <button class="page-link" disabled>...</button>
                </li>
              {/if}
            {:else}
              {#each Array(recordingListPage?.totalPages) as _, i}
                <li class="page-item" class:active={page === i}>
                  <button class="page-link" on:click={() => updatePage(i)}
                    >{i + 1}</button
                  >
                </li>
              {/each}
            {/if}
            <li
              class="page-item"
              class:disabled={recordingListPage?.totalPages ===
                recordingListPage?.number + 1}
            >
              <button
                class="page-link"
                on:click={() => updatePage(page + 1)}
                aria-label="Next"
              >
                <span aria-hidden="true">&rsaquo;</span>
                <span class="sr-only">Next</span>
              </button>
            </li>
            <li
              class="page-item"
              class:disabled={recordingListPage?.totalPages ===
                recordingListPage?.number + 1}
            >
              <button
                class="page-link"
                on:click={() => updatePage(recordingListPage?.totalPages - 1)}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </button>
            </li>
          </ul>
        </nav>
     
        <div class="row">
          <div class="col-md-6 align-self-center">
            <p
              id="dataTable_info"
              class="dataTables_info"
              role="status"
              aria-live="polite"
            >
              {recordingListPage?.totalElements}
              {$_("RESULT_COUNT")}
            </p>
          </div>
          <div class="col-md-6">
            <nav
              class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
            />
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  :global(.recordingRow) {
    cursor: pointer;
  }

  :global(.recordingRow:hover) {
    background-color: whitesmoke;
  }

  .sorting {
    cursor: pointer;
  }
</style>
