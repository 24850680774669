<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { getData, postData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { onMount } from "svelte";
  import AutoComplete from "simple-svelte-autocomplete";
  import StudentRecordingPageHeatMap from "./StudentRecordingPageHeatMap.svelte";
  import SvelteTable from "svelte-table";
  import SvelteHeatmap from "svelte-heatmap";
  import StudentRecordingsDropDown from "../../components/StudentRecordingsDropDown.svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
    userConfig,
  } from "../../components/DataStore";
  import printJS from "print-js";
  import PremiumIcon from "../../components/visual/PremiumIcon.svelte";

  let studentList = [];
  let studentListPage = {};

  let courseList = [];
  let courseListStatus = "loading";

  let studentRecordingListStatus = "loading";

  let selection = {};
  let filterSelections = {
    studentName: "",
    afterDate: "",
    beforeDate: "",
    includeQr: true,
    courseId: "",
  };

  let heatMap;
  let heatMapData = {};

  let page = 0;

  let sortColumn = "createdAt";
  let sortDirection = -1;

  let timer;

  userConfig.subscribe((v) => {
    if (v?.serverFlags?.qrHidden === true) {
      filterSelections["includeQr"] = false;
    }
  });

  onMount(async () => {
    getStudentList();
    getCourseList();
  });

  function refreshList() {
    console.log("refresh");
    studentRecordingListStatus = "loading";

    clearTimeout(timer);
		timer = setTimeout(() => {
			getStudentList();
		}, 750);
  }

  function printList() {
    printJS("dataTable", "html");
  }

  function downloadList() {
    getData(
      `api/studentRecordingListPageQueryForOrg?page=${page}&size=99999&studentName=${filterSelections.studentName}`,
    )
      .then((data) => {
        console.log("studentList successful", data);
        const mimeType = "data:text/csv;charset=utf-8";

        let items = data?.content.map(function (v) {
          let x = Object.assign({}, v);
          delete x.fieldName;
          delete x.courseId;
          delete x.lessonId;
          delete x.exerciseId;
          x.overallScore = Number.parseInt(x?.overallScore);
          x.createdAt = x?.createdAt?.replaceAll("T", " ");
          delete x.fileName;
          //delete x.recordingId;
          return x;
        });

        const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
        //const header = Object.keys(items[0]);
        const header = [
          "studentName",
          "overallScore",
          "teacherScore",
          "createdAt",
          "exerciseType",
          "courseName",
          "lessonName",
          "exerciseTitle",
          "recordingId",
        ];
        const csv = [
          header.join(","), // header row first
          ...items.map((row) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(","),
          ),
        ].join("\r\n");

        const d = new Date();

        var dummy = document.createElement("a");
        dummy.href = URL.createObjectURL(
          new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
            type: mimeType,
          }),
        );
        dummy.download = "student-recordings" + "-" + d.toISOString() + ".csv";
        dummy.click();
      })
      .catch((error) => {
        studentRecordingListStatus = "error";
        console.log("error lessons", error);
      });
  }

  function getStudentList(downloadAll = false) {
    let size = 100;

    if (downloadAll && studentListPage?.totalElements) {
      size = studentListPage?.totalElements;
    }

    let sort = (sortDirection < 0 ? "-" : "") + sortColumn;
    console.log(222, sort);

    studentRecordingListStatus = "loading";
    getData(
      `api/studentRecordingListPageQueryForOrg?page=${page}&size=${size}&sortBy=${sort}&studentName=${filterSelections.studentName}&afterDate=${filterSelections.afterDate}&beforeDate=${filterSelections.beforeDate}&includeQr=${filterSelections.includeQr}&courseId=${filterSelections.courseId}`,
    )
      .then((data) => {
        console.log("studentList successful", data);

        studentList = data?.content;
        studentListPage = data;
        studentRecordingListStatus = "ok";
        try {
          //initHeatMap();
        } catch (error) {
          console.log("error initializing heatmap", error);
        }
      })
      .catch((error) => {
        studentRecordingListStatus = "error";
        console.log("error lessons", error);
      });
  }

  function getCourseList() {
    getData("api/courses-coop")
      .then((data) => {
        console.log("studentList successful", data);

        courseList = data;

        courseListStatus = "ok";
        console.log("studentNameList", studentNameList);
      })
      .catch((error) => {
        courseListStatus = "error";
        console.log("error lessons", error);
      });
  }

  function viewRecordingDetail(r) {
    console.log(
      "viewRecordingDetail",
      r?.courseId,
      r?.lessonId,
      r?.exerciseId,
      r?.recordingId,
    );

    currentCourseId.update((x) => r?.courseId);
    currentCourseName.update((x) => r?.courseName);
    currentLessonId.update((x) => r?.lessonId);
    currentLessonName.update((x) => r?.lessonName);
    currentExerciseId.update((x) => r?.exerciseId);
    currentExerciseName.update((x) => r?.exerciseTitle);
    currentRecordingId.update((x) => r?.recordingId);

    mixpanel.track("Teacher View Recording Detail Clicked", {
      "Course ID": r?.courseId,
      "Lesson ID": r?.lessonId,
      "Exercise ID": r?.exerciseId,
      "Recording ID": r?.recordingId,
      "Button Location": "Student Recording Page",
    });

    clickChangePage("recording-detail/" + $currentRecordingId);
  }

  function initHeatMap() {
    let heatMapData = studentList
      .map((sl) => {
        return { date: new Date(Date.parse(sl.createdAt)), value: 1 };
      })
      .filter((i) => {
        return i.date instanceof Date;
      });
    console.log(heatMapData);
    const startDate = new Date(Date.parse("2021-01-01"));
    //startDate.setMonth(startDate.getMonth() - 12);
    const endDate = new Date(Date.now());
    if (heatMap) {
      heatMap.$destroy();
    }
    heatMap = new SvelteHeatmap({
      props: {
        data: heatMapData,
        startDate: startDate,
        endDate: endDate,
        allowOverflow: false,
        view: "monthly",
        monthGap: 20,
        monthLabelHeight: 10,
      },
      target: document.querySelector("#heatMap"),
    });
  }

  function openInNewTab(href) {
    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: href,
    }).click();
  }

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    $currentPage = e;
    router.goto("/" + e);
  }

  function updatePage(newPage) {
    page = newPage;
    getStudentList();
  }

  function changeOrder(column) {
    if (sortColumn === column) {
      sortDirection = -1 * sortDirection;
    }
    sortColumn = column;

    getStudentList();
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">
        {$_("ALL_RECORDINGS")}
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    {#key filterSelections?.courseId}
    <StudentRecordingPageHeatMap courseId={filterSelections?.courseId}/>
    {/key}
  </div>

  <div class="s-content-spacing">
    <div class="card shadow">
      <div
        class="card-header py-3 no-print d-flex flex-row align-items-center justify-content-between"
      >
        <div>
          <button
            class="btn btn-primary s-button-primary s-mr-10px"
            id="reloadExerciseListButton"
            on:click={refreshList}
            ><i class="fa fa-refresh" style="padding-right: 8px;" />{$_(
              "REFRESH",
            )}<br /></button
          >
          <button class="btn btn-primary s-button-primary" on:click={printList}
            ><i class="fa fa-print" style="padding-right: 8px;" />{$_(
              "PRINT",
            )}<br /></button
          >
          <!-- <button class="btn btn-primary s-button-primary" on:click={downloadList}
        ><i class="fa fa-download" style="padding-right: 8px;" />{$_(
          "EXPORT_CSV"
        )}<br /></button
      > -->
        </div>
        <StudentRecordingsDropDown
          on:refresh={refreshList}
          on:print={printList}
          on:exportAllToCsv={downloadList}
        />
      </div>
      <div class="card-body">
        <div style="display: flex;gap: 2em;">
          <div>
            <label for="studentNameSearch" class="mr-3"
              >{$_("SEARCH_NAME")}</label
            >
            <input
              type="text"
              class="form-control"
              style="min-width: 20em;"
              bind:value={filterSelections.studentName}
              on:input={refreshList}
            />
          </div>
          <div>
            <label class="mr-3">{$_("AFTER_DATE")}</label>
            <input
              type="date"
              class="form-control"
              bind:value={filterSelections.afterDate}
              on:input={refreshList}
            />
          </div>
          <div>
            <label class="mr-3">{$_("BEFORE_DATE")}</label>
            <input
              type="date"
              class="form-control"
              bind:value={filterSelections.beforeDate}
              on:input={refreshList}
            />
          </div>
        </div>
        <div style="display: flex;gap: 2em;">
          <div class="col">
            <label for="studentNameSearch" class="mr-3"
              >{$_("SELECT_COURSE")}</label
            >
            <select
              class="form-select"
              bind:value={filterSelections.courseId}
              on:change={() => {
                refreshList();
              }}
              aria-label="multiple select example"
            >
              <option value="" />
              {#each courseList as c}
                <option value={c.id}>{c.name}</option>
              {/each}
            </select>
          </div>
          <div class="mt-4 form-check">
            {#if $userConfig?.serverFlags?.qrHidden === true}
              <label
                id="includeQr"
                class="mr-3 form-check-label text-secondary"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  bind:checked={filterSelections.includeQr}
                  on:input={refreshList}
                  for="includeQr"
                  disabled
                />{$_("SHOW_QR_SHARE_STUDENTS")}
                <PremiumIcon></PremiumIcon></label
              >
            {:else}
              <label id="includeQr" class="mr-3 form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  bind:checked={filterSelections.includeQr}
                  on:input={refreshList}
                  for="includeQr"
                />{$_("SHOW_QR_SHARE_STUDENTS")}</label
              >
            {/if}
          </div>
        </div>
        {#if studentRecordingListStatus != "ok"}
          <Loader />
        {:else}
          <div
            class="table-responsive table mt-2"
            style="overflow-y: hidden"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info"
          >
            <table class="table my-0 table-hover" id="dataTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    class="sorting"
                    on:click={() => {
                      changeOrder("studentName");
                    }}
                  >
                    {#if sortColumn === "studentName"}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_("STUDENT_NAME")}</strong>
                    {:else}
                      {$_("STUDENT_NAME")}
                    {/if}
                  </th>

                  <th
                    class="sorting"
                    on:click={() => {
                      changeOrder("courseName");
                    }}
                  >
                    {#if sortColumn === "courseName"}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_("COURSE_NAME")}</strong>
                    {:else}
                      {$_("COURSE_NAME")}
                    {/if}
                  </th>

                  <th
                    class="sorting"
                    on:click={() => {
                      changeOrder("lessonName");
                    }}
                  >
                    {#if sortColumn === "lessonName"}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_("LESSON_NAME")}</strong>
                    {:else}
                      {$_("LESSON_NAME")}
                    {/if}
                  </th>

                  <th
                    class="sorting"
                    on:click={() => {
                      changeOrder("exerciseTitle");
                    }}
                  >
                    {#if sortColumn === "exerciseTitle"}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_("EXERCISE_TITLE")}</strong>
                    {:else}
                      {$_("EXERCISE_TITLE")}
                    {/if}
                  </th>

                  <th
                    class="sorting"
                    on:click={() => {
                      changeOrder("exerciseType");
                    }}
                  >
                    {#if sortColumn === "exerciseType"}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_("EXERCISE_TYPE")}</strong>
                    {:else}
                      {$_("EXERCISE_TYPE")}
                    {/if}
                  </th>

                  <th
                    class="sorting"
                    on:click={() => {
                      changeOrder("overallScore");
                    }}
                  >
                    {#if sortColumn === "overallScore"}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_("SCORE")}</strong>
                    {:else}
                      {$_("SCORE")}
                    {/if}
                  </th>
                  <!-- <th
                    on:click={() => {
                      changeOrder("teacherScore");
                    }}
                  >
                    {#if sortColumn === "teacherScore"}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_("TEACHER_SCORE")}</strong>
                    {:else}
                      {$_("TEACHER_SCORE")}
                    {/if}
                  </th> -->
                  <th
                    class="sorting"
                    on:click={() => {
                      changeOrder("createdAt");
                    }}
                  >
                    {#if sortColumn === "createdAt"}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_("DATE_TIME_RECORDED")}</strong>
                    {:else}
                      {$_("DATE_TIME_RECORDED")}
                    {/if}
                  </th>
                </tr>
              </thead>
              <tbody>
                {#each studentList as l, i}
                  <tr
                    class="lessonRow"
                    on:click={(x) => {
                      viewRecordingDetail(l);
                    }}
                  >
                    <th scope="row"
                      >{studentListPage?.pageable.offset + i + 1}<span
                        class="ms-1"
                        ><a
                          on:click|stopPropagation={(e) => {
                            console.log(888, e);
                          }}
                          href={`#/recording-detail/${l?.recordingId}`}
                          target="_blank"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 30 30"
                            width="1em"
                            height="1em"
                            ><path
                              d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"
                            /></svg
                          ></a
                        ></span
                      ></th
                    >
                    <td>{l?.studentName}</td>
                    <td>{l?.courseName}</td>
                    <td>{l?.lessonName}</td>
                    <td>{l?.exerciseTitle}</td>
                    <td>{$_(`EXERCISE_.${l?.exerciseType}`)}</td>
                    <td
                      >{#if l?.overallScore}{Number.parseInt(
                          l?.overallScore,
                        )}{/if}</td
                    >
                    <!-- <td
                      >{#if l?.teacherScore}{Number.parseInt(
                          l?.teacherScore
                        )}{/if}</td
                    > -->
                    <td
                      >{new Date(
                        Date.parse(l?.createdAt + "Z"),
                      ).toLocaleString()}</td
                    >
                  </tr>
                {/each}
              </tbody>
              <tfoot />
            </table>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination flex-wrap justify-content-center">
              <li class="page-item" class:disabled={page === 0}>
                <button
                  class="page-link"
                  on:click={() => updatePage(0)}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </button>
              </li>
              <li class="page-item" class:disabled={page === 0}>
                <button
                  class="page-link"
                  on:click={() => updatePage(page - 1)}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&lsaquo;</span>
                  <span class="sr-only">Previous</span>
                </button>
              </li>

              {#if studentListPage?.totalPages > 8}
                {#if page < 3}
                  {#each Array(3) as _, i}
                    <li class="page-item" class:active={page === i}>
                      <button class="page-link" on:click={() => updatePage(i)}
                        >{i + 1}</button
                      >
                    </li>
                  {/each}
                  <li class="page-item">
                    <button class="page-link" disabled>...</button>
                  </li>
                {:else if page > studentListPage?.totalPages - 3}
                  <li class="page-item">
                    <button class="page-link" disabled>...</button>
                  </li>
                  {#each Array(3) as _, i}
                    <li
                      class="page-item"
                      class:active={page ===
                        studentListPage?.totalPages - 3 + i}
                    >
                      <button class="page-link" on:click={() => updatePage(i)}
                        >{studentListPage?.totalPages - 3 + i + 1}</button
                      >
                    </li>
                  {/each}
                {:else}
                  <li class="page-item">
                    <button class="page-link" disabled>...</button>
                  </li>
                  <li class="page-item" class:active={false}>
                    <button class="page-link" on:click={() => updatePage(page)}
                      >{page}</button
                    >
                  </li>
                  <li class="page-item" class:active={true}>
                    <button
                      class="page-link"
                      on:click={() => updatePage(page + 1)}>{page + 1}</button
                    >
                  </li>
                  <li class="page-item" class:active={false}>
                    <button
                      class="page-link"
                      on:click={() => updatePage(page + 2)}>{page + 2}</button
                    >
                  </li>
                  <li class="page-item">
                    <button class="page-link" disabled>...</button>
                  </li>
                {/if}
              {:else}
                {#each Array(studentListPage?.totalPages) as _, i}
                  <li class="page-item" class:active={page === i}>
                    <button class="page-link" on:click={() => updatePage(i)}
                      >{i + 1}</button
                    >
                  </li>
                {/each}
              {/if}
              <li
                class="page-item"
                class:disabled={studentListPage?.totalPages ===
                  studentListPage?.number + 1}
              >
                <button
                  class="page-link"
                  on:click={() => updatePage(page + 1)}
                  aria-label="Next"
                >
                  <span aria-hidden="true">&rsaquo;</span>
                  <span class="sr-only">Next</span>
                </button>
              </li>
              <li
                class="page-item"
                class:disabled={studentListPage?.totalPages ===
                  studentListPage?.number + 1}
              >
                <button
                  class="page-link"
                  on:click={() => updatePage(studentListPage?.totalPages - 1)}
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
          <!-- <div
          class="table-responsive table mt-2"
          id="dataTable"
          role="grid"
          aria-describedby="dataTable_info"
        >
          <SvelteTable
            {columns}
            rows={studentList}
            {filterSelections}
            classNameRow="recordingRow"
            sortBy="createdAt"
            sortOrder="-1"
            classNameSelect="no-print"
            on:clickRow={(event, row) => {
              console.log(event, event?.detail?.row);
              if (event?.detail?.event.ctrlKey) {
                console.log("handleCtrlEnter");
                openInNewTab(
                  "#/recording-detail/" + event?.detail?.row?.recordingId
                );
              } else {
                viewRecordingDetail(event?.detail?.row);
              }
            }}
          />
        </div> -->
          <div class="row">
            <div class="col-md-6 align-self-center">
              <p
                id="dataTable_info"
                class="dataTables_info"
                role="status"
                aria-live="polite"
              >
                {studentListPage?.totalElements}
                {$_("RESULT_COUNT")}
              </p>
            </div>
            <div class="col-md-6">
              <nav
                class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
              />
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  :global(.recordingRow) {
    cursor: pointer;
  }

  :global(.recordingRow:hover) {
    background-color: whitesmoke;
  }

  .sorting {
    cursor: pointer;
  }
</style>
