<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { postData } from "utils/ApiUtils.svelte";
  import { createEventDispatcher } from "svelte";
  import Loader from "components/Loader.svelte";
  import {
    currentPage,
    userConfig,
    userDetails,
  } from "../../../components/DataStore";
  export let courseId;

  const dispatch = createEventDispatcher();

  let newStudentRoster = { rosterCode: "", rosterName: "" };

  let studentDataStatus = "new";
  let importStatusMessage;

  onMount(async () => {});

  function createRosterFromCourse() {
    let payload = {
      name: newStudentRoster["rosterName"],
      code: newStudentRoster["rosterCode"],
      organizationId: $userDetails.organizationId,
    };
    postData(`api/courses/${courseId}/createRoster`, payload, false)
      .then((data) => {
        console.log("courses - student-accounts successful", data);

        dispatch("importDone", {});
      })
      .catch((error) => {
        console.log("error student accounts", error);
        studentDataStatus = "error";
        importStatusMessage = error?.message;
      });
  }

  function cancelCreateStudentRoster() {
    dispatch("cancelCreateStudentRoster", {});
  }
</script>

<div>
  {#if studentDataStatus === "loading"}
    <Loader />
  {:else if studentDataStatus === "new"}
    <div class="form-group s-form-group">
      <label for="classRoster">{$_("STUDENT_ROSTER.CODE")}</label>
      <input
        class="form-control s-form-input"
        type="text"
        bind:value={newStudentRoster.rosterCode}
      />
    </div>
    <div>
      <label for="classRoster">{$_("STUDENT_ROSTER.NAME")}</label>
      <input
        class="form-control s-form-input"
        type="text"
        bind:value={newStudentRoster.rosterName}
      />
    </div>
  {:else if studentDataStatus === "error"}
    <div>
      <div class="alert alert-danger">
        {#if importStatusMessage === "STUDENT_ACCOUNT_LIMIT_REACHED"}
          {$_("STUDENT_ACCOUNT.STUDENT_ACCOUNT_LIMIT_REACHED")}
        {:else if importStatusMessage === "STUDENT_ACCOUNT_ALREADY_ADDED"}
          {$_("STUDENT_ACCOUNT.STUDENT_ACCOUNT_ALREADY_ADDED")}
        {:else}
          {$_("STUDENT_ACCOUNT.IMPORT_ERROR")}
        {/if}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span
          type="button"
          class="close"
          style="float:right; padding:1em;"
          on:click={() => {
            studentDataStatus = "ok";
          }}
        >
          <span>&times;</span>
        </span>
      </div>
    </div>
  {/if}
</div>
<div class="form-row mt-3">
  <button
    class="btn btn-secondary s-button-secondary s-mr-10px"
    on:click={cancelCreateStudentRoster}
  >
    <i class="far fa-window-close" style="padding-right: 8px;" />{$_("CANCEL")}
  </button>

  <button
    class="btn btn-primary s-button-primary"
    disabled={studentDataStatus === "saving"}
    on:click={createRosterFromCourse}
  >
    <i class="fa fa-save" style="padding-right: 8px;" />{$_(
      "STUDENT_ROSTER.CREATE_ROSTER",
    )}
    <br />
  </button>
</div>
