<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Breadcrumb from "components/Breadcrumb.svelte";
  import {
    currentCourseId,
    currentCourseName,
    userConfig,
    userDetails,
  } from "../../../components/DataStore";
  import LessonTable from "../../Lesson/LessonComponents/LessonTable.svelte";
  import StudentAccountEnrollmentTable from "./StudentAccountEnrollmentTable.svelte";
  import CourseDetailComponent from "./CourseDetailComponent.svelte";
  import StudentAnalyticsPageOverview from "./StudentAnalyticsPageOverview.svelte";
  import StudentAccountReport from "./StudentAccountReport.svelte";
  import CourseCooperation from "./CourseCooperation.svelte";

  export let activeTab;

  if (!activeTab) {
    activeTab = "lessons";
  }

  function changeTab(page) {
    try {
      switch (page) {
        case "lessons":
          mixpanel.track("Teacher Classes Page Lessons Tab Clicked");
          break;
        case "student-accounts":
          mixpanel.track("Teacher Classes Page Invite Student Tab Clicked");
          break;
        case "student-analytics":
          mixpanel.track("Teacher Classes Page Analytics Tab Clicked");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("error mixpanel");
    }

    activeTab = page;
    router.goto(`courses-detail/${$currentCourseId}/${page}`);
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <Breadcrumb />
    </div>
  </div>

  <div class="s-content-spacing">
    <CourseDetailComponent />
  </div>

  <div class="s-content-spacing" style="margin-bottom: 0 !important;">
    <ul class="nav nav-tabs">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li class="nav-item" on:click={(e) => changeTab("lessons")}>
        <span class="nav-link" class:active={activeTab === "lessons"}
          >{$_("LESSONS")}</span
        >
      </li>
      <!-- {#if $userDetails?.userRole === 'ADMIN' || $userDetails?.userRole === 'SURREAL' || $userConfig?.serverFlags?.studentAccount === true} -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li class="nav-item" on:click={(e) => changeTab("student-accounts")}>
        <span
          class="nav-link"
          class:active={activeTab === "student-accounts"}
          on:click={() => {
            activeTab === "student-accounts";
          }}
        >
          {$_("INVITE_STUDENTS")}</span
        >
      </li>

      <!-- {/if} -->
      {#if $userDetails?.userRole === "ADMIN" || $userConfig?.serverFlags?.courseAnalytics === true}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <li
          class="nav-item"
          on:click={(e) => changeTab("student-accounts-report")}
        >
          <span
            class="nav-link"
            class:active={activeTab === "student-accounts-report"}
            on:click={() => {
              activeTab === "student-accounts-report";
            }}
          >
            {$_("STUDENT_ACCOUNT_COURSE_REPORT")}</span
          >
        </li>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <li class="nav-item" on:click={(e) => changeTab("student-analytics")}>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <span
            class="nav-link"
            class:active={activeTab === "student-analytics"}
            on:click={() => {
              activeTab === "student-analytics";
            }}
          >
            {$_("ANALYTICS_SECTION.ANALYTICS")}</span
          >
        </li>
      {/if}

      {#if $userDetails?.userRole === "ADMIN" || $userConfig?.serverFlags?.courseCoop === true}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <li class="nav-item" on:click={(e) => changeTab("course-coop")}>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <span
            class="nav-link"
            class:active={activeTab === "course-coop"}
            on:click={() => {
              activeTab === "course-coop";
            }}
          >
            {$_("COURSE_COOP")}</span
          >
        </li>
      {/if}
    </ul>
  </div>

  {#key activeTab}
    {#if activeTab === "lessons"}
      <div class="s-content-spacing" style="margin-top: 0 !important;">
        <LessonTable />
      </div>
    {:else if activeTab === "student-accounts"}
      {#if $userDetails?.userRole === "ADMIN" || $userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.studentAccount === true}
        <div class="s-content-spacing" style="margin-top: 0 !important;">
          <StudentAccountEnrollmentTable
            courseId={$currentCourseId}
            courseName={$currentCourseName}
          />
        </div>
      {/if}
    {:else if activeTab === "student-accounts-report"}
      {#if $userDetails?.userRole === "ADMIN" || $userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.studentAccount === true}
        <div class="s-content-spacing" style="margin-top: 0 !important;">
          <StudentAccountReport
            courseId={$currentCourseId}
            courseName={$currentCourseName}
          />
        </div>
      {/if}
    {:else if activeTab === "student-analytics"}
      {#if $userDetails?.userRole === "ADMIN" || $userConfig?.serverFlags?.courseAnalytics === true}
        <div class="s-content-spacing" style="margin-top: 0 !important;">
          <StudentAnalyticsPageOverview courseId={$currentCourseId} />
        </div>
      {/if}
    {:else if activeTab === "course-coop"}
      {#if $userDetails?.userRole === "ADMIN" || $userConfig?.serverFlags?.courseCoop === true}
        <div class="s-content-spacing" style="margin-top: 0 !important;">
          <CourseCooperation
            courseId={$currentCourseId}
            organizationId={$userDetails?.organizationId}
          />
        </div>
      {/if}
    {/if}
  {/key}
</main>

<style>
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid #00a094 !important;
    border-bottom-width: 0px !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-item {
    margin-right: 1rem;
    cursor: pointer;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 0px;
  }
  .nav {
    border-bottom: 4px solid #00a094;
    color: black;
  }

  .nav-link {
    color: #575f59;
  }

  .nav-link.active {
    background-color: #00a094;
    color: white;
  }
</style>
