<script>
    export let isActive = false;
</script>

<svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    class:is-active={isActive}
>
    <defs>
        <style>
            .cls-1 {
                fill: #414141;
                stroke-width: 2;
                stroke: #414141;
            }
            .is-active .cls-1 {
                fill: #00a094;
                stroke-width: 4;
                stroke: #00a094;
            }
        </style>
    </defs>
    <path
        stroke="currentColor"
        fill="none"
        class="cls-1"
        d="M152.87,95.92c0-3.03-2.47-5.5-5.5-5.5s-5.5,2.47-5.5,5.5c0,23.09-18.78,41.87-41.87,41.87s-41.87-18.78-41.87-41.87c0-3.03-2.47-5.5-5.5-5.5s-5.5,2.47-5.5,5.5c0,27.09,20.62,49.72,47.37,52.52v14.88h-23.96c-3.03,0-5.5,2.47-5.5,5.5s2.47,5.5,5.5,5.5h58.92c3.03,0,5.5-2.47,5.5-5.5s-2.47-5.5-5.5-5.5h-23.96v-14.88c26.75-2.77,47.37-25.4,47.37-52.52Z"
    />
    <path
        stroke="currentColor"
        fill="none"
        class="cls-1"
        d="M100,128.87c18.17,0,32.95-14.78,32.95-32.95V52.27c0-18.17-14.78-32.95-32.95-32.95s-32.95,14.78-32.95,32.95v43.62c0,18.19,14.78,32.98,32.95,32.98Zm-9.23-37.45c0-3.03-2.47-5.5-5.5-5.5h-7.22v-6.31h7.22c3.03,0,5.5-2.47,5.5-5.5s-2.47-5.5-5.5-5.5h-7.22v-6.31h7.22c3.03,0,5.5-2.47,5.5-5.5s-2.47-5.5-5.5-5.5h-7.2c.52-11.66,10.16-20.98,21.93-20.98s21.4,9.31,21.93,20.95h-7.2c-3.03,0-5.5,2.47-5.5,5.5s2.47,5.5,5.5,5.5h7.22v6.31h-7.22c-3.03,0-5.5,2.47-5.5,5.5s2.47,5.5,5.5,5.5h7.22v6.31h-7.22c-3.03,0-5.5,2.47-5.5,5.5s2.47,5.5,5.5,5.5h7.2c-.52,11.64-10.16,20.95-21.93,20.95s-21.39-9.29-21.93-20.92h7.2c3.03,0,5.5-2.47,5.5-5.5Z"
    />
</svg>
