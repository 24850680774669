<script>
  import { _ } from "svelte-i18n";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { getData, putData, deleteData } from "utils/ApiUtils.svelte";
  import { router } from "tinro";
  import { convertSecToMin, average } from "utils/FormatUtils.svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    userConfig,
  } from "../../../components/DataStore";
  import { get } from "svelte/store";
  import Tooltip from "components/Tooltip.svelte";
    import PremiumIcon from "../../../components/visual/PremiumIcon.svelte";

  let exerciseList = [];
  let studentEnrollmentData = [];
  let studentAccountEnrollmentData = [];
  let studentRecordingList = [];

  let studentProgressStatus = "loading";

  let studentProgressData = {};

  let useTeacherScore = false;

  let showQrCodeStudents = true;

  userConfig.subscribe((v) => {
    if (v?.serverFlags?.qrHidden === true) {
      showQrCodeStudents = false;
    }
  });

  function prepareResult2() {
    let exerciseMap = new Map();

    // exerciseList.forEach((ex) => {
    //   const k =
    //   exerciseMap.
    // })

    studentProgressData.exercises = exerciseList
      .map((e) => {
        return e.title;
      })
      .sort((x) => {
        x.title;
      });
  }

  function prepareResult() {
    console.log("11-1", new Date());

    studentProgressData.exercises = exerciseList
      .map((e) => {
        return e.title;
      })
      .sort((x) => {
        x.title;
      });

    console.log("11-2", new Date());
    let uniqueStudents = new Map();

    // studentEnrollmentData.forEach((r) => {
    //   if (!uniqueStudents.has(r?.studentName)) {
    //     uniqueStudents.set(r?.studentName, {
    //       studentName: r.studentName,
    //       studentEnrollment: true,
    //     })
    //   }
    // })

    console.log("11-3", new Date());

    studentAccountEnrollmentData.forEach((r) => {
      if (r.state === "ACTIVE") {
        let name = r?.studentFirstName + " " + r?.studentLastName;
        if (!uniqueStudents.has(name)) {
          uniqueStudents.set(name, {
            studentName: name,
            studentEnrollment: true,
            studentAccountId: r?.studentId,
          });
        }
      }
    });

    console.log("11-4", new Date());

    if (showQrCodeStudents) {
      studentRecordingList
        .filter((d) => {
          return d?.studentAccountId === null;
        })
        .forEach((r) => {
          if (!uniqueStudents.has(r?.studentName?.toLowerCase())) {
            uniqueStudents.set(r?.studentName?.toLowerCase(), {
              studentName: r.studentName,
              studentEnrollment: false,
            });
          }
        });
    }

    console.log("11-5", new Date());

    uniqueStudents.forEach((v, k) => {
      studentProgressData.exercises.forEach((e) => {
        if (!v?.results) {
          v.results = {};
        }

        let recordingList = studentRecordingList.filter(
          ({ studentName, exerciseTitle, studentAccountId }) => {
            if (v.studentEnrollment) {
              return (
                studentName === v.studentName &&
                exerciseTitle === e &&
                studentAccountId === v.studentAccountId
              );
            }
            return (
              studentName === v.studentName &&
              exerciseTitle === e &&
              studentAccountId === null
            );
          },
        );

        if (recordingList && recordingList.length) {
          let rec = recordingList.reduce((prev, current) => {
            return Number.parseFloat(prev.overallScore) >
              Number.parseFloat(current.overallScore)
              ? prev
              : current;
          });

          if (useTeacherScore) {
            rec = recordingList.reduce((prev, current) => {
              return Number.parseFloat(prev.teacherScore) >
                Number.parseFloat(current.teacherScore)
                ? prev
                : current;
            });
          }

          // console.log('rec', rec)

          let recording = {
            recordingId: rec.recordingId,
            score: Number.parseFloat(rec.overallScore).toFixed(0),
          };
          if (useTeacherScore) {
            recording.score = rec.teacherScore ? rec.teacherScore : "";
          }

          v.results[e] = recording;
        }
      });
    });

    console.log("11-6", new Date());

    console.log("uniqueStudents", uniqueStudents);

    studentProgressData.score = Array.from(uniqueStudents.values());

    studentProgressData.score.forEach((s) => {
      s.allExerciseFinished =
        Object.keys(s?.results).length == studentProgressData.exercises.length;

      //if (s.allExerciseFinished){
      let scores = Object.values(s?.results).map((r) =>
        Number.parseFloat(r.score),
      );
      // console.log('scores', scores)
      s.allExerciseAverage = average(scores).toFixed(0);
      //}
    });

    console.log("11-7", new Date());

    studentProgressData.score.sort((a, b) =>
      a.studentName?.localeCompare(b.studentName),
    );

    console.log("11-8", new Date());

    console.log("studentProgressData", studentProgressData);
  }

  onMount(async () => {
    console.log(
      "$userConfig?.serverFlags?.qrHidden === true",
      $userConfig?.serverFlags?.qrHidden,
    );
    if ($userConfig?.serverFlags?.qrHidden === true) {
      showQrCodeStudents = false;
    } else {
      showQrCodeStudents = true;
    }
    console.log("10-1", new Date());
    Promise.all([
      getExerciseList($currentCourseId, $currentLessonId),
      // getStudentEnrollmentList($currentCourseId),
      getStudentAccountEnrollmentList($currentCourseId),
      getStudentRecordingList($currentCourseId, $currentLessonId),
    ])
      .then(() => {
        console.log("10-2", new Date());
        studentProgressStatus = "ok";
        prepareResult();
      })
      .catch((error) => {
        console.log("onMountError", error);
        studentProgressStatus = "error";
      });
  });

  async function getExerciseList(courseId, lessonId) {
    await getData(
      "api/courses/" + courseId + "/lessons/" + lessonId + "/exercises",
    )
      .then((data) => {
        // console.log('lessons successful', data)
        exerciseList = data;
      })
      .catch((error) => {
        console.log("error courses", error);
      });
  }

  async function getStudentRecordingList(courseId, lessonId) {
    await getData(
      `api/courses/${courseId}/lessons/${lessonId}/studentRecordingList`,
    ).then((data) => {
      // console.log('studentList successful', data)
      studentRecordingList = data.filter((v) => {
        return v.courseId == courseId && v.lessonId == lessonId;
      });
    });
  }

  async function getStudentEnrollmentList(courseId) {
    await getData("api/courses/" + courseId + "/studentEnrollment")
      .then((data) => {
        // console.log('courses - studentEnrollment successful', data)
        studentEnrollmentData = data;
      })
      .catch((error) => {
        console.log("error courses", error);
        courseDetailStatus = "error";
      });
  }

  async function getStudentAccountEnrollmentList(courseId) {
    await getData("api/courses/" + courseId + "/student-accounts")
      .then((data) => {
        // console.log('courses - studentEnrollment successful', data)
        studentAccountEnrollmentData = data;
      })
      .catch((error) => {
        console.log("error courses", error);
        courseDetailStatus = "error";
      });
  }

  function openStudentDetail(studentName) {
    console.log("openStudentDetail", studentName);
    mixpanel.track("Teacher View Student Detail Clicked", {
      "Button Location": "Lesson Detail - Student Progress Table",
    });
    $currentPage = studentName;
    router.goto("student-detail/" + studentName);
  }

  function openStudentAccountDetail(studentAccountId) {
    console.log("openStudentAccountDetail", studentAccountId);
    mixpanel.track("Teacher View Student Account Detail Clicked", {
      "Button Location": "Lesson Detail - Student Progress Table",
    });
    $currentPage = studentAccountId;
    router.goto("student-account-detail/" + studentAccountId);
  }

  function openRecordingDetail(recordingId) {
    console.log("viewRecordingDetail", recordingId);
    mixpanel.track("Teacher View Recording Detail Clicked", {
      "Course ID": $currentCourseId,
      "Lesson ID": $currentLessonId,
      "Exercise ID": $currentExerciseId,
      "Recording ID": recordingId,
      "Button Location": "Lesson Detail - Student Progress Table",
    });
    router.goto("recording-detail/" + recordingId);
  }

  function changeUseTeacherScore() {
    mixpanel.track("Teacher Use Teacher Score Checkbox Clicked");
    useTeacherScore = !useTeacherScore;
    prepareResult();
  }

  function changeShowQrCodeStudents() {
    mixpanel.track("Teacher Show QR Code Students Checkbox Clicked");
    showQrCodeStudents = !showQrCodeStudents;
    prepareResult();
  }
</script>

{#if studentProgressStatus === "loading"}
  <Loader />
{:else}
  <div class="card shadow">
    <div class="card-header">
      <div>
        <div class="custom-control custom-checkbox">
          <label class="custom-control-label" for="useTeacherScore"
            ><input
              type="checkbox"
              class="custom-control-input"
              id="useTeacherScore"
              checked={useTeacherScore}
              on:change={changeUseTeacherScore}
            />
            {$_("USE_TEACHER_SCORE_FLAG")}</label
          >
        </div>
        <div class="custom-control custom-checkbox">
          {#if $userConfig?.serverFlags?.qrHidden === true}
            <label class="custom-control-label text-secondary" for="showQrCodeStudents"
              ><input
                type="checkbox"
                class="custom-control-input"
                id="showQrCodeStudents"
                checked={showQrCodeStudents}
                disabled
                on:change={changeShowQrCodeStudents}
              />
              {$_("SHOW_QR_SHARE_STUDENTS")}
              <PremiumIcon></PremiumIcon></label
            >
          {:else}
            <label class="custom-control-label" for="showQrCodeStudents"
              ><input
                type="checkbox"
                class="custom-control-input"
                id="showQrCodeStudents"
                checked={showQrCodeStudents}
                on:change={changeShowQrCodeStudents}
              />
              {$_("SHOW_QR_SHARE_STUDENTS")}</label
            >
          {/if}
        </div>
      </div>
    </div>
    <div class="card-body">
      <div>
        <div
          class="table-responsive mt-2"
          aria-describedby="dataTable_info"
          style="overflow-y: hidden;"
        >
          <table class="table my-0 table-hover" id="dataTable">
            <thead>
              <tr>
                <th class="grow">#</th>
                <th class="grow">{$_("PROGRESS")}</th>
                <th class="grow"
                  ><span style="position: relative; min-width:8rem;">
                    <Tooltip title={$_("FINAL_SCORE_TOOLTIP")}>
                      <i class="fa-solid fa-circle-info" />
                    </Tooltip>{$_("FINAL_SCORE")}
                  </span></th
                >
                <th>{$_("STUDENT_NAME")}</th>
                {#each studentProgressData?.exercises as ex, i}
                  <th>{ex}</th>
                {/each}
              </tr>
            </thead>
            <tbody>
              {#each studentProgressData?.score as s, i}
                <tr class="exerciseRow">
                  <th scope="row">{i + 1}</th>
                  <th scope="row">
                    {#if s?.allExerciseFinished}
                      <i style="color:green">Y</i>
                    {:else}
                      <i style="color:orange">X</i>
                    {/if}
                  </th>
                  <th class:notEnrolled={!s?.allExerciseFinished}>
                    {#if s?.allExerciseAverage != "NaN"}
                      {s?.allExerciseAverage}
                    {:else}
                      -
                    {/if}</th
                  >

                  {#if s?.studentEnrollment}
                    <th
                      scope="row"
                      class:notEnrolled={!s?.studentEnrollment}
                      class="clickable"
                      on:click={(x) => {
                        openStudentAccountDetail(s?.studentAccountId);
                      }}>{s?.studentName}</th
                    >
                  {:else}
                    <th
                      scope="row"
                      class:notEnrolled={!s?.studentEnrollment}
                      class="clickable"
                      on:click={(x) => {
                        openStudentDetail(s?.studentName);
                      }}>{s?.studentName}</th
                    >
                  {/if}
                  {#each studentProgressData?.exercises as ex, i}
                    {#if s?.results && ex in s?.results}
                      <!-- svelte-ignore a11y-click-events-have-key-events -->
                      <td
                        class="clickable"
                        on:click={(x) => {
                          openRecordingDetail(s?.results[ex].recordingId);
                        }}>{s?.results[ex].score}</td
                      >
                    {:else}
                      <td><i style="color:red">X</i></td>
                    {/if}
                  {/each}
                </tr>
              {/each}
            </tbody>
            <tfoot>
              <tr />
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .clickable {
    cursor: pointer;
  }
  .clickable:hover {
    background-color: #c8c8c8;
  }

  .notEnrolled {
    font-weight: 100;
  }

  .grow {
    width: 0.1%;
    white-space: nowrap;
  }

  @media (min-width: 1300px) {
  }
</style>
