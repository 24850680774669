<script context="module">
  import { router } from 'tinro'
  import { currentPage, loginToken, userDetails } from '../components/DataStore'
  import { getCookie, eraseCookie } from './CookieUtils.svelte'

  // export const BASE_URL = 'https://api.oksensay.com/'
  export const BASE_URL = 'https://api2.oksensay.com/'
  // export const BASE_URL = "http://localhost:8088/";
  // export const BASE_URL = 'http://192.241.145.55:8088/'

  export function checkJwtTokenValidity(jwtToken) {
    try {
      const base64Url = jwtToken.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      const jwtTokenJson = JSON.parse(jsonPayload)
      const isValid = new Date(jwtTokenJson?.exp * 1000) > new Date()
      // console.log(223, jwtTokenJson, isValid)
      return isValid
    } catch (error) {
      return false
    }
  }

  export async function postData(
    url = '',
    data = {},
    logoutParam = true,
    fullUrl = false
  ) {
    if (!fullUrl) {
      url = BASE_URL + url
    }
    //let token = sessionStorage.getItem("jwtToken") || "";
    let token = getCookie('jwtToken') || ''

    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    if (response.status >= 400 && response.status < 600) {
      if (logoutParam) {
        handleServerError(response.status)
      } else {
        let errorMessage = 'Bad response from server status:' + response.status
        try {
          errorMessage = (await response.json()).errorCode
          console.log(555, errorMessage)
        } catch (error2) {
          errorMessage = 'Bad response from server status:' + response.status
        }
        if (!errorMessage) {
          errorMessage = 'Bad response from server status:' + response.status
        }
        throw new Error(errorMessage)
      }
    }

    return response.json() // parses JSON response into native JavaScript objects
  }

  export async function putData(url = '', data = {}, logoutParam = true) {
    url = BASE_URL + url
    //let token = sessionStorage.getItem("jwtToken");
    let token = getCookie('jwtToken')

    const response = await fetch(url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    if (response.status >= 400 && response.status < 600) {
      if (logoutParam) {
        handleServerError(response.status)
      } else {
        let errorMessage = 'Bad response from server status:' + response.status
        try {
          errorMessage = (await response.json()).errorCode
          console.log(555, errorMessage)
        } catch (error2) {
          errorMessage = 'Bad response from server status:' + response.status
        }
        if (!errorMessage) {
          errorMessage = 'Bad response from server status:' + response.status
        }
        throw new Error(errorMessage)
      }
    }

    return response.json() // parses JSON response into native JavaScript objects
  }

  export async function patchData(url = '', data = {}, readJson = true) {
    url = BASE_URL + url
    //let token = sessionStorage.getItem("jwtToken");
    let token = getCookie('jwtToken') || ''

    const response = await fetch(url, {
      method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    if (response.status >= 400 && response.status < 600) {
      handleServerError(response.status)
    }
    if (readJson){
      return response.json() // parses JSON response into native JavaScript objects
    } else{
      return response.text()
    }
  }

  export async function getData(url = '', fullUrl = false, logoutParam = true) {
    if (!fullUrl) {
      url = BASE_URL + url
    }

    //let token = sessionStorage.getItem("jwtToken") || "";
    let token = getCookie('jwtToken') || ''

    let headerPayload = {
      'Content-Type': 'application/json',
    }
    if (token != null) {
      if (!checkJwtTokenValidity(token)) {
        logout()
        throw new Error('Invalid token')
      }
      headerPayload = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      }
    }

    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headerPayload,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })

    if (response.status >= 400 && response.status < 600) {
      if (logoutParam) {
        handleServerError(response.status)
      } else {
        throw new Error('Bad response from server status:' + response.status)
      }
    }

    return response.json() // parses JSON response into native JavaScript objects
  }

  export async function deleteData(url = '') {
    url = BASE_URL + url
    //let token = sessionStorage.getItem("jwtToken");
    let token = getCookie('jwtToken')

    const response = await fetch(url, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + token,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })

    if (response.status >= 400 && response.status < 600) {
      handleServerError(response.status)
    }

    return response // parses JSON response into native JavaScript objects
  }

  export async function getDataOutside(url = '') {
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })

    if (response.status >= 400 && response.status < 600) {
      throw new Error('Bad response from server status:' + response.status)
    }

    return response.json() // parses JSON response into native JavaScript objects
  }

  export async function postJsonFileGetText(
    url = '',
    headersValue = {},
    params = {},
    data = {}
  ) {
    var urlValue = new URL(url)
    urlValue.search = new URLSearchParams(params).toString()

    // Default options are marked with *
    const response = await fetch(urlValue, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      headers: headersValue,
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    return response.text() // parses JSON response into native JavaScript objects
  }

  export async function postDataRawResponse(
    url = '',
    data = {},
    hanldeError = true
  ) {
    url = BASE_URL + url
    //let token = sessionStorage.getItem("jwtToken") || "";
    let token = getCookie('jwtToken')

    let headers = {
      'Content-Type': 'application/json',
    }
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }

    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    if (hanldeError && response.status >= 400 && response.status < 600) {
      handleServerError(response.status)
    } else if (response.status >= 400 && response.status < 600) {
      throw new Error('Bad response from server')
    }

    return response // parses JSON response into native JavaScript objects
  }

  export async function postFile(url = '', params = {}, data = '') {
    let urlValue = new URL(url)
    urlValue.search = new URLSearchParams(params).toString()

    // Default options are marked with *
    const response = await fetch(urlValue, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'image/jpg',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: data, // body data type must match "Content-Type" header
    })

    return response.json() // parses JSON response into native JavaScript objects
  }

  function handleServerError(status) {
    if (status === 401 || status === 406) {
      logout()
    }

    throw new Error('Bad response from server')
  }

  export function logout() {
    sessionStorage.removeItem('jwtToken')
    mixpanel.reset()
    eraseCookie('jwtToken')
    window.location.reload()
    loginToken.update((x) => undefined)
    userDetails.update((x) => undefined)
    currentPage.update((x) => 'login')
    //router.goto("/login");
  }
</script>
