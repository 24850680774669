<script>
  import LeftMenu from '../components/LeftMenu.svelte'
  import TopMenu from '../components/TopMenu.svelte'
  import { leftMenuToggled } from '../components/DataStore'
  import StudentRosterView from './StudentRoster/StudentRosterView.svelte'
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="student-roster" />
    </div>
    <div id="layoutSidenav_content">
      <StudentRosterView />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
