<script>
  import { _ } from "svelte-i18n";
  import { patchData } from "../../utils/ApiUtils.svelte";
  import IconChangePassword from "./IconChangePassword.svelte";
  import { createEventDispatcher } from "svelte";
  import { onMount } from "svelte";

  export let studentAccountId;
  export let studentDetails;

  onMount(async () => {
    newFirstName = studentDetails?.firstName;
    newLastName = studentDetails?.lastName;
  });

  const dispatch = createEventDispatcher();

  let isDialog = false;

  let dialogDiv;

  let newFirstName;
  let newLastName;

  let dataStatus;

  function showDialog() {
    isDialog = true;
    dialogDiv.showModal();
  }

  function save() {
    let payloadData = {
      newFirstName: newFirstName,
      newLastName: newLastName,
    };

    dataStatus = "saving";

    patchData(
      `api/teacher/student-accounts/${studentAccountId}/new-name`,
      payloadData,
    )
      .then((data) => {
        console.log("courses successful", data);

        dataStatus = "saved";
        closeDialog();

        dispatch("saved");
      })
      .catch((error) => {
        console.log("error courses", error);
        dataStatus = "error";
      });
  }

  function closeDialog() {
    isDialog = false;
    dialogDiv.close();
  }
</script>

<dialog class="dialogBox" bind:this={dialogDiv}>
  <header><h3>{$_("CHANGE_STUDENT_NAME")}</h3></header>

  <div>
    <label for="exampleFormControlInput1" class="form-label"
      >{$_("FIRST_NAME")}</label
    >
    <input
      class="form-control"
      type="text"
      style="width:20em;"
      bind:value={newFirstName}
    />
  </div>

  <div>
    <label for="exampleFormControlInput1" class="form-label"
      >{$_("LAST_NAME")}</label
    >
    <input
      class="form-control"
      type="text"
      style="width:20em;"
      bind:value={newLastName}
    />
  </div>

  <footer>
    <div class="mt-2">
      <button class="btn btn-primary s-button-primary" on:click={save}
        >{$_("SAVE")}</button
      >
      <button class="btn btn-danger s-button-danger" on:click={closeDialog}
        >{$_("CANCEL")}</button
      >
    </div>
  </footer>
</dialog>

<button class="btn btn-danger s-button-danger ms-2" on:click={showDialog}
  >{$_("RENAME_STUDENT")}</button
>

<style>
  .dialogBox::backdrop {
    opacity: 1;
    /* background-color: rgb(25, 25, 170); */
    backdrop-filter: blur(2px);
  }
  .dialogBox {
    box-shadow: 2px 4px #cccccc;
    border-radius: 8px;
    border: none;
  }
  .dialogBox header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dialogBox footer {
    /* background-color: gray; */
    border: none;
    color: white;
    /* padding: 12px 32px; */
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
</style>
