<svg
    style="height:1em;"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
>
    <defs>
        <style>
            .cls-svg {
                fill: #f0f0f0;
                stroke-width: 2;
                stroke: #f0f0f0;
            }
        </style>
    </defs>
    <path
        class="cls-svg"
        d="M164.09,76.73c-2.56,0-4.73,2.17-4.73,4.73v66.25c0,4.6-3.84,8.44-8.44,8.44H36.94c-4.6,0-8.44-3.84-8.44-8.44V71.87c0-4.6,3.84-8.44,8.44-8.44h103.24c2.56,0,4.73-2.17,4.73-4.73s-2.17-4.73-4.73-4.73H36.94c-9.84,0-17.9,8.05-17.9,17.9v75.84c0,9.84,8.05,17.9,17.9,17.9h113.98c9.84,0,17.9-8.05,17.9-17.9V81.46c0-2.56-2.05-4.73-4.73-4.73Z"
    />
    <path
        class="cls-svg"
        d="M153.39,36.42c-2.56,0-4.73,2.17-4.73,4.73s2.17,4.73,4.73,4.73h16.11l-32.23,32.23c-1.79,1.79-1.79,4.86,0,6.65,.89,.89,2.17,1.41,3.32,1.41s2.43-.51,3.32-1.41l32.74-32.74v16.11c0,2.56,2.17,4.73,4.73,4.73s4.73-2.17,4.73-4.73v-31.71h-32.73Z"
    />
    <g>
        <path
            class="cls-svg"
            d="M57.03,110.38c1.47-.06,2.91,.35,4.1,1.12,1.28,.96,2.34,2.24,3.04,3.67,.44,.93,1.03,1.79,1.73,2.54l.1,.09c.8,.64,1.8,.95,2.72,.87,1.13,.01,2.16-.45,2.87-1.24,.76-.79,1.18-1.84,1.16-2.98-.1-1.99-.8-3.86-2.01-5.4-1.44-1.96-3.37-3.55-5.55-4.61-2.55-1.26-5.39-1.89-8.21-1.84-2.44-.02-4.85,.43-7.12,1.34-2.23,.89-4.2,2.22-5.88,3.95-1.72,1.8-3.02,3.9-3.85,6.25-.92,2.62-1.37,5.35-1.33,8.11,0,1.76,.17,3.53,.51,5.26,.35,1.63,.89,3.21,1.6,4.71,.68,1.43,1.54,2.78,2.55,4,1.08,1.28,2.35,2.36,3.78,3.23,1.4,.83,2.92,1.44,4.52,1.8,1.77,.39,3.59,.59,5.38,.57,.11,0,.22,0,.33,0,2.29,0,4.53-.44,6.66-1.29,1.88-.76,3.56-1.89,5-3.36,1.16-1.24,2.1-2.67,2.78-4.22,.57-1.29,.88-2.66,.91-4.08,.05-1.13-.38-2.24-1.18-3.06-.78-.77-1.82-1.19-2.85-1.2-1.16-.1-2.31,.37-3.1,1.31-.58,.79-1.03,1.67-1.32,2.61-.51,1.64-1.5,3.06-2.82,4.08-1.36,.93-2.95,1.41-4.65,1.38-1.63,.06-3.23-.36-4.58-1.2-1.34-.9-2.36-2.17-2.94-3.67-.82-2.11-1.2-4.34-1.12-6.6v-.05s0-.05,0-.05c-.23-3.19,.63-6.41,2.39-9.01,1.49-1.97,3.84-3.11,6.38-3.02Z"
        />
        <path
            class="cls-svg"
            d="M98.87,133.19c-1.33,.92-2.95,1.39-4.66,1.33-1.14,.06-2.3-.17-3.33-.66-.74-.35-1.39-.89-1.84-1.54-.55-.84-1.02-1.74-1.37-2.66-.34-.96-.9-1.84-1.63-2.58-.72-.68-1.68-1.05-2.61-1.03-1.17-.03-2.29,.44-3.1,1.26-.78,.8-1.22,1.89-1.2,3.02,.06,1.87,.65,3.68,1.69,5.2,1.09,1.71,2.58,3.16,4.37,4.21,2.65,1.42,5.6,2.15,8.54,2.15,.23,0,.46,0,.69-.01,2.8,.08,5.63-.46,8.2-1.56,2.18-.93,4.04-2.47,5.38-4.46,1.22-1.93,1.85-4.15,1.82-6.43,.02-1.69-.37-3.39-1.11-4.9-.67-1.37-1.67-2.58-2.93-3.5-1.37-.95-2.87-1.7-4.45-2.22-1.84-.63-3.73-1.16-5.58-1.57l-3.43-.92c-.66-.21-1.31-.48-1.92-.81-.5-.27-.93-.64-1.29-1.1-.26-.34-.4-.76-.38-1.2,.02-.89,.49-1.7,1.27-2.18,1.18-.75,2.55-1.12,3.95-1.06h.07s.07,0,.07,0c1.31-.12,2.63,.21,3.72,.94,.88,.77,1.57,1.71,2.05,2.76,.36,.83,.84,1.6,1.42,2.3l.1,.1c.73,.65,1.7,.97,2.58,.9,1.2,.02,2.32-.51,3.03-1.38,.77-.82,1.2-1.89,1.21-3.04-.01-1.17-.3-2.34-.84-3.39-.65-1.22-1.52-2.29-2.59-3.17-1.3-1.05-2.75-1.85-4.35-2.38-1.97-.61-4.01-.89-6.03-.85-2.6-.05-5.14,.38-7.58,1.28-2.06,.78-3.84,2.12-5.16,3.92-1.19,1.7-1.82,3.69-1.82,5.73-.07,2.06,.55,4.12,1.75,5.81,1.17,1.59,2.75,2.85,4.55,3.64,2.14,.96,4.39,1.7,6.68,2.19,1.5,.33,3,.75,4.47,1.25,.91,.29,1.75,.79,2.4,1.41,.55,.59,.85,1.39,.82,2.23,0,1.18-.59,2.27-1.58,2.93Z"
        />
        <path
            class="cls-svg"
            d="M124.01,134.34c.31,.94,.63,1.83,.95,2.63,.31,.82,.71,1.61,1.19,2.35,.51,.77,1.21,1.41,2.04,1.87,.95,.49,2.02,.73,3,.68,.1,0,.19,0,.29,0,.99,0,1.97-.25,2.85-.73,.79-.44,1.47-1.07,1.98-1.83,.49-.74,.89-1.53,1.19-2.35,.31-.84,.63-1.71,.93-2.59l8.71-23.4,.15-.44c.09-.27,.18-.52,.28-.79,.16-.44,.3-.89,.39-1.32,.12-.46,.18-.94,.19-1.43,0-.75-.22-1.48-.59-2.04-.38-.69-.95-1.26-1.62-1.63-.68-.4-1.46-.6-2.18-.6-.96-.06-1.92,.22-2.72,.82-.57,.46-1.03,1.05-1.31,1.68-.3,.59-.72,1.81-1.01,2.72l-7.23,21.47-7.18-21.31c-.45-1.39-1.1-2.71-1.94-3.97-.81-1.06-2.09-1.64-3.42-1.54-1.22-.04-2.42,.44-3.26,1.29-.83,.78-1.31,1.89-1.32,3.09,.02,.47,.1,.94,.2,1.36,.11,.49,.25,.98,.43,1.46,.06,.15,.13,.31,.2,.46,.07,.14,.17,.35,.17,.42v.18l8.63,23.47Z"
        />
    </g>
    <path
        class="cls-svg"
        d="M164.09,76.73c-2.56,0-4.73,2.17-4.73,4.73v66.25c0,4.6-3.84,8.44-8.44,8.44H36.94c-4.6,0-8.44-3.84-8.44-8.44V71.87c0-4.6,3.84-8.44,8.44-8.44h103.24c2.56,0,4.73-2.17,4.73-4.73s-2.17-4.73-4.73-4.73H36.94c-9.84,0-17.9,8.05-17.9,17.9v75.84c0,9.84,8.05,17.9,17.9,17.9h113.98c9.84,0,17.9-8.05,17.9-17.9V81.46c0-2.56-2.05-4.73-4.73-4.73Z"
    />
</svg>
