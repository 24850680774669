<script>
  import { _ } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import {
    getData,
    postData,
    deleteData,
    patchData,
  } from '../../utils/ApiUtils.svelte'
  import Loader from '../../components/Loader.svelte'
  import { userDetails } from '../../components/DataStore'
  import { createEventDispatcher } from 'svelte'
  import { dynamicSortLocaleTwo } from 'utils/SortingUtils.svelte'
  import TableHeaderItem from "../../components/TableHeaderItem.svelte";
  import { dynamicSort } from "utils/SortingUtils.svelte";

  export let organizationId
  export let teacherLimit = 10

  const dispatch = createEventDispatcher()

  onMount(async () => {
    if (organizationId) {
      getOrganizationTeachers(organizationId)
    }
  })

  let teachersData = []
  let teachersStatus = 'new'
  let teachersStatusError = ''

  let addNewTeacherFlag = false

  let newTeacher = {}
  let isValidEmail = true

  let isAdminTeacher = false

  let resendInvitationNotificationSent = []

  let sortColumn = "userLogin";
  let sortDirection = 1;

  function refreshList() {
    dispatch('update', {})
    getOrganizationTeachers(organizationId)
  }

  function getOrganizationTeachers(organizationId) {
    getData(
      `api/organization/${organizationId}/teachers-projection`,
      false,
      false
    )
      .then((data) => {
        console.log('organization teachers successful', data)
        // Save data to sessionStorage
        teachersData = data.sort(dynamicSortLocaleTwo('pendingInvitationEmail', 'userLogin', 1))
        teachersStatus = 'ok'

        if (
          teachersData?.filter((t) => t?.userLogin === $userDetails?.login)
            ?.length > 0
        ) {
          isAdminTeacher = true
        }
      })
      .catch((error) => {
        console.log('error organization teachers', error)
      })
  }

  function changeSort(e) {
        console.log(111, e.detail);
        sortColumn = e.detail.sortColumn;
        sortDirection = e.detail.sortDirection;

        // getStudentList();

        teachersStatus = "loading";

        teachersData = teachersData.sort(dynamicSort(sortColumn, sortDirection));

        teachersStatus = "ok";
    }

  function addNewTeacher() {
    mixpanel.track('Teacher Admin Invite New Teacher Button Clicked')
    addNewTeacherFlag = !addNewTeacherFlag
  }

  function addNewTeacherSave() {
    console.log(222)

    let emails = []

    if (newTeacher?.teacherEmail.includes(',')) {
      emails = newTeacher?.teacherEmail.split(',')
      emails = emails.map((element) => element.trim())

      console.log(888, emails)
    } else {
      console.log(777, newTeacher?.teacherEmail)
      emails = [newTeacher?.teacherEmail.trim()]
    }

    Promise.all(emails.map((e) => addNewTeacherMultiple(e)))
      .finally((data) => {
        teachersStatus = 'loading'
        addNewTeacherFlag = false
        refreshList()
        newTeacher = {}
      })
      .catch((error) => {
        console.log(555, 'error')
        teachersStatus = 'error'
      })
  }

  function addNewTeacherMultiple(teacherEmail) {
    let payloadData = {
      otagnizationId: organizationId,
      pendingInvitationEmail: teacherEmail,
    }

    isValidEmail = validateEmail(teacherEmail)

    if (!isValidEmail) {
      return
    }

    console.log('save course', payloadData)

    return postData(
      'api/organization/' + organizationId + '/teachers',
      payloadData,
      false
    )
      .then((data) => {
        refreshList()
        teachersStatus = 'loading'
      })
      .catch((error) => {
        console.log('error organization teachers', error)
        teachersStatusError = error?.message
        throw new Error(teachersStatus, teachersStatusError)
      })
  }

  function skipEnterOnInput(event) {
    console.log(event)
    if (event.key === 'Enter' && !event.ctrlKey) {
      event.preventDefault()
    } else if (event.key === 'Enter' && event.ctrlKey) {
      console.log('Save')
      addNewTeacherSave()
    }
  }

  function validateEmail(value) {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  function resendInvitation(organizationTeacherEnrollmentId) {
    let payloadData = {}

    return postData(
      `api/organization/${organizationId}/teachers/${organizationTeacherEnrollmentId}/resend`,
      payloadData,
      false
    )
      .then((data) => {
        resendInvitationNotificationSent[organizationTeacherEnrollmentId] = true
      })
      .catch((error) => {
        console.log('error organization teachers', error)
      })
  }

  function makeAdminTeacher() {
    if (confirm($_('ORGANIZATION.CONVERT_TO_TEACHER'))) {
      addNewTeacherMultiple($userDetails?.login)
    } else {
      console.log('Thing was not saved to the database.')
    }
  }

  function removeTeacher(organizationTeacherEnrollmentId, teacherEmail) {
    if (
      confirm(
        $_('ORGANIZATION.REMOVE_TEACHER', {
          values: { teacherEmail: teacherEmail },
        })
      )
    ) {
      deleteData(
        'api/organization/' +
          organizationId +
          '/teachers/' +
          organizationTeacherEnrollmentId
      )
        .then((data) => {
          refreshList()
          teachersStatus = 'loading'
        })
        .catch((error) => {
          console.log('error courses', error)
        })
    } else {
      console.log('Thing was not saved to the database.')
    }
  }

  function teacherFlags(teacherSubscriptionFlags) {
    if (teacherSubscriptionFlags) {
      return JSON.parse(teacherSubscriptionFlags)
    } else {
      return {}
    }
  }

  function changeStudentEdit(organizationTeacherEnrollmentId, flag) {
    let payloadData = {
      studentManagement: flag,
    }

    return patchData(
      `api/organization/${organizationId}/teachers/${organizationTeacherEnrollmentId}/change-edit?studentManagement=${flag}`,
      payloadData,
      false
    )
      .then((data) => {
        refreshList()
      })
      .catch((error) => {
        console.log('error organization teachers', error)
      })
  }
</script>

<div class="card shadow">
  <div class="card-header py-3 no-print">
    <div class="row mt-2">
      <div class="col">
        {#if teachersData?.length < teacherLimit}
          <!-- svelte-ignore a11y-interactive-supports-focus -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <span
            class="btn btn-primary s-button-primary"
            role="button"
            on:click={addNewTeacher}
          >
            <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
              'ORGANIZATION.INVITE_NEW_TEACHER'
            )}<br />
          </span>
          {#if !isAdminTeacher}
            <!-- svelte-ignore a11y-interactive-supports-focus -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              class="btn btn-primary s-button-primary"
              style="margin-left: 10px"
              role="button"
              on:click={makeAdminTeacher}
            >
              <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
                'ORGANIZATION.MAKE_ADMIN_A_TEACHER'
              )}<br />
            </span>
          {/if}
        {/if}
      </div>
    </div>
  </div>
  <div class="card-body">
    {#if addNewTeacherFlag && teachersData?.length < teacherLimit}
      <div>
        <div class="form-row">
          <div class="col-lg-12 offset-lg-0">
            <label
              for="courseEditEmail"
              style="padding-right: 12px;padding-top: 6px;"
              >{$_('ORGANIZATION.TEACHER_EMAIL')}</label
            >
            <input
              id="courseEditEmail"
              class="form-control"
              class:is-invalid={!isValidEmail}
              type="text"
              placeholder={$_('ORGANIZATION.TEACHER_EMAIL')}
              bind:value={newTeacher.teacherEmail}
              on:keydown={skipEnterOnInput}
            />
            <div class="invalid-feedback">
              {$_('EMAIL_ADDRESS_INVALID_FEEDBACK')}
            </div>
          </div>
        </div>

        <div class="form-row mt-3">
          <button
            class="btn btn-secondary s-button-secondary s-mr-10px"
            on:click={() => {
              addNewTeacherFlag = false
            }}
          >
            <i class="far fa-window-close" style="padding-right: 8px;" />{$_(
              'CANCEL'
            )}
          </button>

          <button
            class="btn btn-success s-button-success"
            disabled={teachersStatus === 'saving'}
            on:click|preventDefault={(x) => {
              addNewTeacherSave()
            }}
          >
            <i class="fa fa-save" style="padding-right: 8px;" />{$_('SAVE')}
            <br />
          </button>
        </div>
      </div>
    {:else if teachersStatus != 'ok'}
      <Loader />
    {:else if teachersData?.length > 0}
      {#if teachersStatusError}
        <div>
          <div class="alert alert-danger">
            {#if teachersStatusError === 'NOT_ALLOWED_TO_ADD_STUDENT_AS_TEACHER'}
              {$_('ORGANIZATION.NOT_ALLOWED_TO_ADD_STUDENT_AS_TEACHER')}
            {:else}
              {$_('ORGANIZATION.INVITATION_ERROR')}
            {/if}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              type="button"
              class="close"
              on:click={() => {
                teachersStatusError = undefined
              }}
            >
              <span>&times;</span>
            </span>
          </div>
        </div>
      {/if}
      <div
        class="table table mt-2"
        id="dataTable"
        role="grid"
        aria-describedby="dataTable_info"
      >
        <table class="table my-0 table-hover" id="dataTable">
          <thead>
            <tr>
              <th>#</th>
              <th> 
                <TableHeaderItem
                column="userLogin"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_('ORGANIZATION.TEACHER_EMAIL')}</TableHeaderItem
            >
              </th>
              <th>
                <TableHeaderItem
                column="teacherName"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_('ORGANIZATION.TEACHER')}</TableHeaderItem
            >
                </th>
              <th> {$_('ORGANIZATION.STATE')}</th>
              <th>
                <TableHeaderItem
                column="courseCount"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_('ORGANIZATION.COURSE_COUNT')}</TableHeaderItem
            >
                </th>
              <th> {$_('ORGANIZATION.CONFIG')}</th>

              <th class="action-buttons">{$_('ACTIONS')}</th>
            </tr>
          </thead>
          <tbody>
            {#each teachersData as t, i}
              <tr
                class="exerciseRow"
                on:click={(x) => {
                  console.log(t.id)
                }}
              >
                <th scope="row">{i + 1}</th>

                <td>
                  {#if t?.state === 'PENDING'}
                    {t?.pendingInvitationEmail}
                  {:else if t?.state === 'ACTIVE'}
                  <a href="/#/teacher-account/{t?.userId}">{t?.userLogin}</a>
                  {/if}
                </td>
                <td>{t?.teacherName ? t?.teacherName : ''}</td>
                <td
                  >{#if t?.state === 'PENDING'}<i
                      class="fas fa-user-clock me-2"
                    />{:else if t?.state === 'ACTIVE'}
                    <i class="fas fa-user-check me-2" />{/if}{$_(
                    `ORGANIZATION.${t?.state}`
                  )}</td
                >
                <td>{t?.courseCount}</td>
                <td
                  >{#if teacherFlags(t?.currentSubscriptionFlags)?.studentManagement == 'R'}
                    {$_(
                      'ORGANIZATION.STUDENT_READ_ONLY'
                    )}{:else if teacherFlags(t?.teacherSubscriptionFlags)?.studentManagement == 'RW'}
                    {$_('ORGANIZATION.STUDENT_READ_WRITE')}{/if}</td
                >
                <td class="action-buttons">
                  {#if teacherFlags(t?.currentSubscriptionFlags)?.studentManagement == 'R'}
                    <button
                      class="btn btn-primary s-button-primary"
                      on:click={(x) => {
                        changeStudentEdit(t?.id, 'RW')
                      }}>{$_('ORGANIZATION.ENABLE_EDIT')}</button
                    >{:else if teacherFlags(t?.currentSubscriptionFlags)?.studentManagement == 'RW'}
                    <button
                      class="btn btn-danger s-button-danger"
                      on:click={(x) => {
                        changeStudentEdit(t?.id, 'R')
                      }}>{$_('ORGANIZATION.DISABLE_EDIT')}</button
                    >{/if}
                  {#if t?.state === 'PENDING'}
                    {#if resendInvitationNotificationSent[t?.id] !== undefined}
                      <!-- svelte-ignore a11y-click-events-have-key-events -->
                      <span class="alert alert-success" role="alert">
                        {$_('ORGANIZATION.RESEND_OK')}<span
                          type="button"
                          class="close"
                          on:click={() => {
                            resendInvitationNotificationSent[t?.id] = undefined
                          }}
                        >
                          <span>&times;</span>
                        </span></span
                      >
                    {:else}
                      <button
                        class="btn btn-primary s-button-primary"
                        on:click={(x) => {
                          resendInvitation(t?.id)
                        }}
                        ><i
                          class="far fa-window-close"
                          style="padding-right: 8px;"
                        />{$_('ORGANIZATION.RESEND_INVITATION')}</button
                      >
                    {/if}
                  {/if}
                  {#if t?.userLogin !== $userDetails?.login}
                    <button
                      class="btn btn-danger s-button-danger"
                      style="pointer-events: auto;"
                      disabled={t?.courseCount > 0}
                      title={t?.courseCount > 0
                        ? $_(
                            'ORGANIZATION.ONLY_CAN_DELETE_TEACHER_WITHOUT_CLASSES'
                          )
                        : ''}
                      on:click={(x) => {
                        removeTeacher(
                          t?.id,
                          t?.state === 'PENDING'
                            ? t?.pendingInvitationEmail
                            : t?.userLogin
                        )
                      }}
                      >&#8998; {$_('DELETE')}</button
                    >
                  {/if}
                </td>
              </tr>
            {/each}
          </tbody>
          <tfoot>
            <tr />
          </tfoot>
        </table>
      </div>
    {/if}
  </div>
</div>

<style>
  .close {
    padding-left: 0.75rem;
  }

  .action-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;
  }
</style>