<script>
  import { _ } from "svelte-i18n";
  import Loader from "../../components/Loader.svelte";
  import AnalyticsComponent from "../../components/AnalyticsComponent.svelte";

  export let studentAccountId;
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">
        <h1 class="mt-4">{$_("ANALYTICS_SECTION.ANALYTICS")}</h1>
        <p>{$_("ANALYTICS_SECTION.SENSAY_ANALYTICS_INTRODUCTION")}</p>
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    <AnalyticsComponent studentAccountId={studentAccountId}></AnalyticsComponent>
  </div>
</main>

<style>
</style>
