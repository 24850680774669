<script>
  import { _ } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import { createEventDispatcher } from 'svelte'
  import { postData } from 'utils/ApiUtils.svelte'

  export let courseId
  const dispatch = createEventDispatcher()

  onMount(async () => {})

  let lessonName = 'Tongue Twisters'
  let lessonDetailStatus = 'new'

  function createLesson() {
    mixpanel.track('Teacher FRE Next: Create an exercise Button Clicked')
    saveLesson()
  }

  function saveLesson() {
    lessonDetailStatus = 'saving'

    let payloadData = {
      name: lessonName,
      description: '',
      activeDateStart: undefined,
      activeDateEnd: undefined,
    }

    console.log('save lesson', payloadData)

    postData('api/courses/' + courseId + '/lessons', payloadData)
      .then((data) => {
        console.log('lesson successful', data)

        lessonDetailStatus = 'saved'
        dispatch('nextPage', { lessonId: data?.id, lessonName: lessonName })
      })
      .catch((error) => {
        console.log('error courses', error)
        lessonDetailStatus = 'error'
      })
  }

  function closeModal(skip) {
    let event
    skip === 'skip'
      ? (event = 'Teacher FRE Skip for now Button Clicked')
      : (event = 'Teacher Teacher FRE Close Modal Clicked')
    mixpanel.track(event, { Step: 'Create a lesson' })
    dispatch('closeModal', {})
  }
</script>

<div class="step-layout">
  <div class="steps-list-group">
    <span class="steps-list-heading"
      >{$_('FRE_STEP_CREATE_LESSON_HEADING')}</span
    >
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_CLASS')}
    </div>
    <div class="steps-list-item steps-list-highlight">
      <i class="far fa-circle mr-2" />{$_('FRE_STEP_CREATE_LESSON')}
    </div>
    <div class="steps-list-item">
      <i class="far fa-circle mr-2" />{$_('FRE_STEP_CREATE_EXERCISE')}
    </div>
    <div class="steps-list-item">
      <i class="far fa-circle mr-2" />{$_('FRE_STEP_SHARE')}
    </div>

    <div class="skip">
      <button class="button-skip" on:click={() => closeModal('skip')}>
        {$_('FRE_SKIP_BUTTON')}
      </button>
    </div>
  </div>

  <div class="exit">
    <button
      type="button"
      class="s-modal-close"
      on:click={() => closeModal('dismiss')}
    >
      &times;
    </button>
  </div>

  <div class="interaction-area">
    <div class="form-group fre-form-group">
      <label class="lessonLabel" for="lessonNameInput"
        >{$_('FRE_STEP_CREATE_LESSON_PROMPT')}</label
      >
      <input
        bind:value={lessonName}
        type="text"
        class="form-control lessonName"
        id="lessonNameInput"
      />
    </div>

    <div class="fre-btn-group">
      <button class="btn btn-primary s-button-primary" on:click={createLesson}
        >{$_('FRE_STEP_CREATE_LESSON_NEXT_STEP_BUTTON')}</button
      >
    </div>
  </div>
</div>

<style>
  .step-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    grid-template-areas:
      'steps exit exit'
      'steps interact interact'
      'steps interact interact';
  }

  .steps-list-group {
    grid-area: steps;

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    width: 100%;

    padding-top: 5vh;

    background-color: #cacaca30;
  }

  .steps-list-heading {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6em;
    color: #000000;

    align-items: center;
    padding: 0 3vw 0 3vw;
    margin-bottom: 3vh;
  }

  .steps-list-item {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #00000060;

    align-items: center;
    padding: 0 3vw 0 3vw;
  }

  .steps-list-highlight {
    font-weight: 600;
    color: #00a094;
  }

  .steps-list-complete {
    text-decoration-line: line-through;
  }

  .interaction-area {
    grid-area: interact;

    display: flex;
    justify-self: center;
    width: 80%;
    flex-direction: column;
  }

  .lessonLabel {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    color: #000000;
  }

  .lessonName {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    width: 75%;
  }

  .fre-form-group {
    margin-top: 10vh;
  }

  .fre-btn-group {
    margin-top: 3vh;
    align-self: center;
    margin-bottom: 5vh;
  }

  .exit {
    grid-area: exit;
    height: 2vh;
  }

  .skip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .button-skip {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    text-align: center;
    text-decoration-line: underline;

    color: rgba(17, 17, 17, 0.4);
    border: none;
    background-color: transparent;
  }

  @media only screen and (min-width: 992px) {
    .steps-list-heading {
      font-size: 2em;
    }

    .step-layout {
      grid-template-columns: repeat(3, 1fr);
    }
  }
</style>
