<script>
  import { _, locale } from "svelte-i18n";
  import { router } from "tinro";
  import { onMount } from "svelte";
  import {
    getData,
    deleteData,
    postDataRawResponse,
  } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import {
    currentPage,
    userConfig,
    userDetails,
    userContext,
  } from "../../components/DataStore";
  import FiveStepsPartnerZhTw from "../FirstRunExperience/Infographics/FiveStepsPartnerZhTw.svelte";
  import FiveStepsPartnerEn from "../FirstRunExperience/Infographics/FiveStepsPartnerEn.svelte";
  import {
    dynamicSortLocale,
    dynamicSortLocaleTwo,
  } from "utils/SortingUtils.svelte";
  import TableHeaderItem from "../../components/TableHeaderItem.svelte";

  onMount(async () => {
    getStudentRoster();
  });

  let studentRosterData = [];
  let studentRosterStatus = "new";
  let studentRosterId = undefined;

  let sortColumn = "code";
  let sortDirection = 1;

  function refreshList() {
    getStudentRoster();
  }

  function changeSort(e) {
    console.log(111, e.detail);
    sortColumn = e.detail.sortColumn;
    sortDirection = e.detail.sortDirection;

    // getStudentList();

    studentRosterStatus = "loading";

    studentRosterData = studentRosterData.sort(
      dynamicSortLocale(sortColumn, sortDirection),
    );

    studentRosterStatus = "ok";
  }

  function getStudentRoster() {
    studentRosterStatus = "loading";
    getData("api/student-roster", false, false)
      .then((data) => {
        console.log("student-roster successful", data);
        // Save data to sessionStorage
        studentRosterData = data.sort(
          dynamicSortLocaleTwo("userLogin", "code", 1),
        );

        studentRosterStatus = "ok";
      })
      .catch((error) => {
        console.log("error student-roster", error);
      });
  }

  function loadStudentRosterData(id) {
    studentRosterId = id;
    clickChangePage(`student-roster/${id}`);
  }

  function editStudentRoster(id) {
    clickChangePage(`student-roster/${id}/edit`);
  }

  function deleteStudentRoster(subRow) {
    console.log("delete studentRoster", subRow);

    if (
      confirm(
        $_("STUDENT_ROSTER.DELETE_CONFIRM_DIALOG", {
          values: { studentRosterName: subRow?.name },
        }),
      )
    ) {
      deleteData(`api/student-roster/${subRow.id}`)
        .then((data) => {
          console.log("studentRoster successful", data);

          getStudentRoster();
        })
        .catch((error) => {
          console.log("studentRoster courses", error);
        });
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  }

  function clickChangePage(e) {
    // console.log('clickChangePage', e)
    $currentPage = e;
    router.goto("/" + e);
  }

  function loadListFromOneCampus() {
    getData("api/onecampus/course", false, false)
      .then((data) => {
        console.log("onecampus/classes successful", data);
        // Save data to sessionStorage
        studentRosterStatus = "ok";
      })
      .catch((error) => {
        console.log("error student-roster", error);
      });

    getData("api/onecampus/course-students", false, false)
      .then((data) => {
        console.log("onecampus/class-students successful", data);
        // Save data to sessionStorage
        studentRosterStatus = "ok";
      })
      .catch((error) => {
        console.log("error student-roster", error);
      });
  }

  function syncOneCampus() {
    studentRosterStatus = "loading";
    postDataRawResponse("api/onecampus/student-roster/sync", false, false)
      .then((data) => {
        console.log("onecampus/student-roster/sync successful", data);
        // Save data to sessionStorage
        getStudentRoster();
      })
      .catch((error) => {
        console.log("error sync onecampus student-roster", error);
      });
  }

  function syncSchoolPlus() {
    studentRosterStatus = "loading";
    postDataRawResponse("api/schoolplus/student-roster/sync", false, false)
      .then((data) => {
        console.log("schoolplus/student-roster/sync successful", data);
        // Save data to sessionStorage
        getStudentRoster();
      })
      .catch((error) => {
        console.log("error sync schoolplus student-roster", error);
      });
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">
        {$_("STUDENT_ROSTER.HEADER")}
      </div>
    </div>
  </div>

  {#if $userContext != null && $userContext?.fiveSteps?.status !== "CLOSED"}
    <div class="s-content-spacing">
      {#if $userDetails?.spRefreshToken}
        {#if $locale === "zh-TW"}
          <FiveStepsPartnerZhTw partner={"School+"} />
        {:else}
          <FiveStepsPartnerEn partner={"School+"} />
        {/if}
      {/if}
    </div>
  {/if}

  <div class="s-content-spacing">
    <div class="card shadow">
      <div class="card-header">
        {#if $userConfig?.serverFlags?.adminStudentRoster === true}
          <button
            class="btn btn-primary s-button-primary"
            on:click={() => editStudentRoster(undefined)}
            >{$_("STUDENT_ROSTER.ADD_NEW")}</button
          >
        {/if}
        {#if $userDetails?.dsns}
          <button
            class="btn btn-primary s-button-primary"
            on:click={() => syncOneCampus()}
            >{$_("STUDENT_ROSTER.SYNC_ONECAMPUS")}</button
          >
        {/if}
        {#if $userDetails?.spRefreshToken}
          <button
            class="btn btn-primary s-button-primary"
            on:click={() => syncSchoolPlus()}
            >{$_("STUDENT_ROSTER.SYNC_SCHOOL_PLUS")}</button
          >
        {/if}
      </div>

      <div class="card-body">
        {#if studentRosterStatus === "loading"}
          <Loader />
        {:else if studentRosterStatus === "ok"}
          {#if studentRosterData?.length > 0}
            <div
              class="table table mt-2"
              id="dataTable"
              role="grid"
              aria-describedby="dataTable_info"
            >
              <table class="table my-0 table-hover" id="dataTable">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col"
                      ><TableHeaderItem
                        column="code"
                        {sortColumn}
                        {sortDirection}
                        on:changeSort={changeSort}
                        >{$_("STUDENT_ROSTER.CODE")}</TableHeaderItem
                      ></th
                    >
                    <th scope="col">
                      <TableHeaderItem
                        column="userLogin"
                        {sortColumn}
                        {sortDirection}
                        on:changeSort={changeSort}
                        >{$_("STUDENT_ROSTER.TEACHER")}</TableHeaderItem
                      ></th
                    >
                    <th scope="col">
                      <TableHeaderItem
                        column="studentAccountCount"
                        {sortColumn}
                        {sortDirection}
                        on:changeSort={changeSort}
                        >{$_("STUDENT_ROSTER.STUDENTS")}</TableHeaderItem
                      ></th
                    >
                    <th scope="col">
                      <TableHeaderItem
                        column="studentInvitationCount"
                        {sortColumn}
                        {sortDirection}
                        on:changeSort={changeSort}
                        >{$_("STUDENT_ROSTER.INVITATIONS")}</TableHeaderItem
                      ></th
                    >
                    <!-- <th scope="col">{$_('STUDENT_ROSTER.ORGANIZATION')}</th> -->
                    <!-- <th scope="col">{$_('STUDENT_ROSTER.TEACHER')}</th> -->
                    <th scope="col">{$_("STUDENT_ROSTER.ACTION")}</th>
                  </tr>
                </thead>
                <tbody>
                  {#if studentRosterData?.length > 0}
                    {#each studentRosterData as subRow, i}
                      <tr class:table-primary={subRow.id === studentRosterId}>
                        <th scope="row">{i + 1}</th>
                        <td
                          ><a href="#/student-roster/{subRow.id}"
                            >{#if subRow.code}{subRow.code}{/if}</a
                          ></td
                        >
                        <td>{subRow.userFullName} {subRow.userLogin}</td>
                        <td>{subRow.studentAccountCount} </td>
                        <td
                          >{#if subRow.studentInvitationCount > 0}{subRow.studentInvitationCount}{/if}</td
                        >
                        <!-- <td
                          >{#if subRow.organizationId}{subRow.organizationName}{/if}</td
                        >
                        <td>{subRow.userLogin} - {subRow.userFullName}</td> -->
                        <td
                          >{#if $userConfig?.serverFlags?.adminStudentRoster === true}
                            <button
                              class="btn btn-danger"
                              title={$_("DELETE")}
                              on:click|stopPropagation={() =>
                                deleteStudentRoster(subRow)}>⌦</button
                            >{/if}
                        </td>
                      </tr>
                    {/each}
                  {/if}
                </tbody>
                <tfoot>
                  <tr />
                </tfoot>
              </table>
            </div>
          {:else}
            <div class="row">
              <svg
                class="text-center"
                width="201"
                height="200"
                viewBox="0 0 201 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M102.5 89.3701C99.7311 89.3701 97.0243 88.549 94.722 87.0107C92.4197 85.4724 90.6253 83.2859 89.5657 80.7277C88.5061 78.1695 88.2288 75.3546 88.769 72.6389C89.3092 69.9231 90.6426 67.4286 92.6005 65.4706C94.5584 63.5127 97.053 62.1793 99.7687 61.6391C102.484 61.0989 105.299 61.3762 107.858 62.4358C110.416 63.4954 112.602 65.2899 114.141 67.5921C115.679 69.8944 116.5 72.6012 116.5 75.3701C116.484 79.0783 115.004 82.6301 112.382 85.2522C109.76 87.8743 106.208 89.3543 102.5 89.3701Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M119.59 115.08L151.95 74.8998L139.36 64.8198L115.02 93.9998H85.39C82.5024 94.013 79.7371 95.1673 77.6971 97.2111C75.6571 99.2548 74.5079 102.022 74.5 104.91V117.34H119.58L119.59 115.08Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M103.61 87.5698C100.841 87.5698 98.1347 86.7487 95.8324 85.2104C93.5301 83.6721 91.7357 81.4856 90.676 78.9274C89.6164 76.3692 89.3392 73.5543 89.8794 70.8386C90.4196 68.1228 91.7529 65.6283 93.7109 63.6703C95.6688 61.7124 98.1634 60.379 100.879 59.8388C103.595 59.2986 106.41 59.5759 108.968 60.6355C111.526 61.6951 113.713 63.4896 115.251 65.7918C116.789 68.0941 117.61 70.8009 117.61 73.5698C117.595 77.278 116.115 80.8298 113.492 83.4519C110.87 86.074 107.319 87.554 103.61 87.5698ZM103.61 65.7998C102.071 65.7979 100.566 66.2525 99.2853 67.1061C98.0046 67.9598 97.0058 69.1742 96.4155 70.5956C95.8251 72.0171 95.6696 73.5817 95.9686 75.0915C96.2677 76.6013 97.0079 77.9885 98.0955 79.0776C99.1832 80.1666 100.569 80.9086 102.079 81.2096C103.588 81.5106 105.153 81.3571 106.575 80.7686C107.998 80.18 109.213 79.1828 110.068 77.9032C110.924 76.6236 111.38 75.119 111.38 73.5798C111.372 71.5206 110.552 69.5478 109.096 68.0908C107.641 66.6337 105.67 65.8104 103.61 65.7998Z"
                  fill="#707070"
                />
                <path
                  d="M120.71 113.27L153.07 73.09L140.5 63L116.14 92.23H86.4996C83.6181 92.2432 80.8583 93.3937 78.8208 95.4312C76.7832 97.4688 75.6327 100.228 75.6196 103.11V115.54H66.3096V121.76H72.4996V152.85H78.7196V121.76H122.24V152.85H128.5V121.76H134.72V115.54H120.72L120.71 113.27ZM92.7096 115.54V107.77H86.4996V115.54H81.8496V103.11C81.8548 101.877 82.3461 100.697 83.2167 99.8242C84.0873 98.9517 85.267 98.4579 86.4996 98.45H119.05L141.34 71.69L144.34 74.06L114.53 111.06V115.52L92.7096 115.54Z"
                  fill="#707070"
                />
                <path
                  d="M164.23 121.76H150.23V115.54H158V53.3699H42.9995V115.54H50.7695V121.76H36.7695V47.1499H164.23V121.76Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.8701 64.25H75.6301V70.47H53.8701V64.25Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.8701 79.79H67.8701V86H53.8701V79.79Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.8701 95.3398H67.8701V101.55H53.8701V95.3398Z"
                  fill="#707070"
                />
              </svg>
              <h3 class="text-center">
                {$_("STUDENT_ROSTER.TABLE_EMPTY_PROMPT_NEW")}
              </h3>
              <p style="padding-left:15%; padding-right:15%;">
                {$_("STUDENT_ROSTER.TABLE_EMPTY_PROMPT_EXISTING")}
              </p>
            </div>
          {/if}
        {:else if studentRosterStatus === "ERROR_NO_ORGANIZATION"}
          <div class="alert alert-danger" role="alert">
            {$_("ORGANIZATION.ERROR_NO_ORGANIZATION")}
          </div>
        {:else if studentRosterStatus === "ERROR_MORE_THAN_ONE_ORGANIZATION"}
          <div class="alert alert-danger" role="alert">
            {$_("ORGANIZATION.ERROR_MORE_THAN_ONE_ORGANIZATION")}
          </div>
        {:else}
          {$_("ERROR")} - {studentRosterStatus}
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  .strong {
    font-weight: 700;
  }
</style>
