<script>
  import { _ } from "svelte-i18n";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { getData, putData, deleteData } from "utils/ApiUtils.svelte";
  import { CSVToArray, arrayIsEmpty } from "utils/FormatUtils.svelte";
  import { dynamicSortLocale } from "utils/SortingUtils.svelte";
  import {
    trimText,
    formatNumberWith4Colors,
  } from "utils/FormatUtils.svelte";
  import {
    currentCourseId,
    currentLessonId,
    isOpenSendFeedbackModal,
    userConfig,
    userDetails,
  } from "../../../components/DataStore";
  import Tooltip from "components/Tooltip.svelte";
  import PieIcon from "components/visual/PieIcon.svelte";
  import PremiumIcon from "../../../components/visual/PremiumIcon.svelte";

  export let courseId;
  export let lessonId;
  export let courseName;

  let studentData = [];
  let studentDataStatus = "loading";
  let addStudentFlag = false;
  let uploadStudentCsvFlag = false;
  let addStudentRosterFlag = false;
  let newStudent = { studentEmail: "" };
  let isValidEmail = true;
  let dropArea = undefined;
  let dropAreaHighlighted = false;
  let importStatus = undefined;
  let importStatusMessage = undefined;

  let studentAccountEnrollmentData 

  let csvTable = [];

  let sortColumn = "idStudent";
  let sortDirection = 1;

  let showDetails = false;
  let showQrCodeStudents = true;
  let showRemovedStudents = false;
  let ignoreCase = true;
  let studentNameFilter = "";

  userConfig.subscribe((v) => {
    if (v?.serverFlags?.qrHidden === true) {
      showQrCodeStudents = false;
    }
  });

  function changeSort(c, d) {
    sortColumn = c;
    sortDirection = d;

    studentDataStatus = "loading";

    studentData = studentData.sort(dynamicSort(c, d));

    studentDataStatus = "ok";
  }

  onMount(async () => {
    console.log("studentData", studentData);
    dataStatus = "loading";
    if ($userConfig?.serverFlags?.qrHidden === true) {
      showQrCodeStudents = false;
    } else {
      showQrCodeStudents = true;
    }
    await getStudentAccountEnrollmentList($currentCourseId),

    getStudentAccountReport();
  });

  let saSet = new Set();

  let sMap = new Map();
  let sAMap = new Map();

  let sMapSorted = [];

  let sMap2 = new Map();

  let lMap = new Map();
  let eMap = new Map();
  let cGroups = {};
  let eGroups = {};

  let dataStatus = "new";

  async function getStudentAccountEnrollmentList(courseId) {
    await getData("api/courses/" + courseId + "/student-accounts")
      .then((data) => {
        // console.log('courses - studentEnrollment successful', data)
        studentAccountEnrollmentData = data;
        saSet = new Set([...data.map((v)=>v.studentId)])
         
      })
      .catch((error) => {
        console.log("error courses", error);
        studentDataStatus = "error";
      });
  }

  function getStudentAccountReport() {
    dataStatus = "loading";

    sMap = new Map();
    sAMap = new Map();
    sMapSorted = [];
    sMap2 = new Map();

    getData(`api/courses/${courseId}/lessons/${lessonId}/report`)
      .then((data) => {
        data = data.filter((d) => d.lessonId == $currentLessonId);

        console.log("getReportData successful", data);

        if (showQrCodeStudents == false) {
          data = data.filter((d) => d.idStudentAccount != undefined);
        }

        if (showRemovedStudents == false) {
          data = data.filter((d) => (showQrCodeStudents && !d.idStudentAccount) 
          || saSet.has(d.idStudentAccount)  );
        }


        data.forEach((e) => {
          if (e?.idStudentAccount == undefined) {
            if (ignoreCase) {
              sMap.set(e.studentName?.toLowerCase(), {
                name: e.studentName,
                id: null,
              });
              e.idStudentAccount = e.studentName?.toLowerCase();
            } else {
              sMap.set(e.studentName, { name: e.studentName, id: null });
              e.idStudentAccount = e.studentName;
            }
          } else {
            sMap.set(e.idStudentAccount, {
              name: e.studentAccountName,
              id: e?.idStudentAccount,
            });
          }

          eMap.set(e.exerciseId, e.exerciseTitle);
        });

        studentAccountEnrollmentData.forEach((sa) => {
          if (!sMap.has(sa.studentId)){
            sMap.set(sa.studentId, { name: `${sa?.studentFirstName || ''} ${sa?.studentLastName || ''}`, id: sa.studentId })
          }
        });

        

        [...sMap].forEach((s) => {
          if (s[0] != null) {
            sMapSorted.push({
              idStudentAccount: s[0],
              studentAccountName: s[1]?.name,
              id: s[1]?.id,
            });
          }
        });

        if (studentNameFilter) {
          if (ignoreCase) {
            sMapSorted = sMapSorted.filter((s) =>
              s?.studentAccountName
                ?.toLowerCase()
                ?.includes(studentNameFilter?.toLowerCase()),
            );
          } else {
            sMapSorted = sMapSorted.filter((s) =>
              s?.studentAccountName?.includes(studentNameFilter),
            );
          }
        }

        

        sMapSorted = sMapSorted.sort(dynamicSortLocale("studentAccountName", 1));

        [...sMap.keys()].forEach((k) => {
          let sEMap = new Map();
          let progress = 0;
          let avgScore = 0;

          let counterTotal = 0;
          let counterWithRec = 0;
          let sumScore = 0;

          Array.from(eMap.keys()).forEach((exerciseId) => {
            //console.log(lessonId, obj[Number(lessonId)])

            let prBestId;
            let prCount = 0;
            counterTotal++;

            let x = data.find(
              (v) => v.idStudentAccount === k && v.exerciseId === exerciseId,
            );

            if (x) {
              counterWithRec++;
              prBestId = x?.prBestId;
              sumScore += x?.prBestScore;
              prCount = x?.recordingCount;
            }

            sEMap.set(exerciseId, {
              leMap: x,
              prCount: prCount,
              prBestId: prBestId,
              prBestScore: x?.prBestScore,
            });
          });

          if (counterWithRec > 0) {
            progress = counterWithRec / counterTotal;
            avgScore = sumScore / counterWithRec;
          }

          sMap2.set(k, {
            studentAccountName: sMap.get(k),
            exercise: sEMap,
            progress: progress,
            avgScore: avgScore,
          });
        });

        cGroups = data.reduce((r, a) => {
          const k = a.idCourse; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        eGroups = data.reduce((r, a) => {
          const k = a.idExercise; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        // sMapSorted = new Map([...sMap2.entries()].sort((a,b) => b['studentAccountName'] - a['studentAccountName']))

        console.log("x", sMap, sMapSorted, sMap2, lMap, eMap, cGroups, eGroups);

        dataStatus = "ok";
      })
      .catch((error) => {
        dataStatus = "error";
        // console.log('error lessons', error)
      });
  }

  function changeShowQrCodeStudents() {
    getStudentAccountReport();
  }
</script>

<div class="card shadow">
  <div>
    {#if dataStatus === "loading"}
      <Loader />
    {:else if dataStatus === "error"}
      {$_("ERROR_LOADING_STUDENT_ACCOUNT_LIST")}
    {:else}
      <div class="card-header">
        <div>
          <div class="form-check">
            <label for="studentNameFilterInput" class="form-check-label"
              >{$_("STUDENT_NAME")}</label
            >
            <input
              id="studentNameFilterInput"
              class="form-control s-form-input"
              type="text"
              bind:value={studentNameFilter}
              on:blur={getStudentAccountReport}
            />
          </div>

          <div class="form-check">
            <input
              id="showDetailsCheckbox"
              class="form-check-input"
              type="checkbox"
              bind:checked={showDetails}
            />
            <label for="showDetailsCheckbox" class="form-check-label"
              >{$_("SHOW_DETAILS")}</label
            >
          </div>

          <!-- <div class="form-check">
            <input
              id="ignoreCaseCheckbox"
              class="form-check-input"
              type="checkbox"
              bind:checked={ignoreCase}
              on:change={getStudentAccountReport}
            />
            <label for="ignoreCaseCheckbox" class="form-check-label"
              >{$_("IGNORE_CASE_SENSITIVITY_IN_NAMES")}</label
            >
          </div> -->

          <div class="form-check">
            <input
              id="showRemovedStudentsCheckbox"
              class="form-check-input"
              type="checkbox"
              bind:checked={showRemovedStudents}
              on:change={changeShowQrCodeStudents}
            />
            <label for="showRemovedStudentsCheckbox" class="form-check-label"
              >{$_("SHOW_REMOVED_STUDENTS")}</label
            >
          </div>

          

          {#if $userConfig?.serverFlags?.qrHidden === true}
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="showQrCodeStudents"
                checked={showQrCodeStudents}
                disabled
                on:change={changeShowQrCodeStudents}
              />
              <label
                class="custom-control-label text-secondary"
                for="showQrCodeStudents"
              >
                {$_("SHOW_QR_SHARE_STUDENTS")}
                <PremiumIcon></PremiumIcon></label
              >
            </div>
          {:else}
            <div class="form-check">
              <input
                id="showQrCodeStudents"
                class="form-check-input"
                type="checkbox"
                bind:checked={showQrCodeStudents}
                on:change={changeShowQrCodeStudents}
              />
              <label for="showQrCodeStudents" class="form-check-label"
                >{$_("SHOW_QR_SHARE_STUDENTS")}</label
              >
            </div>
          {/if}
        </div>
      </div>
      <div class="card-body">
        <div>
          <!-- <div>
            {$_("STUDENT_COUNT")}:{studentData?.length} / 30
          </div> -->
          <div
            class="table-responsive table mt-2"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info"
          >
            <table class="table table-hover" id="dataTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{$_("PROGRESS")}</th>
                  <th
                    ><Tooltip title={$_("AVG_BEST_SCORE_TOOLTIP")}>
                      <i class="fa-solid fa-circle-info" />
                    </Tooltip>{$_("AVG_BEST_SCORE")}</th
                  >
                  <th>{$_("STUDENT_NAME")}</th>
                  {#each [...eMap] as [idExercise, e]}
                    <th
                      ><a
                        href="#courses/{courseId}/lessons/{lessonId}/exercise-detail/{idExercise}/tabRecording"
                        >{#if showDetails}{e}{:else}{trimText(e,20)}{/if}</a
                      ></th
                    >
                  {/each}
                </tr>
              </thead>
              <tbody>
                {#each sMapSorted as x, i}
                  {@const s = sMap2.get(x.idStudentAccount)}

                  <!-- {#each [...sMap2] as [studentAccountId, s], i} -->
                  <tr>
                    <th scope="row">
                      {i + 1}
                    </th>
                    <td>
                      {#if s.progress === 1}
                        ✅
                      {:else if s.progress > 0}
                        {#if showDetails}
                          {formatNumberWith4Colors(s.progress * 100)}%
                        {:else}
                          ❓
                        {/if}
                      {:else}❌
                      {/if}
                    </td>

                    <td
                      class:good={s.avgScore >= 60}
                      class:bad={s.avgScore > 0 && s.avgScore < 60}
                    >
                      {#if s.avgScore > 0}{formatNumberWith4Colors(
                          s.avgScore,
                        )}{:else}0{/if}
                    </td>
                    <td>
                      {#if x.id}<strong
                          ><a href="/#/student-account-detail/{x.id}"
                            >{x?.studentAccountName}</a
                          ></strong
                        >{:else}<a
                          href="#/student-detail/{x?.studentAccountName}"
                          >{x?.studentAccountName}</a
                        >{/if}
                    </td>

                    {#each [...s?.exercise] as [idExercise, e]}
                      <td>
                        {#if e.prBestId}
                          <a href="#recording-detail/{e.prBestId}">
                            {#if e.prBestScore > 0}{formatNumberWith4Colors(
                                e.prBestScore,
                              )}{:else}0{/if}</a
                          >{#if showDetails}
                            ({e.prCount}){/if}
                        {:else}❌
                        {/if}</td
                      >
                    {/each}
                  </tr>
                {:else}NO_DATA
                {/each}
              </tbody>
              <tfoot>
                <tr />
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .card {
    overflow-x: auto;
  }

  .done {
    background-color: green;
    color: aliceblue;
  }

  .good {
    color: darkgreen;
    font-weight: 700;
  }

  .bad {
    color: red;
    font-weight: 700;
  }
</style>
