<script>
  import { _ } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import Loader from '../../components/Loader.svelte'
  import { createEventDispatcher } from 'svelte'
  import { userDetails } from '../../components/DataStore'

  const dispatch = createEventDispatcher()

  let frame
  export let loaded = false

  let isExerciseDone = false

  let progress = 30

  let progressTimer = setInterval(() => {
    progress = Math.min(95, progress + 10)
    console.log(444, progress)
  }, 1000)

  onMount(async () => {
    setup()
  })

  function setup() {
    window.addEventListener('message', receiveMessage)
  }

  function receiveMessage(e) {
    isExerciseDone = true
  }

  function loadDone() {
    loaded = true
    progressTimer = null
    progress = 100
  }

  function skipPage() {
    mixpanel.track("Teacher FRE I can't talk right now Button Clicked")
    dispatch('nextPage', {})
  }

  function nextPage() {
    mixpanel.track('Teacher FRE Create your first exercise Button Clicked')
    dispatch('nextPage', {})
  }

  function skipModal() {
    mixpanel.track('Teacher FRE Skip for now Button Clicked ', {
      Step: 'Student Demo',
    })
    dispatch('closeModal', {})
  }
</script>

<div style="width: 100%;">
  <div class:hidden={loaded}>
    <!-- <Loader /> -->
    {#key progress}
      <div class="progress m-4">
        <div
          class="progress-bar"
          style="width: {progress}%;"
          role="progressbar"
        />
      </div>
    {/key}
  </div>
  <div class:hidden={!loaded}>
    <iframe
      id="inlineFrameExample"
      title="Inline Frame Example"
      width="500"
      height="250"
      allow="microphone * autoplay allow-scripts"
      bind:this={frame}
      on:load={loadDone}
      src="https://beta.student.oksensay.com/?iframe=true&key=85zk05&firstName={$userDetails?.firstName}"
    />
  </div>
  <div class="skip">
    {#if isExerciseDone}
      <div>
        <button class="btn btn-primary s-button-primary" on:click={nextPage}
          >{$_('FRE_DEMO_NEXT_STEP_BUTTON')}</button
        >
      </div>
      <div>
        <button class="button-skip" on:click={skipModal}>
          {$_('FRE_SKIP_BUTTON')}
        </button>
      </div>
    {:else}
      <button class="button-skip" on:click={skipPage}
        >{$_('FRE_DEMO_CANT_TALK_BUTTON')}</button
      >
    {/if}
  </div>
</div>

<style>
  iframe {
    width: 100%;
    min-height: 595px;
    border: none;
  }

  .hidden {
    display: none;
  }

  .skip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .button-skip {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    text-align: center;
    text-decoration-line: underline;

    color: rgba(17, 17, 17, 0.4);
    border: none;
    background-color: transparent;
  }
</style>
