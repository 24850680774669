<script>
  import { _ } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import TextAreaAutoresize from '../../components/TextAreaAutoresize.svelte'
  import { createEventDispatcher } from 'svelte'
  import { postData } from 'utils/ApiUtils.svelte'
  import Loader from '../../components/Loader.svelte'

  export let courseId
  export let lessonId

  const dispatch = createEventDispatcher()

  onMount(async () => {})

  let exerciseText = 'Sally sells seashells by the seashore.'
  let exerciseDetailStatus = 'new'

  function createExercise() {
    mixpanel.track('Teacher FRE Next: Share the lesson Button Clicked')
    saveExercise()
  }

  function generateToken() {
    let tokenLength = 6
    let result = ''
    let characters = 'abcdefghijkmnopqrstuvwxyz023456789'
    let charactersLength = characters.length

    for (var i = 0; i < tokenLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  function generateExercise() {
    let exerciseList = [
      "Fuzzy Wuzzy was a bear. Fuzzy Wuzzy had no hair. Fuzzy Wuzzy wasn't very fuzzy, was he?",
      "Betty's big bunny bobbled by the blueberry bush.",
      'Fred fed Ted bread and Ted fed Fred bread.',
      'If a dog chews shoes, whose shoes does he choose?',
      'How much wood would a woodchuck chuck if a woodchuck could chuck wood?',
      'Peter Piper picked a peck of pickled peppers.',
      'The thirty-three thieves thought that they thrilled the throne throughout Thursday.',
      'If you must cross a course cross cow across a crowded cow crossing, cross the cross coarse cow across the crowded cow crossing carefully.',
      'Imagine an imaginary menagerie manager managing an imaginary menagerie.',
      'Can you can a canned can into an un-canned can like a canner can can a canned can into an un-canned can?',
      'Which witch is which?',
      'Scissors sizzle, thistles sizzle.',
      'Six sleek swans swam swiftly southwards.',
    ]

    mixpanel.track(
      'Teacher FRE Exercise Generate a different exercise Button Clicked'
    )
    exerciseText = exerciseList[Math.floor(Math.random() * exerciseList.length)]
  }

  function replaceCharactersWithTTSProblems(text) {
    text = text.replaceAll(/\u2019/g, "'")
    text = text.replaceAll(/\2026/g, '.')
    return text
  }

  function saveExercise() {
    exerciseDetailStatus = 'saving'

    exerciseText = replaceCharactersWithTTSProblems(exerciseText)

    let payloadData = {
      key: generateToken(),
      language: '',
      data: '{}',
      text: exerciseText,
      title: 'Sample Exercise',
      type: 'READ',
      multiChoiceAnswers: [null, null, null, null],
    }

    console.log('save exercise', payloadData)

    postData(
      'api/courses/' + courseId + '/lessons/' + lessonId + '/exercises',
      payloadData
    )
      .then((data) => {
        console.log('courses successful', data)
        exerciseDetailStatus = 'saved'
        dispatch('nextPage', {})
      })
      .catch((error) => {
        console.log('error courses', error)
        exerciseDetailStatus = 'error'
      })
  }

  function closeModal(skip) {
    let event
    skip === 'skip'
      ? (event = 'Teacher FRE Skip for now Button Clicked')
      : (event = 'Teacher Teacher FRE Close Modal Clicked')
    mixpanel.track(event, { Step: 'Create an exercise' })
    dispatch('closeModal', {})
  }
</script>

<div class="step-layout">
  <div class="steps-list-group">
    <span class="steps-list-heading"
      >{$_('FRE_STEP_CREATE_EXERCISE_HEADING')}</span
    >
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_CLASS')}
    </div>
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_LESSON')}
    </div>
    <div class="steps-list-item steps-list-highlight">
      <i class="far fa-circle mr-2" />{$_('FRE_STEP_CREATE_EXERCISE')}
    </div>
    <div class="steps-list-item">
      <i class="far fa-circle mr-2" />{$_('FRE_STEP_SHARE')}
    </div>

    <div class="skip">
      <button class="button-skip" on:click={() => closeModal('skip')}>
        {$_('FRE_SKIP_BUTTON')}
      </button>
    </div>
  </div>

  <div class="exit">
    <button
      type="button"
      class="s-modal-close"
      on:click={() => closeModal('dismiss')}
    >
      &times;
    </button>
  </div>

  <div class="interaction-area">
    <div class="form-group fre-form-group">
      <label class="exerciseLabel" for="exerciseTextToRead"
        >{$_('FRE_STEP_CREATE_EXERCISE_PROMPT')}</label
      >

      <TextAreaAutoresize
        class="fre-exercise-text-area"
        id="exerciseTextToRead"
        bind:value={exerciseText}
        placeholder="Text to Read"
        style="color:#000000;"
      />
    </div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="generateExercise" on:click={generateExercise}>
      <span style="cursor: pointer"
        ><i class="fas fa-lightbulb me-2" />{$_(
          'FRE_STEP_CREATE_EXERCISE_GENERATE_BUTTON'
        )}</span
      >
    </div>

    <div class="fre-btn-group">
      <button class="btn btn-primary s-button-primary" on:click={createExercise}
        >{$_('FRE_STEP_CREATE_EXERCISE_NEXT_STEP_BUTTON')}</button
      >
    </div>
  </div>
</div>

<style>
  .step-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    grid-template-areas:
      'steps exit exit'
      'steps interact interact'
      'steps interact interact';
  }

  .steps-list-group {
    grid-area: steps;

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    width: 100%;

    padding-top: 5vh;

    background-color: #cacaca30;
  }

  .steps-list-heading {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6em;
    color: #000000;

    align-items: center;
    padding: 0 3vw 0 3vw;
    margin-bottom: 3vh;
  }

  .steps-list-item {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #00000060;

    align-items: center;
    padding: 0 3vw 0 3vw;
  }

  .steps-list-highlight {
    font-weight: 600;
    color: #00a094;
  }

  .steps-list-complete {
    text-decoration-line: line-through;
  }

  .interaction-area {
    grid-area: interact;

    display: flex;
    justify-self: center;
    width: 80%;
    flex-direction: column;
  }

  .exerciseLabel {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    color: #000000;
  }

  :global(.fre-exercise-text-area) {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #00000070;
    width: 75%;
  }

  .fre-form-group {
    margin-top: 10vh;
    margin-bottom: 0;
  }

  .fre-btn-group {
    margin-top: 3vh;
    align-self: center;
    margin-bottom: 5vh;
  }

  .exit {
    grid-area: exit;
    height: 2vh;
  }

  .skip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .button-skip {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    text-align: center;
    text-decoration-line: underline;

    color: rgba(17, 17, 17, 0.4);
    border: none;
    background-color: transparent;
  }

  .generateExercise {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    text-align: center;
    text-decoration-line: underline;

    color: rgba(17, 17, 17, 0.4);
  }

  @media only screen and (min-width: 992px) {
    .steps-list-heading {
      font-size: 2em;
    }

    .step-layout {
      grid-template-columns: repeat(3, 1fr);
    }
  }
</style>
