<script>
  import LeftMenu from '../components/LeftMenu.svelte'
  import TopMenu from '../components/TopMenu.svelte'
  import { leftMenuToggled } from '../components/DataStore'
  import StudentAnalyticsPageOverview from './StudentAnalytics/StudentAnalyticsPageOverview.svelte'

  export let studentAccountId
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="students" />
    </div>
    <div id="layoutSidenav_content">
      <StudentAnalyticsPageOverview {studentAccountId} />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
