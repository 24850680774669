<script>
  import { _ } from 'svelte-i18n'
  import * as yup from 'yup'

  export let showValidation = true
  export let password = ''
  export let isValidPassword = true

  const schema = yup
    .string()
    .test('valid-password', 'Password Invalid', (value) =>
      value.match(/^(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/)
    )

  let error
  let isChanged = false
  let show_password = false

  $: type = show_password ? 'text' : 'password'
  $: style = show_password ? 'letter-spacing: 0' : 'letter-spacing: 0.3rem;'

  export async function validatePassword() {
    try {
      await schema.validate(password)
      // for debugging validation result
      // console.log(JSON.stringify(registrationDetails, null, 2))
      error = ''
      isValidPassword = true
    } catch (error) {
      isValidPassword = false
    }
  }
</script>

<div>
  {#if type === 'password'}
    <input
      class="form-control s-form-input"
      type="password"
      id="password"
      name="password"
      {style}
      bind:value={password}
      on:change={() => {
        isChanged = true
      }}
      on:keyup={validatePassword}
      class:is-valid={showValidation && isChanged && isValidPassword}
      class:is-invalid={showValidation && !isValidPassword}
      on:blur={validatePassword}
      autocomplete="off"
    />
  {:else}
    <input
      class="form-control s-form-input"
      type="text"
      id="password"
      name="password"
      {style}
      bind:value={password}
      on:change={() => {
        isChanged = true
      }}
      on:keyup={validatePassword}
      class:is-valid={showValidation && isChanged && isValidPassword}
      class:is-invalid={showValidation && !isValidPassword}
      on:blur={validatePassword}
      autocomplete="off"
    />
  {/if}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="password-visibility"
    on:click={() => (show_password = !show_password)}
  >
    <span class="password-show-hide">
      {#if show_password}
        {$_('HIDE')}
      {:else}
        {$_('SHOW')}
      {/if}
    </span>
  </div>
  <div class="valid-feedback" />
  <div class="invalid-feedback">
    {$_('PASSWORD_INVALID_FEEDBACK')}
  </div>
</div>

<style>
  .password-visibility {
    height: 1.25rem;
    width: 2rem;

    float: right;
    transform: translate(-3em, -1.95rem);

    cursor: pointer;
  }

  .password-show-hide {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;

    text-decoration-line: underline;
    text-transform: uppercase;

    color: rgba(50, 50, 50, 0.8);
  }
</style>
