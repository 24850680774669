<script lang="ts">
  import { Route, router } from 'tinro'
  import { onMount } from 'svelte'
  import IndexPage from './pages/IndexPage.svelte'
  import LoginPage from './pages/LoginPage.svelte'
  import RegistrationPage from './pages/RegistrationPage.svelte'
  import ProfilePage from './pages/ProfilePage.svelte'
  import StudentPage from './pages/StudentPage.svelte'
  import StudentDetailPage from './pages/StudentDetailPage.svelte'
  import StudentRecordingPage from './pages/StudentRecordingPage.svelte'
  import CoursePage from './pages/Course/CoursePage.svelte'
  import CourseDetailPage from './pages/Course/CourseDetailPage.svelte'
  import CourseDetailEditPage from './pages/Course/CourseDetailEditPage.svelte'
  import LessonDetailPage from './pages/Lesson/LessonDetailPage.svelte'
  import LessonDetailEditPage from './pages/Lesson/LessonDetailEditPage.svelte'
  import ExerciseDetailPage from './pages/Exercise/ExerciseDetailPage.svelte'
  import ExerciseDetailEditPage from './pages/Exercise/ExerciseDetailEditPage.svelte'
  import RecordingDetailPage from './pages/Recording/RecordingDetailPage.svelte'
  import ResetPasswordPage from './pages/ResetPasswordPage.svelte'
  import ResetPasswordVerifyPage from './pages/ResetPasswordVerifyPage.svelte'
  import SearchResultPage from './pages/SearchResultPage.svelte'
  import ContentMarketPlaceCallbackPage from './pages/ContentMarketPlaceCallbackPage.svelte'
  import ActivityLogPage from './pages/ActivityLogPage.svelte'
  import OrganizationManagement from './pages/OrganizationManagement.svelte'
  import OneCampusPage from './pages/OneCampusPage.svelte'
  import SchoolPlusPage from './pages/SchoolPlusPage.svelte'
  import { getProfile } from './utils/ApiCalls.svelte'
  import { getCookie } from './utils/CookieUtils.svelte'
  import {
    addMessages,
    init,
    getLocaleFromNavigator,
  } from 'svelte-i18n'
  import {
    currentPage,
    loginToken,
    leftMenuToggled,
  } from './components/DataStore'

  import en from './i18n/en.json'
  import zhTW from './i18n/zh-TW.json'
  import RegistrationFromInvitePage from './pages/RegistrationFromInvitePage.svelte'
  import StudentAnalyticsPage from './pages/StudentAnalyticsPage.svelte'
  import StudentRosterEditPage from './pages/StudentRosterEditPage.svelte'
  import StudentRosterPage from './pages/StudentRosterPage.svelte'
  import StudentRosterDetailPage from './pages/StudentRosterDetailPage.svelte'
  import StudentAccountDetailPage from './pages/StudentAccountDetailPage.svelte'
  import TeacherDetailPage from './pages/TeacherDetailPage.svelte';
  import StudentsInOrgPage from './pages/StudentsInOrgPage.svelte'
  import ImportStudentsToOrgPage from './pages/ImportStudentsToOrgPage.svelte';
    import RequestQuotePage from './pages/RequestQuotePage.svelte';

  addMessages('en', en)
  addMessages('zh-TW', zhTW)

  //register('en', () => import('./i18n/en.json'));
  //register('zh-TW', () => import('./i18n/zh-TW.json'));

  router.mode.hash()

  let page

  console.log('getLocaleFromNavigator', getLocaleFromNavigator())
  init({
    fallbackLocale: 'en',
    initialLocale: getLocaleFromNavigator(),
  })

  onMount(async () => {
    const url_string = window.location.href
    if (url_string.match('reset-password-verify')) {
      return
    }
    if (url_string.match('reset-password')) {
      return
    }
    if (url_string.match('registration')) {
      return
    }

    if (url_string.match('one-campus')) {
      let urlParams = new URLSearchParams(document.location.search)
      //document.location.search = ''
      page = 'one-campus'
      router.goto(
        `/one-campus?dsns=${urlParams.get('dsns')}&code=${urlParams.get(
          'code'
        )}&role=${urlParams.get('role')}`
      )
      return
    }

    if (url_string.match('schoolplus')) {
      let urlParams = new URLSearchParams(document.location.search)
      //document.location.search = ''
      page = 'schoolplus'
      router.goto(
        `/schoolplus?&code=${urlParams.get(
          'code'
        )}&role=${urlParams.get('role')}`
      )
      return
    }

    getProfile()
  })

  //let token = sessionStorage.getItem("jwtToken") || "";
  let token = getCookie('jwtToken')
  loginToken.update((x) => token)

  currentPage.subscribe((x) => {
    console.log('pageChange', x)
    page = x
  })

  loginToken.subscribe((x) => {
    console.log('loginTokenChange', x)
    if (x === undefined) {
      page = 'login'
      router.goto('/' + page)
    }
  })

  function handleMessage(event) {
    console.log(event)
    page = event.detail
    router.goto('/' + page)
  }
</script>

<div class:leftMenuToggled={$leftMenuToggled} style="display: contents;">
  <Route>
    <Route path="/login">
      <LoginPage on:message={handleMessage} />
    </Route>
    <Route path="/registration">
      <RegistrationPage on:message={handleMessage} />
    </Route>
    <Route path="/one-campus" let:meta>
      <OneCampusPage
        dsns={meta.query.dsns}
        code={meta.query.code}
        role={meta.query.role}
      />
    </Route>
    <Route path="/schoolplus" let:meta>
      <SchoolPlusPage code={meta.query.code}
      role={meta.query.role}></SchoolPlusPage>
    </Route>

    <Route path="/registration-invite" let:meta>
      <RegistrationFromInvitePage
        email={meta.query.email}
        schoolName={meta.query.schoolName}
      />
    </Route>

    <Route path="/reset-password">
      <ResetPasswordPage />
    </Route>
    <Route path="/reset-password-verify/:jwtToken" let:meta>
      <ResetPasswordVerifyPage jwtToken={meta.params.jwtToken} />
    </Route>
    <Route path="/login" fallback>
      <LoginPage on:message={handleMessage} />
    </Route>

    {#if $loginToken}
      <Route path="/">
        <IndexPage on:message={handleMessage} />
      </Route>
      <Route path="/index">
        <IndexPage on:message={handleMessage} />
      </Route>

      <Route path="/marketplace-callback/:contentPackId" let:meta>
        <ContentMarketPlaceCallbackPage
          contentPackId={meta.params.contentPackId}
        />
      </Route>

      <Route path="/profile">
        <ProfilePage on:message={handleMessage} />
      </Route>
      <Route path="/students">
        <StudentPage on:message={handleMessage} />
      </Route>
      <Route path="/student-detail/:studentName" let:meta>
        <StudentDetailPage
          on:message={handleMessage}
          studentName={meta.params.studentName}
        />
      </Route>
      <Route path="/student-account-detail/:studentAccountId" let:meta>
        <StudentAccountDetailPage
          on:message={handleMessage}
          studentAccountId={meta.params.studentAccountId}
          activeTab={"SAOverview"}
        />
      </Route>
      <Route path="/student-account-detail/:studentAccountId/:activeTab" let:meta>
        <StudentAccountDetailPage
          on:message={handleMessage}
          studentAccountId={meta.params.studentAccountId}
          activeTab={meta.params.activeTab}
        />
      </Route>
      <Route path="/student-analytics/:studentAccountId" let:meta>
        <StudentAnalyticsPage
          on:message={handleMessage}
          studentAccountId={meta.params.studentAccountId}
        />
      </Route>
      <Route path="/students-recording">
        <StudentRecordingPage on:message={handleMessage} />
      </Route>

      <Route path="/courses">
        <CoursePage on:message={handleMessage} />
      </Route>
      <Route path="/courses-detail/:courseId" let:meta>
        <CourseDetailPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          tab="lessons"
        />
      </Route>
      <Route path="/courses-detail/:courseId/:tab" let:meta>
        <CourseDetailPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          tab={meta.params.tab}
        />
      </Route>
      <Route path="/courses-edit">
        <CourseDetailEditPage on:message={handleMessage} />
      </Route>
      <Route path="/courses-edit/:courseId/*" let:meta>
        <CourseDetailEditPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
        />
      </Route>

      <Route path="/courses/:courseId/lesson-detail/:lessonId" let:meta>
        <LessonDetailPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          lessonId={meta.params.lessonId}
          tab="exercises"
        />
      </Route>
      <Route path="/courses/:courseId/lesson-detail/:lessonId/:tab" let:meta>
        <LessonDetailPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          lessonId={meta.params.lessonId}
          tab={meta.params.tab}
        />
      </Route>

      <Route path="/courses/:courseId/lesson-edit" let:meta>
        <LessonDetailEditPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
        />
      </Route>
      <Route path="/courses/:courseId/lesson-edit/:lessonId" let:meta>
        <LessonDetailEditPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          lessonId={meta.params.lessonId}
        />
      </Route>

      <Route
        path="/courses/:courseId/lessons/:lessonId/exercise-detail/:exerciseId"
        let:meta
      >
        <ExerciseDetailPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          lessonId={meta.params.lessonId}
          exerciseId={meta.params.exerciseId}
        />
      </Route>

      <Route
        path="/courses/:courseId/lessons/:lessonId/exercise-detail/:exerciseId/:selectedTab"
        let:meta
      >
        <ExerciseDetailPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          lessonId={meta.params.lessonId}
          exerciseId={meta.params.exerciseId}
          selectedTab={meta.params.selectedTab}
        />
      </Route>

      <Route path="/courses/:courseId/lessons/:lessonId/exercise-edit" let:meta>
        <ExerciseDetailEditPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          lessonId={meta.params.lessonId}
        />
      </Route>
      <Route
        path="/courses/:courseId/lessons/:lessonId/exercise-edit/:exerciseId"
        let:meta
      >
        <ExerciseDetailEditPage
          on:message={handleMessage}
          courseId={meta.params.courseId}
          lessonId={meta.params.lessonId}
          exerciseId={meta.params.exerciseId}
        />
      </Route>

      <Route path="/recording-detail/:recordingId" let:meta>
        <RecordingDetailPage
          on:message={handleMessage}
          recordingId={meta.params.recordingId}
        />
      </Route>
      <Route path="/recording-detail">
        <RecordingDetailPage on:message={handleMessage} />
      </Route>

      <Route path="/search*">
        <SearchResultPage on:message={handleMessage} />
      </Route>

      <Route path="/activity-log">
        <ActivityLogPage on:message={handleMessage} />
      </Route>

      <Route path="/organization-management">
        <OrganizationManagement on:message={handleMessage} />
      </Route>

      <Route path="/organization-management/:activeTab" let:meta>
        <OrganizationManagement on:message={handleMessage} activeTab={meta.params.activeTab}/>
      </Route>

      <Route path="/teacher-account/:teacherUserId" let:meta>
        <TeacherDetailPage teacherUserId={meta.params.teacherUserId}  />
      </Route>

      <Route path="/student-roster">
        <StudentRosterPage on:message={handleMessage} />
      </Route>

      <Route path="/student-roster/:studentRosterId" let:meta>
        <StudentRosterDetailPage
          on:message={handleMessage}
          studentRosterId={meta.params.studentRosterId}
        />
      </Route>

      <Route path="/student-roster/:studentRosterId/edit" let:meta>
        <StudentRosterEditPage
          on:message={handleMessage}
          studentRosterId={meta.params.studentRosterId}
        />
      </Route>

      <Route path="/students-in-org">
        <StudentsInOrgPage on:message={handleMessage} />
      </Route>

      <Route path="/import-students-in-org">
        <ImportStudentsToOrgPage on:message={handleMessage} />
      </Route>

      <Route path="/request-quote">
        <RequestQuotePage on:message={handleMessage} />
      </Route>

      
    {/if}
  </Route>
</div>
