<script>
    import { _ } from "svelte-i18n";
    import Loader from "./Loader.svelte";
    import { getData, BASE_URL } from "utils/ApiUtils.svelte";
    import { onMount } from "svelte";
    import TableHeaderItem from "./TableHeaderItem.svelte";
    import { dynamicSort } from "utils/SortingUtils.svelte";
    import { average } from "utils/FormatUtils.svelte";
    import Tooltip from "components/Tooltip.svelte";

    export let studentAccountId = undefined;
    export let courseId = undefined;

    let analyticsResultList = [];

    let analyticsResultStatus = "loading";

    let analyticsType = "WORD";

    let sortColumn = "count";
    let sortDirection = -1;
    let filterItem = "";

    onMount(() => {
        if (courseId || studentAccountId) {
            getAnalyticsResultList();
        }
    });

    function getAnalyticsResultList() {
        analyticsResultStatus = "loading";
        let URL;
        if (studentAccountId) {
            URL = `${BASE_URL}api/analytics/student?studentAccountId=${studentAccountId}&type=${analyticsType}`;
        } else if (courseId) {
            URL = `${BASE_URL}api/analytics/course?cid=${courseId}&type=${analyticsType}`;
        }
        getData(URL, true)
            .then((data) => {
                if (filterItem && filterItem.length > 0) {
                    data = data.filter((s) => {
                        return s?.item?.includes(filterItem);
                    });
                }

                data.forEach((el) => {
                    if (el.count > 50) {
                        let avgPart1 = average(
                            el.values.slice(0, el.count / 4),
                        );
                        let avgPart2 = average(
                            el.values.slice((el.count * 3) / 4, el.count),
                        );

                        el["trend"] = avgPart2 - avgPart1;
                    }

                    if (el.count > 200) {
                        el.values = sampleSize(el.values, 200);
                    }
                });

                analyticsResultList = data;
                analyticsResultStatus = "ok";
                console.log("course successful", data);
            })
            .catch((error) => {
                analyticsResultStatus = "error";
                console.log("error", error);
            });
    }

    function changeSort(e) {
        console.log(111, e.detail);
        sortColumn = e.detail.sortColumn;
        sortDirection = e.detail.sortDirection;

        analyticsResultStatus = "loading";

        analyticsResultList = analyticsResultList.sort(
            dynamicSort(sortColumn, sortDirection),
        );

        analyticsResultStatus = "ok";
    }

    const shuffle = ([...arr]) => {
        let m = arr.length;
        while (m) {
            const i = Math.floor(Math.random() * m--);
            [arr[m], arr[i]] = [arr[i], arr[m]];
        }
        return arr;
    };

    const sampleSizeRandom = ([...arr], n = 1) => shuffle(arr).slice(0, n);

    const sampleSize = (arr, n = 1) => {
        const result = [];
        const len = arr.length;

        // Generate n unique random indices
        const indices = new Set();
        while (indices.size < n) {
            indices.add(Math.floor(Math.random() * len));
        }

        // Sort the indices to maintain the original sequence
        const sortedIndices = Array.from(indices).sort((a, b) => a - b);

        // Collect elements at the sorted indices
        sortedIndices.forEach((index) => {
            result.push(arr[index]);
        });

        return result;
    };

    function classBasedOnScore(score) {
        if (score >= 90) {
            return "excellent";
        } else if (score >= 80) {
            return "good";
        } else if (score >= 60) {
            return "poor";
        }
        return "bad";
    }
</script>

<div class="card shadow">
    <div class="card-header py-3">
        <div
            class="no-print d-flex flex-row align-items-center justify-content-between"
        >
            <div class="row">
                <div style="display: flex;gap: 2em;">
                    <div class="form-check">
                        <label
                            for="courseNameFilterInput"
                            class="form-check-label">{$_("COURSE_NAME")}</label
                        >
                        <select
                            class="form-control"
                            bind:value={analyticsType}
                            on:change={getAnalyticsResultList}
                        >
                            <option value="WORD"
                                >{$_("ANALYTICS_SECTION.WORDS")}</option
                            >
                            <option value="SYLLABLE"
                                >{$_("ANALYTICS_SECTION.SYLLABLE")}</option
                            >
                            <option value="PHONEME"
                                >{$_("ANALYTICS_SECTION.PHONEME")}</option
                            >
                        </select>
                    </div>
                    <div class="form-check">
                        <label
                            for="studentNameFilterInput"
                            class="form-check-label"
                            >{$_(`ANALYTICS_SECTION.${analyticsType}`)}</label
                        >
                        <input
                            id="studentNameFilterInput"
                            class="form-control s-form-input"
                            type="text"
                            style="min-width: 20em;"
                            bind:value={filterItem}
                            on:change={getAnalyticsResultList}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-group">
        {#if analyticsResultStatus === "loading"}
            <Loader />
        {:else if analyticsResultStatus === "error"}
            {$_("ANALYTICS_SECTION.ERROR_LOADING_ANALYTICS")}
        {:else}
            <div class="card-body">
                <table class="table">
                    <tr
                        ><th scope="col">#</th>
                        <th scope="col">
                            <TableHeaderItem
                                column="item"
                                {sortColumn}
                                {sortDirection}
                                on:changeSort={changeSort}
                                >{$_(
                                    `ANALYTICS_SECTION.${analyticsType}`,
                                )}</TableHeaderItem
                            ></th
                        >

                        <th scope="col">
                            <TableHeaderItem
                                column="count"
                                {sortColumn}
                                {sortDirection}
                                on:changeSort={changeSort}
                                >{$_(
                                    "ANALYTICS_SECTION.COUNT",
                                )}</TableHeaderItem
                            >
                        </th>
                        <!-- <th scope="col">
                <TableHeaderItem
                  column="min"
                  {sortColumn}
                  {sortDirection}
                  on:changeSort={changeSort}
                  >{$_("ANALYTICS_SECTION.MIN")}</TableHeaderItem
                > 
              </th>-->
                        <th scope="col">
                            <TableHeaderItem
                                column="mean"
                                {sortColumn}
                                {sortDirection}
                                on:changeSort={changeSort}
                                >{$_(
                                    "ANALYTICS_SECTION.SCORE",
                                )}</TableHeaderItem
                            >
                        </th>
                        <!-- <th scope="col">
                <TableHeaderItem
                  column="max"
                  {sortColumn}
                  {sortDirection}
                  on:changeSort={changeSort}
                  >{$_("ANALYTICS_SECTION.MAX")}</TableHeaderItem
                >
              </th> -->

                        <th scope="col">
                            <TableHeaderItem
                                column="trend"
                                {sortColumn}
                                {sortDirection}
                                on:changeSort={changeSort}
                            >
                                {$_("ANALYTICS_SECTION.TREND")}
                                <Tooltip
                                    title={$_(
                                        "ANALYTICS_SECTION_TREND_TOOLTIP",
                                    )}
                                >
                                    <i class="fa-solid fa-circle-info" />
                                </Tooltip>
                            </TableHeaderItem>
                        </th>
                        <th scope="col"
                            >{$_("ANALYTICS_SECTION.VISUALIZATION")}</th
                        >
                    </tr>
                    <tbody>
                        {#each analyticsResultList as analytics, i}
                            <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{analytics?.item}</td>
                                <td>{analytics?.count}</td>
                                <!-- <td>{analytics?.min}</td> -->
                                <td>{analytics?.mean.toFixed(0)}</td>
                                <!-- <td>{analytics?.max}</td> -->
                                <td class="result"
                                    ><span
                                        class:result-excellent={analytics?.trend >
                                            0}
                                        class:result-bad={analytics?.trend < 0}
                                    >
                                        {analytics?.trend?.toFixed(0) || ""}
                                        {#if analytics?.trend > 0}↗{:else if analytics?.trend < 0}↘{/if}</span
                                    ></td
                                >
                                <td
                                    ><div class="visualization-wrapper">
                                        {#each analytics?.values.slice(0, 200) as x}
                                            {#if x >= 90}
                                                <span class="point-excellent" />
                                            {:else if x >= 80}
                                                <span class="point-green" />
                                            {:else if x >= 60}
                                                <span class="point-orange" />
                                            {:else}
                                                <span class="point-red" />
                                            {/if}
                                        {/each}
                                    </div></td
                                >
                            </tr>
                        {:else}
                            {$_("ANALYTICS_SECTION.NO_DATA")}
                        {/each}
                    </tbody>
                </table>
            </div>
        {/if}
    </div>
</div>

<style>
    .visualization-wrapper {
        display: flex;
    }

    .point-excellent {
        width: 2px;
        height: 1em;
        background-color: #008154;
    }

    .point-green {
        width: 2px;
        height: 1em;
        background-color: #40ac86;
    }

    .point-orange {
        width: 2px;
        height: 1em;
        background-color: #f7ba31;
    }

    .point-red {
        width: 2px;
        height: 1em;
        background-color: #fe5b5b;
    }

    .result .result-excellent {
        background-color: #008154;
        color: aliceblue;
        min-width: 1.5em;
        min-height: 1.5em;
        padding: 2px;
    }

    .result .result-good {
        background-color: #40ac86;
        color: aliceblue;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .result .result-poor {
        background-color: #f7ba31;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .result .result-bad {
        background-color: #fe5b5b;
        color: aliceblue;
        min-width: 1.5em;
        min-height: 1.5em;
        padding: 2px;
    }
</style>
