<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { getData, postData, deleteData } from "utils/ApiUtils.svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentCourseRoleCheck,
    userDetails,
    userConfig,
  } from "../../../components/DataStore";
  import { mixed } from "yup/lib/locale";
  import CourseDetailDropdown from "./CourseDetailDropdown.svelte";
  import DialogCopyClassToOtherOrganization from "./DialogCopyClassToOtherOrganization.svelte";
  import DialogCopyClassToOtherTeacher from "./DialogCopyClassToOtherTeacher.svelte";

  let courseData = {};

  let copyCourseToUserFlag = false;
  let copyCourseToUserSuccessStatus = "";
  let copyCourseToUserEmail = "";
  let courseDetailStatus = "loading";
  let copyCourseStatus;

  let dialogCopyClassToOtherOrganization;
  let dialogCopyClassToOtherTeacher;

  onMount(async () => {
    let cid = $currentCourseId;
    console.log("$currentCourseId", $currentCourseId);
    getCourses($currentCourseId);
  });

  const unsubscribe = currentCourseId.subscribe((value) => {
    if (value) {
      getCourses(value);
    }
  });

  function editCourseDetail() {
    console.log("editCourseDetail", $currentCourseId);
    mixpanel.track("Teacher Edit Course Button Clicked", {
      "Course ID": $currentCourseId,
    });
    clickChangePage("courses-edit/" + $currentCourseId);
  }

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    currentPage.update((x) => e);
    router.goto("/" + e);
  }

  function getCourses(id) {
    getData("api/courses-2/" + id)
      .then((data) => {
        console.log("courses successful", data);
        courseData = data;
        currentCourseName.update((x) => courseData?.name);
        courseDetailStatus = "ok";
      })
      .catch((error) => {
        console.log("error courses", error);
        courseDetailStatus = "error";
      });

    getData(`api/courses/${$currentCourseId}/user-roles/check`)
      .then((data) => {
        console.log("courses successful", data);
        $currentCourseRoleCheck = data;
        // currentCourseName.update((x) => courseData?.name)
        // courseDetailStatus = 'ok'
      })
      .catch((error) => {
        console.log("error courses", error);
        courseDetailStatus = "error";
      });
  }

  function copyCourse() {
    copyCourseStatus = "loading";
    mixpanel.track("Teacher Duplicate Class Button Clicked", {
      "Course ID": $currentCourseId,
    });
    postData("api/courses/" + $currentCourseId + "/copy", {})
      .then((data) => {
        console.log("courses successful", data);
        copyCourseStatus = "ok";
      })
      .catch((error) => {
        console.log("error courses", error);
        copyCourseStatus = "error";
      });
  }

  function copyCourseToUser() {
    mixpanel.track("Teacher Duplicate Class Button Clicked", {
      "Course ID": $currentCourseId,
    });
    copyCourseToUserFlag = !copyCourseToUserFlag;
  }

  function copyCourseToSelectedUser() {
    dialogCopyClassToOtherTeacher.openCopyToOtherTeacherDialog();
  }

  function copyCourseToAnotherOrganizationTeacher() {
    dialogCopyClassToOtherOrganization.openCopyToOtherTeacherDialog();
  }

  function copyCourseToUserConfirm() {
    let payload = {
      courseId: $currentCourseId,
      targetEmail: copyCourseToUserEmail,
    };

    copyCourseToUserSuccessStatus = "saving";

    postData("api/courses/" + $currentCourseId + "/copyToUsers", payload)
      .then((data) => {
        console.log("courses successful", data);
        copyCourseToUserSuccessStatus = "ok";
      })
      .catch((error) => {
        console.log("error courses", error);
        copyCourseToUserSuccessStatus = "error";
      });
  }

  function refresh() {
    getCourses($currentCourseId);
  }

  function deleteCourse() {
    console.log("delete course");
    mixpanel.track("Teacher Delete Button Clicked", {
      "Button Location": "Course Edit Page",
    });
    if (
      confirm(
        $_("COURSE_DELETE_CONFIRM_DIALOG", {
          values: { courseName: courseData?.name },
        }),
      )
    ) {
      // Save it!
      console.log("Thing was saved to the database.");
      deleteData("api/courses/" + $currentCourseId)
        .then((data) => {
          console.log("courses successful", data);

          //moveToPage('course-table.html');
          currentCourseId.update((x) => undefined);
          currentCourseName.update((x) => undefined);
          clickChangePage("courses");
        })
        .catch((error) => {
          console.log("error courses", error);
        });
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  }

  function deleteRecordingsInCourse() {
    console.log("delete deleteRecordingsInCourse");
    mixpanel.track(
      "Teacher Delete Public Recordings In Course Button Clicked",
      {
        "Button Location": "Course Edit Page",
      },
    );
    if (
      confirm(
        $_("RECORDING_DELETE_CONFIRM_DIALOG", {
          values: { courseName: courseData?.name },
        }),
      )
    ) {
      // Save it!
      console.log("Thing was saved to the database.");
      deleteData(`api/courses/${$currentCourseId}/publicrecordings`)
        .then((data) => {
          console.log("courses successful", data);

          refresh();
        })
        .catch((error) => {
          console.log("error courses", error);
        });
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  }
</script>

{#if $userDetails?.userRole === "ADMIN" 
|| $userDetails?.userRole === "SURREAL" 
|| $userConfig?.serverFlags?.copyClassToSameDistributorOrg === true 
|| $userDetails?.organizationDistributor === "AMC"}
<DialogCopyClassToOtherOrganization
  bind:this={dialogCopyClassToOtherOrganization}
  courseId={$currentCourseId}
></DialogCopyClassToOtherOrganization>
{/if}

{#if $userDetails?.userRole === "ADMIN" 
|| $userDetails?.userRole === "SURREAL" 
|| $userConfig?.serverFlags?.copyClassToOrganizations === true}
  <DialogCopyClassToOtherTeacher
    bind:this={dialogCopyClassToOtherTeacher}
    courseId={$currentCourseId}
  ></DialogCopyClassToOtherTeacher>
{/if}

{#if courseDetailStatus === "loading"}
  <Loader />
{:else if courseDetailStatus === "error"}
  {$_("COURSE_LOAD_ERROR")}
{:else}
  <div class="card shadow">
    <div class="card-body">
      <div class="row">
        <div class="col offset-lg-0">
          <h3>{courseData.name}</h3>
        </div>
        <div class="col">
          <CourseDetailDropdown
            on:editCourseDetail={editCourseDetail}
            on:copyCourse={copyCourse}
            on:deleteCourse={deleteCourse}
            on:deleteRecordingsInCourse={deleteRecordingsInCourse}
            on:copyCourseToSelectedUser={copyCourseToSelectedUser}
            on:copyCourseToAnotherOrganizationTeacher={copyCourseToAnotherOrganizationTeacher}
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-lg-12 offset-lg-0">
          <pre
            style="word-wrap: break-word; white-space: pre-wrap;">{courseData.description}</pre>
        </div>
      </div>
      <div class="row" style="display: none;">
        <div class="col-sm">
          {$_("EVALUATION_LANGUAGE")}: {$_(courseData?.language)}
          <!-- {#if courseData?.language === "en-us"}{$_(courseData?.language)}
          {:else if courseData?.language === "cs-CZ"}{$_("CZECH")}
          {:else if courseData?.language === "zh-TW"}{$_("CHINESE")}
          {/if} -->
        </div>
      </div>
      {#if courseData?.organizationId}
        <div class="row">
          <div class="col-sm">
            {$_("ORGANIZATION.NAME")}:
            <strong>{$_(courseData?.organizationName)}</strong>
          </div>
        </div>
      {/if}
      {#if courseData?.teacherUserId !== $userDetails?.id}
        <div class="row">
          <div class="col-sm">
            {$_("COOP.OWNER")}:
            <a href="#/teacher-account/{courseData?.teacherUserId}"
              ><strong>{$_(courseData?.teacherName)}</strong></a
            >
          </div>
        </div>
      {/if}
      {#if courseData?.teacherUserId !== $userDetails?.id}
        <div class="row">
          <div class="col-sm">
            {$_("COOP.PERMISSIONS")}:
            <strong
              >{$_(
                `COOP_PERMISSIONS.${$currentCourseRoleCheck?.permissions || ""}`,
              )}</strong
            >
          </div>
        </div>
      {/if}
      <div class="row">
        <div class="col-sm">
          {$_("SCORING_TYPE")}:
          <strong>{$_(courseData?.scoringType)}</strong>
          <!-- {#if courseData?.language === "en-us"}{$_(courseData?.language)}
          {:else if courseData?.language === "cs-CZ"}{$_("CZECH")}
          {:else if courseData?.language === "zh-TW"}{$_("CHINESE")}
          {/if} -->
        </div>
      </div>

      {#if courseData?.reportType}
        <div class="row">
          <div class="col-sm">
            {$_("REPORT_TYPE")}:
            <strong>{$_(courseData?.reportType)}</strong>
          </div>
        </div>
      {/if}
      {#if $userConfig?.serverFlags?.enableOtherTTSLanguages === true}
        <div class="row">
          <div class="col-sm">
            {$_("EVALUATION_LANGUAGE")}:
            <strong>{$_(courseData?.language)}</strong>
          </div>
        </div>
      {/if}
      {#if $userDetails?.userRole === "ADMIN"}
        {#if courseData?.privacyType}
          <div class="row">
            <div class="col-sm">
              {$_("ADMIN_ONLY")}
              {$_("PRIVACY_TYPE")}:
              <strong>{$_(courseData?.privacyType)}</strong>
            </div>
          </div>
        {/if}
        {#if courseData?.marketplaceType}<div class="row">
            <div class="col-sm">
              {$_("ADMIN_ONLY")}
              {$_("MARKETPLACE_TYPE")}:
              <strong>{$_(courseData?.marketplaceType)}</strong>
            </div>
          </div>
        {/if}
        {#if copyCourseToUserFlag}
          <div class="row align-items-end mb-2">
            <div class="col-6" />
            <div class="col-sm">
              <label for="copyCourseToEmail">{$_("SPECIFY_TARGET_EMAIL")}</label
              >
              <div class="form-group">
                <input
                  id="copyCourseToEmail"
                  class="form-control"
                  bind:value={copyCourseToUserEmail}
                />
                <button
                  class="btn btn-success s-button-success mt-2"
                  on:click={copyCourseToUserConfirm}>{$_("CONFIRM")}</button
                >
                {#if copyCourseToUserSuccessStatus === "error"}
                  <div class="alert alert-danger" role="alert">
                    {$_("COURSE_SAVING_ERROR")}
                  </div>
                {:else if copyCourseToUserSuccessStatus === "saving"}
                  <div class="alert alert-info" role="alert">
                    {$_("PROCESSING")}
                  </div>
                {:else if copyCourseToUserSuccessStatus === "ok"}
                  <div class="alert alert-success" role="alert">
                    {$_("COPIED")}
                  </div>
                {/if}
              </div>
            </div>
          </div>
        {/if}
      {/if}

      <div class="float-end" style="margin-top: 15px">
        {#if $userDetails?.userRole === "ADMIN"}
          <div class="btn-group" role="group">
            <button
              class="btn btn-info s-button-info s-mr-10px"
              on:click={copyCourseToUser}
            >
              <i class="far fa-copy pr-2" />
              {$_("COPY_COURSE_TO_USER")}
            </button>
          </div>
        {/if}

        {#if $userDetails?.userRole === "ADMIN" || $userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.copyClassToOrganizations === true}
          {#if courseData?.organizationName}
            <div class="btn-group" role="group">
              <button
                class="btn btn-secondary s-button-secondary s-mr-10px"
                on:click={copyCourseToSelectedUser}
              >
                <i class="far fa-copy pr-2" />
                {$_("COPY_COURSE_TO_SELECTED_USER")}
              </button>
            </div>
          {/if}
        {/if}

        {#if $userDetails?.userRole === "ADMIN" || $userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.copyClassToSameDistributorOrg === true || $userDetails?.organizationDistributor === "AMC"}
          <div class="btn-group" role="group">
            <button
              class="btn btn-secondary s-button-secondary s-mr-10px"
              on:click={copyCourseToAnotherOrganizationTeacher}
            >
              <i class="far fa-copy pr-2" />
              {$_("COPY_TO_ANOTHER_ORG")}
            </button>
          </div>
        {/if}
        {#if copyCourseStatus === "loading"}<Loader />
        {:else if copyCourseStatus === "error"}<span
            >{$_("DUPLICATE_COURSE_ERROR")}</span
          >
        {:else if copyCourseStatus === "ok"}<span class="alert alert-success"
            >{$_("DUPLICATE_COURSE_OK")}</span
          >
        {:else}
          <button
            class="btn btn-secondary s-button-secondary s-mr-10px"
            on:click={copyCourse}
          >
            <i class="far fa-copy pr-2" />
            {$_("DUPLICATE_COURSE")}
          </button>
        {/if}
        <div class="btn-group" role="group">
          <button
            class="btn btn-secondary s-button-secondary"
            disabled={"RW" !== $currentCourseRoleCheck?.permissions}
            on:click={editCourseDetail}
          >
            <i class="fa fa-edit" style="padding-right: 8px;" />{$_(
              "EDIT_COURSE_INFO",
            )}</button
          >
        </div>
      </div>
    </div>
  </div>
{/if}
