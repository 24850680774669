<script>
  import { _ } from 'svelte-i18n'
  import { putData } from '../../utils/ApiUtils.svelte'

  import { userDetails } from '../../components/DataStore'
  import Loader from '../../components/Loader.svelte'

  let userProfileStatus = 'ok'
  let errorMessage

  function saveUserProfile() {
    userProfileStatus = 'loading'

    let updateProfileJson = {
      email: $userDetails?.login,
      firstName: $userDetails?.firstName,
      lastName: $userDetails?.lastName,
      schoolName: $userDetails?.schoolName,
    }

    putData('api/user/me', updateProfileJson)
      .then((data) => {
        console.log('login successful', data)
        userProfileStatus = 'updated'
      })
      .catch((error) => {
        console.log('error login', error)
        userProfileStatus = 'error'
        errorMessage = 'Error saving data'
      })
  }
</script>

<div class="mb-3">
  <div class="s-content-header">
    <div class="s-content-header-title">{$_('USER_SETTINGS')}</div>
  </div>

  <div>
    <form>
      {#if $userDetails}
        <div class="form-row">
          <div class="col">
            <div class="form-group s-form-group">
              <label for="userName" class="s-form-field-prompt"
                >{$_('USER_LOGIN')}</label
              >
              <div>{$userDetails.login}</div>
            </div>
          </div>
          <!-- <div class="col">
            <div class="form-group s-form-group">
              <label for="email" class="s-form-field-prompt"
                >{$_('EMAIL_ADDRESS')}</label
              ><input
                id="profileEmail"
                class="form-control s-form-input"
                type="email"
                placeholder="user@example.com"
                name="email"
                bind:value={$userDetails.email}
              />
            </div>
          </div> -->
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group s-form-group">
              <label for="first_name" class="s-form-field-prompt"
                >{$_('FIRST_NAME')}</label
              ><input
                id="profileFirstName"
                class="form-control s-form-input"
                type="text"
                name="first_name"
                bind:value={$userDetails.firstName}
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group s-form-group">
              <label for="last_name" class="s-form-field-prompt"
                >{$_('LAST_NAME')}</label
              ><input
                id="profileLastName"
                class="form-control s-form-input"
                type="text"
                name="last_name"
                bind:value={$userDetails.lastName}
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group s-form-group">
              <label for="school_name" class="s-form-field-prompt"
                >{$_('SCHOOL_NAME')}</label
              >
              {#if $userDetails?.organizationId}
              <strong>{$userDetails.organizationName}</strong>
              {:else}
              <input
                id="profileLastName"
                class="form-control s-form-input"
                type="text"
                name="school_name"
                bind:value={$userDetails.schoolName}
              />{/if}
            </div>
          </div>
          {#if $userDetails?.dsns}
          <div class="col">
            <div class="form-group s-form-group">
              <label for="school_name" class="s-form-field-prompt"
                >{$_('ONE_CAMPUS_DSNS')}</label
              >
              <strong>{$userDetails?.dsns}</strong>
            </div>
          </div>
          {/if}
        </div>
      {:else}
        <Loader />
      {/if}
      {#if userProfileStatus === 'updated'}
        <div class="alert alert-success with-top-margin" role="alert">
          {$_('PROFILE_UPDATED')}
        </div>
      {:else if userProfileStatus === 'loading'}
        <div class="alert alert-info with-top-margin" role="alert">
          {$_('PROCESSING')}
        </div>
      {:else if userProfileStatus === 'error'}
        <div class="alert alert-info with-top-margin" role="alert">
          Error {errorMessage}
        </div>
      {/if}

      <div class="form-group s-form-group">
        <button
          id="buttonSaveProfile"
          class="btn btn-primary s-button-primary"
          style="width: 100%"
          type="button"
          on:click={saveUserProfile}>{$_('SAVE_SETTINGS')}</button
        >
      </div>
    </form>
  </div>
</div>
