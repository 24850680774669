<script>
  import { _, locale } from "svelte-i18n";
  import { router } from "tinro";
  import { onMount } from "svelte";
  import Hoverable from "components/Hoverable.svelte";
  import { createEventDispatcher } from "svelte";
  import { getData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
    currentRecordingStudentName,
    userDetails,
    userConfig,
    trialModalOpen,
  } from "../../components/DataStore";

  import FreTaskSection from "../FirstRunExperience/FreTaskSection.svelte";
  import PaywallModal from "./PaywallModal.svelte";
  import TrialEndModal from "./TrialEndModal.svelte";
  import CourseCardListComponent from "../Course/CourseComponents/CourseCardListComponent.svelte";
  import SurrealLockedModal from "./SurrealLockedModal.svelte";
  import FreTaskSectionFiveSteps from "../FirstRunExperience/FreTaskSectionFiveSteps.svelte";
  import CourseTable from "../Course/CourseComponents/CourseTable.svelte";

  onMount(async () => {
    // getDashboardData()
    dashboardStatus = "ok";
    userDetails.subscribe((x) => {
      subscriptionEnd = new Date(
        $userDetails?.currentSubscriptionExpiration
      ).toLocaleDateString();

      if ($userDetails?.currentSubscriptionExpiration) {
        trialExpiryDays = Math.floor(
          (Date.parse($userDetails?.currentSubscriptionExpiration) -
            Date.parse(new Date())) /
            86400000
        );
      }

      $trialModalOpen = trialExpiryDays < 5;
    });
  });

  let dashboardData = undefined;
  let dashboardCourseTotal = "";
  let dashboardLessonTotal = "";
  let dashboardExerciseTotal = "";
  let dashboardRecordingTotal = "";
  let dashboardStatus = "loading";
  let introVideoOpen = false;
  let subscriptionEnd = "";

  let trialExpiryDays = Math.floor(
    (Date.parse("2022-06-13") - Date.parse(new Date())) / 86400000
  );

  if (
    $userDetails?.currentSubscriptionLevel === "TRIAL_ACTIVE" ||
    $userDetails?.currentSubscriptionLevel === "TRIAL_SCHOOL_ADMIN"
  ) {
    mixpanel.track_links(
      ".mp-purchase-subscription",
      "Teacher Purchase Subscription Button Clicked"
    );
  }

  const dispatch = createEventDispatcher();

  function clickChangePage(e) {
    console.log("clickChangePage", e);

    currentCourseId.update((x) => undefined);
    currentCourseName.update((x) => undefined);
    currentLessonId.update((x) => undefined);
    currentLessonName.update((x) => undefined);
    currentExerciseId.update((x) => undefined);
    currentExerciseName.update((x) => undefined);
    currentRecordingId.update((x) => undefined);
    currentRecordingStudentName.update((x) => undefined);

    currentPage.update((x) => e);

    switch (e) {
      // case 'courses':
      //   mixpanel.track('Teacher Classes Button Clicked', {
      //     'Button Location': 'Dashboard',
      //   })
      //   break
      // case 'students-recording':
      //   mixpanel.track('Teacher All Recordings Button Clicked', {
      //     'Button Location': 'Dashboard',
      //   })
      //   break
      case "courses-edit":
        mixpanel.track("Teacher Create Class Button Clicked", {
          "Button Location": "Dashboard",
        });
        break;
      default:
        break;
    }
    dispatch("changePage", e);
    router.goto("/" + e);
  }

  function createNewCourse() {
    clickChangePage("courses-edit");
  }

  function isExpired($userDetails) {
    let r =
      $userDetails?.currentSubscriptionLevel === "TRIAL_EXPIRED" ||
      $userDetails?.currentSubscriptionLevel === "TRIAL_LOCKED" ||
      $userDetails?.organizationSubscriptionLevel === "TRIAL_EXPIRED" ||
      $userDetails?.organizationSubscriptionType === "TRIAL_EXPIRED" || $userDetails?.accessFlag;
    console.log("isExpired", $userDetails, r);
    return r;
  }
</script>

<!-- {#if $userDetails?.currentSubscriptionLevel === "TRIAL_ACTIVE"}
  <TrialEndModal isOpen={$trialModalOpen} />
{/if} -->

{#key $userDetails}
  {#if isExpired($userDetails)}
    <PaywallModal isOpen={isExpired($userDetails)} />
  {/if}

  {#if $userDetails?.currentSubscriptionLevel === "SURREAL_LOCKED"}
    <SurrealLockedModal
      isOpen={$userDetails?.currentSubscriptionLevel === "SURREAL_LOCKED"}
    />
  {/if}

  
{/key}

<main class="container-fluid px-4 s-container-fluid">
  {#if dashboardStatus === "loading"}
    <Loader />
  {:else}
    <!-- Paywall -->
    {#if $userDetails?.currentSubscriptionLevel === "TRIAL_ACTIVE" || $userDetails?.currentSubscriptionLevel === "TRIAL_SCHOOL_ADMIN" || $userDetails?.currentSubscriptionLevel === "DEMO_ADMIN"}
      <div class="row mt-3">
        <div class="col">
          <div class="alert alert-warning" role="alert">
            {$_("PAYWALL.MSG", {
              values: {
                name: $userDetails.firstName,
                days: trialExpiryDays,
                dayLabel:
                  trialExpiryDays === 1
                    ? $_("PAYWALL.DAY")
                    : $_("PAYWALL.DAYS"),
              },
            })}
            {#if $userDetails?.userRole === "SURREAL"}
              <a
                target="_blank"
                href={`https://www.oksensay.com/surreal-upgrade/?email=${encodeURIComponent(
                  $userDetails?.login
                )}`}
                class="alert-link mp-purchase-subscription"
                >{$_("PAYWALL.PURCHASE_SUBSCRIPTION")}</a
              >
            {:else if $locale == "zh-TW"}
              <a
                id="upgrade"
                class="alert-link mp-purchase-subscription"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSd0H8-oEzd7_hp1uvBqHAeG2uQeUpyq9EbMW2fzi5WkSkHRLg/viewform"
                >{$_("PAYWALL.REQUEST_QUOTE")}</a
              >
            {:else}
              <a
                id="upgrade"
                class="alert-link mp-purchase-subscription"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSd0H8-oEzd7_hp1uvBqHAeG2uQeUpyq9EbMW2fzi5WkSkHRLg/viewform"
                >{$_("PAYWALL.REQUEST_QUOTE")}</a
              >
            {/if}
          </div>
        </div>
      </div>
    {/if}
    {#if $userDetails?.currentSubscriptionLevel === "TRIAL_PAID" && subscriptionEnd}
      <div class="row mt-3">
        <div class="col">
          <div class="alert alert-success" role="alert">
            <i class="fas fa-dollar-sign mr-2" />
            {$_("PAYWALL.THANK_YOU", {
              values: {
                date: subscriptionEnd,
              },
            })}
          </div>
        </div>
      </div>
    {/if}

    {#if $userDetails?.currentSubscriptionLevel !== "TRIAL_EXPIRED" && $userDetails?.currentSubscriptionLevel !== "TRIAL_LOCKED"}
      {#if $userConfig?.serverFlags?.adminOnlyView !== true}
        <div class="s-content-spacing">
          <FreTaskSectionFiveSteps />
        </div>
      {/if}
    {/if}

    <!-- {#if $userDetails?.userRole !== 'SURREAL'}
      {#if $userDetails?.currentSubscriptionLevel}
        {#if !($userDetails?.currentSubscriptionLevel === 'TRIAL_EXPIRED' || $userDetails?.currentSubscriptionLevel === 'TRIAL_LOCKED')}
          
          <div class="s-content-spacing">
            <FreTaskSection />
          </div>
        {/if}
      {/if}
    {/if} -->

    <!-- Dashboard Cards-->
    <!-- <div class="s-content-spacing">
      <div class="row mt-3">
         <div class="col-md-6 col-xl-4 mb-4">
          <Hoverable let:hovering={active}>
            <div
              class="card border-left-primary py-2 clickable"
              class:shadow={!active}
              on:click={() => clickChangePage("courses-edit")}
            >
              <div class="card-body">
                <div class="row align-items-center no-gutters">
                  <div class="col mr-2">
                    <div
                      class="text-uppercase text-primary font-weight-bold text-xs mb-1"
                    >
                      <span>{$_("CREATE_NEW_COURSE")}</span>
                    </div>
                    <div class="text-dark font-weight-bold h5 mb-0">
                      <span id="dashboardCourseTotal" />
                    </div>
                  </div>

                  <div class="col-auto">
                    <i
                      class="fas fa-plus fa-2x text-gray-300"
                      class:text-gray-600={active}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Hoverable>
        </div> 

        <div class="col-md-6 col-xl-4 mb-4">
          <Hoverable let:hovering={active}>
            <div
              class="card border-left-primary py-2 clickable"
              class:shadow={!active}
              on:click={() => clickChangePage("courses")}
            >
              <div class="card-body">
                <div class="row align-items-center no-gutters">
                  <div class="col mr-2">
                    <div
                      class="text-uppercase text-primary font-weight-bold text-xs mb-1"
                    >
                      <span>{$_("COURSES_TOTAL")}</span>
                    </div>
                    <div class="text-dark font-weight-bold h5 mb-0">
                      <span id="dashboardCourseTotal"
                        >{dashboardCourseTotal}</span
                      >
                    </div>
                  </div>
                  <div class="col-auto">
                    <i
                      class="fas fa-school fa-2x text-gray-300"
                      class:text-gray-600={active}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Hoverable>
        </div>

        <div class="col-md-6 col-xl-4 mb-4">
          <Hoverable let:hovering={active}>
            <div
              class="card border-left-secondary py-2 clickable"
              class:shadow={!active}
              on:click={() => clickChangePage("students-recording")}
            >
              <div class="card-body">
                <div class="row align-items-center no-gutters">
                  <div class="col mr-2">
                    <div
                      class="text-uppercase text-secondary font-weight-bold text-xs mb-1"
                    >
                      <span>{$_("RECORDINGS_TOTAL")}</span>
                    </div>
                    <div class="text-dark font-weight-bold h5 mb-0">
                      <span id="dashboardRecordingTotal"
                        >{dashboardRecordingTotal}</span
                      >
                    </div>
                  </div>
                  <div class="col-auto">
                    <i
                      class="fas fa-file-medical-alt fa-2x text-gray-300"
                      class:text-gray-600={active}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Hoverable>
        </div>
      </div>
    </div> -->

    {#if $userConfig?.serverFlags?.adminOnlyView !== true}
      <div class="s-content-spacing">
        <div class="card shadow">
          <div class="card-header py-3">
            <div>
              <button
                class="btn btn-primary s-button-primary"
                on:click={createNewCourse}
              >
                <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
                  "CREATE_NEW_COURSE"
                )}<br />
              </button>
            </div>
          </div>
          <div class="card-body">
            <!-- <CourseCardListComponent /> -->
            <CourseTable />
          </div>
        </div>
      </div>
    {:else}
      <div class="s-content-spacing">
        <div class="card shadow">
          <div class="card-header">
            {$_("ORGANIZATION.WELCOME_MESSAGE")}
          </div>

          <div class="card-body">            
            <p>
              {$_("ORGANIZATION.WELCOME_ADMIN_PORTAL_PART_1")}<a
                href="#/organization-management"
                >{$_("ORGANIZATION.WELCOME_ADMIN_PORTAL_PART_2")}</a
              >{$_("ORGANIZATION.WELCOME_ADMIN_PORTAL_PART_3")}
            </p>
            {#if $userDetails?.organizationSubscriptionType === "SURREAL"}
              <p>
                {$_("ORGANIZATION.WELCOME_MESSAGE_QUESTIONS")}<a
                  href="https://docs.google.com/presentation/d/1DLc6SpvxE9dOJFxCxclncz0xpbpsz4BCoezIui7G8Go"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{$_("ORGANIZATION.WELCOME_VIEW_SOP_LINK")}</a
                >{@html $_("ORGANIZATION.WELCOME_MESSAGE_OR_CONTACT_SURREAL")}
              </p>
            {/if}
            {#if $userDetails?.organizationSubscriptionType === "POWERBOOK"}
              <p>
                {$_("ORGANIZATION.WELCOME_MESSAGE_QUESTIONS")}<a
                  href="https://docs.google.com/presentation/d/1DLc6SpvxE9dOJFxCxclncz0xpbpsz4BCoezIui7G8Go"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{$_("ORGANIZATION.WELCOME_VIEW_SOP_LINK")}</a
                >{@html $_("ORGANIZATION.WELCOME_MESSAGE_OR_CONTACT_POWERBOOK")}
              </p>
            {/if}
          </div>
        </div>
      </div>

      <div class="s-content-spacing">
        <div class="row mt-3">
          <div class="col-md-6 col-xl-4 mb-4">
            <Hoverable let:hovering={active}>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                class="card border-left-primary py-2 clickable"
                class:shadow={!active}
                on:click={() => clickChangePage("organization-management")}
              >
                <div class="card-body">
                  <div class="row align-items-center no-gutters">
                    <div class="col mr-2">
                      <div
                        class="text-uppercase text-primary font-weight-bold text-xs mb-1"
                      >
                        <span>{$_("ORGANIZATION_MANAGEMENT")}</span>
                      </div>
                    </div>
                    <div class="col-auto">
                      <i
                        class="fas fa-school fa-2x text-gray-300"
                        class:text-gray-600={active}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Hoverable>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</main>

<style>
</style>
