<script>
  import { _ } from "svelte-i18n";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { getData, putData, deleteData } from "utils/ApiUtils.svelte";
  import { CSVToArray, arrayIsEmpty } from "utils/FormatUtils.svelte";
  import { dynamicSort } from "utils/SortingUtils.svelte";
  import {
    generateUUID,
    formatNumberWith4Colors,
  } from "utils/FormatUtils.svelte";
  import {
    currentCourseId,
    currentLessonId,

    isOpenSendFeedbackModal,
    userConfig,
    userDetails,
  } from "../../../components/DataStore";
  import Tooltip from "components/Tooltip.svelte";
  import PieIcon from "components/visual/PieIcon.svelte";

  export let courseId;
  export let lessonId;
  export let courseName;

  let studentData = [];
  let studentDataStatus = "loading";
  let addStudentFlag = false;
  let uploadStudentCsvFlag = false;
  let addStudentRosterFlag = false;
  let newStudent = { studentEmail: "" };
  let isValidEmail = true;
  let dropArea = undefined;
  let dropAreaHighlighted = false;
  let importStatus = undefined;
  let importStatusMessage = undefined;
  let studentNameFilter = "";

  let csvTable = [];

  let sortColumn = "idStudent";
  let sortDirection = 1;

  let showDetails = false;

  function changeSort(c, d) {
    sortColumn = c;
    sortDirection = d;

    studentDataStatus = "loading";

    studentData = studentData.sort(dynamicSort(c, d));

    studentDataStatus = "ok";
  }

  onMount(async () => {
    console.log("studentData", studentData);
    dataStatus = "loading";

    getStudentAccountReport();
  });

  let sMap = new Map();
  let sMapSorted = [];

  let sMap2 = new Map();

  let lMap = new Map();
  let eMap = new Map();
  let cGroups = {};
  let eGroups = {};

  let dataStatus = "new";

  function getStudentAccountReport() {

    dataStatus = "loading";

    sMapSorted = [];
    sMap = new Map();
    sMap2 = new Map();
    lMap = new Map();
    eMap = new Map();


    getData(`api/courses/${courseId}/report`)
      .then((data) => {

        data = data.filter((d) => d.idLesson == $currentLessonId)
        
        console.log('getReportData successful', data)

        data.forEach((e) => {
          sMap.set(e.studentAccountId, e.studentAccountName);

          lMap.set(e.idLesson, e.lessonName);
          eMap.set(e.idExercise, e.title);
        });

        [...sMap].forEach((s) => {
          sMapSorted.push({ studentAccountId: s[0], studentAccountName: s[1] });
        });

        if (studentNameFilter) {
            sMapSorted = sMapSorted.filter((s) =>
              s?.studentAccountName
                ?.toLowerCase()
                ?.includes(studentNameFilter?.toLowerCase()),
            );
        }

        sMapSorted = sMapSorted.sort(dynamicSort("studentAccountName", 1));

        [...sMap.keys()].forEach((k) => {
          let sLMap = new Map();
          let progress = 0;
          let avgScore = 0;

          let counterTotal = 0;
          let counterWithRec = 0;
          let sumScore = 0;

          Array.from(eMap.keys()).forEach((exerciseId) => {
            //console.log(lessonId, obj[Number(lessonId)])

            let x = data.filter(
              (v) => v.studentAccountId === k && v.idExercise === exerciseId,
            );

            let LEmap = new Map();

            let lessonCounterTotal = 0;
            let lessonCounterWithRec = 0;
            let lessonSumScore = 0;
            let prBestId

            x.forEach((d) => {
              counterTotal++;
              lessonCounterTotal++;
              if (d?.recordingCount > 0) {
                counterWithRec++;
                lessonCounterWithRec++;
                sumScore += d.maxScore;
                lessonSumScore += d.maxScore;
              }
              prBestId = d.prBestId
            });

            let lessonProgress = 0;
            let lessonAvgScore = 0;

            if (lessonCounterWithRec > 0) {
              lessonProgress = lessonCounterWithRec / lessonCounterTotal;
              lessonAvgScore = lessonSumScore / lessonCounterWithRec;
            }

            sLMap.set(exerciseId, {
              leMap: LEmap,
              lessonProgress: lessonProgress,
              lessonAvgScore: lessonAvgScore,
              prBestId: prBestId,
              prCount: lessonCounterTotal
            });
          });

          if (counterWithRec > 0) {
            progress = counterWithRec / counterTotal;
            avgScore = sumScore / counterWithRec;
          }

          sMap2.set(k, {
            studentAccountName: sMap.get(k),
            exercise: sLMap,
            progress: progress,
            avgScore: avgScore,
          });
        });

        cGroups = data.reduce((r, a) => {
          const k = a.idCourse; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        eGroups = data.reduce((r, a) => {
          const k = a.idExercise; //{ idCourse: a.idCourse, courseName: a.courseName };
          r[k] = r[k] || [];
          r[k].push(a);
          return r;
        }, Object.create(null));

        // sMapSorted = new Map([...sMap2.entries()].sort((a,b) => b['studentAccountName'] - a['studentAccountName']))

        console.log("x", sMap, sMapSorted, sMap2, lMap, eMap, cGroups, eGroups);

        dataStatus = "ok";
      })
      .catch((error) => {
        dataStatus = "error";
        // console.log('error lessons', error)
      });
  }
</script>

<div class="card shadow">
  <div class="card-group">
    {#if dataStatus === "loading"}
      <Loader />
    {:else if dataStatus === "error"}
      {$_("ERROR_LOADING_STUDENT_ACCOUNT_LIST")}
    {:else}
      <div class="card-body">
        <div>
          <div>

            <div class="form-check">
              <label for="studentNameFilterInput" class="form-check-label"
                >{$_("STUDENT_NAME")}</label
              >
              <input
                id="studentNameFilterInput"
                class="form-control s-form-input"
                type="text"
                bind:value={studentNameFilter}
                on:blur={getStudentAccountReport}
              />
            </div>

            <div class="form-check">
              <input
                id="showDetailsCheckbox"
                class="form-check-input"
                type="checkbox"
                bind:checked={showDetails}
              />
              <label for="showDetailsCheckbox" class="form-check-label"
                >{$_("SHOW_DETAILS")}</label
              >
            </div>
          </div>
          <!-- <div>
            {$_("STUDENT_COUNT")}:{studentData?.length} / 30
          </div> -->
          <div
            class="table-responsive table mt-2"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info"
          >
            <table class="table table-hover" id="dataTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{$_("PROGRESS")}</th>
                  <th
                    ><Tooltip title={$_("AVG_BEST_SCORE_TOOLTIP")}>
                      <i class="fa-solid fa-circle-info" />
                    </Tooltip>{$_("AVG_BEST_SCORE")}</th
                  >
                  <th>{$_("STUDENT_NAME")}</th>
                  {#each [...eMap] as [idExercise, e]}
                    <th><a href="#courses/{courseId}/lessons/{lessonId}/exercise-detail/{idExercise}/tabRecording">{e}</a></th>
                  {/each}
                </tr>
              </thead>
              <tbody>
                {#each sMapSorted as x, i}
                  {@const s = sMap2.get(x.studentAccountId)}

                  <!-- {#each [...sMap2] as [studentAccountId, s], i} -->
                  <tr>
                    <th scope="row">
                      {i + 1}
                    </th>
                    <td>
                      {#if s.progress === 1}
                        ✅
                      {:else if s.progress > 0}
                        {#if showDetails}
                          {formatNumberWith4Colors(s.progress * 100)}%
                        {:else}
                          ❓
                        {/if}
                      {:else}❌
                      {/if}
                    </td>

                    <td
                      class:good={s.avgScore >= 60}
                      class:bad={s.avgScore > 0 && s.avgScore < 60}
                    >
                      {#if s.avgScore > 0}{formatNumberWith4Colors(
                          s.avgScore,
                        )}{:else}0{/if}
                    </td>
                    <td>
                      {#if x.studentAccountId}<strong
                          ><a
                            href="/#/student-account-detail/{x.studentAccountId}"
                            >{s?.studentAccountName}</a
                          ></strong
                        >{/if}
                    </td>

                    {#each [...s?.exercise] as [idExercise, e]}
                      <td>
                        {#if e.lessonProgress === 1}
                          <a href="#recording-detail/{e.prBestId}">
                          {#if e.lessonAvgScore > 0}{formatNumberWith4Colors(
                              e.lessonAvgScore,
                            )}{:else}0{/if}</a>{#if showDetails} ({e.prCount}){/if}
                        {:else if e.lessonProgress > 0}
                          {#if showDetails}
                            {#if e.lessonAvgScore > 0}{formatNumberWith4Colors(
                                e.lessonAvgScore,
                              )}{:else}0{/if}
                            ({formatNumberWith4Colors(e.lessonProgress * 100)}%)
                          {:else}
                            ❓
                          {/if}

                          <!-- <PieIcon value={l.lessonProgress * 100}></PieIcon> -->
                        {:else}❌
                        {/if}</td
                      >
                    {/each}
                  </tr>
                {:else}NO_DATA
                {/each}
              </tbody>
              <tfoot>
                <tr />
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .card {
    overflow-x: auto;
  }

  .done {
    background-color: green;
    color: aliceblue;
  }

  .good {
    color: darkgreen;
    font-weight: 700;
  }

  .bad {
    color: red;
    font-weight: 700;
  }
</style>
