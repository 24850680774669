<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { onMount } from "svelte";
  import Loader from "../../components/Loader.svelte";
  import { postData } from "utils/ApiUtils.svelte";

  onMount(async () => {
    addContentPack();
  });

  export let contentPackId;

  let requestContentStatus = "";

  function addContentPack() {
    requestContentStatus = "saving";

    let payloadData = {
      courseId: contentPackId,
    };

    console.log("request content-pack", payloadData);

    postData("api/courses/content-pack-request", payloadData)
      .then((data) => {
        console.log("courses successful", data);
        requestContentStatus = "saved";
      })
      .catch((error) => {
        console.log("error courses", error);
        requestContentStatus = "error";
      });
  }
</script>

<div class="container-fluid">
  <div class="d-sm-flex justify-content-between align-items-center mb-4">
    <h3 class="text-dark mb-0">{$_("DASHBOARD")}</h3>
  </div>
  {#if requestContentStatus === "loading"}
    <Loader />
  {:else if requestContentStatus === "error"}
    Error processing request
  {:else}
    <div class="row">
      <div class="col-6 mt-3">
        <h5>Your content pack was added to your course list.</h5>
      </div>
    </div>
  {/if}
</div>

<style>
</style>
