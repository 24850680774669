<script>
    import LeftMenu from "../components/LeftMenu.svelte";
    import TopMenu from "../components/TopMenu.svelte";
    import Footer from "../components/Footer.svelte";
    import Overview from "./IndexPage/IndexOverview.svelte";
</script>

<div id="wrapper">
    <LeftMenu />
    <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
            <TopMenu />
            <h1>Activity Log</h1>
        </div>
        <Footer />
    </div>
</div>
