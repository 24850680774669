<script>
  import ChevronCaret from './visual/ChevronCaret.svelte'
  import ChevronDown from './visual/ChevronDown.svelte'
  import ChevronUp from './visual/ChevronUp.svelte'

  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let column
  export let sortColumn
  export let sortDirection

  function changeSort(newSortDirection) {
    console.log('changeSort', column, newSortDirection)
    dispatch('changeSort', {
      sortColumn: column,
      sortDirection: newSortDirection,
    })
  }
</script>

<slot />
{#if column === sortColumn}
  {#if sortDirection > 0}
    <span on:click={() => changeSort(-1)}><ChevronUp /></span>
  {:else}<span on:click={() => changeSort(1)}><ChevronDown /></span>{/if}
{:else}
  <span on:click={() => changeSort(1)}><ChevronCaret /></span>
{/if}

<style>
  span {
    cursor: pointer;
  }
</style>
