<script>
  import { createEventDispatcher, onMount } from 'svelte'
  import { _ } from 'svelte-i18n'

  import { userDetails, userConfig } from '../../../components/DataStore'
  import TextAreaAutoresize from '../../../components/TextAreaAutoresize.svelte'

  const dispatch = createEventDispatcher()
  export let questionScoring
  export let errors
  export let allowAnswer = true



  function skipEnterOnInput() {
    dispatch('skipEnterOnInput')
  }
</script>

{#if $userDetails?.userRole === 'ADMIN' || $userConfig?.serverFlags?.questionScoring === true}
  <div class="form-group form-check">
    <input
      type="checkbox"
      class="form-check-input"
      id="questionScoringEnabled"
      bind:checked={questionScoring.enabled}
    />
    <label class="form-check-label" for="questionScoringEnabled"
      >{$_('SCORING.ADVANCED_SCORING')}</label
    >
  </div>

  {#if questionScoring.enabled}
    <div class="mb-3 p-2" style="background-color:beige;">
      <div class="form-group">
        <label for="scoringMinWords">{$_('SCORING.MIN_WORDS')}</label>
        <div>
          <small>{$_('SCORING.MIN_WORDS_PROMPT')}</small>
        </div>
        <input
          id="scoringMinWords"
          class="form-control"
          style="width: 10em;"
          class:validation-input-box-error={errors.scoringMinWords}
          type="text"
          bind:value={questionScoring.minWords}
          on:keydown={skipEnterOnInput}
        />
      </div>
      <div class="form-group">
        <label for="scoringMinDurationSec">{$_('SCORING.MIN_DURATION')}</label>
        <div>
          <small>{$_('SCORING.MIN_DURATION_PROMPT')}</small>
        </div>
        <input
          id="scoringMinDurationSec"
          class="form-control"
          style="width: 10em;"
          class:validation-input-box-error={errors.minDurationSec}
          type="text"
          bind:value={questionScoring.minDurationSec}
          on:keydown={skipEnterOnInput}
        />
      </div>

      <div class="form-group">
        <label for="scoringContainWords">{$_('SCORING.CONTAIN_WORDS')}</label>
        <div>
          <small>{$_('SCORING.CONTAIN_WORDS_PROMPT')}</small>
        </div>
        <input
          id="scoringContainWords"
          class="form-control"
          class:validation-input-box-error={errors.containWords || errors.eitherOne}
          type="text"
          placeholder={$_('SCORING.CONTAIN_WORDS_PLACEHOLDER')}
          bind:value={questionScoring.containWords}
          on:keydown={skipEnterOnInput}
        />
      </div>

      {#if allowAnswer}
        <div class="form-group">
          <label for="scoringContainWords">{$_('SCORING.MATCH_TEXT')}</label>
          <div>
            <small>{$_('SCORING.MATCH_TEXT_PROMPT')}</small>
          </div>
          <TextAreaAutoresize
            id="exerciseQuestion"
            error={errors.answer || errors.eitherOne}
            bind:value={questionScoring.answer}
            placeholder={$_('EXERCISE_.EXPECTED_ANSWER_PLACEHOLDER')}
          />
        </div>
      {/if}

      <div class="validation-text-error">
        {#if errors.eitherOne}
          {$_(errors.eitherOne)}
        {/if}
      </div>
    </div>
  {/if}
{/if}
