<script>
  import Loader from "../../../components/Loader.svelte";
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import {
    getData,
    postData,
    deleteData,
  } from "../../../utils/ApiUtils.svelte";
  import { userConfig } from "../../../components/DataStore";
  import DialogImportFileToStudentRoster from "./DialogImportFileToStudentRoster.svelte";
  import IconCsv from "../../../components/icons/IconCsv.svelte";
  import TableHeaderItem from "../../../components/TableHeaderItem.svelte";
  import { dynamicSortLocale } from "utils/SortingUtils.svelte";

  export let studentRosterId;
  let addStudentFlag = false;
  let addStudentEmail = "";
  let isValidEmail = true;

  let sortColumn = "studentAccountLogin";
  let sortDirection = 1;

  onMount(async () => {
    if (studentRosterId) {
      loadStudentRosterData(studentRosterId);
    }
  });

  let studentEnrollmentStatus = "new";
  let studentEnrollmentData = [];

  let importStatus;
  let importStatusMessage;

  let dialogImportFileToStudentRoster;

  function changeSort(e) {
    console.log(111, e.detail);
    sortColumn = e.detail.sortColumn;
    sortDirection = e.detail.sortDirection;

    // getStudentList();

    studentEnrollmentStatus = "loading";

    studentEnrollmentData = studentEnrollmentData.sort(
      dynamicSortLocale(sortColumn, sortDirection),
    );

    studentEnrollmentStatus = "ok";
  }

  function loadStudentRosterData(srId) {
    //
    studentRosterId = srId;
    studentEnrollmentStatus = "loading";

    getData(
      `api/student-roster/${studentRosterId}/student-account`,
      false,
      false,
    )
      .then((data) => {
        console.log("student-roster successful", data);
        // Save data to sessionStorage

        data.forEach((d) => {
          d["login"] = d?.studentAccountId
            ? d?.studentAccountLogin
            : d?.pendingInvitationEmail;
        });

        studentEnrollmentData = data;

        studentEnrollmentStatus = "ok";
      })
      .catch((error) => {
        console.log("error student-roster", error);
      });
  }

  async function addStudentSave() {
    let emailList = addStudentEmail.split(/[ ,]+/).filter((e) => {
      return validateEmail(e) != null;
    });

    console.log("emailList", emailList);

    if (emailList.length > 0) {
      emailList.forEach(async (e) => {
        await addStudentByEmail(e);
      });
    } else {
      isValidEmail = false;
    }
  }

  async function addStudentByEmail(email) {
    await postData(
      `api/student-roster/${studentRosterId}/student-account?studentAccountLogin=${encodeURIComponent(
        email,
      )}`,
      {},
      false,
    )
      .then((data) => {
        console.log("successful", data);

        addStudentEmail = "";
        addStudentFlag = false;
        loadStudentRosterData(studentRosterId);
      })
      .catch((error) => {
        console.log("error courses", error);
        importStatus = "error";
        importStatusMessage = error?.message;
      });
  }

  function deleteStudent(studentEnrollmentId) {
    deleteData(
      `api/student-roster/${studentRosterId}/student-account/${studentEnrollmentId}`,
      {},
    )
      .then((data) => {
        console.log("successful", data);
        loadStudentRosterData(studentRosterId);
      })
      .catch((error) => {
        console.log("error courses", error);
        studentEnrollmentStatus = "error";
      });
  }

  function addStudent() {
    addStudentFlag = true;
    addStudentEmail = "";
  }

  function cancelAddStudentEdit() {
    addStudentFlag = false;
    addStudentEmail = "";
  }

  function validateEmail(value) {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }

  let selectActive = false;
  let selectionSet = new Set();

  function changeSelectCheckbox() {
    if (selectActive) {
      selectNone();
    } else {
      selectActive = true;
      document.getElementById("flexCheckDefault").indeterminate = true;
    }
  }

  function changeSelect(vId) {
    if (selectionSet.has(vId)) {
      selectionSet.delete(vId);
    } else {
      selectionSet.add(vId);
    }
    selectActive = true;

    if (selectionSet.size === studentEnrollmentData.length) {
      document.getElementById("flexCheckDefault").indeterminate = false;
    } else if (selectionSet.size === 0) {
      document.getElementById("flexCheckDefault").indeterminate = true;
    } else {
      document.getElementById("flexCheckDefault").indeterminate = true;
    }
  }

  function selectNone() {
    selectionSet.clear();
    selectActive = false;
    document.getElementById("flexCheckDefault").indeterminate = false;
  }

  function selectAll() {
    selectionSet = new Set(studentEnrollmentData.map((s) => s.id));
    selectActive = true;
    document.getElementById("flexCheckDefault").indeterminate = false;
  }

  let okCount = 0;
  let errorCount = 0;

  async function massDelete() {
    console.log("111", selectionSet);
    if (
      confirm(
        $_("STUDENT_ROSTER.REMOVE_ALL_SELECTED_STUDENTS", {
          values: { count: selectionSet.size },
        }),
      )
    ) {
      // Save it!
      studentEnrollmentStatus = "loading";
      okCount = 0;
      errorCount = 0;

      let parts = [...selectionSet];
      for (let i = 0; i < parts.length; i++) {
        let e = parts[i];

        await deleteData(
          `api/student-roster/${studentRosterId}/student-account/${e}`,
          {},
        )
          .then((data) => {
            console.log("successful", data);
            okCount++;
          })
          .catch((error) => {
            console.log("error courses", error);
            errorCount++;
            studentEnrollmentStatus = "error";
          });
      }

      loadStudentRosterData(studentRosterId);
      studentEnrollmentStatus = "loading";

      console.log("remove-all summary", okCount, errorCount);
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  }
</script>

<DialogImportFileToStudentRoster
  bind:this={dialogImportFileToStudentRoster}
  {studentRosterId}
  on:importDone={() => {
    loadStudentRosterData(studentRosterId);
  }}
></DialogImportFileToStudentRoster>

<div class="card shadow mt-4">
  <div class="card-header">
    {#if $userConfig?.serverFlags?.adminStudentRoster === true}
      {#if !addStudentFlag}
        <button
          class="btn btn-primary s-button-primary me-2"
          on:click={addStudent}>{$_("STUDENT_ROSTER.ADD_STUDENT")}</button
        >
        <button
          class="btn btn-primary s-button-primary me-2"
          on:click={() => {
            dialogImportFileToStudentRoster.openDialog();
          }}
        >
          <IconCsv></IconCsv>
          {$_("STUDENT_ROSTER.IMPORT_FILE")}</button
        >
      {/if}
      {#if selectActive}
        <button
          class="btn btn-danger s-button-danger me-2"
          on:click={massDelete}
          >⌦ {$_("STUDENT_ROSTER.MASS_DELETE_STUDENTS")}</button
        >
      {/if}
    {/if}

    {#if addStudentFlag}
      <div>
        <div class="form-row">
          <div class="col-lg-12 offset-lg-0">
            <label
              for="courseEditEmail"
              style="padding-right: 12px;padding-top: 6px;"
              >{$_("STUDENT_EMAIL")}</label
            >
            <input
              id="courseEditEmail"
              class="form-control"
              class:is-invalid={!isValidEmail}
              type="text"
              placeholder={$_("STUDENT_EMAIL")}
              bind:value={addStudentEmail}
            />
            <div class="invalid-feedback">
              {$_("EMAIL_ADDRESS_INVALID_FEEDBACK")}
            </div>
          </div>
        </div>

        <div class="form-row mt-3">
          <button
            class="btn btn-secondary s-button-secondary s-mr-10px"
            on:click={cancelAddStudentEdit}
          >
            <i class="far fa-window-close" style="padding-right: 8px;" />{$_(
              "CANCEL",
            )}
          </button>

          <button
            class="btn btn-primary s-button-primary"
            disabled={addStudentEmail === ""}
            on:click|preventDefault={(x) => {
              addStudentSave();
            }}
          >
            <i class="fa fa-save" style="padding-right: 8px;" />{$_(
              "INVITE_STUDENT",
            )}
            <br />
          </button>
        </div>
      </div>
    {/if}

    {#if importStatus === "error"}
      <div>
        <div class="alert alert-danger">
          {#if importStatusMessage === "STUDENT_ACCOUNT_LIMIT_REACHED"}
            {$_("STUDENT_ACCOUNT.STUDENT_ACCOUNT_LIMIT_REACHED")}
          {:else if importStatusMessage === "STUDENT_ACCOUNT_ALREADY_ADDED"}
            {$_("STUDENT_ACCOUNT.STUDENT_ACCOUNT_ALREADY_ADDED")}
          {:else}
            {$_("STUDENT_ACCOUNT.IMPORT_ERROR")}
          {/if}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <span
            type="button"
            class="close"
            on:click={() => {
              importStatus = undefined;
            }}
          >
            <span>&times;</span>
          </span>
        </div>
      </div>
    {:else if importStatus === "ok"}
      <div>
        <div class="alert alert-success">
          {$_("STUDENT_ACCOUNT.IMPORT_SUCCESS")}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <span
            type="button"
            class="close"
            on:click={() => {
              importStatus = undefined;
            }}
          >
            <span>&times;</span>
          </span>
        </div>
      </div>
    {/if}
  </div>
  <div class="card-body">
    {#if studentEnrollmentStatus === "loading"}
      <Loader />
    {:else if studentEnrollmentStatus === "ok"}
      {#if studentEnrollmentData?.length > 0}
        <div
          class="table table mt-2"
          id="dataTable"
          role="grid"
          aria-describedby="dataTable_info"
        >
          <table class="table my-0 table-hover" id="dataTable">
            <thead>
              <tr>
                <th scope="col"
                  ><div class="btn-group">
                    <button type="button" class="btn btn-light">
                      <input
                        on:click={changeSelectCheckbox}
                        class="form-check-input"
                        type="checkbox"
                        bind:checked={selectActive}
                        id="flexCheckDefault"
                      />
                    </button><button
                      type="button"
                      class="btn btn-light dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span class="visually-hidden">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a on:click={selectNone} class="dropdown-item"
                          >{$_("CLEAR")}</a
                        >
                      </li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      <li>
                        <a class="dropdown-item" on:click={selectAll}
                          >{$_("SELECT_ALL")}</a
                        >
                      </li>
                    </ul>
                  </div></th
                >
                <!-- <th scope="col">{$_('STUDENT_ROSTER.CODE')}</th> -->
                <th scope="col">
                  <TableHeaderItem
                    column="login"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                  >
                    {$_("STUDENT_ROSTER.STUDENT_ACCOUNT")}</TableHeaderItem
                  ></th
                >
                <th scope="col">
                  <TableHeaderItem
                    column="studentAccountFirstName"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                  >
                    {$_("STUDENT_ROSTER.STUDENT_NAME")}</TableHeaderItem
                  ></th
                >
                <th scope="col">
                  <TableHeaderItem
                    column="state"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                    >{$_("STUDENT_ROSTER.STATE")}</TableHeaderItem
                  ></th
                >
                <th scope="col"> {$_("STUDENT_ROSTER.ACTION")}</th>
              </tr>
            </thead>
            <tbody>
              {#if studentEnrollmentData?.length > 0}
                {#each studentEnrollmentData as subRow, i}
                  <tr>
                    <th scope="row">
                      {#if selectActive}
                        <input
                          class="form-check-input me-2"
                          type="checkbox"
                          checked={selectionSet.has(subRow.id)}
                          on:click={() => changeSelect(subRow.id)}
                        />
                      {/if}
                      {i + 1}</th
                    >
                    <!-- <td
                      >{#if subRow.studentRosterCode}{subRow.studentRosterCode} -
                      {/if}{#if subRow.studentRosterName}{subRow.studentRosterName}{/if}</td
                    > -->
                    <td>
                      {#if subRow.state === "ACTIVE"}
                        <span class="debug"
                          >{subRow.studentAccountId} -
                        </span>{#if subRow?.studentAccountId}<a
                            href="/#/student-account-detail/{subRow?.studentAccountId}"
                            >{subRow?.studentAccountLogin}</a
                          >{/if}
                      {:else}{subRow.pendingInvitationEmail}
                      {/if}</td
                    >
                    <td>
                      {#if subRow.state === "ACTIVE"}
                        <span
                          >{subRow.studentAccountFirstName}
                          {subRow.studentAccountLastName}
                        </span>
                      {/if}
                    </td>
                    <td>
                      {#if subRow?.state === "PENDING"}<i
                          class="fas fa-user-clock me-2"
                        />{:else if subRow?.state === "ACTIVE"}
                        <i class="fas fa-user-check me-2" />{/if}{$_(
                        `ORGANIZATION.${subRow?.state}`,
                      )}
                    </td>
                    <td
                      >{#if $userConfig?.serverFlags?.adminStudentRoster === true}
                        <button
                          class="btn btn-danger s-button-danger"
                          disabled={selectActive}
                          title={$_("DELETE")}
                          on:click={() => deleteStudent(subRow.id)}>⌦</button
                        >
                      {/if}</td
                    >
                  </tr>
                {/each}
              {/if}
            </tbody>
            <tfoot>
              <tr />
            </tfoot>
          </table>
        </div>
      {:else}
        <div class="row">
          <svg
            class="text-center"
            width="201"
            height="200"
            viewBox="0 0 201 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M102.5 89.3701C99.7311 89.3701 97.0243 88.549 94.722 87.0107C92.4197 85.4724 90.6253 83.2859 89.5657 80.7277C88.5061 78.1695 88.2288 75.3546 88.769 72.6389C89.3092 69.9231 90.6426 67.4286 92.6005 65.4706C94.5584 63.5127 97.053 62.1793 99.7687 61.6391C102.484 61.0989 105.299 61.3762 107.858 62.4358C110.416 63.4954 112.602 65.2899 114.141 67.5921C115.679 69.8944 116.5 72.6012 116.5 75.3701C116.484 79.0783 115.004 82.6301 112.382 85.2522C109.76 87.8743 106.208 89.3543 102.5 89.3701Z"
              fill="#DCDCDC"
            />
            <path
              d="M119.59 115.08L151.95 74.8998L139.36 64.8198L115.02 93.9998H85.39C82.5024 94.013 79.7371 95.1673 77.6971 97.2111C75.6571 99.2548 74.5079 102.022 74.5 104.91V117.34H119.58L119.59 115.08Z"
              fill="#DCDCDC"
            />
            <path
              d="M103.61 87.5698C100.841 87.5698 98.1347 86.7487 95.8324 85.2104C93.5301 83.6721 91.7357 81.4856 90.676 78.9274C89.6164 76.3692 89.3392 73.5543 89.8794 70.8386C90.4196 68.1228 91.7529 65.6283 93.7109 63.6703C95.6688 61.7124 98.1634 60.379 100.879 59.8388C103.595 59.2986 106.41 59.5759 108.968 60.6355C111.526 61.6951 113.713 63.4896 115.251 65.7918C116.789 68.0941 117.61 70.8009 117.61 73.5698C117.595 77.278 116.115 80.8298 113.492 83.4519C110.87 86.074 107.319 87.554 103.61 87.5698ZM103.61 65.7998C102.071 65.7979 100.566 66.2525 99.2853 67.1061C98.0046 67.9598 97.0058 69.1742 96.4155 70.5956C95.8251 72.0171 95.6696 73.5817 95.9686 75.0915C96.2677 76.6013 97.0079 77.9885 98.0955 79.0776C99.1832 80.1666 100.569 80.9086 102.079 81.2096C103.588 81.5106 105.153 81.3571 106.575 80.7686C107.998 80.18 109.213 79.1828 110.068 77.9032C110.924 76.6236 111.38 75.119 111.38 73.5798C111.372 71.5206 110.552 69.5478 109.096 68.0908C107.641 66.6337 105.67 65.8104 103.61 65.7998Z"
              fill="#707070"
            />
            <path
              d="M120.71 113.27L153.07 73.09L140.5 63L116.14 92.23H86.4996C83.6181 92.2432 80.8583 93.3937 78.8208 95.4312C76.7832 97.4688 75.6327 100.228 75.6196 103.11V115.54H66.3096V121.76H72.4996V152.85H78.7196V121.76H122.24V152.85H128.5V121.76H134.72V115.54H120.72L120.71 113.27ZM92.7096 115.54V107.77H86.4996V115.54H81.8496V103.11C81.8548 101.877 82.3461 100.697 83.2167 99.8242C84.0873 98.9517 85.267 98.4579 86.4996 98.45H119.05L141.34 71.69L144.34 74.06L114.53 111.06V115.52L92.7096 115.54Z"
              fill="#707070"
            />
            <path
              d="M164.23 121.76H150.23V115.54H158V53.3699H42.9995V115.54H50.7695V121.76H36.7695V47.1499H164.23V121.76Z"
              fill="#707070"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M53.8701 64.25H75.6301V70.47H53.8701V64.25Z"
              fill="#707070"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M53.8701 79.79H67.8701V86H53.8701V79.79Z"
              fill="#707070"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M53.8701 95.3398H67.8701V101.55H53.8701V95.3398Z"
              fill="#707070"
            />
          </svg>
          <h3 class="text-center">
            {$_("STUDENT_ROSTER.TABLE_EMPTY_PROMPT_NEW")}
          </h3>
          <p style="padding-left:15%; padding-right:15%;">
            {$_("STUDENT_ROSTER.TABLE_EMPTY_PROMPT_EXISTING")}
          </p>
        </div>
      {/if}
    {:else if studentEnrollmentStatus === "ERROR_NO_ORGANIZATION"}
      <div class="alert alert-danger" role="alert">
        {$_("ORGANIZATION.ERROR_NO_ORGANIZATION")}
      </div>
    {:else if studentEnrollmentStatus === "ERROR_MORE_THAN_ONE_ORGANIZATION"}
      <div class="alert alert-danger" role="alert">
        {$_("ORGANIZATION.ERROR_MORE_THAN_ONE_ORGANIZATION")}
      </div>
    {:else}
      {$_("STUDENT_ROSTER.ERROR")} - {studentEnrollmentStatus}
    {/if}
  </div>
</div>

<style>
  .debug {
    color: grey;
    display: none;
  }
</style>
