<script>
  import { _ } from 'svelte-i18n'
  import ShareModalContent from './ShareModalContent.svelte'

  export let pin = ''
  export let id
  export let shareModalShow = false
  export let asButton = false
  export let shareType = 'EXERCISE'
  export let isEnabled = true
  export let isActive = true

  function showShareModal() {
    if (shareType === 'LESSON') {
      mixpanel.track('Teacher Share Lesson Button Clicked', { 'Lesson ID': id })
    } else {
      mixpanel.track('Teacher Share Exercise Button Clicked', {
        'Exercise ID': id,
      })
    }
    shareModalShow = true
  }
</script>

{#if shareModalShow}
<ShareModalContent {pin} {shareType} {isActive} bind:shareModalShow />
{/if}
<button
  class="btn btn-primary s-button-primary s-ml-10px"
  class:btn-link={!asButton}
  class:btn-info={asButton}
  class:disabled={!isEnabled}
  on:click={showShareModal}
>
  {#if shareType === 'LESSON'}
  <svg class="svg-inline--fa fa-share-from-square" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="share-from-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M568.9 143.5l-150.9-138.2C404.8-6.773 384 3.039 384 21.84V96C241.2 97.63 128 126.1 128 260.6c0 54.3 35.2 108.1 74.08 136.2c12.14 8.781 29.42-2.238 24.94-16.46C186.7 252.2 256 224 384 223.1v74.2c0 18.82 20.84 28.59 34.02 16.51l150.9-138.2C578.4 167.8 578.4 152.2 568.9 143.5zM416 384c-17.67 0-32 14.33-32 32v31.1l-320-.0013V128h32c17.67 0 32-14.32 32-32S113.7 64 96 64H64C28.65 64 0 92.65 0 128v319.1c0 35.34 28.65 64 64 64l320-.0013c35.35 0 64-28.66 64-64V416C448 398.3 433.7 384 416 384z"></path></svg>
  {:else}
  <svg class="svg-inline--fa fa-share-from-square" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="share-from-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M568.9 143.5l-150.9-138.2C404.8-6.773 384 3.039 384 21.84V96C241.2 97.63 128 126.1 128 260.6c0 54.3 35.2 108.1 74.08 136.2c12.14 8.781 29.42-2.238 24.94-16.46C186.7 252.2 256 224 384 223.1v74.2c0 18.82 20.84 28.59 34.02 16.51l150.9-138.2C578.4 167.8 578.4 152.2 568.9 143.5zM416 384c-17.67 0-32 14.33-32 32v31.1l-320-.0013V128h32c17.67 0 32-14.32 32-32S113.7 64 96 64H64C28.65 64 0 92.65 0 128v319.1c0 35.34 28.65 64 64 64l320-.0013c35.35 0 64-28.66 64-64V416C448 398.3 433.7 384 416 384z"></path></svg>
  {/if}
  {#if asButton}
    <span class="ml-1">
      {#if shareType === 'LESSON'}
        {$_('SHARE_LESSON_WITH_STUDENTS')}
      {:else}
        {$_('SHARE_EXERCISE_WITH_STUDENTS')}
      {/if}
    </span>{/if}
</button>
