<script>
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { router } from "tinro";
    import Breadcrumb from "components/Breadcrumb.svelte";
    import {
        currentCourseId,
        currentCourseName,
        userConfig,
        userDetails,
    } from "../../../components/DataStore";
    import Loader from "components/Loader.svelte";
    import { getData, postData, deleteData } from "utils/ApiUtils.svelte";

    export let courseId;
    export let organizationId;

    let userRolesStatus = "new";
    let userRolesData;

    let newStudent = {};

    let userList = [];
    let selectedUserId;
    let selectedUserLogin;
    let loginSuggestion = "";

    let editPermissions = "";
    let editRole = "T";

    let addStudentFlag = false;
    let addStudentEmail = "";

    onMount(async () => {
        // eslint-disable-next-line no-unused-vars
        loadData();
    });

    function loadData() {
        userRolesStatus = "loading";
        getData(`api/courses/${courseId}/user-roles`)
            .then((data) => {
                console.log("exercises successful", data);
                userRolesData = data;

                userRolesStatus = "ok";
            })
            .catch((error) => {
                console.log("error exercises", error);
                userRolesStatus = "error";
                throw new Error("XYX");
            });

        getData("api/organization/all-teachers")
            .then((data) => {
                console.log(321, data);
                userList = data.filter((t) => t?.state === "ACTIVE");
            })
            .catch((error) => {
                console.log("error teachers", error);
            });
    }

    function addUserRole() {
        let payload = {
            courseId: courseId,
            role: editRole,
            permissions: editPermissions,
            userId: selectedUserId,
        };
        addStudentFlag = false;
        postData(`api/courses/${courseId}/user-roles`, payload)
            .then((data) => {
                console.log("exercises successful", data);
                loadData();
            })
            .catch((error) => {
                console.log("error exercises", error);
                userRolesStatus = "error";
                throw new Error("XYX");
            });
    }

    function deleteUserRole(userRoleId) {
        return deleteData(`api/courses/${courseId}/user-roles/${userRoleId}`)
            .then((data) => {
                console.log("courses successful", data);
                loadData();
            })
            .catch((error) => {
                console.log("error courses", error);
                userRolesStatus = "error";
            });
    }

    function addTeacherButton() {
        addStudentFlag = !addStudentFlag;
        editPermissions = "R";
        editRole = "T";
        selectedUserId = undefined;
    }
</script>

<div class="card shadow">
    
        <div class="card-header py-3">
            <div class="mb-2">
                <button
                    class="btn btn-primary s-button-primary"
                    on:click={addTeacherButton}>{$_("COOP.ADD_TEACHER")}</button
                >
                {#if addStudentFlag}
                    <select
                        class="form-select"
                        bind:value={selectedUserId}
                        aria-label="multiple select example"
                    >
                        {#each userList as t}
                            <option value={t.teacherUserId}
                                >{t.teacherFirstName}
                                {t.teacherLastName} ({t.teacherEmail})</option
                            >
                        {:else}<option
                                >{$_(
                                    "NO_OTHER_TEACHER_IN_ORGANIZATION",
                                )}</option
                            >
                        {/each}
                    </select>

                    <div>
                        <label for="cc-role" class="form-label"
                            >{$_("ROLE")}</label
                        >
                        <select class="form-select" bind:value={editRole}>
                            <option value="T" selected>Teacher</option>
                        </select>
                    </div>
                    <div>
                        <label for="cc-role" class="form-label"
                            >{$_("PERMISSIONS")}</label
                        >
                        <select
                            class="form-select"
                            bind:value={editPermissions}
                        >
                            <option value="R">{$_("COOP_PERMISSIONS.R")}</option>
                            <option value="RW">{$_("COOP_PERMISSIONS.RW")}</option>
                        </select>
                    </div>
                    <div class="mt-2">
                        <button
                            class="btn btn-primary s-button-primary"
                            on:click={addUserRole}>{$_("SAVE")}</button
                        >
                    </div>
                {/if}
            </div>
        </div>


    <div class="card-group">
        {#if userRolesStatus === "error"}ERROR{:else if userRolesStatus === "loading"}<Loader
            />{:else}
            <div
                class="table table mt-2"
                id="dataTable"
                role="grid"
                aria-describedby="dataTable_info"
            >
                <table class="table my-0 table-hover" id="dataTable">
                    <thead>
                        <tr
                            ><th scope="col">{$_("ID")}</th><th scope="col"
                                >{$_("ORGANIZATION.TEACHER_EMAIL")}</th
                            ><th scope="col"
                            >{$_("ORGANIZATION.TEACHER")}</th
                        ><th scope="col">{$_("COOP.ROLE")}</th><th scope="col"
                                >{$_("COOP.PERMISSIONS")}</th
                            ><th>{$_("ACTIONS")}</th></tr
                        ></thead
                    >
                    <tbody>
                        {#if userRolesData?.length > 0}
                            {#each userRolesData as subRow, i}
                                <tr>
                                    <th scope="row">#{i + 1}</th>
                                    <td
                                        ><a
                                            href="/#/teacher-account/{subRow.userId}"
                                        >
                                            {subRow.userLogin}</a
                                        ></td
                                    >
                                    <td> {subRow.userFirstName || ""} {subRow.userLastName || ""}</td>
                                    <td> {$_(`COOP_ROLE.${subRow.role}`)}</td>
                                    <td>
                                        {$_(
                                            `COOP_PERMISSIONS.${subRow.permissions}`,
                                        )}</td
                                    >
                                    <td
                                        >
                                        {#if subRow.role === "T"}<button
                                            class="btn btn-danger"
                                            on:click={() =>
                                                deleteUserRole(subRow.id)}
                                            >{$_("DELETE")}</button
                                        >{/if}
                                    </td>
                                </tr>
                            {/each}
                        {/if}
                    </tbody>
                </table>
            </div>
        {/if}
    </div>
</div>

<style>
    #drop-area {
        border: 2px dashed #ccc;
        border-radius: 20px;
        width: 480px;
        margin: 50px auto;
        padding: 20px;
    }
    #drop-area.highlight {
        border-color: purple;
    }
    p {
        margin-top: 0;
    }
    .my-form {
        margin-bottom: 10px;
    }
    #gallery {
        margin-top: 10px;
    }
    .button {
        display: inline-block;
        padding: 10px;
        background: #ccc;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
    .button:hover {
        background: #ddd;
    }
    #fileElem {
        display: none;
    }
    .close {
        padding-left: 0.75rem;
    }
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
