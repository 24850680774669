<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import {
    currentPage,
    currentCourseId,
    currentLessonId,
    currentExerciseId,
    currentCourseRoleCheck
  } from '../../../components/DataStore'
  import { createEventDispatcher } from 'svelte'

  // export let publishHandler = (id) => {}
  export let deleteHandler = (id) => {}
  export let deleteRecordingsHandler = (id) => {}
  export let moveToOtherLessonHandler = (id) => {}
  export let id
  export let exerciseDetail

  const dispatch = createEventDispatcher()

  function hideDropdown() {
    let dropdownId = '#dropdownMenu' + id
    bootstrap.Dropdown.getInstance(dropdownId).hide()
  }

  function openExercise() {
    console.log('openExercise', id)
    currentExerciseId.update((x) => id)

    clickChangePage(
      'courses/' +
        $currentCourseId +
        '/lessons/' +
        $currentLessonId +
        '/exercise-detail/' +
        $currentExerciseId
    )
  }

  function editExerciseDetail() {
    console.log('editExerciseDetail', $currentCourseId, $currentLessonId)
    currentExerciseId.update((x) => id)

    clickChangePage(
      'courses/' +
        $currentCourseId +
        '/lessons/' +
        $currentLessonId +
        '/exercise-edit/' +
        $currentExerciseId
    )
  }

  // function publishExercise() {
  //   if (
  //     confirm(
  //       "Are you sure you want to publish the exercise? It won't be possible to edit once there is some recording."
  //     )
  //   ) {
  //     publishHandler(id);
  //     hideDropdown();
  //   } else {
  //     console.log("Cancel");
  //   }
  // }

  function deleteExercise() {
    if (confirm($_('EXERCISE_CONFIRM_DELETE'))) {
      deleteHandler(id)
      hideDropdown()
    } else {
      // console.log('Cancel')
    }
  }

  function deleteRecordingsInExercise() {
    if (confirm($_('EXERCISE_CONFIRM_DELETE_ALL'))) {
      deleteRecordingsHandler(id)
      hideDropdown()
    } else {
      // console.log('Cancel')
    }
  }

  function duplicateExerciseHandler(){
    dispatch('duplicateExerciseHandler', {exerciseId: id})
  }

  function moveToOtherLesson() {
    moveToOtherLessonHandler(id)
  }

  function clickChangePage(e) {
    $currentPage = e
    router.goto('/' + e)
  }
</script>

<div class="btn-group">
  <button
    id={'dropdownMenu' + id}
    type="button"
    class="dropdown-toggle no-border"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    {$_('SELECT')}
  </button>
  <ul class="dropdown-menu">
    <li>
      <a
        type="button"
        target="_blank"
        style="margin-bottom: 0.5em;"
        rel="noopener noreferrer"
        class="dropdown-item"
        href="https://student.oksensay.com/?key={exerciseDetail.key}"
        >{$_('PREVIEW_EXERCISE')}</a
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={openExercise}>{$_('MORE_DETAILS')}</button
      >
    </li>
    {#if exerciseDetail.status != 'PUBLISHED'}
      <li>
        <button
          class="dropdown-item"
          type="button"
          disabled={"RW" !== $currentCourseRoleCheck?.permissions}
          on:click|stopPropagation={editExerciseDetail}>{$_('EDIT')}</button
        >
      </li>
    {/if}
    <!-- {#if exerciseDetail.status != "PUBLISHED"}
      <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={publishExercise}>Publish</button
      >
      </li>
    {/if} -->
    <li>
      <button
        class="dropdown-item"
        type="button"
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        on:click|stopPropagation={deleteExercise}
        >{$_('DELETE_EXERCISE_AND_RECORDINGS')}</button
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        on:click|stopPropagation={deleteRecordingsInExercise}
        >{$_('DELETE_RECORDINGS_ONLY')}</button
      >
    </li>

    <li>
      <button
        class="dropdown-item"
        type="button"
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        on:click|stopPropagation={moveToOtherLesson}
        >{$_('MOVE_TO_OTHER_LESSON')}</button
      >
    </li>

    <li>
      <button
        class="dropdown-item"
        type="button"
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        on:click|stopPropagation={duplicateExerciseHandler}
        >{$_('DUPLICATE_EXERCISE')}</button
      >
    </li>
  </ul>
</div>
