<script>
  import { _, locale } from 'svelte-i18n'
  import Modal from 'sv-bootstrap-modal'
  import { userDetails } from '../../components/DataStore'
  import { logout } from '../../utils/ApiUtils.svelte'
  import { getData, postDataRawResponse } from '../../utils/ApiUtils.svelte'

  export let isOpen = false

  let extendResult = ''

  mixpanel.track_links('#upgrade', 'Upgrade Now Button Clicked', {
    'Button Location': 'Dashboard',
  })

  function logoutAction() {
    logout()
  }

  function extendTrial(){
    extendResult = 'processing'
    postDataRawResponse('api/teacher/subscription/extend-trial')
      .then((data) => {
        console.log('courses successful', data)
        extendResult = 'done'
      })
      .catch((error) => {
        console.log('error courses', error)
        extendResult = 'error'
        // extendResult = 'done'
      })
  }
</script>

<Modal
  bind:open={isOpen}
  ignoreBackdrop={true}
  keyboard={false}
  dialogClasses="modal-xl"
>
  <div class="modal-header">
    <h5 class="modal-title">{$_('PAYWALL.TRIAL_EXPIRED')}</h5>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger" role="alert">
      {$_('PAYWALL.TRIAL_EXPIRED_MESSAGE')}
    </div>
    <p>
      {$_('PAYWALL.CONTACT_US')}<a href="mailto:info@oksensay.com"
        >info@oksensay.com</a
      >
    </p>

    <div class="mt-4 mb-2" style="text-align: center;">
      <button
        class="btn btn-primary s-button-primary"
        target="_blank"
        on:click={extendTrial}>{$_('PAYWALL.EXTEND_TRIAL')}</button
      >
      <a
        id="upgrade"
        role="button"
        style="margin: 0 0 0.5em 0;"
        class="btn btn-primary s-button-primary"
        target="_blank"
        href="https://docs.google.com/forms/d/e/1FAIpQLSd0H8-oEzd7_hp1uvBqHAeG2uQeUpyq9EbMW2fzi5WkSkHRLg/viewform"
        >{$_('PAYWALL.REQUEST_QUOTE')}</a
      >
      <button
        class="btn btn-danger s-button-danger"
        target="_blank"
        on:click={logoutAction}>{$_('LOGOUT')}</button
      >

      {#if extendResult === 'error'}
            <div class="alert alert-danger mt-2" role="alert">
              {$_('PAYWALL.ERROR')}
            </div>
          {:else if extendResult === 'done'}
            <div class="alert alert-success mt-2" role="alert">
              {$_('PAYWALL.EXTENSION_DONE')}
            </div>
          {:else if extendResult === 'processing'}
            <div class="alert alert-info mt-2" role="alert">
              {$_('PAYWALL.PROCESSING')}
            </div>
          {/if}
    </div>
  </div>
</Modal>

<style>
  .btn-primary {
    color: white;
  }
</style>
