<script>
  import { _ } from "svelte-i18n";
  import { putData } from "../../utils/ApiUtils.svelte";

  import { userDetails } from "../../components/DataStore";
  import Loader from "../../components/Loader.svelte";
</script>

<div class="mb-3">
  <div class="py-3">
    <div class="s-content-header">
      <div class="s-content-header-title">{$_("SUBSCRIPTION")}</div>
    </div>
  </div>

  <div>
    <strong
      >{$_("SUBSCRIPTION_LEVEL")}
    </strong>{$userDetails?.currentSubscriptionLevel}
  </div>

  <div>
    <strong
      >{$_("EXPIRES_ON")}:
    </strong>{#if $userDetails?.currentSubscriptionExpiration}{new Date(
        $userDetails?.currentSubscriptionExpiration
      ).toLocaleDateString()}{:else}{$_("UNLIMITED")}{/if}
  </div>
</div>
