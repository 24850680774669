<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Breadcrumb from 'components/Breadcrumb.svelte'
  import { onMount } from 'svelte'
  import CourseCardListComponent from './CourseCardListComponent.svelte'
  import CourseTable from './CourseTable.svelte'
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    userDetails,
  } from '../../../components/DataStore'

  let marketPlaceLink = ''

  onMount(async () => {
    userDetails.subscribe((x) => prepareMarketLink())
    mixpanel.track_links(
      '#marketplace-classes',
      'Teacher Content Marketplace Button Clicked',
      { 'Button Location': 'Classes Page' }
    )
  })

  function prepareMarketLink() {
    marketPlaceLink =
      'https://www.oksensay.com/sensay-packs?group=A&userId=' +
      $userDetails?.id +
      '&userName=' +
      encodeURIComponent($userDetails?.login)
  }

  function createNewCourse() {
    currentCourseId.update((x) => undefined)
    mixpanel.track('Teacher Create New Class Clicked')
    clickChangePage('courses-edit')
  }

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    currentPage.update((x) => e)
    router.goto('/' + e)
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <Breadcrumb />
    </div>
  </div>

  <div class="s-content-spacing">
    <div class="card shadow">
      <div class="card-header py-3">
        <div>
          <button
            class="btn btn-primary s-button-primary"
            on:click={createNewCourse}
          >
            <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
              'CREATE_NEW_COURSE'
            )}<br />
          </button>
        </div>
      </div>
      <div class="card-body">
        <!-- <CourseCardListComponent /> -->
        <CourseTable />
      </div>
    </div>
  </div>

  <!-- <div class="s-content-spacing">
    <div class="card shadow mt-3">
      <div class="card-header py-3">
        <div><h3>{$_("COURSE_MARKETPLACE")}</h3></div>
      </div>
      <div class="card-body">
        <a
          class="btn btn-primary s-button-primary"
          id="marketplace-classes"
          href={marketPlaceLink}
          target="_blank"
          ><i class="fa fa-plus" style="padding-right: 8px;" />{$_(
            "EXPLORE"
          )}</a
        >
      </div>
    </div>
  </div> -->
</main>

<style>
</style>
