<script>
  import { _ } from 'svelte-i18n'
  import Loader from 'components/Loader.svelte'
  export let courseId
  export let lessonId
  let studentData = []
  let courseDetailStatus = 'loading'
  import { onMount } from 'svelte'
  import { postData } from 'utils/ApiUtils.svelte'
  import { CSVToArray } from 'utils/FormatUtils.svelte'
  import { createEventDispatcher } from 'svelte'

  export let uploadExerciseCsvFlag = true

  const dispatch = createEventDispatcher()

  let dropArea = undefined
  let dropAreaHighlighted = false

  let csvTable = []

  onMount(async () => {
    console.log('studentData', studentData)
    courseDetailStatus = 'loading'
    //loadStudents();
  })

  function highlight(e) {
    console.log(333, e)
    dropAreaHighlighted = true
    dropArea.classList.add('highlight')
  }

  function unhighlight(e) {
    console.log(334, e)
    dropAreaHighlighted = false
    dropArea.classList.remove('active')
  }

  function handleDrop(e) {
    console.log(335, e)
    var dt = e.dataTransfer
    var files = dt.files

    handleFiles(files)
  }

  function handleFiles(files) {
    files = [...files]
    files.forEach(previewFile)
  }

  function generateToken() {
    let tokenLength = 6
    let result = ''
    let characters = 'abcdefghijkmnopqrstuvwxyz023456789'
    let charactersLength = characters.length

    for (var i = 0; i < tokenLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  function previewFile(file) {
    let reader = new FileReader()
    reader.readAsText(file)
    reader.onloadend = function () {
      let csvContent = reader.result

      csvTable = CSVToArray(csvContent)
      if (csvTable?.length > 1) {
        csvTable.shift()
      }
      csvTable.forEach((exerciseData) => {
        exerciseData[0] = generateToken()
      })
      console.log(444, csvContent, csvTable)
    }
  }

  function cancelStudentUploadCsv() {
    csvTable = []
    uploadExerciseCsvFlag = false
  }

  function addNewStudentUploadCsv() {
    csvTable.forEach((row) => {
      let exerciseData = {
        key: row[0],
        type: row[1],
        title: row[2],
        question: row[3],
        text: row[4],
        youtubeUrl: row[5],
        imageUrl: row[6],
      }

      let payloadData = {}

      if (exerciseData.type === 'READ') {
        payloadData = {
          key: exerciseData?.key,
          language: '',
          data: '{}',
          text: exerciseData?.text,
          title: exerciseData?.title,
          type: exerciseData.type,
          multiChoiceAnswers: [null, null, null, null],
        }
      } else if (exerciseData.type === 'VIDEO') {
        payloadData = {
          key: exerciseData?.key,
          language: '',
          data: '{}',
          title: exerciseData?.title,
          type: exerciseData.type,
          question: exerciseData?.question,
          youtubeUrl: exerciseData?.youtubeUrl,
        }
      } else if (exerciseData.type === 'QUESTION') {
        payloadData = {
          key: exerciseData?.key,
          language: '',
          data: '{}',
          text: exerciseData?.text,
          title: exerciseData?.title,
          type: exerciseData.type,
          question: exerciseData?.question,
        }
      } else if (exerciseData.type === 'SELF_PRACTICE') {
        payloadData = {
          key: exerciseData?.key,
          language: '',
          data: '{}',
          text: exerciseData?.text,
          title: exerciseData?.title,
          type: exerciseData.type,
        }
      } else if (exerciseData.type === 'IMAGE') {
        payloadData = {
          key: exerciseData?.key,
          language: '',
          data: '{}',
          text: exerciseData?.text,
          title: exerciseData?.title,
          type: exerciseData.type,
          question: exerciseData?.question,
          imageUrl: exerciseData.imageUrl,
          imageName: exerciseData.imageName,
        }
      }

      console.log('save exercise', payloadData)

      postData(
        'api/courses/' + courseId + '/lessons/' + lessonId + '/exercises/',
        payloadData
      )
        .then((data) => {
          console.log('courses successful', data)
        })
        .catch((error) => {
          console.log('error courses', error)
          exerciseDetailStatus = 'error'
        })
    })

    csvTable = []
    uploadExerciseCsvFlag = false
    dispatch('csvImportDone', {})
  }
</script>

{#if uploadExerciseCsvFlag}
  <div>
    <div
      bind:this={dropArea}
      id="drop-area"
      on:handleDrop={handleDrop}
      on:dragleave|preventDefault={unhighlight}
      on:drop|preventDefault={handleDrop}
      on:dragenter|preventDefault={highlight}
      on:dragover|preventDefault={highlight}
      class:highlight={dropAreaHighlighted}
    >
      <form class="my-form">
        <p>
          {$_('UPLOAD_CSV_EXERCISE_INSTRUCTIONS')}
        </p>
        <input
          type="file"
          id="fileElem"
          accept="text/*"
          on:change={(e) => {
            handleFiles(e.target.files)
          }}
        />
        <label class="button" for="fileElem">{$_('SELECT_CSV_FILE')}</label>
      </form>
      <div id="gallery" />
      <div>
        <table class="table">
          <thead>
            <th>{$_('PIN')}</th><th>{$_('EXERCISE_TYPE')}</th><th
              >{$_('TITLE')}</th
            ><th>{$_('QUESTION')}</th><th>{$_('TEXT')}</th><th
              >{$_('YOUTUBE_URL')}</th
            ><th>{$_('IMAGE_URL')}</th>
          </thead>
          <tbody>
            {#each csvTable as r, i}
              {#if r && r?.length == 5}
                <tr>
                  <td>{r[0]}</td>
                  <td>{r[1]}</td>
                  <td>{r[2]}</td>
                  <td
                    >{#if r[3]}{r[3]}{/if}</td
                  >
                  <td
                    >{#if r[4]}{r[4]}{/if}</td
                  >
                  <td
                    >{#if r[5]}{r[5]}{/if}</td
                  >
                  <td
                    >{#if r[6]}{r[6]}{/if}</td
                  >
                </tr>
              {/if}
            {/each}
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="form-row mt-3">
    <button
      class="btn btn-secondary s-button-secondary s-mr-10px"
      on:click={cancelStudentUploadCsv}
    >
      <i class="far fa-window-close" style="padding-right: 8px;" />{$_(
        'CANCEL'
      )}
    </button>

    <button
      class="btn btn-success s-button-success"
      disabled={csvTable.length === 0}
      on:click|preventDefault={(x) => {
        addNewStudentUploadCsv()
      }}
    >
      <i class="fa fa-save" style="padding-right: 8px;" />{$_('IMPORT')}
      <br />
    </button>
  </div>
{/if}

<style>
  #drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    /* width: 480px; */
    margin: 50px auto;
    padding: 20px;
  }
  #drop-area.highlight {
    border-color: purple;
  }
  p {
    margin-top: 0;
  }
  .my-form {
    margin-bottom: 10px;
  }
  #gallery {
    margin-top: 10px;
  }
  .button {
    display: inline-block;
    padding: 10px;
    background: #ccc;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .button:hover {
    background: #ddd;
  }
  #fileElem {
    display: none;
  }
</style>
