<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { getData, postData, deleteData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { userDetails } from "../../components/DataStore";
  import { createEventDispatcher } from "svelte";
  import { dynamicSort, dynamicSortLocale } from "utils/SortingUtils.svelte";
  import TableHeaderItem from "../../components/TableHeaderItem.svelte";

  export let organizationId;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    if (organizationId) {
      getOrganizationStudents();
    }
  });

  let studentsData = [];
  let studentsStatus = "new";

  let sortColumn = "login";
  let sortDirection = 1;
  let filterStudentName = "";
  let filterRosterName = "";

  function refreshList() {
    getOrganizationStudents();
    dispatch("update", {});
  }

  function getOrganizationStudents() {
    getData(
      `api/organization/organizations/${organizationId}/roster-statistics`,
      false,
      false,
    )
      .then((data) => {
        console.log("organization student statistics successful", data);
        // Save data to sessionStorage
        data.forEach((subRow) => {
          subRow["rosterJsonParsed"] = parseJsonData(subRow.rosterJson);
        });

        studentsData = data;

        studentsData = studentsData.sort(
          dynamicSort(sortColumn, sortDirection),
        );

        if (filterStudentName && filterStudentName.length > 0) {
          studentsData = studentsData.filter((s) => {
            return (
              s?.login?.includes(filterStudentName) ||
              s?.studentName?.includes(filterStudentName)
            );
          });
        }

        if (filterRosterName && filterRosterName.length > 0) {
          studentsData = studentsData.filter((s) => {
            let rCount = s?.rosterJsonParsed.filter((n) => {
              return n?.name?.includes(filterRosterName);
            });
            if (rCount) {
              s["rosterJsonParsed"] = rCount;
            }
            console.log("xx", rCount);
            return rCount.length > 0;
          });
        }

        studentsStatus = "ok";
      })
      .catch((error) => {
        console.log("error organization students", error);
      });
  }

  function changeSort(e) {
    console.log(111, e.detail);
    sortColumn = e.detail.sortColumn;
    sortDirection = e.detail.sortDirection;

    studentsStatus = "loading";
    studentsData = studentsData.sort(dynamicSort(sortColumn, sortDirection));
    studentsStatus = "ok";
  }

  function parseJsonData(x) {
    try {
      const y = JSON.parse(x);
      return y;
    } catch (e) {
      console.log(444, e);
    }

    return [];
  }

  function removeStudent(subRow, c) {

    console.log(333, subRow, c)
    if (confirm("XX")){
      deleteData(
      `api/student-roster/${c?.idRoster}/student-account/${c?.id}`,
      {},
    )
      .then((data) => {
        console.log("successful", data);
        refreshList()
      })
      .catch((error) => {
        console.log("error courses", error);
      });
    }
    
  }

</script>

<div class="card shadow">
  <div class="card-header py-3 no-print">
    <div class="row">
      <div style="display: flex;gap: 2em;">
        <div class="form-check">
          <label for="studentNameFilterInput" class="form-check-label"
            >{$_("STUDENT_NAME")}</label
          >
          <input
            id="studentNameFilterInput"
            class="form-control s-form-input"
            type="text"
            style="min-width: 20em;"
            bind:value={filterStudentName}
            on:blur={getOrganizationStudents}
          />
        </div>
        <div class="form-check">
          <label for="courseNameFilterInput" class="form-check-label"
            >{$_("ORGANIZATION.ROSTER_NAME")}</label
          >
          <input
            id="courseNameFilterInput"
            class="form-control s-form-input"
            type="text"
            style="min-width: 20em;"
            bind:value={filterRosterName}
            on:blur={getOrganizationStudents}
          />
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    {#if studentsStatus != "ok"}
      <Loader />
    {:else if studentsData?.length > 0}
      <div
        class="table table mt-2"
        id="dataTable"
        role="grid"
        aria-describedby="dataTable_info"
      >
        <table class="table my-0 table-hover" id="dataTable">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col"
                ><TableHeaderItem
                  column="login"
                  {sortColumn}
                  {sortDirection}
                  on:changeSort={changeSort}
                  >{$_("STUDENT_EMAIL")}</TableHeaderItem
                ></th
              >
              <th scope="col">
                <TableHeaderItem
                  column="studentName"
                  {sortColumn}
                  {sortDirection}
                  on:changeSort={changeSort}
                  >{$_("STUDENT_NAME")}</TableHeaderItem
                >
              </th>
              <th scope="col">
                <TableHeaderItem
                  column="srCount"
                  {sortColumn}
                  {sortDirection}
                  on:changeSort={changeSort}
                  >{$_("ORGANIZATION.ROSTER_COUNT")}</TableHeaderItem
                >
              </th>
              <th scope="col">
                {$_("ORGANIZATION.STUDENT_ROSTERS")}
              </th>
            </tr>
          </thead>
          <tbody>
            {#if studentsData?.length > 0}
              {#each studentsData as subRow, i}
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td
                    ><a href="/#/student-account-detail/{subRow.idStudent}"
                      >{subRow.login}</a
                    ></td
                  >
                  <td>{subRow.studentName}</td>
                  <td>{subRow.srCount}</td>
                  <td>
                    <table class="table my-0 table-hover" id="dataTable2">
                      <thead
                        ><tr
                          ><th>{$_("ORGANIZATION.TEACHER_EMAIL")}</th><th
                            >{$_("ORGANIZATION.TEACHER")}</th
                          ><th>{$_("ORGANIZATION.ROSTER_NAME")}</th><th
                            class="action-buttons">{$_("ACTIONS")}</th
                          ></tr
                        ></thead
                      >
                      <tbody>
                        {#each subRow.rosterJsonParsed.sort(dynamicSortLocale("teacherLogin", 1)) as c, y}
                          <tr>
                            <td
                              ><a href="#/teacher-account/{c?.userId}"
                                >{c.teacherLogin}</a
                              ></td
                            >
                            <td>{c.teacherName}</td>
                            <td>
                              <a
                                href={`#/student-roster/${c.idRoster}`}
                                >{c.name}</a
                              >
                            </td>
                            <td class="action-buttons"
                              ><button
                                class="btn btn-danger s-button-danger"
                                on:click={() => removeStudent(subRow, c)}
                                >&#8998; {$_("DELETE")}</button
                              ></td
                            >
                          </tr>
                        {:else}No Class
                        {/each}
                      </tbody>
                    </table>
                    <!-- {#each parseJsonData(subRow.classJson) as c, y}
                      <li>
                        {c.teacherName} - {c.teacherLogin} - {#if c?.teacherLogin === $userDetails?.login}
                          <a
                            href={`#courses-detail/${c.idCourse}/student-accounts`}
                            >{c.name}</a
                          >
                        {:else}{c.name}{/if}
                      </li>
                    {:else}No Class
                    {/each} -->
                  </td>
                </tr>
              {/each}
            {/if}
          </tbody>
          <tfoot>
            <tr />
          </tfoot>
        </table>
      </div>
    {/if}
  </div>
</div>

<style>
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;
  }
</style>
