<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import { onMount } from 'svelte'
  import { getData, postData, deleteData } from '../../utils/ApiUtils.svelte'
  import Loader from '../../components/Loader.svelte'
  import { currentPage, userConfig } from '../../components/DataStore'
  import StudentRosterEnrollmentTable from './Components/StudentRosterEnrollmentTable.svelte'

  export let studentRosterId

  let studentRosterStatus = 'new'
  let studentRosterData = {}

  onMount(async () => {
    if (Number.parseInt(studentRosterId)) {
      getStudentRoster(studentRosterId)
    } else {
      studentRosterStatus = 'new'
    }
  })

  function editStudentRoster(id) {
    clickChangePage(`student-roster/${id}/edit`)
  }

  function getStudentRoster(studentRosterId) {
    studentRosterStatus = 'loading'
    getData(`api/student-roster/${studentRosterId}`)
      .then((data) => {
        console.log('successful', data)

        studentRosterData = data

        studentRosterStatus = 'ok'
      })
      .catch((error) => {
        console.log('error courses', error)
        studentRosterStatus = 'error'
      })
  }

  function clickChangePage(e) {
    // console.log('clickChangePage', e)
    $currentPage = e
    router.goto('/' + e)
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">
        <a href="student-roster">{$_('STUDENT_ROSTER.HEADER')}</a> / {#if studentRosterStatus === 'ok'}{studentRosterData?.code}{/if}
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    <div class="card shadow">
      <div class="card-body">
        {#if studentRosterStatus === 'loading'}
          <Loader />
        {:else if studentRosterStatus === 'ok'}
          <h3>
            <span class="strong"
              >{#if studentRosterData?.code}{studentRosterData?.code}{/if}
              </span
            >
          </h3>

          <div>
            {$_('STUDENT_ROSTER.ORGANIZATION')}:
            <span class="strong"
              >{#if studentRosterData?.organizationId}<span class="debug"
                >{studentRosterData?.organizationId} -
              </span>{studentRosterData?.organizationName}{:else}{$_("NA")}{/if}</span
            >
          </div>
          <div>
            {$_('STUDENT_ROSTER.TEACHER')}:
            <span class="strong"
              ><span class="debug"
                >{studentRosterData?.userId} -
              </span>{studentRosterData?.userLogin}</span
            >
          </div>

          <div>
            {$_("STUDENT_ROSTER.DESCRIPTION")}:
            <span>{#if studentRosterData?.name}{studentRosterData?.name}{/if}</span>
          </div>  

          <div class="float-end" style="margin-top: 15px">
            <div class="btn-group" role="group">
              {#if $userConfig?.serverFlags?.adminStudentRoster === true}<button
                  class="btn btn-secondary s-button-secondary"
                  on:click={() => editStudentRoster(studentRosterId)}
                >
                  <i class="fa fa-edit" style="padding-right: 8px;" />{$_(
                    'STUDENT_ROSTER.EDIT'
                  )}</button
                >{/if}
            </div>
          </div>
        {:else}
          {$_('ERROR')} - {studentRosterStatus}
        {/if}
      </div>
    </div>

    {#if studentRosterId}
      <StudentRosterEnrollmentTable {studentRosterId} />
    {/if}
  </div>
</main>

<style>
  .strong {
    font-weight: 800;
  }
  .debug {
    display: none;
  }
</style>
