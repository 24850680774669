<script>
  import { _ } from 'svelte-i18n'
  import QRJS from 'components/QRJS.svelte'
  import Modal from 'sv-bootstrap-modal'

  export let pin
  export let shareType = 'EXERCISE'
  export let shareModalShow = false
  export let isActive = true

  let linkCopied = false
  let timer

  const debounce = (v) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      linkCopied = false
    }, 5000)
  }

  function copyLink() {
    let url = 'https://student.oksensay.com/?key=' + pin
    copyTextToClipboard(url)
  }

  function copyTextToClipboard(text) {
    linkCopied = true
    debounce()
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!')
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }

  window.$(document).ready(function () {
    window.$('#qrcode > img').css({ width: '100%' })
  })
</script>

<Modal bind:open={shareModalShow} dialogClasses="modal-lg s-modal">
  <div class="modal-header">
    <h4 class="modal-title" style="position: absolute">
      {#if shareType === 'LESSON'}{$_('SHARE_LESSON_WITH_STUDENTS_NOW')}
      {:else}{$_('SHARE_EXERCISE_WITH_STUDENTS_NOW')}
      {/if}
    </h4>
    <div class="float-end" style="margin-left: auto">
      <button
        type="button"
        class="s-modal-close"
        on:click={() => (shareModalShow = false)}
      >
        &times;
      </button>
    </div>
  </div>
  <div>
    <div class="alert alert-light" role="alert" style="font-size: medium;">
      {#if shareType === 'LESSON'}{$_('SHARE_LESSON_NOTE')}
      {:else if shareType === 'EXERCISE'}{$_('SHARE_EXERCISE_NOTE')}
      {/if}
    </div>
  </div>
  <div class="modal-body container">
    {#if isActive === false}
      <div class="row">
        <div class="col">
          <div class="alert alert-danger" role="alert">
            {$_('SHARE_LESSON_NOT_WITHIN_ACTIVE_DATES')}
          </div>
        </div>
      </div>
    {/if}
    <div class="row">
      <div class="col-sm">
        {$_('GOTO')}
        <div class="codeLink">
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://student.oksensay.com/?key={pin}"
              >student.oksensay.com</a
            >
          </div>
        </div>
        <div style="margin-top:1em;">
          {$_('PIN')}:
          <div class="codeLink">
            <div style="font-size: 150%; font-weight: bold;">
              {pin}
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="align-self-center">
          <QRJS
            codeValue="https://student.oksensay.com/?key={pin}"
            squareSize="250"
          />
        </div>
      </div>
    </div>

    <div class="row float-end" style="margin-top: 1rem">
      <div class="col-sm">
        {#if linkCopied}
          <div>{$_('LINK_COPIED_TO_CLIPBOARD')}</div>
        {:else}
          <button class="btn btn-success s-button-success" on:click={copyLink}
            >{$_('COPY_LINK')}</button
          >
        {/if}
      </div>
    </div>
  </div>
</Modal>

<style>
  .modal-header {
    align-items: center;
    justify-content: center;
  }
  .modal-title {
    color: #1cc88a;
  }
  .modal-header {
    align-items: center;
    justify-content: center;
  }

  :global(.s-modal) {
    max-width: 35rem;
  }

  .codeLink {
    padding: 1em;
    border: 5px;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    border-style: solid;
    border-radius: 4px;
    border-color: #1cc88a;
    text-align: center;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .codeLink {
      font-size: 1.2em;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .codeLink {
      font-size: 0.6em;
    }
  }
</style>
