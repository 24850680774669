<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import ExerciseTabs from './ExerciseComponent/ExerciseTabs.svelte'
  import Breadcrumb from '../../components/Breadcrumb.svelte'
  import {
    currentPage,
    currentCourseId,
    currentLessonId,
    currentExerciseId,
    currentRecordingId,
    currentRecordingStudentName,
  } from '../../components/DataStore'

  export let selectedTab
  export let share

  console.log('selectedTab', selectedTab)

  if (!selectedTab) {
    selectedTab = 'tabExerciseDetail'
  }

  console.log('selectedTab', selectedTab)

  function navigateExercise(tab) {
    console.log('navigateExercise')
    currentRecordingId.update((x) => undefined)
    currentRecordingStudentName.update((x) => undefined)

    clickChangePage(
      'courses/' +
        $currentCourseId +
        '/lessons/' +
        $currentLessonId +
        '/exercise-detail/' +
        $currentExerciseId +
        '/' +
        tab
    )
  }

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    currentPage.update((x) => e)
    router.goto('/' + e)
  }

  function selectTab(tab) {
    try {
      switch (tab) {
        case 'tabExerciseDetail':
          mixpanel.track('Teacher Exercises Page Exercise Content Tab Clicked')
          break
        case 'tabRecording':
          mixpanel.track(
            'Teacher Exercises Page Student Recordings Tab Clicked'
          )
          break
        default:
          break
      }
    } catch (error) {
      console.log('error mixpanel')
    }

    selectedTab = tab
    navigateExercise(tab)
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <Breadcrumb />
    </div>
  </div>

  <div class="s-content-spacing" style="margin-bottom: 0 !important;">
    <ul class="nav nav-tabs no-print" role="tablist">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li class="nav-item" role="presentation">
        <!-- svelte-ignore a11y-interactive-supports-focus -->
        <span
          on:click={() => {
            selectTab('tabExerciseDetail')
          }}
          class="nav-link clickable"
          class:active={selectedTab === 'tabExerciseDetail'}
          role="tab"
          data-toggle="tabExerciseDetail"
          aria-selected="true"
          ><i class="fas fa-edit" style="padding-right: 8px;" />{$_(
            'EXERCISE_CONTENT'
          )}</span
        >
      </li>
      <li class="nav-item" role="presentation">
        <!-- svelte-ignore a11y-interactive-supports-focus -->
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span
          on:click={() => {
            selectTab('tabRecording')
          }}
          class="nav-link clickable"
          class:active={selectedTab === 'tabRecording'}
          role="tab"
          data-toggle="tabRecording"
          aria-selected="false"
          ><i class="fas fa-microphone-alt" style="padding-right: 8px;" />{$_(
            'STUDENT_RECORDINGS'
          )}</span
        >
      </li>
    </ul>
  </div>

  <div class="s-content-spacing" style="margin-top: 0 !important;">
    <ExerciseTabs {selectedTab} {share} />
  </div>
</main>

<style>
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid #00a094 !important;
    border-bottom-width: 0px !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-item {
    margin-right: 1rem;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 0px;
  }
  .nav {
    border-bottom: 4px solid #00a094;
    color: black;
  }

  .nav-link {
    color: #575f59;
  }

  .nav-link.active {
    background-color: #00a094;
    color: white;
  }
</style>
