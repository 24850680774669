<script>
  import { onMount } from 'svelte'
  import { _ } from 'svelte-i18n'
  import {
    isOpenSendFeedbackModal,
    userDetails,
  } from '../../components/DataStore'
  import Modal from 'sv-bootstrap-modal'
  import { postJsonFileGetText, postData } from '../../utils/ApiUtils.svelte'
  import UAParser from 'ua-parser-js'

  let feedback = { email: undefined, type: undefined, text: '' }
  let parser = new UAParser() // you need to pass the user-agent for nodejs
  let parserResults = {}

  onMount(async () => {
    isOpenSendFeedbackModal.subscribe((x) => {
      console.log(x)
    })
    parserResults = parser.getResult()
    console.log(parserResults)
  })

  function sendEmailEvent() {
    mixpanel.track('Teacher Feedback Sent', { Method: 'Email' })
  }

  function sendApi() {
    let payloadData = {
      feedbackType: feedback.feedbackType,
      text: feedback.text,
      email: $userDetails?.login,
    }

    postData('api/user/send-feedback', payloadData)
      .then((data) => {
        console.log('successful: send feedback', data)
      })
      .catch((error) => {
        console.log('error: send feedback', error)
      })
  }

  function sendDiscord() {
    let messageData = {
      username: 'Teacher Feedback Bot',
      embeds: [
        {
          color: 14499904,
          title: feedback.feedbackType,
          fields: [
            {
              name: 'Feedback',
              value: feedback.text,
            },
            {
              name: 'Author',
              value: $userDetails?.login,
            },
            {
              name: 'Subscription Level',
              value: $userDetails?.currentSubscriptionLevel,
            },
          ],
        },
        {
          color: 4251101,
          title: 'User Agent Info',
          fields: [
            {
              name: 'User Agent',
              value:
                parserResults.ua === undefined ? 'undefined' : parserResults.ua,
            },
            {
              name: 'Browser Name',
              value:
                parserResults.browser.name === undefined
                  ? 'undefined'
                  : parserResults.browser.name,
            },
            {
              name: 'Browser Version',
              value:
                parserResults.browser.version === undefined
                  ? 'undefined'
                  : parserResults.browser.version,
            },
            {
              name: 'Device Model',
              value:
                parserResults.device.model === undefined
                  ? 'undefined'
                  : parserResults.device.model,
            },
            {
              name: 'Device Type',
              value:
                parserResults.device.type === undefined
                  ? 'undefined'
                  : parserResults.device.type,
            },
            {
              name: 'Device Vendor',
              value:
                parserResults.device.vendor === undefined
                  ? 'undefined'
                  : parserResults.device.vendor,
            },
            {
              name: 'OS name',
              value:
                parserResults.os.name === undefined
                  ? 'undefined'
                  : parserResults.os.name,
            },
            {
              name: 'OS Version',
              value:
                parserResults.os.version === undefined
                  ? 'undefined'
                  : parserResults.os.version,
            },
          ],
        },
      ],
    }

    postJsonFileGetText(
      'https://discord.com/api/webhooks/970598291458912316/5I7s8LwVdm0oyJC4U0K8z2gcee-t5xZ6QXU-nF6Poo-K4UB0JRfQnKV3eVDppEIZ2YC-',
      { 'Content-Type': 'application/json' },
      {},
      messageData
    )
      .then((data) => {
        console.log('postJsonFile', data)
        mixpanel.track('Teacher Feedback Sent', { Method: 'Form' })
        feedback = {}
      })
      .catch((error) => {
        console.log('error calling webhook', error)
      })
  }

  function sendFeedbackButton() {
    sendApi()
    sendDiscord()
    $isOpenSendFeedbackModal = false
  }
</script>

<Modal bind:open={$isOpenSendFeedbackModal} dialogClasses="modal-lg">
  {#if $userDetails?.organizationSubscriptionType === 'SURREAL' || $userDetails?.organizationSubscriptionType === 'POWERBOOK'}
    <div class="modal-header">
      <h5 class="modal-title">{$_('SURREAL.SEND_FEEDBACK')}</h5>
      <button
        type="button"
        class="s-modal-close"
        on:click={() => ($isOpenSendFeedbackModal = false)}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        {$_('SURREAL.SEND_FEEDBACK_MESSAGE')}
      </p>
      {#if $userDetails?.organizationSubscriptionType === 'SURREAL'}
        <p>
          <a
            target="_blank"
            href="mailto:jack@powerbook.com.tw?subject=Sensay+Feedback"
            on:click={sendEmailEvent}
          >
            ben@surreal.com.tw
          </a>
        </p>
        <p>Ben Lin 林本益</p>
        <p>02-8772-5948 #218</p>
        <p>0926-289-826</p>
      {/if}
      {#if $userDetails?.organizationSubscriptionType === 'POWERBOOK'}
        <p>
          <a
            target="_blank"
            href="mailto:jack@powerbook.com.tw?subject=Sensay+Feedback"
            on:click={sendEmailEvent}
          >
            jack@powerbook.com.tw
          </a>
        </p>
        <p>Jack Hsiao</p>
        <p>02-86605465/10</p>
        <p>0916-917-399</p>
      {/if}
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        on:click={() => {
          $isOpenSendFeedbackModal = false
        }}>{$_('CANCEL')}</button
      >
    </div>
  {:else}
    <div class="modal-header">
      <div class="modal-head-layout">
        <div class="heading">
          <h3>{$_('CONTACT_SENSAY')}</h3>
        </div>
        <div class="exit">
          <button
            type="button"
            class="s-modal-close"
            on:click={() => ($isOpenSendFeedbackModal = false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="sub">
          <div style="display: inline-block">
            {$_('FEEDBACK.SEND_FEEDBACK_MESSAGE')}
            <a
              href="mailto:support+platform@oksensay.com?subject=Feedback"
              target="_blank"
              on:click={sendEmailEvent}>support@oksensay.com</a
            >{$_('FEEDBACK.END_OF_SENTENCE_STATEMENT_PUNCTUATION')}
            {$_('FEEDBACK.SEND_FEEDBACK_MESSAGE_LINE')}
            <a href="https://lin.ee/Ag9Z35c"> @sensay</a>{$_(
              'FEEDBACK.END_OF_SENTENCE_STATEMENT_PUNCTUATION'
            )}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div>
        <div class="form-group">
          <label class="feedback-section-heading" for="feedbackType"
            >{$_('FEEDBACK.SEND_FEEDBACK_TYPE')}</label
          >
          <select
            class="custom-select"
            id="feedbackType"
            bind:value={feedback.feedbackType}
          >
            <option selected />
            <option value="BUG_REPORT"
              >{$_('FEEDBACK.FEEDBACK_TYPE_BUG')}</option
            >
            <option value="FEEDBACK.FEEDBACK"
              >{$_('FEEDBACK.FEEDBACK_TYPE_FEEDBACK')}</option
            >
            <option value="QUESTION"
              >{$_('FEEDBACK.FEEDBACK_TYPE_QUESTION')}</option
            >
            <option value="OTHER">{$_('FEEDBACK.FEEDBACK_TYPE_OTHER')}</option>
          </select>
        </div>

        <div class="form-group" style="margin-top: 3vh">
          <label class="feedback-section-heading" for="feedbackText"
            >{$_('FEEDBACK.SEND_FEEDBACK_TEXT')}</label
          >
          <textarea
            class="form-control"
            id="feedbackText"
            rows="5"
            bind:value={feedback.text}
          />
          <span class="feedback-sub"
            >{$_('FEEDBACK.SEND_FEEDBACK_TEXT_SUB')}</span
          >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary s-button-secondary s-mr-10px"
        data-dismiss="modal"
        on:click={() => {
          $isOpenSendFeedbackModal = false
        }}>{$_('CANCEL')}</button
      >
      <button
        type="button"
        class="btn btn-primary s-button-primary"
        on:click={sendFeedbackButton}>{$_('SUBMIT')}</button
      >
    </div>
  {/if}
</Modal>

<style>
  .modal-head-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 0.3fr;
    grid-template-areas:
      'heading . exit'
      'sub sub sub'
      'sub sub sub';
  }

  .heading {
    grid-area: heading;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    color: #000000;

    align-items: center;
    padding: 0;
    margin-bottom: 3vh;
  }

  .sub {
    grid-area: sub;
  }

  .exit {
    grid-area: exit;
    height: 2vh;
  }

  .feedback-section-heading {
    font-size: 1.14rem;
    font-weight: 500;
    margin-bottom: 1vh;
  }

  .feedback-sub {
    font-size: 0.85rem;
    color: #333333;
  }
</style>
