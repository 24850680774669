<script>
  import { _ } from "svelte-i18n";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { getData, putData, deleteData, postData } from "utils/ApiUtils.svelte";
  import { router } from "tinro";
  import { convertSecToMin, arrayIsEmpty } from "utils/FormatUtils.svelte";
  import ShareExercise from "./ShareExercise.svelte";
  import ExerciseTableDropdown from "./ExerciseTableDropdown.svelte";
  import ExerciseCsvImport from "./ExerciseCsvImport.svelte";
  import {
    currentPage,
    currentCourseId,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    userDetails,
    userConfig,
    currentCourseRoleCheck
  } from "../../../components/DataStore";
  import { dynamicSort } from "utils/FormatUtils.svelte";
  import Tooltip from "components/Tooltip.svelte";
  import MoveToOtherLessonModal from "./MoveToOtherLessonModal.svelte";
  import ExerciseTypeLabel from "./VisualComponentns/ExerciseTypeLabel.svelte";

  export let exerciseList = [];

  let pop;
  let moveToOtherLessonModal;

  let uploadExerciseCsvFlag = false;
  let exerciseListStatus = "loading";

  const publishHandler = (id) => {
    // console.log('publishHandler', id)
    publishExercise(id);
  };

  const deleteHandler = (id) => {
    // console.log('deleteHandler', id)
    deleteExercise(id);
  };

  const deleteRecordingsHandler = (id) => {
    // console.log('deleteRecordingsHandler', id)
    deleteRecordingsInExercise(id);
  };

  const moveToOtherLessonHandler = (id) => {
    showMoveExerciseToOtherLesson(id);
  };

  function duplicateExerciseHandler(e) {
    duplicateExercise(e.detail.exerciseId)
  }

  onMount(async () => {
    getExerciseList($currentCourseId, $currentLessonId);
  });

  function editExerciseDetail() {
    // console.log('editExerciseDetail', $currentCourseId, $currentLessonId)
    mixpanel.track("Teacher Create New Exercise Button Clicked");
    clickChangePage(
      "courses/" +
        $currentCourseId +
        "/lessons/" +
        $currentLessonId +
        "/exercise-edit"
    );
  }

  function clickChangePage(e) {
    // console.log('clickChangePage', e)
    $currentPage = e;
    router.goto("/" + e);
  }

  function getExerciseList(courseId, lessonId) {
    exerciseListStatus = "loading";
    getData("api/courses/" + courseId + "/lessons/" + lessonId + "/exercises3")
      .then((data) => {
        // console.log('lessons successful', data)
        exerciseList = data;
        exerciseList.sort(dynamicSort("customOrder"));
        exerciseListStatus = "ok";
      })
      .catch((error) => {
        // console.log('error courses', error)
      });
  }

  function openExercise(exerciseId = 1) {
    // console.log('openExercise', exerciseId)
    mixpanel.track("Teacher View Exercise Detail Clicked", {
      "Exercise ID": exerciseId,
    });
    currentExerciseId.update((x) => exerciseId);
    clickChangePage(
      "courses/" +
        $currentCourseId +
        "/lessons/" +
        $currentLessonId +
        "/exercise-detail/" +
        $currentExerciseId
    );
    //clickChangePage("exercise-detail");
  }

  function publishExercise(id) {
    putData(
      "api/courses/" +
        $currentCourseId +
        "/lessons/" +
        $currentLessonId +
        "/exercises/" +
        id +
        "/publish",
      {}
    )
      .then((data) => {
        // console.log('publishExercise successful', data)
        getExerciseList($currentCourseId, $currentLessonId);
      })
      .catch((error) => {
        // console.log('error publishExercise', error)
      });
  }

  function deleteExercise(id) {
    // console.log('delete exercise')

    deleteData("api/exercises/" + id)
      .then((data) => {
        // console.log('Exericse successful', data)
        getExerciseList($currentCourseId, $currentLessonId);
      })
      .catch((error) => {
        // console.log('error Exericse', error)
      });
  }

  function deleteRecordingsInExercise(id) {
    // console.log('delete exercise')

    deleteData("api/exercises/" + id + "/publicrecordings")
      .then((data) => {
        // console.log('Exercise successful', data)
        getExerciseList($currentCourseId, $currentLessonId);
      })
      .catch((error) => {
        console.log("error Exericse", error);
      });
  }

  function showMoveExerciseToOtherLesson(id) {
    console.log(id);
    moveToOtherLessonModal.openModal(id, $currentLessonId, $currentCourseId);
  }

  function duplicateExercise(id){
    postData(`api/exercises/${id}/copy`).then((data)=>{
      getExerciseList($currentCourseId, $currentLessonId);
    })
  }

  function exportExerciseToCsv() {
    const mimeType = "data:text/csv;charset=utf-8";

    const exerciseListOut = exerciseList.map((r) => {
      let x = {
        question: r.question,
        text: r.text,
        title: r.title,
        type: r.type,
        key: r.key,
        youtubeUrl: r.youtubeUrl,
        imageUrl: r.imageUrl,
      };
      return x;
    });

    let items = exerciseListOut.map(function (v) {
      let x = Object.assign({}, v);
      return x;
    });

    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    //const header = Object.keys(items[0]);
    const header = [
      "key",
      "type",
      "title",
      "question",
      "text",
      "youtubeUrl",
      "imageUrl",
    ];
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) =>
            JSON.stringify(row[fieldName], replacer)?.replaceAll("\n", "\r\n")
          )
          .join(",")
      ),
    ].join("\r\n");

    const d = new Date();

    var dummy = document.createElement("a");
    dummy.href = URL.createObjectURL(
      new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
        type: mimeType,
      })
    );
    dummy.download =
      "export-exercise-" + $currentLessonName + "-" + d.toISOString() + ".csv";
    dummy.click();
  }

  function changeOrder(exerciseId, targetOrder) {
    putData(
      `api/courses/${$currentCourseId}/lessons/${$currentLessonId}/exercises/${exerciseId}/reorder/${targetOrder}`,
      {}
    )
      .then((data) => {
        console.log("change order", data);
        getExerciseList($currentCourseId, $currentLessonId);
      })
      .catch((error) => {
        exerciseListStatus = "error";
        console.log("error lessons", error);
      });
  }
</script>

<MoveToOtherLessonModal bind:this={moveToOtherLessonModal} />

{#if exerciseListStatus === "loading"}
  <Loader />
{:else}
  <div class="card shadow">
    <div class="card-header py-3">
      <div>
        <div class="" role="group">
          <button
            class="btn btn-primary s-button-primary"
            disabled={"RW" !== $currentCourseRoleCheck?.permissions}
            on:click={editExerciseDetail}
          >
            <i class="fa fa-edit" style="padding-right: 8px;" />{$_(
              "CREATE_NEW_EXERCISE"
            )}</button
          >
          {#if $userDetails?.userRole === "ADMIN"}
            <button
              class="btn btn-primary s-button-primary ml-2"
              on:click={exportExerciseToCsv}
            >
              <i class="fa fa-download" style="padding-right: 8px;" />{$_(
                "EXPORT_EXERCISE_TO_CSV"
              )}</button
            >
            <button
              class="btn btn-primary s-button-primary ml-2"
              on:click={() => {
                uploadExerciseCsvFlag = !uploadExerciseCsvFlag;
              }}
            >
              <i class="fa fa-upload" style="padding-right: 8px;" />{$_(
                "UPLOAD_EXERCISE_CSV"
              )}</button
            >
          {/if}
        </div>
      </div>
      {#if $userDetails?.userRole === "ADMIN" && uploadExerciseCsvFlag}<ExerciseCsvImport
          courseId={$currentCourseId}
          lessonId={$currentLessonId}
          bind:uploadExerciseCsvFlag
          on:csvImportDone={() => {
            getExerciseList($currentCourseId, $currentLessonId);
          }}
        />
      {/if}
    </div>
    <div class="card-body">
      <div>
        {#if !arrayIsEmpty(exerciseList)}
          <div
            class="table table mt-2"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info"
          >
            <table class="table table-hover" id="dataTable">
              <thead>
                <tr>
                  <th style="min-width:5.5em">#</th>
                  <th>{$_("TITLE")}</th>
                  <th>{$_("EXERCISE_TYPE")}</th>
                  <!-- <th>Status</th> -->
                  <th>{$_("NUMBER_OF_RECORDINGS_EXERCISE")}</th>
                  <th>{$_("LATEST_RECORDING", { values: { n: 1 } })}</th>
                  <th>{$_("TOTAL_RECORDING_TIME")}</th>
                  <th>{$_("TOTAL_WORD_COUNT")}</th>
                  <th>{$_("SHARE")}</th>
                  <th>{$_("ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>
                {#each exerciseList as e, i}
                  <tr
                    class="exerciseRow"
                    on:click={(x) => {
                      openExercise(e.id);
                    }}
                  >
                    <th scope="row" style="min-width:7em">
                      <button
                        class="arrows"
                        disabled={"RW"!==$currentCourseRoleCheck?.permissions}
                        on:click|stopPropagation={(event) => {
                          event.preventDefault();
                          changeOrder(e.id, e?.customOrder - 1);
                        }}>↑</button
                      ><button
                        class="arrows"
                        disabled={"RW"!==$currentCourseRoleCheck?.permissions}
                        on:click|stopPropagation={(event) => {
                          event.preventDefault();
                          changeOrder(e.id, e?.customOrder + 2);
                        }}>↓</button
                      ><span class="ms-3">{i + 1}</span>
                    </th>
                    <td>{e?.title}</td>
                    <td><ExerciseTypeLabel type={e?.type} /></td>
                    <!-- <td>{e?.status}</td> -->
                    <td>{e?.publicRecordingsCount}</td>
                    <td
                      >{#if e?.publicRecordingsLatest}
                        {new Date(
                          Date.parse(e?.publicRecordingsLatest + "Z")
                        ).toLocaleString()}
                      {/if}</td
                    >
                    <td>{convertSecToMin(e?.publicRecordingsTimeSum)}</td>
                    <td
                      >{#if e?.publicRecordingsWordCountSum}{e?.publicRecordingsWordCountSum}{/if}</td
                    >
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <td
                      on:click|stopPropagation={() => {
                        return;
                      }}
                    >
                      {#if $userConfig?.serverFlags?.disablePinSharing === false || !($userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.disablePinSharing === true)}
                        <ShareExercise pin={e.key} id={e.id} />
                      {:else}
                        <span style="position: relative;">
                          <Tooltip title={$_("ADD_STUDENTS_TO_CLASS")}>
                            <i class="fa-solid fa-circle-info" />
                          </Tooltip>
                        </span>
                      {/if}
                    </td>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <td
                      on:click|stopPropagation={(x) => {
                        console.log("dropdown Click");
                      }}
                    >
                      <ExerciseTableDropdown
                        bind:exerciseDetail={e}
                        bind:id={e.id}
                        {publishHandler}
                        {deleteHandler}
                        {deleteRecordingsHandler}
                        {moveToOtherLessonHandler}
                        on:duplicateExerciseHandler={duplicateExerciseHandler}
                      />
                    </td>
                  </tr>
                {/each}
              </tbody>
              <tfoot>
                <tr />
              </tfoot>
            </table>
          </div>
        {:else}
          <div class="row" style="padding-left:15%; padding-right:15%;">
            <svg
              class="text-center"
              width="201"
              height="200"
              viewBox="0 0 201 200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M152.5 154.07L107.62 138.07H66.7002V132.15L66.8802 121.29L83.0502 114.64L128.71 112.73L133.5 96.53V71.8L135.4 68.94L149.53 68.54C153.448 68.5558 157.201 70.1191 159.971 72.8894C162.741 75.6596 164.304 79.4124 164.32 83.33V123.25C164.313 126.655 163.134 129.954 160.982 132.593C158.829 135.231 155.834 137.049 152.5 137.74V154.07Z"
                fill="#DCDCDC"
              />
              <path
                d="M42.83 131.46V115.2C39.4847 114.507 36.4808 112.681 34.3256 110.03C32.1704 107.379 30.9958 104.066 31 100.65V60.7197C31.0184 56.8028 32.5826 53.0516 35.3522 50.2819C38.1219 47.5122 41.8731 45.9481 45.79 45.9297H125.64C129.556 45.9507 133.306 47.5157 136.075 50.2848C138.844 53.0539 140.409 56.8036 140.43 60.7197V100.65C140.409 104.565 138.844 108.313 136.074 111.081C133.305 113.849 129.555 115.411 125.64 115.43H87.71L42.83 131.46ZM45.83 51.8497C43.4799 51.8602 41.2288 52.7978 39.566 54.4587C37.9033 56.1195 36.9632 58.3695 36.95 60.7197V100.65C36.9632 103 37.9033 105.25 39.566 106.911C41.2288 108.572 43.4799 109.509 45.83 109.52H48.83V123.06L86.76 109.52H125.76C128.109 109.509 130.359 108.571 132.02 106.91C133.682 105.249 134.619 102.999 134.63 100.65V60.7197C134.619 58.3705 133.682 56.1205 132.02 54.4593C130.359 52.7981 128.109 51.8602 125.76 51.8497H45.83Z"
                fill="#707070"
              />
              <path
                d="M158.17 152.16L113.29 136.16H72.4004V130.24H114.33L152.26 143.79V130.22H155.26C157.611 130.209 159.862 129.272 161.524 127.611C163.187 125.95 164.127 123.7 164.14 121.35V81.4196C164.127 79.0695 163.187 76.8195 161.524 75.1586C159.862 73.4977 157.611 72.5602 155.26 72.5497H152.26V66.6396H155.26C159.168 66.6685 162.906 68.2363 165.665 71.0029C168.424 73.7696 169.982 77.5123 170 81.4196V121.35C169.993 124.756 168.812 128.056 166.658 130.695C164.504 133.334 161.507 135.151 158.17 135.84V152.16Z"
                fill="#707070"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.1396 66.6396H118.25V72.5497H56.1396V66.6396Z"
                fill="#707070"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.1396 88.8203H88.6696V94.7303H56.1396V88.8203Z"
                fill="#707070"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M97.5 88.8203H107.85V94.7303H97.5V88.8203Z"
                fill="#707070"
              />
            </svg>

            <h3 class="text-center">
              {$_("EXERCISE_TABLE_EMPTY_PROMPT_EMPTY")}
            </h3>
            <p>{$_("EXERCISE_TABLE_EMPTY_PROMPT_NEW")}</p>
          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}

<style>
  .exerciseRow {
    cursor: pointer;
  }

  .arrows {
    color: #fff;
    background-color: #00a094;
    outline: none !important;
    border-bottom: 2px solid #018d83 !important;
    border-radius: 5px;
    border-left: 1px solid #018d83 !important;
    border-right: 1px solid #018d83 !important;
    border-top: 1px solid #018d83 !important;
    margin: 10px 2px 0;
    padding: 0em 0.375em;
  }

  .arrows:disabled{
    pointer-events: none;
    opacity: 0.65;
  }
</style>
