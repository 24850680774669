<script>
  import LeftMenu from "components/LeftMenu.svelte";
  import TopMenu from "components/TopMenu.svelte";
  import Footer from "components/Footer.svelte";
  import CourseDetailView from "./CourseComponents/CourseDetailView.svelte";
  import { currentCourseId, leftMenuToggled } from "../../components/DataStore";
  export let courseId;
  export let tab;

  $currentCourseId = courseId;
  console.log("$currentCourseId", $currentCourseId);
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="courses" />
    </div>
    <div id="layoutSidenav_content">
      <CourseDetailView activeTab={tab} />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
