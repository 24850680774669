<script>
  import { onMount } from "svelte";
  export let textValue = undefined;

  let readabilityValue;
  let score = "N/A";
  let grade = "N/A";

  function updateScore() {
    if (textValue) {
      readabilityValue = calculateFleschResult(textValue);
      console.log("updateScore", textValue, readabilityValue);
      score = readabilityValue.fleschScore;
      grade = readabilityValue.fleschGrade;
    }
  }

  onMount(() => {
    updateScore();
  });

  function calculateFlesch(totalSentences, totalWords, totalSyllables) {
    return (
      206.835 -
      1.015 * (totalWords / totalSentences) -
      84.6 * (totalSyllables / totalWords)
    );
  }

  function calculateFleschGrade(totalSentences, totalWords, totalSyllables) {
    return (
      -15.59 +
      0.39 * (totalWords / totalSentences) +
      11.8 * (totalSyllables / totalWords)
    );
  }

  function getSyllables(word) {
    word = word.toLowerCase();
    if (word?.length <= 3) {
      return 1;
    }
    word = word?.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, "");
    word = word?.replace(/^y/, "");
    var syl = word?.match(/[aeiouy]{1,2}/g);
    return (syl && syl?.length) || 0;
  }

  function getLetters(text) {
    var rx = /[a-zA-Z]/gi;
    var m = text?.match(rx);
    return m?.length || 0;
  }

  function calculateFleschResult(text) {
    var totalSentences = 0,
      totalWords = 0,
      totalSyllables = 0;

    var sentences = text?.split(/[\\.!\?\r\n]/);

    sentences = sentences.filter((x) => x?.length);

    totalSentences = sentences?.length;
    sentences.forEach((sentence) => {
      var word = "";
      for (var i = 0; i < sentence.length; i++) {
        word += sentence[i];
        if (sentence[i] == " ") {
          totalWords++;
          totalSyllables += getSyllables(word);
          word = "";
        }
      }

      if (word?.length > 0) {
        totalWords++;
        totalSyllables += getSyllables(word);
        word = "";
      }
    });

    var result = {
      fleschScore: (
        Math.round(
          calculateFlesch(totalSentences, totalWords, totalSyllables) * 100
        ) / 100
      ).toFixed(0),
      fleschGrade: (
        Math.round(
          calculateFleschGrade(totalSentences, totalWords, totalSyllables) * 100
        ) / 100
      ).toFixed(1),
      totalSentences: totalSentences,
      totalWords: totalWords,
      totalSyllables: totalSyllables,
      totalCharacters: text.length,
      totalCharacters: getLetters(text),
    };
    //console.log("Flesch: ", result);

    return result;
  }
</script>

<span style="font-size:90%">
  Readability Score: <span class="boldValue">{score}</span> 
  Grade <span class="boldValue">{grade}</span>
  Characters: <span class="boldValue">{readabilityValue?.totalCharacters}</span> 
  Syllables: <span class="boldValue">{readabilityValue?.totalSyllables}</span>
  Words: <span class="boldValue">{readabilityValue?.totalWords}</span>
  Sentences: <span class="boldValue">{readabilityValue?.totalSentences}</span>
</span>

<style>
  .boldValue {
    font-weight: 600;
  }
</style>
