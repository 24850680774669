<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Breadcrumb from "components/Breadcrumb.svelte";
  import Loader from "components/Loader.svelte";
  import { onMount } from "svelte";
  import { processBlobToWav } from "../../../utils/CodecUtils.svelte";
  import {
    getData,
    getDataOutside,
    deleteData,
    patchData,
  } from "utils/ApiUtils.svelte";
  import {
    convertSecToMin,
    formatNumberWith4Colors,
  } from "utils/FormatUtils.svelte";

  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
    currentRecordingStudentName,
    userConfig,
    currentCourseRoleCheck,
  } from "../../../components/DataStore";
  import PhonemeBreakdown from "./PhonemeBreakdown.svelte";
  import TeacherScore from "./TeacherScore.svelte";

  let showAudioVisualization = false;
  let showDebug = false;
  let recordingData = {};
  let recordingDataDetail = { text: "" };
  let iOS = navigator.vendor == "Apple Computer, Inc.";
  let exerciseData = {};

  onMount(async () => {
    getRecording($currentRecordingId);
  });

  let recordingDetailStatus = "loading";

  let recordingUrl;
  //= "https://www.googleapis.com/download/storage/v1/b/shuoshuo/o/recordings%2FR_neVHyH_READ_Caren_2021-02-03T15:14:24.615Z.wav?generation=1612365266671798&alt=media";

  function getExercise(courseId, lessonId, exerciseId) {
    getData(
      "api/courses/" +
        courseId +
        "/lessons/" +
        lessonId +
        "/exercises/" +
        exerciseId,
    )
      .then((data) => {
        // console.log('exercises successful', data)
        exerciseData = data;
      })
      .catch((error) => {
        // console.log('error exercises', error)
      });

    getData(`api/courses/${$currentCourseId}/user-roles/check`)
      .then((data) => {
        console.log("courses successful", data);
        $currentCourseRoleCheck = data;
        // currentCourseName.update((x) => courseData?.name)
        // courseDetailStatus = 'ok'
      })
      .catch((error) => {
        console.log("error courses", error);
        courseDetailStatus = "error";
      });
  }

  function getRecording(recordingId) {
    getData("api/publicrecordings/" + recordingId)
      .then((data) => {
        // console.log('publicrecordings successful', data)
        recordingData = data;
        currentRecordingStudentName.update((x) => recordingData?.studentName);

        currentCourseId.update((x) => recordingData?.courseId);
        currentCourseName.update((x) => recordingData?.courseName);
        currentLessonId.update((x) => recordingData?.lessonId);
        currentLessonName.update((x) => recordingData?.lessonName);
        currentExerciseId.update((x) => recordingData?.exerciseId);
        currentExerciseName.update((x) => recordingData?.exerciseTitle);

        recordingDataDetail = JSON.parse(recordingData?.dataString);
        recordingDataDetail["Display"] = recordingDataDetail.NBest[0].Display;
        recordingDetailStatus = "ok";

        getExercise($currentCourseId, $currentLessonId, $currentExerciseId);

        let filePath = recordingData?.fileName.endsWith(".ogg")
          ? "https://www.googleapis.com/storage/v1/b/shuoshuo/o/recordings%2F" +
            recordingData?.fileName
          : "https://www.googleapis.com/storage/v1/b/shuoshuo/o/recordings%2F" +
            recordingData?.fileName +
            ".wav";

        getDataOutside(filePath)
          .then((data2) => {
            console.log("recordingUrl", data2);
            if (!iOS) {
              recordingUrl = data2?.mediaLink;
            } else {
              fetch(data2?.mediaLink).then((audioData) => {
                audioData.blob().then((audioBlob) => {
                  console.log(audioBlob);
                  processBlobToWav(audioBlob).then((audioResult) => {
                    console.log(audioResult);
                    recordingUrl = URL.createObjectURL(audioResult);
                  });
                });
              });
            }
          })
          .catch((error2) => {
            console.log("error get media", error2);
          });
      })
      .catch((error) => {
        console.log("error courses", error);
        recordingDetailStatus = "error";
      });
  }

  function showDetailsForWords(w) {
    console.log("showDetailsForWords", w);
  }

  const scoreSettings = {
    greenTop: 90,
    orangeTop: 70,
    redTop: 50,
  };

  function classBasedOnScore(score) {
    if (score > scoreSettings.greenTop) {
      return "success";
    } else if (score > scoreSettings.orangeTop) {
      return "primary";
    } else if (score > scoreSettings.redTop) {
      return "warning";
    }
    return "danger";
  }

  function printReport() {
    mixpanel.track("Teacher Recording Print Button Clicked");
    window.print();
  }

  function deleteRecording() {
    console.log("delete recording");
    mixpanel.track("Teacher Recording Delete Button Clicked");
    if (
      confirm(
        $_("RECORDING_DELETE_CONFIRM", {
          values: { studentName: $currentRecordingStudentName },
        }),
      )
    ) {
      // Save it!
      console.log("Thing was saved to the database.");
      deleteData("api/publicrecordings/" + $currentRecordingId)
        .then((data) => {
          console.log("recording deleted successfully", data);

          clickChangePage(
            "courses/" +
              $currentCourseId +
              "/lessons/" +
              $currentLessonId +
              "/exercise-detail/" +
              $currentExerciseId +
              "/tabRecording",
          );
        })
        .catch((error) => {
          console.log("error recording", error);
        });
    } else {
      console.log("Thing was not saved to the database.");
    }
  }

  let fixStudentNameFlag = false;

  function fixStudentNameSave() {
    // console.log('fix student name')
    mixpanel.track("Teacher Fix Student Name Button Clicked", {
      "Button Location": "Recording Detail Page",
    });
    if (
      confirm(
        $_("RECORDING_CHANGE_NAME_CONFIRM", {
          values: { studentName: $currentRecordingStudentName },
        }),
      )
    ) {
      // Save it!
      let patchPayload = {
        id: $currentRecordingId,
        studentName: recordingData.studentName,
      };

      console.log("Thing was saved to the database.");
      patchData("api/publicrecordings/" + $currentRecordingId, patchPayload)
        .then((data) => {
          console.log("recording patched successfully", data);
          currentRecordingStudentName.update((x) => recordingData?.studentName);
          //recordingDataDetail = JSON.parse(recordingData?.dataString);
          fixStudentNameFlag = false;
        })
        .catch((error) => {
          console.log("error patch recording - studentName", error);
        });
    } else {
      console.log("Thing was not saved to the database.");
    }
  }

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    $currentPage = e;
    router.goto("/" + e);
  }

  function mixpanelTrackPlay(e) {
    mixpanel.track("Teacher Audio Recording Play Clicked", {
      "Class ID": $currentCourseId,
      "Lesson ID": $currentCourseId,
      "Exercise ID": $currentExerciseId,
      "Recording ID": $currentRecordingId,
    });
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  {#if recordingDetailStatus === "loading"}
    <Loader />
  {:else}
    <div class="s-content-spacing">
      <div class="s-content-header">
        <Breadcrumb />
      </div>
    </div>

    <div class="s-content-spacing">
      <h4>
        <span class="hidden">{$currentRecordingId}</span>
        {#if recordingData?.studentAccountLogin}{$_(
            "STUDENT_ACCOUNT.STUDENT_ACCOUNT",
          )}{:else}{$_("STUDENT")}{/if}: {$currentRecordingStudentName}
        {#if recordingData?.studentAccountLogin}- <a
            href="/#/student-account-detail/{recordingData?.studentAccountId}"
            >{recordingData?.studentAccountLogin}</a
          >{/if}
      </h4>
    </div>

    <div class="s-content-spacing">
      <div class="row">
        <div class="col-6 col-lg-3 mb-4">
          <div
            class="card border-left-{classBasedOnScore(
              recordingData?.overallScore,
            )} shadow h-100 py-2"
          >
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                  >
                    {$_("OVERALL_SCORE")}
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {formatNumberWith4Colors(recordingData?.overallScore)}
                      </div>
                    </div>
                    <div class="col">
                      <div class="progress progress-sm mr-2">
                        <div
                          class="progress-bar bg-{classBasedOnScore(
                            recordingData?.overallScore,
                          )}"
                          role="progressbar"
                          style="width: {recordingData?.overallScore}%"
                          aria-valuenow={recordingData?.overallScore}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-clipboard-list fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-lg-3 mb-4">
          <div
            class="card border-left-{classBasedOnScore(
              recordingData?.accuracyScore,
            )} shadow h-100 py-2"
          >
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-info text-uppercase mb-1"
                  >
                    {$_("ACCURACY_SCORE")}
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {formatNumberWith4Colors(recordingData?.accuracyScore)}
                      </div>
                    </div>
                    <div class="col">
                      <div class="progress progress-sm mr-2">
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          style="width: {recordingData?.accuracyScore}%"
                          aria-valuenow={recordingData?.accuracyScore}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="far fa-lightbulb fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {#if exerciseData.type === "READ" || exerciseData.type === "READ_LONG"}
          <div class="col-6 col-lg-3 mb-4">
            <div
              class="card border-left-{classBasedOnScore(
                recordingData?.completenessScore,
              )} shadow h-100 py-2"
            >
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div
                      class="text-xs font-weight-bold text-info text-uppercase mb-1"
                    >
                      {$_("COMPLETENESS_SCORE")}
                    </div>
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto">
                        <div
                          class="h5 mb-0 mr-3 font-weight-bold text-gray-800"
                        >
                          {formatNumberWith4Colors(
                            recordingData?.completenessScore,
                          )}
                        </div>
                      </div>
                      <div class="col">
                        <div class="progress progress-sm mr-2">
                          <div
                            class="progress-bar bg-info"
                            role="progressbar"
                            style="width: {recordingData?.completenessScore}%"
                            aria-valuenow={recordingData?.completenessScore}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-compress-arrows-alt fa-2x text-gray-300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/if}

        <div class="col-6 col-lg-3 mb-4">
          <div
            class="card border-left-{classBasedOnScore(
              recordingData?.fluencyScore,
            )} shadow h-100 py-2"
          >
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-info text-uppercase mb-1"
                  >
                    {$_("FLUENCY_SCORE")}
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {formatNumberWith4Colors(recordingData?.fluencyScore)}
                      </div>
                    </div>
                    <div class="col">
                      <div class="progress progress-sm mr-2">
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          style="width: {recordingData?.fluencyScore}%"
                          aria-valuenow={recordingData?.fluencyScore}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-wind fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-lg-3 mb-4">
          <div
            class="card border-left-{classBasedOnScore(
              recordingData?.pronScore,
            )} shadow h-100 py-2"
          >
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-info text-uppercase mb-1"
                  >
                    {$_("PRONUNCIATION_SCORE")}
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {formatNumberWith4Colors(recordingData?.pronScore)}
                      </div>
                    </div>
                    <div class="col">
                      <div class="progress progress-sm mr-2">
                        <div
                          class="progress-bar bg-info"
                          role="progressbar"
                          style="width: {recordingData?.pronScore}%"
                          aria-valuenow={recordingData?.pronScore}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-crosshairs fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {#if (exerciseData.type === "READ" || exerciseData.type === "READ_LONG") && recordingData?.similarityValue}
          <div class="col-6 col-lg-3 mb-4">
            <div
              class="card border-left-{classBasedOnScore(
                recordingData?.similarityValue,
              )} shadow h-100 py-2"
            >
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div
                      class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                    >
                      {$_("SIMILARITY_SCORE")}
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                      {formatNumberWith4Colors(recordingData?.similarityValue)}
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-people-arrows fa-2x text-gray-300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/if}

        {#if recordingData?.prosodyScore}
          <div class="col-6 col-lg-3 mb-4">
            <div
              class="card border-left-{classBasedOnScore(
                recordingData?.prosodyScore,
              )} shadow h-100 py-2"
            >
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div
                      class="text-xs font-weight-bold text-info text-uppercase mb-1"
                    >
                      {$_("PROSODY_SCORE")}
                    </div>
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto">
                        <div
                          class="h5 mb-0 mr-3 font-weight-bold text-gray-800"
                        >
                          {formatNumberWith4Colors(recordingData?.prosodyScore)}
                        </div>
                      </div>
                      <div class="col">
                        <div class="progress progress-sm mr-2">
                          <div
                            class="progress-bar bg-info"
                            role="progressbar"
                            style="width: {recordingData?.prosodyScore}%"
                            aria-valuenow={recordingData?.prosodyScore}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-crosshairs fa-2x text-gray-300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/if}

        <div class="col-6 col-lg-3 mb-4">
          <div class="card border-left-dark shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                  >
                    {$_("SPOKEN_WORD_COUNT")}
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {formatNumberWith4Colors(recordingData?.wordCount)}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-comments fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 col-lg-3 mb-4">
          <div class="card border-left-dark shadow h-100 py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                  >
                    {$_("WORDS_PER_MINUTE")}
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    {#if recordingData?.wordPerMinute}
                      {recordingData?.wordPerMinute?.toFixed(1)}
                    {/if}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fas fa-tachometer-alt fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="s-content-spacing">
      <div class="card shadow">
        <div class="card-header py-3 no-print">
          <!-- 
            // Related to SEN-916
            <button class="btn btn-primary s-button-primary" on:click={printReport}
            ><i class="fa fa-print" style="padding-right: 8px;" />{$_(
              'PRINT'
            )}<br /></button
          > -->

          <div class="btn-group ml-4" role="group">
            <button
              class="btn btn-danger s-button-danger s-mr-10px"
              disabled={"RW" !== $currentCourseRoleCheck?.permissions}
              on:click={deleteRecording}
            >
              <i class="fas fa-trash" style="padding-right: 8px;" />{$_(
                "DELETE",
              )}
            </button>
          </div>

          <div class="btn-group ml-4" role="group">
            <button
              class="btn btn-danger s-button-danger"
              disabled={"RW" !== $currentCourseRoleCheck?.permissions}
              on:click={() => {
                fixStudentNameFlag = !fixStudentNameFlag;
              }}
            >
              <i class="fas fa-wrench" style="padding-right: 8px;" />{$_(
                "FIX_STUDENT_NAME",
              )}
            </button>
          </div>
          {#if fixStudentNameFlag}
            <span class="ms-2">
              <label for="teacherScore">{$_("STUDENT_NAME")}</label>
              <input
                id="studentName"
                type="text"
                placeholder=""
                class="form-control w-25"
                bind:value={recordingData.studentName}
              />

              <button
                class="btn btn-primary s-button-primary"
                on:click={fixStudentNameSave}
                ><i class="fa fa-save" style="padding-right: 8px;" />{$_(
                  "SAVE",
                )}</button
              >
            </span>
          {/if}
        </div>
        <div class="card-body">
          <div>
            <table class="table">
              <tbody>
                <tr
                  ><th scope="row">{$_("RECORDING_TIME")}</th><td>
                    {#if recordingData?.createdAt}
                      {new Date(
                        Date.parse(recordingData?.createdAt + "Z"),
                      ).toLocaleString()}
                    {/if}</td
                  ></tr
                >
                <tr>
                  <th scope="row">{$_("DURATION")}</th>
                  <td>
                    {#if recordingData?.durationSecond}
                      {convertSecToMin(recordingData?.durationSecond)}
                    {/if}
                  </td>
                </tr>
                <tr>
                  <th scope="row">{$_("AUDIO_RECORDING")}</th><td>
                    {#key recordingUrl}
                      {#if iOS}
                        <audio controls on:play={mixpanelTrackPlay}>
                          {#if iOS}
                            <source src={recordingUrl} type="audio/mpeg" />
                          {:else}
                            <source src={recordingUrl} type="audio/wave" />
                          {/if}

                          <track
                            default
                            kind="captions"
                            srclang="en"
                            src=""
                          />{$_("NOT_SUPPORTED")}</audio
                        >
                      {:else}
                        <audio
                          controls
                          src={recordingUrl}
                          on:play={mixpanelTrackPlay}
                        >
                          <source src={recordingUrl} type="audio/mp3" />
                          <source src={recordingUrl} type="audio/wave" />
                          <track
                            default
                            kind="captions"
                            srclang="en"
                            src=""
                          />{$_("NOT_SUPPORTED")}</audio
                        >
                      {/if}
                    {/key}
                  </td></tr
                >
                {#if exerciseData.type === "READ"}
                  <tr>
                    <th scope="row">{$_("EXERCISE_TEXT")}</th><td
                      ><div>{exerciseData?.text}</div></td
                    >
                  </tr>
                {:else if exerciseData.type === "QUESTION"}
                  <tr>
                    <th scope="row">{$_("EXERCISE_QUESTION")}</th><td
                      ><div>{exerciseData?.question}</div></td
                    >
                  </tr>
                {/if}
                <tr
                  ><th scope="row">{$_("RECOGNIZED_TEXT")}</th><td
                    ><pre
                      style="white-space: pre-wrap;">{recordingDataDetail?.Display}</pre></td
                  ></tr
                >
              </tbody>
            </table>

            <form />
          </div>
        </div>
      </div>
    </div>

    <div class="s-content-spacing">
      <TeacherScore {recordingData} />
    </div>

    <div class="s-content-spacing">
      <div class="card shadow" style="margin-top: 10px">
        <div class="card-header" id="headingFive">
          <h4 class="mb-0">{$_("TEXT_ANALYZER")}</h4>
          <small
            ><a
              href="#/courses/{recordingData?.courseId}/lessons/{recordingData?.lessonId}/exercise-detail/{recordingData?.exerciseId}/"
              >{recordingData?.exerciseTitle}</a
            >
            | {$currentRecordingStudentName}
            {#if recordingData?.studentAccountLogin}
              - <a
                href="/#/student-account-detail/{recordingData?.studentAccountId}"
                >{recordingData?.studentAccountLogin}</a
              >{/if} | {formatNumberWith4Colors(recordingData?.overallScore)}</small
          >
        </div>
        <div class="card-body">
          {#key recordingUrl || exerciseData.language}
            <PhonemeBreakdown
              {recordingUrl}
              language={exerciseData.language}
              resultData={recordingDataDetail}
            />
          {/key}
        </div>
      </div>
    </div>
  {/if}
</main>

<style>
  .excellent {
    color: #008154;
  }

  .good {
    color: #40ac86;
  }

  .poor {
    color: #f7ba31;
  }

  .bad {
    color: #fe5b5b;
  }

  .hidden {
    display: none;
  }
</style>
