<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { onMount } from "svelte";
  import { getData, postData, deleteData } from "../../utils/ApiUtils.svelte";
  import { dateDiffInDays } from "../../utils/SortingUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { userDetails, userConfig } from "../../components/DataStore";
  import OrganizationTeacherTable from "./OrganizationTeacherTable.svelte";
  import OrganizationCourseTable from "./OrganizationCourseTable.svelte";
  import { getProfile } from "../../utils/ApiCalls.svelte";
  import OrganizationStudentTable from "./OrganizationStudentTable.svelte";
  import OrganizationPendingInvitation from "./OrganizationPendingInvitation.svelte";
  import OrganizationTeacherClassTable from "./OrganizationTeacherClassTable.svelte";
  import OrganizationStudentRosterTable from "./OrganizationStudentRosterTable.svelte";
  import Tooltip from "../../components/Tooltip.svelte";

  export let organizationId;
  onMount(async () => {
    getOrganization();
    getOrganizationStatistics();
  });

  let organizationData = {};
  let organizationStatus = "new";
  let teachersData = [];
  let statisticsData = {};
  let statisticsStatus = "new";

  let teacherLimit = 10;
  let studentLimit = 30;
  let recordingLimit = 10;

  let invitationCount = undefined;

  export let activeTab;

  if (!activeTab) {
    activeTab = "OrganizationTeacherTable";
  }

  function changeTab(page) {
    activeTab = page;
    router.goto(`/organization-management/${page}`);
  }

  function refreshList() {
    getOrganization();
    getOrganizationStatistics();
  }

  function getOrganization() {
    organizationStatus = "loading";
    getData(`api/organization/${organizationId}`, false, false)
      .then((data) => {
        console.log("organization successful", data);
        // Save data to sessionStorage
        organizationData = data;

        // if (organizationData.length === 0) {
        //   organizationStatus = 'ERROR_NO_ORGANIZATION'
        //   return
        // }
        // if (organizationData.length > 1) {
        //   organizationStatus = 'ERROR_MORE_THAN_ONE_ORGANIZATION'
        //   return
        // }
        organizationStatus = "ok";
        console.log(333, organizationData.id);

        teacherLimit = JSON.parse(
          organizationData?.currentSubscriptionFlags,
        )?.numberOfTeachers;

        studentLimit = JSON.parse(
          organizationData?.currentSubscriptionFlags,
        )?.numberOfStudent;

        recordingLimit = JSON.parse(
          organizationData?.currentSubscriptionFlags,
        )?.organizationRecordingLimit;
      })
      .catch((error) => {
        console.log("error organization", error);
      });
  }

  function getOrganizationStatistics() {
    statisticsStatus = "loading";
    getData(`api/organization/${organizationId}/statistics`, false, false)
      .then((data) => {
        console.log("organization teachers successful", data);
        // Save data to sessionStorage
        statisticsData = data[0];
        statisticsStatus = "ok";
      })
      .catch((error) => {
        console.log("error organization statistics", error);
      });
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">
        {$_("ORGANIZATION_MANAGEMENT")}
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    <div class="card shadow">
      <!-- <div
        class="card-header py-3 no-print d-flex flex-row align-items-center justify-content-between"
      >
        <div>
          <button
            class="btn btn-primary s-button-primary"
            id="reloadExerciseListButton"
            on:click={refreshList}
            ><i class="fa fa-refresh" style="padding-right: 8px;" />{$_(
              "REFRESH"
            )}<br /></button
          >
          <h3>{$_("ORGANIZATION.NAME")}</h3>
        </div>
      </div> -->
      <div class="card-body">
        {#if organizationStatus === "loading" || statisticsStatus === "loading"}
          <Loader />
        {:else if organizationStatus === "ok" && statisticsStatus === "ok"}
          <div class="row">
            <div class="col-3">
              {$_("ORGANIZATION.NAME")}
            </div>
            <div class="col-auto strong">
              {organizationData?.organizationName}
            </div>
          </div>

          <!-- {#if organizationData?.subscriptionType}
            <div class="row">
              <div class="col-3">
                {$_("ORGANIZATION.SUBSCRIPTION_TYPE")}
              </div>
              <div class="col-auto">{organizationData?.subscriptionType}</div>
            </div>
          {/if}

          {#if organizationData?.currentSubscriptionLevel}
            <div class="row">
              <div class="col-3">
                {$_("ORGANIZATION.SUBSCRIPTION_LEVEL")}
              </div>
              <div class="col-auto">
                {organizationData?.currentSubscriptionLevel}
              </div>
            </div>
          {/if} -->

          <div class="row">
            <div class="col-3">
              {$_("ORGANIZATION.SUBSCRIPTION_EXPIRATION")}
            </div>
            <div class="col-auto">
              {#if organizationData?.currentSubscriptionExpiration}{new Date(
                  organizationData?.currentSubscriptionExpiration,
                ).toLocaleDateString()}{:else}{$_("UNLIMITED")}{/if}
            </div>
            <div class="col-auto">
              ({$_("REMAINING")}:
              {dateDiffInDays(
                new Date(),
                new Date(
                  Date.parse(organizationData.currentSubscriptionExpiration),
                ),
              )}
              {$_("DAYS")})
            </div>
          </div>

          <!-- <div class="row" style="display: none;">
              <div class="col-3">
                {$_("ORGANIZATION.SUBSCRIPTION_FLAGS")}
              </div>
              <div class="col-auto">
                {o?.currentSubscriptionFlags}
              </div>
            </div> -->

          <div class="row">
            <div class="col-3">
              {$_("ORGANIZATION.TEACHER_LIMIT")}
            </div>
            <div class="col-auto">
              {#if statisticsData?.teacherCount}{statisticsData?.teacherCount}{:else}0{/if}
              / {teacherLimit}
            </div>
          </div>

          <div class="row">
            <div class="col-3">
              {$_("ORGANIZATION.STUDENT_LIMIT")}  <Tooltip title={$_("ORGANIZATION.TEACHER_LIMIT_TOOLTIP")}>
                <i class="fa-solid fa-circle-info" />
              </Tooltip>
            </div>
            <div class="col-auto">
              {#if statisticsData?.studentAccountCount}{statisticsData?.studentAccountCount}{:else}0{/if}
              / {studentLimit}
            </div>
            <div class="col-auto">
              ({$_("ORGANIZATION.INVITATION_COUNT")}: {#if invitationCount}{invitationCount}{:else}0{/if})
            </div>
          </div>

          <div class="row">
            {#if recordingLimit && recordingLimit !== 999999}
              <div class="col-3">
                {$_("ORGANIZATION.RECORDING_LIMIT")}
              </div>
              <div class="col-auto">
                {#if statisticsData?.recordingCount}{statisticsData?.recordingCount}{/if}
                / {recordingLimit}
              </div>
            {:else}
              <div class="col-3">
                {$_("ORGANIZATION.RECORDING_TOTAL")}
              </div>
              <div class="col-auto">
                {#if statisticsData?.recordingCount}{statisticsData?.recordingCount}{/if}
              </div>
            {/if}
          </div>
        {:else if organizationStatus === "ERROR_NO_ORGANIZATION"}
          <div class="alert alert-danger" role="alert">
            {$_("ORGANIZATION.ERROR_NO_ORGANIZATION")}
          </div>
        {:else if organizationStatus === "ERROR_MORE_THAN_ONE_ORGANIZATION"}
          <div class="alert alert-danger" role="alert">
            {$_("ORGANIZATION.ERROR_MORE_THAN_ONE_ORGANIZATION")}
          </div>
        {:else}
          {$_("ERROR")} - {organizationStatus}
        {/if}
      </div>
    </div>
  </div>

  <div class="s-content-spacing" style="margin-bottom: 0 !important;">
    <ul class="nav nav-tabs">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li
        class="nav-item"
        on:click={(e) => changeTab("OrganizationTeacherTable")}
      >
        <span
          class="nav-link"
          class:active={activeTab === "OrganizationTeacherTable"}
          >{$_("ORGANIZATION.ORGANIZATION_TEACHERS")}</span
        >
      </li>

      <li
        class="nav-item"
        on:click={(e) => changeTab("OrganizationPendingInvitation")}
      >
        <span
          class="nav-link"
          class:active={activeTab === "OrganizationPendingInvitation"}
          on:click={() => {
            activeTab === "OrganizationPendingInvitation";
          }}
        >
          {$_("ORGANIZATION.PENDING_STUDENT_INVITATIONS")}</span
        >
      </li>

      <li
        class="nav-item"
        on:click={(e) => changeTab("OrganizationStudentTable")}
      >
        <span
          class="nav-link"
          class:active={activeTab === "OrganizationStudentTable"}
          on:click={() => {
            activeTab === "OrganizationStudentTable";
          }}
        >
          {$_("ORGANIZATION.ORGANIZATION_STUDENTS_CLASSES")}</span
        >
      </li>

      <li
        class="nav-item"
        on:click={(e) => changeTab("OrganizationStudentRosterTable")}
      >
        <span
          class="nav-link"
          class:active={activeTab === "OrganizationStudentRosterTable"}
          on:click={() => {
            activeTab === "OrganizationStudentRosterTable";
          }}
        >
          {$_("ORGANIZATION.ORGANIZATION_STUDENTS_ROSTER")}</span
        >
      </li>

      <li
        class="nav-item"
        on:click={(e) => changeTab("OrganizationTeacherClassTable")}
      >
        <span
          class="nav-link"
          class:active={activeTab === "OrganizationTeacherClassTable"}
          on:click={() => {
            activeTab === "OrganizationTeacherClassTable";
          }}
        >
          {$_("ORGANIZATION.TEACHER_CLASS_LIST")}</span
        >
      </li>
    </ul>
  </div>

  {#if organizationId}
    <div class="s-content-spacing" style="margin-top: 0 !important;">
      {#key activeTab}
        {#if activeTab === "OrganizationTeacherTable"}
          <OrganizationTeacherTable
            {organizationId}
            {teacherLimit}
            on:update={() => {
              refreshList();
              getProfile();
            }}
          />
        {:else if activeTab === "OrganizationPendingInvitation"}
          <OrganizationPendingInvitation
            {organizationId}
            on:load={(e) => {
              invitationCount = e?.detail?.size;
            }}
          />
        {:else if activeTab === "OrganizationStudentTable"}
          <OrganizationStudentTable
            {organizationId}
            on:update={() => {
              refreshList();
              getProfile();
            }}
          />
        {:else if activeTab === "OrganizationStudentRosterTable"}
          <OrganizationStudentRosterTable
            {organizationId}
            on:update={() => {
              refreshList();
              getProfile();
            }}
          />
        {:else if activeTab === "OrganizationTeacherClassTable"}
          <OrganizationTeacherClassTable
            {organizationId}
            on:update={() => {
              refreshList();
              getProfile();
            }}
          />
        {/if}
      {/key}
    </div>
  {/if}
</main>

<style>
  .strong {
    font-weight: 700;
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid #00a094 !important;
    border-bottom-width: 0px !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-item {
    margin-right: 1rem;
    cursor: pointer;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 0px;
  }
  .nav {
    border-bottom: 4px solid #00a094;
    color: black;
  }

  .nav-link {
    color: #575f59;
  }

  .nav-link.active {
    background-color: #00a094;
    color: white;
  }
</style>
