<script>
  import { onMount } from 'svelte'
  import { cleanCharactersForSSML } from 'utils/FormatUtils.svelte'
  export let textToRead
  export let language
  let ttsButtonState = 'starting'

  // subscription key and region for speech services.
  let subscriptionKey
  let serviceRegion = 'eastasia'
  let authorizationToken = ''
  let SpeechSDK
  let synthesizer
  let player
  let audioConfig
  let ttsToken
  let speechCounter = 0

  const languageMapping = {
    'en-US': {
      alternatives: ['en-us'],
      voiceNames: ['en-US-GuyNeural', 'en-US-JennyNeural'],
    },
    'en-GB': {
      alternatives: ['en-gb', 'en-uk', 'en-UK'],
      voiceNames: ['en-GB-LibbyNeural', 'en-GB-RyanNeural'],
    },
    'zh-TW': {
      alternatives: ['zh-tw'],
      voiceNames: ['zh-TW-HsiaoChenNeural', 'zh-TW-YunJheNeural'],
    },
    'cs-CZ': {
      alternatives: ['cs-cz'],
      voiceNames: ['cs-CZ-VlastaNeural', 'cs-CZ-AntoninNeural'],
    },
    'jp-JP': {
      alternatives: ['jp-JP'],
      voiceNames: ['ja-JP-NanamiNeural', 'ja-JP-KeitaNeural'],
    },
    'zh-CN': {
      alternatives: ['zh-cn'],
      voiceNames: ['zh-CN-XiaoxiaoNeural', 'zh-CN-YunyangNeural'],
    },
    'de-DE': {
      alternatives: ['de-DE'],
      voiceNames: ['de-DE-KatjaNeural', 'de-DE-ConradNeural'],
    },
  }

  onMount(async () => {
    intialize()
  })

  function intialize() {
    if (!!window.SpeechSDK) {
      SpeechSDK = window.SpeechSDK
      ttsButtonState = 'starting'

      initPlayPage()
    } else {
      ttsButtonState = 'error'
      console.log('error with SpeechSDK')
    }
  }

  function initPlayPage() {
    let data = {
      'Ocp-Apim-Subscription-Key': '07314c54f87947ecbb1b93c067a7c463',
    }
    return postToken(
      'https://eastasia.api.cognitive.microsoft.com/sts/v1.0/issuetoken',
      data
    )
      .then((data) => {
        console.log('token', data)
        ttsToken = data
        ttsButtonState = 'ready'
      })
      .catch((error) => {
        console.log('token', error)
        ttsButtonState = 'error'
      })
  }

  async function postToken(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
        ...data,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    console.log('postToken - status', response.status)
    //uploadStatus.innerHTML = response.status;
    return response.text() // parses JSON response into native JavaScript objects
  }

  function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }
  function checkTokenValid(ttsToken) {
    const jwtPartExp = parseJwt(ttsToken)?.exp

    return new Date(jwtPartExp * 1000) >= new Date()
  }

  function playTextToSpeech() {
    if (ttsToken != null) {
      authorizationToken = ttsToken
    } else {
      console.log('missing token')
      return
    }

    if (!checkTokenValid(ttsToken)) {
      initPlayPage().then(() => playTextToSpeech())
      return
    }

    let voiceName

    // if we got an authorization token, use the token. Otherwise use the provided subscription key
    let speechConfig
    if (authorizationToken) {
      speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
        authorizationToken,
        serviceRegion
      )
    } else {
      if (
        subscriptionKey.value === '' ||
        subscriptionKey.value === 'subscription'
      ) {
        ttsButtonState = 'error'
        return
      }
      speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        subscriptionKey.value,
        serviceRegion
      )
    }

    console.log('language', language)

    if (!languageMapping[language]) {
      for (const [key, value] of Object.entries(languageMapping)) {
        const found = value.alternatives.find((el) => el == language)
        if (found) {
          language = key
          console.log(found)
        }
      }
    }

    speechConfig.speechSynthesisLanguage = language

    if (languageMapping[language]) {
      let numberOfVoice = languageMapping[language].voiceNames.length
      let selectedVoice =
        languageMapping[language].voiceNames[speechCounter % 2]
      console.log('numberOfVoice', numberOfVoice)
      console.log('selectedVoice', selectedVoice)
      speechConfig.speechSynthesisVoiceName = selectedVoice
      voiceName = selectedVoice
    } else {
      console.log('Error no language is available')
    }

    speechCounter++

    //speechConfig.speechSynthesisOutputFormat =
    //  SpeechSDK.SpeechSynthesisOutputFormat.Ogg16Khz16BitMonoOpus;

    player = new SpeechSDK.SpeakerAudioDestination()
    audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player)
    synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig)

    ttsButtonState = 'playing'

    let ssml =
      `<speak xmlns="http://www.w3.org/2001/10/synthesis"
       xmlns:mstts="http://www.w3.org/2001/mstts"
       xmlns:emo="http://www.w3.org/2009/10/emotionml"
       version="1.0"
       xml:lang="` +
      language +
      `">
	<voice name="` +
      voiceName +
      `">
		<prosody rate="-20%"
		         pitch="0%">` +
      cleanCharactersForSSML(textToRead) +
      `</prosody>
	</voice>
</speak>`

    synthesizer.speakSsmlAsync(
      ssml,
      function (result) {
        console.log(result)
        if (
          result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
        ) {
          console.log('synthesis finished for [' + textToRead + ']')
        } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
          console.log('synthesis failed. Error detail: ' + result.errorDetails)
          ttsButtonState = 'error'
          initPlayPage()
        }
        synthesizer.close()
        synthesizer = undefined
      },
      function (err) {
        ttsButtonState = 'error'
        console.log('Synthesizer Error', err)

        synthesizer.close()
        synthesizer = undefined
        initPlayPage()
      }
    )

    player.onAudioEnd = function (s) {
      console.log('onAudioEnd', s)
      ttsButtonState = 'ready'
    }
  }

  export function stopTextToSpeech() {
    console.log(player)
    if (player != null) {
      player.pause()
      ttsButtonState = 'ready'
    }
  }

  function clickPlayTTS() {
    console.log('clickPlayTTS', ttsButtonState)
    if (ttsButtonState === 'ready') {
      mixpanel.track('Teacher Recording Page Text Analyzer Play AI Clicked')
      playTextToSpeech()
      ttsButtonState = 'playing'
    } else if (ttsButtonState === 'playing') {
      stopTextToSpeech()
    }
  }
</script>

{#if ttsButtonState === 'starting'}
  TTS initializing...
{:else if ttsButtonState === 'playing'}
  <button
    class="btn btn-primary s-button-primary"
    style="padding: 6px 12px 6px 12px"
    on:click={clickPlayTTS}
    type="button"
  >
    <i class="fas fa-volume-up spin" />
  </button>
{:else if ttsButtonState === 'ready'}
  <button
    class="btn btn-primary s-button-primary"
    style="padding: 6px 12px 6px 12px"
    on:click={clickPlayTTS}
    type="button"><i class="fas fa-volume-up" /></button
  >
{:else if ttsButtonState === 'error'}TTS Error!{/if}

<style>
  .spin {
    animation-name: stretch;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes stretch {
    50% {
      color: black;
    }
  }
</style>
