<svg
  width="16px"
  height="16px"
  viewBox="0 0 20 20"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
>
  <path
    fill="#000000"
    fill-rule="evenodd"
    d="M15.293 8.707a1 1 0 101.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 001.414 1.414L10 3.414l5.293 5.293zM4.707 11.293a1 1 0 00-1.414 1.414l6 6a1 1 0 001.414 0l6-6a1 1 0 00-1.414-1.414L10 16.586l-5.293-5.293z"
  />
</svg>
