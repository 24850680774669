<script>
  import { _ } from 'svelte-i18n'
  import { writable } from 'svelte/store'
  import { patchData } from 'utils/ApiUtils.svelte'
  import { onMount } from 'svelte'
  import * as yup from 'yup'

  export let recordingData // = writable({});
  export let teacherScoreData = writable({})

  let scoreStatus = 'new'
  let errors
  const scoreSchema = yup.object().shape({
    "teacherScore": 
    yup.mixed().test(
  'is-empty-null-or-numeric',
  'Value must be either empty, null or a numeric value between 0 and 100',
  value => {
    return (
      value === '' || // Check if value is empty
      value === null || // Check if value is null
      (
        parseInt(value) && // Check if value is a number
        value >= 0 && value <= 100 // Check if value is between 0 and 100
      )
    );
  }
),
    "teacherLabel": yup.string().nullable(),
    "teacherComment": yup.string().nullable(),
  })

  teacherScoreData.subscribe(() =>{
    scoreStatus = 'new'
  })

  onMount(async () => {
    if (recordingData) {
      $teacherScoreData.teacherScore = recordingData.teacherScore
      $teacherScoreData.teacherLabel = recordingData.teacherLabel
      $teacherScoreData.teacherComment = recordingData.teacherComment
    }
  })

  async function saveScore() {
    try {
      errors = null
      await scoreSchema.validate($teacherScoreData, {
        abortEarly: false,
      })
      

      scoreStatus = 'saving'

      let payloadData = {
        id: recordingData?.id,
        teacherScore: $teacherScoreData.teacherScore,
        teacherLabel: $teacherScoreData.teacherLabel,
        teacherComment: $teacherScoreData.teacherComment,
      }

      // console.log('saveScore', payloadData)
      mixpanel.track('Teacher Recording Page Save Button Clicked', {
        'Recording ID': recordingData?.id,
        'Teacher Score': $teacherScoreData.teacherScore,
        'Teacher Label': $teacherScoreData.teacherLabel,
        'Teacher Comment': $teacherScoreData.teacherComment,
      })

      patchData('api/publicrecordings/' + recordingData?.id, payloadData)
        .then((data) => {
          // console.log('saveScore successful', data)
          scoreStatus = 'saved'
        })
        .catch((error) => {
          // console.log('error saveScore', error)
          scoreStatus = 'error'
        })
    } catch (err) {
      errors = err.inner.reduce((acc, err) => {
        return { ...acc, [err.path]: err.message }
      }, {})
      console.log(333, errors)
    }
  }

  function copyOverallScore() {
    $teacherScoreData.teacherScore = Number.parseFloat(
      recordingData?.overallScore
    ).toFixed(0)
    mixpanel.track('Teacher Recording Page Copy Overall Button Clicked')
    console.log('copyOverallScore', recordingData)
  }

  function skipEnterOnInput(event) {
    console.log(event)
    if (event.key === 'Enter' && !event.ctrlKey) {
      event.preventDefault()
    } else if (event.key === 'Enter' && event.ctrlKey) {
      console.log('Save')
      saveLesson(true)
    }
  }

  function handleCtrlEnter(event) {
    if ((event.keyCode == 10 || event.keyCode == 13) && event.ctrlKey) {
      console.log('Save')
      saveLesson(true)
    }
  }
</script>

<div class="card shadow mt-3">
  <div class="card-header" id="headingFive">
    <h4 class="mb-0">{$_('TEACHER_SCORE_AND_COMMENT')}</h4>
  </div>
  <div class="card-body">
    <div>
      <div class="row">
        <div class="col-lg-6 offset-lg-0">
          <div class="form-row">
            <label class="mb-3" for="teacherScore">{$_('TEACHER_SCORE')}</label
            ><button class="ml-3" on:click={copyOverallScore}>
              {$_('COPY_OVERALL')}</button
            >
            <input
              id="teacherScore"
              type="text"
              placeholder=""
              class="form-control"
              class:validation-input-box-error={errors}
              bind:value={$teacherScoreData.teacherScore}
              on:keydown={skipEnterOnInput}
            />
            <div class="validation-text-error">
              {#if errors}
                {$_('ENTER_VALID_TEACHER_SCORE')}
              {/if}
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-6 offset-lg-0">
          <div class="form-row">
            <label class="mb-3" for="scoreLabel">{$_('TEACHER_LABEL')}</label>
            <select
              class="custom-select"
              id="scoreLabel"
              bind:value={$teacherScoreData.teacherLabel}
              on:keydown={skipEnterOnInput}
            >
              <option selected value="" />
              <option value={$_('TEACHER_LABEL_OPTIONS.OK')}
                >{$_('TEACHER_LABEL_OPTIONS.OK')}</option
              >
              <option value={$_('TEACHER_LABEL_OPTIONS.AWESOME')}
                >{$_('TEACHER_LABEL_OPTIONS.AWESOME')}</option
              >
              <option value={$_('TEACHER_LABEL_OPTIONS.CONTENT_IRRELEVANT')}
                >{$_('TEACHER_LABEL_OPTIONS.CONTENT_IRRELEVANT')}</option
              >
              <option value={$_('TEACHER_LABEL_OPTIONS.CUT_OFF')}
                >{$_('TEACHER_LABEL_OPTIONS.CUT_OFF')}</option
              >
              <option value={$_('TEACHER_LABEL_OPTIONS.WRONG_LANGUAGE')}
                >{$_('TEACHER_LABEL_OPTIONS.WRONG_LANGUAGE')}</option
              >
              <option value={$_('TEACHER_LABEL_OPTIONS.MINOR_SST_ISSUE')}
                >{$_('TEACHER_LABEL_OPTIONS.MINOR_SST_ISSUE')}</option
              >
              <option value={$_('TEACHER_LABEL_OPTIONS.GRAMMAR_ISSUE')}
                >{$_('TEACHER_LABEL_OPTIONS.GRAMMAR_ISSUE')}</option
              >
              <option value={$_('TEACHER_LABEL_OPTIONS.VOCABULARY_ISSUE')}
                >{$_('TEACHER_LABEL_OPTIONS.VOCABULARY_ISSUE')}</option
              >
              <option value={$_('TEACHER_LABEL_OPTIONS.FLAG_FOR_REVIEW')}
                >{$_('TEACHER_LABEL_OPTIONS.FLAG_FOR_REVIEW')}</option
              >
            </select>
          </div>
        </div> -->
      </div>

      <div class="col-lg-12 offset-lg-0">
        <div class="form-row">
          <label for="teacherComment">{$_('TEACHER_COMMENT')}</label>
          <textarea
            id="teacherComment"
            class="form-control"
            bind:value={$teacherScoreData.teacherComment}
            on:keydown={handleCtrlEnter}
          />
        </div>
      </div>
    </div>
  </div>
  <div class="card-header py-3 no-print">
    {#if scoreStatus === 'new'}
      <button class="btn btn-primary s-button-primary" on:click={saveScore}
        ><i class="fa fa-save" style="padding-right: 8px;" />{$_('SAVE')}<br
        /></button
      >
    {:else if scoreStatus === 'saved'}
    <div class="alert alert-primary alert-dismissible fade show" role="alert">
      {$_("SAVED")}
    </div>
    <button class="btn btn-primary s-button-primary" on:click={saveScore}
        ><i class="fa fa-save" style="padding-right: 8px;" />{$_('SAVE')}<br
        /></button
      >
      {:else if scoreStatus === 'saving'}<button
        class="btn btn-primary s-button-primary"
        disabled
        on:click={saveScore}
        ><i class="fa fa-save" style="padding-right: 8px;" />{$_('SAVING')}<br
        /></button
      >
    {:else if scoreStatus === 'error'}{$_('ERROR_SAVING')}
    {/if}
  </div>
</div>
