<script>
  import LeftMenu from "../../components/LeftMenu.svelte";
  import TopMenu from "../../components/TopMenu.svelte";
  import Footer from "../../components/Footer.svelte";
  import LessonDetailView from "./LessonComponents/LessonDetailView.svelte";

  import {
    currentCourseId,
    currentLessonId,
    leftMenuToggled,
  } from "../../components/DataStore";
  export let courseId = undefined;
  export let lessonId = undefined;
  export let tab;

  if (courseId && lessonId) {
    $currentCourseId = courseId;
    $currentLessonId = lessonId;
    console.log("$currentCourseId", $currentCourseId);
    console.log("$currentLessonId", $currentLessonId);
  }
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="courses" />
    </div>
    <div id="layoutSidenav_content">
      <LessonDetailView activeTab={tab} />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
