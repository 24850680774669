<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import {
    currentPage,
    currentCourseId,
    currentLessonId,
    currentCourseRoleCheck
  } from '../../../components/DataStore'
  import { getData, postData, putData } from 'utils/ApiUtils.svelte'
  import { dynamicSort, arrayIsEmpty } from 'utils/FormatUtils.svelte'
  import Loader from 'components/Loader.svelte'
  import { onMount } from 'svelte'

  let lessonList = []

  let lessonTableStatus = 'loading'

  onMount(async () => {
    getLessons($currentCourseId)
  })

  function getLessons() {
    lessonTableStatus = 'loading'
    if ($currentCourseId) {
      getData('api/courses/' + $currentCourseId + '/lessons/projection')
        .then((data) => {
          console.log('lessons successful', data)

          lessonList = data
          lessonList.sort(dynamicSort('customOrder'))

          lessonTableStatus = 'ok'
        })
        .catch((error) => {
          lessonTableStatus = 'error'
          console.log('error lessons', error)
        })
    }
  }

  function createNewLesson() {
    currentLessonId.update((x) => undefined)
    mixpanel.track('Teacher Create New Lesson Button Clicked')
    clickChangePage('courses/' + $currentCourseId + '/lesson-edit/')
  }

  function viewLessonDetail(id) {
    console.log('viewLessonDetail', id)
    mixpanel.track('Teacher View Lesson Detail Clicked', { 'Lesson ID': id })
    currentLessonId.update((x) => id)
    clickChangePage('courses/' + $currentCourseId + '/lesson-detail/' + id)
  }

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    $currentPage = e
    router.goto('/' + e)
  }

  function changeOrder(lessonId, targetOrder) {
    putData(
      `api/courses/${$currentCourseId}/lessons/${lessonId}/reorder/${targetOrder}`,
      {}
    )
      .then((data) => {
        console.log('change order', data)
        getLessons($currentCourseId)
      })
      .catch((error) => {
        lessonTableStatus = 'error'
        console.log('error lessons', error)
      })
  }
</script>

<div class="card shadow">
  <div class="card-header py-3">
    <!-- svelte-ignore a11y-interactive-supports-focus -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <button
      class="btn btn-primary s-button-primary"
      disabled={"RW"!==$currentCourseRoleCheck?.permissions}
      on:click={createNewLesson}
    >
      <i class="fa fa-plus" style="padding-right: 8px;" />{$_(
        'CREATE_NEW_LESSON'
      )}<br />
    </button>
  </div>

  <div class="card-group">
    {#if lessonTableStatus === 'loading'}
      <Loader />
    {:else if lessonTableStatus === 'error'}
      {$_('ERROR_LOADING_LESSON_LIST')}
    {:else}
      <div class="card-body">
        <div>
          {#if !arrayIsEmpty(lessonList)}
            <div
              class="table-responsive table mt-2"
              id="dataTable"
              role="grid"
              aria-describedby="dataTable_info"
            >
              <table class="table table-hover" id="dataTable">
                <thead>
                  <tr>
                    <th style="min-width:5.5em">#</th>
                    <th>{$_('LESSON_NAME')}</th>
                    <th>{$_('LESSON_DESCRIPTION_SHORT')}</th>
                    <th>{$_('ACTIVE_DATES')}</th>
                    <th>{$_('NUMBER_OF_RECORDINGS_EXERCISE')}</th>
                    <th>{$_('LATEST_RECORDING', { values: { n: 1 } })}</th>
                  </tr>
                </thead>
                <tbody>
                  {#each lessonList as l, i}
                    <tr
                      class="lessonRow"
                      on:click={(x) => {
                        viewLessonDetail(l?.lessonId)
                      }}
                    >
                      <th scope="row">
                        <button
                          class="arrows"
                          disabled={"RW"!==$currentCourseRoleCheck?.permissions}
                          on:click|stopPropagation={(e) => {
                            e.preventDefault()
                            changeOrder(l.lessonId, l?.customOrder - 1)
                          }}>↑</button
                        ><button
                          class="arrows"
                          disabled={"RW"!==$currentCourseRoleCheck?.permissions}
                          on:click|stopPropagation={(e) => {
                            e.preventDefault()
                            changeOrder(l.lessonId, l?.customOrder + 2)
                          }}>↓</button
                        ><span class="ms-3">{i + 1}</span>
                      </th>
                      <td>{l?.lessonName}</td>
                      <td>
                        {#if l?.lessonDescription}
                          {l?.lessonDescription}
                        {/if}
                      </td>
                      <td>
                        {#if l?.activeDateStart}
                          {new Date(
                            Date.parse(l?.activeDateStart)
                          ).toLocaleString()}
                        {/if}
                        {#if l?.activeDateEnd}
                          <br />
                          {new Date(
                            Date.parse(l?.activeDateEnd)
                          ).toLocaleString()}
                        {/if}
                      </td>
                      <td>
                        {l?.publicRecordingsCount}
                      </td>
                      <td>
                        {#if l?.publicRecordingsLatest}
                          {new Date(
                            Date.parse(l?.publicRecordingsLatest + 'Z')
                          ).toLocaleString()}
                        {/if}
                      </td>
                    </tr>
                  {/each}
                </tbody>
                <tfoot>
                  <tr />
                </tfoot>
              </table>
            </div>
          {:else}
            <div class="row" style="padding-left:15%; padding-right:15%;">
              <svg
                class="text-center"
                width="200"
                height="200"
                viewBox="0 0 200 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M72.8301 138.19V39.19C70.8301 35.19 54.8301 37.19 54.8301 44.19L54.8901 150.36C55.4598 152.274 56.6313 153.954 58.2308 155.15C59.8304 156.346 61.7728 156.995 63.7701 157H143.17V139C132.59 140.21 83.2401 138.14 72.8301 138.19Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M72.8196 37.1904H65.8196C63.366 37.2036 61.0168 38.1848 59.2828 39.9207C57.5487 41.6566 56.5701 44.0068 56.5596 46.4604L56.9396 148.36C57.5068 150.267 58.6708 151.94 60.2603 153.136C61.8498 154.331 63.7809 154.985 65.7696 155H71.0596V149.71H65.7696C65.2072 149.78 64.6366 149.729 64.0955 149.561C63.5544 149.393 63.0551 149.112 62.6307 148.737C62.2063 148.361 61.8664 147.9 61.6337 147.384C61.4009 146.867 61.2806 146.307 61.2806 145.74C61.2806 145.174 61.4009 144.614 61.6337 144.097C61.8664 143.581 62.2063 143.119 62.6307 142.744C63.0551 142.369 63.5544 142.088 64.0955 141.92C64.6366 141.752 65.2072 141.701 65.7696 141.77H139.88V149.71H78.9996V155H145.17V37.1904H72.8196ZM65.8196 136.45C64.4344 136.446 63.0665 136.757 61.8196 137.36V46.4604C61.8196 45.3996 62.241 44.3821 62.9911 43.632C63.7413 42.8819 64.7587 42.4604 65.8196 42.4604H67.5796V125.83H72.8696V42.4904H139.93V136.49L65.8196 136.45Z"
                  fill="#707070"
                />
                <path
                  d="M84.3002 162.85L75.0002 159.14L65.7402 162.85V144.39H71.0302V155L75.0302 153.41L79.0002 155V144.39H84.3002V162.85Z"
                  fill="#707070"
                />
              </svg>

              <h3 class="text-center">
                {$_('LESSON_TABLE_EMPTY_PROMPT_EMPTY')}
              </h3>
              <p>{$_('LESSON_TABLE_EMPTY_PROMPT_NEW')}</p>
              <p>{$_('LESSON_TABLE_EMPTY_PROMPT_EXISTING')}</p>
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .lessonRow {
    cursor: pointer;
  }
  .arrows {
    color: #fff;
    background-color: #00a094;
    outline: none !important;
    border-bottom: 2px solid #018d83 !important;
    border-radius: 5px;
    border-left: 1px solid #018d83 !important;
    border-right: 1px solid #018d83 !important;
    border-top: 1px solid #018d83 !important;
    margin: 10px 2px 0;
    padding: 0em 0.375em;
  }

  .arrows:disabled{
    pointer-events: none;
    opacity: 0.65;
  }
</style>
