<script>
  import Modal from 'sv-bootstrap-modal'

  export let isOpen = false
  export let label = 'Quick Start Guide'
  export let videoSRC = ''
</script>

<Modal bind:open={isOpen} dialogClasses="modal-xl">
  <div class="modal-header">
    <h5 class="modal-title">{label}</h5>
    <button
      type="button"
      class="s-modal-close"
      on:click={() => {
        isOpen = false
      }}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe
      src={videoSRC}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</Modal>

<style>
  iframe {
    width: 100%;
    min-height: 500px;
    border: none;
  }
</style>
