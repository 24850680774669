<script>
  import { onMount } from 'svelte'

  export let codeValue
  export let squareSize

  let qrcode

  onMount(() => {
    qrcode.innerHTML = ''
    qrcode = new QRCode('qrcode', {
      text: codeValue,
      width: squareSize,
      height: squareSize,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H,
    })
  })
</script>

<div bind:this={qrcode} id="qrcode" />

<style>
  #qrcode {
    width: 100%;
  }
</style>
