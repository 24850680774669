<script context="module">
  import { init, getLocaleFromNavigator } from 'svelte-i18n'
  import { router } from 'tinro'
  import {
    userDetails,
    userContext,
    userConfig,
    avatarImageSrc,
    loginToken,
  } from '../components/DataStore'
  import { getData, putData, checkJwtTokenValidity } from './ApiUtils.svelte'
  import { setCookie, getCookie, eraseCookie } from './CookieUtils.svelte'
  import merge from 'deepmerge'

  export async function getProfile() {
    //let token = sessionStorage.getItem("jwtToken") || "";
    let token = getCookie('jwtToken')
    loginToken.update((x) => token)

    if (token && checkJwtTokenValidity(token)) {
      return getData('api/user/me')
        .then((data) => {
          console.log('profile successful', data)
          try {
            userConfig.update((x) => {
              x.serverFlags = JSON.parse(data['currentSubscriptionFlags'])
              return x
            })
          } catch (error) {
            console.log('Error parsing currentSubscriptionFlags', error)
          }
          try {
            userConfig.update((x) => {
              x.organizationFlags = JSON.parse(
                data['organizationSubscriptionFlags']
              )
              return x
            })
          } catch (error) {
            console.log('Error parsing currentSubscriptionFlags', error)
          }

          userDetails.update((x) => data)

          changeLanguage(data?.languagePreference)

          loadContext()
          calculateHashForGravatar(data.login)
          //router.goto("/");
        })
        .catch((error) => {
          console.log('error courses', error)
        })
    } else {
      console.error('Invalid token')
      eraseCookie('jwtToken')
      router.goto('/login')
    }
  }

  function changeLanguage(newLanguage) {
    if (newLanguage && (newLanguage === 'en-US' || newLanguage === 'zh-TW')) {
      init({
        fallbackLocale: 'en',
        initialLocale: newLanguage,
      })
    } else {
      init({
        fallbackLocale: 'en',
        initialLocale: getLocaleFromNavigator(),
      })
    }
  }

  export async function loadContext() {
    getData('api/user/context').then((data) => {
      console.log('context', data)
      let jsonData = JSON.parse(data?.data)
      if (!jsonData?.fre?.freStatus) {
        jsonData = merge(jsonData, {
          fre: { freStatus: 'NEW', frePage: 'try' },
        })
      }
      userContext.update((x) => jsonData)
    })
  }

  export async function updateContext(value) {
    userContext.update((x) => {
      let userContextValue = merge(x, value)
      console.log(111, userContextValue)
      putData('api/user/context', { data: JSON.stringify(userContextValue) })
      return userContextValue
    })
  }

  async function calculateHashForGravatar(login) {
    let link = await hashwasm.md5(login)
    avatarImageSrc.update(
      (x) =>
        'https://www.gravatar.com/avatar/' +
        link.toLowerCase() +
        '?d=robohash&s=200'
    )
  }
</script>
