<script>
  import { _ } from 'svelte-i18n'
  import { createEventDispatcher, onMount } from 'svelte'

  const dispatch = createEventDispatcher()

  function hideDropdown() {
    bootstrap.Dropdown.getInstance('#dropdownMenu').hide()
  }

  function refresh() {
    hideDropdown()
    dispatch('refresh', {})
  }

  function print() {
    hideDropdown()
    dispatch('print', {})
  }

  function exportAllToCsv() {
    hideDropdown()
    dispatch('exportAllToCsv', {})
  }
</script>

<div class="btn-group">
  <button
    id="dropdownMenu"
    type="button"
    class="dropdown-toggle no-border"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
  </button>
  <ul class="dropdown-menu">
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={refresh}
        ><i class="fa fa-refresh" style="padding-right: 8px;" />{$_(
          'REFRESH'
        )}</button
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={print}
        ><i class="fa fa-print" style="padding-right: 8px;" />{$_(
          'PRINT'
        )}</button
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={exportAllToCsv}
        ><i class="fa fa-download" style="padding-right: 8px;" />{$_(
          'EXPORT_ALL_TO_CSV'
        )}</button
      >
    </li>
  </ul>
</div>

<style>
  .no-border {
    border: 0px;
    background-color: transparent;
  }
</style>
