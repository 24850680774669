<script>
  import { _ } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import { getData, postData, deleteData } from '../../utils/ApiUtils.svelte'
  import Loader from '../../components/Loader.svelte'
  import { userDetails } from '../../components/DataStore'
  export let teacherUserId

  onMount(async () => {
    if (teacherUserId) {
      getTeacherCourses(teacherUserId)
    }
  })

  let courseData = []
  let courseStatus = 'new'

  function refreshList() {
    getTeacherCourses(teacherUserId)
  }

  function getTeacherCourses(teacherUserId) {
    getData(
      `api/school-admin/teacher/${teacherUserId}/courses-projection`,
      false,
      false
    )
      .then((data) => {
        console.log('organization courses successful', data)
        // Save data to sessionStorage
        courseData = data.filter(c=>!c?.deleteFlag)
        courseStatus = 'ok'
      })
      .catch((error) => {
        courseStatus = 'error'
        console.log('error organization courses', error)
      })
  }
</script>

<div class="card shadow mt-4">
  <div class="card-header py-3 no-print">
    <h2>{$_('ORGANIZATION.ORGANIZATION_COURSES')}</h2>

    <div class="row mt-2">
      <div class="col" />
    </div>
  </div>
  <div class="card-body">
    {#if courseStatus != 'ok'}
      <Loader />
    {:else if courseData?.length > 0}
      <div
        class="table table mt-2"
        id="dataTable"
        role="grid"
        aria-describedby="dataTable_info"
      >
        <table class="table my-0 table-hover" id="dataTable">
          <thead>
            <tr>
              <th>#</th>
              <th> {$_('COURSE_NAME')}</th>
              <th> {$_('ORGANIZATION.STUDENT_ACCOUNT_COUNT')}</th>
              <th> {$_('RECORDING_COUNT')}</th>
            </tr>
          </thead>
          <tbody>
            {#each courseData as c, i}
              <tr
                class="exerciseRow"
                on:click={(x) => {
                  console.log(c.id)
                }}
              >
                <th scope="row">{i + 1}</th>
                <td>
                  <a href="/#/courses-detail/{c?.courseId}" >{c?.courseName}</a>
                </td>
                <td>
                  {c?.studentAccountCount}
                </td>
                <td>
                  {c?.recordingCount}
                </td>
              </tr>
            {/each}
          </tbody>
          <tfoot>
            <tr />
          </tfoot>
        </table>
      </div>
    {/if}
  </div>
</div>

