<script>
  import { currentCourseRoleCheck } from "../../../components/DataStore";
  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";
  import { userDetails, userConfig } from "../../../components/DataStore";

  export let elementId = "dropdownMenu";

  const dispatch = createEventDispatcher();

  function hideDropdown() {
    bootstrap.Dropdown.getInstance(`#${elementId}`).hide();
  }

  function refresh() {
    hideDropdown();
    dispatch("refresh", {});
  }

  function editCourseDetail() {
    hideDropdown();
    dispatch("editCourseDetail", {});
  }

  function copyCourse() {
    hideDropdown();
    dispatch("copyCourse", {});
  }

  function copyCourseToSelectedUser() {
    hideDropdown();
    dispatch("copyCourseToSelectedUser", {});
  }

  function copyCourseToAnotherOrganizationTeacher() {
    hideDropdown();
    dispatch("copyCourseToAnotherOrganizationTeacher", {});
  }

  function deleteCourse() {
    hideDropdown();
    dispatch("deleteCourse", {});
  }

  function deleteRecordingsInCourse() {
    hideDropdown();
    dispatch("deleteRecordingsInCourse", {});
  }
</script>

<div class="btn-group float-end">
  <button
    id={elementId}
    type="button"
    class="dropdown-toggle no-border"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
  </button>
  <ul class="dropdown-menu">
    <li>
      <button
        class="dropdown-item"
        type="button"
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        on:click|stopPropagation={editCourseDetail}
        ><i class="fa fa-edit" style="padding-right: 8px;" />{$_(
          "EDIT_COURSE_INFO",
        )}</button
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={copyCourse}
        ><i class="fa fa-download" style="padding-right: 8px;" />{$_(
          "DUPLICATE_COURSE",
        )}</button
      >
    </li>

    {#if $userDetails?.userRole === "ADMIN" || $userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.copyClassToSameDistributorOrg === true || $userDetails?.organizationDistributor === "AMC"}
      <li><hr class="dropdown-divider" /></li>
      <li>
        <button
          class="dropdown-item"
          type="button"
          on:click|stopPropagation={copyCourseToSelectedUser}
          ><i class="far fa-copy pe-2" />{$_(
            "COPY_COURSE_TO_SELECTED_USER",
          )}</button
        >
      </li>
    {/if}

    {#if $userDetails?.userRole === "ADMIN" || $userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.copyClassToSameDistributorOrg === true || $userDetails?.organizationDistributor === "AMC"}
      <li>
        <button
          class="dropdown-item"
          type="button"
          on:click|stopPropagation={copyCourseToAnotherOrganizationTeacher}
          ><i class="far fa-copy pe-2" />{$_("COPY_TO_ANOTHER_ORG")}</button
        >
      </li>
    {/if}

    <li><hr class="dropdown-divider" /></li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        on:click|stopPropagation={deleteCourse}
        ><i class="far fa-copy pe-2" />{$_("COURSE_DELETE")}</button
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        on:click|stopPropagation={deleteRecordingsInCourse}
        ><i class="far fa-copy pe-2" />{$_("DELETE_RECORDINGS_ONLY")}</button
      >
    </li>
  </ul>
</div>

<style>
  .no-border {
    border: 0px;
    background-color: white;
  }
</style>
