<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import { getData, postData, deleteData } from '../../utils/ApiUtils.svelte'
  import Loader from '../../components/Loader.svelte'
  import { onMount } from 'svelte'
  import Line from 'svelte-chartjs/src/Line.svelte'
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
  } from '../../components/DataStore'
  import SvelteTable from 'svelte-table'
  import printJS from 'print-js'
  import {
    formatNumberWith4Colors,
  } from 'utils/FormatUtils.svelte'

  export let studentName

  let studentDetails = {}
  let studentRecordingList = []

  let studentDetailsStatus = 'loading'
  let studentRecordingListStatus = 'loading'

  onMount(async () => {
    getStudentDetails()
  })

  function getStudentDetails() {
    getData('api/student/' + studentName + '/statistics')
      .then((data) => {
        // console.log('getStudentDetails successful', data)

        studentDetails = data
        studentDetailsStatus = 'ok'
        getStudentRecordingList()
      })
      .catch((error) => {
        studentDetailsStatus = 'error'
        // console.log('error lessons', error)
      })
  }

  function getStudentRecordingList() {
    getData('api/student/' + studentName + '/publicrecordingsTable')
      .then((data) => {
        console.log('studentRecordingList successful', data)

        studentRecordingList = data
        studentRecordingListStatus = 'ok'

        prepareStudentProgressData()
      })
      .catch((error) => {
        studentRecordingListStatus = 'error'
        console.log('error lessons', error)
      })
  }

  function viewRecordingDetail(r) {
    console.log(
      'viewRecordingDetail',
      r?.courseId,
      r?.lessonId,
      r?.exerciseId,
      r?.id
    )

    currentCourseId.update((x) => r?.courseId)
    currentCourseName.update((x) => r?.courseName)
    currentLessonId.update((x) => r?.lessonId)
    currentLessonName.update((x) => r?.lessonName)
    currentExerciseId.update((x) => r?.exerciseId)
    currentExerciseName.update((x) => r?.exerciseTitle)
    currentRecordingId.update((x) => r?.id)
    clickChangePage('recording-detail/' + $currentRecordingId)
  }

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    $currentPage = e
    router.goto('/' + e)
  }

  let filterSelections = {}

  function removeDuplicates(originalArray, prop) {
    var newArray = []
    var lookupObject = {}

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i]
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i])
    }
    return newArray
  }

  let studentProgressData

  function prepareStudentProgressData() {
    studentRecordingList = studentRecordingList.sort((a, b) => {
      return a.id - b.id
    })

    let dataLabels = studentRecordingList.map((sr) => {
      console.log(sr)
      return new Date(Date.parse(sr.createdAt)).toLocaleDateString()
    })

    let data = studentRecordingList.map((sr) => {
      console.log(sr)
      return Number.parseFloat(sr.overallScore).toFixed(0)
    })

    //data = [1, 2, 3];
    console.log(555, dataLabels)

    studentProgressData = {
      labels: dataLabels,
      datasets: [
        {
          label: studentDetails?.studentName,
          data: data,
          borderColor: 'rgb(78, 115, 223)',
          lineTension: 0,
        },
      ],
    }
  }

  let columns = [
    {
      key: 'studentName',
      title: $_('STUDENT_NAME'),
      value: (v) => v.studentName,
      sortable: true,
      filterOptions: (rows) => {
        let x = rows.map((r) => {
          return { name: r.studentName, value: r.studentName }
        })
        x = removeDuplicates(x, 'value')
        return Object.values(x)
      },
      filterValue: (v) => v.studentName,
    },
    {
      key: 'courseName',
      title: $_('COURSE_NAME'),
      value: (v) => v.courseName,
      sortable: true,
      filterOptions: (rows) => {
        let x = rows.map((r) => {
          return { name: r.courseName, value: r.courseName }
        })
        x = removeDuplicates(x, 'value')
        return Object.values(x)
      },
      filterValue: (v) => v.courseName,
    },
    {
      key: 'lessonName',
      title: $_('LESSON_NAME'),
      value: (v) => v.lessonName,
      sortable: true,
      filterOptions: (rows) => {
        let x = rows.map((r) => {
          return { name: r.lessonName, value: r.lessonName }
        })
        x = removeDuplicates(x, 'value')
        return Object.values(x)
      },
      filterValue: (v) => v.lessonName,
    },
    {
      key: 'exerciseTitle',
      title: $_('EXERCISE_TITLE'),
      value: (v) => v.exerciseTitle,
      sortable: true,
      //filterOptions: ["Test1", "aaa", "Test Question"],
      filterOptions: (rows) => {
        let x = rows.map((r) => {
          return { name: r.exerciseTitle, value: r.exerciseTitle }
        })
        x = removeDuplicates(x, 'value')
        return Object.values(x)
      },
      filterValue: (v) => v.exerciseTitle,
    },
    {
      key: 'exerciseType',
      title: $_('EXERCISE_TYPE'),
      value: (v) => $_(v.exerciseType),
      sortable: true,
      filterOptions: ['READ', 'VIDEO', 'QUESTION'], // provide array
      renderValue: (v2) => {
        return `<div class="text-center">${$_(v2.exerciseType)}</div>`
      },
    },
    {
      key: 'overallScore',
      title: $_('OVERALL_SCORE'),
      value: (v) => (v.overallScore),
      sortable: true,
      filterOptions: (rows) => {
        // generate groupings of 0-10, 10-20 etc...
        let nums = {}
        rows.forEach((row) => {
          let num = Math.ceil(Number.parseFloat(row.overallScore) / 10)
          if (nums[num] === undefined)
            nums[num] = {
              name: `${(num - 1) * 10} to ${num * 10}`,
              value: num,
            }
        })
        // fix order
        nums = Object.entries(nums)
          .sort()
          .reduce((o, [k, v]) => ((o[k] = v), o), {})
        console.log('Object.values(nums)', Object.values(nums))
        return Object.values(nums)
      },
      filterValue: (v) => Math.ceil(Number.parseFloat(v.overallScore) / 10),
      renderValue: (v2) => {
        return `<div class="text-center">${formatNumberWith4Colors(
          v2.overallScore
        )}</div>`
      },
    },
    {
      key: 'teacherScore',
      title: $_('TEACHER_SCORE'),
      value: (v) => v.teacherScore,
      renderValue: (v) => {
        return v.teacherScore ? v.teacherScore : ''
      },
      sortable: true,
      filterOptions: (rows) => {
        // generate groupings of 0-10, 10-20 etc...
        let nums = {}
        rows.forEach((row) => {
          let num = Math.floor(Number.parseFloat(row.teacherScore) / 10)
          if (nums[num] === undefined)
            nums[num] = {
              name: `${num * 10} to ${(num + 1) * 10}`,
              value: num,
            }
        })
        // fix order
        nums = Object.entries(nums)
          .sort()
          .reduce((o, [k, v]) => ((o[k] = v), o), {})
        console.log('Object.values(nums)', Object.values(nums))
        return Object.values(nums)
      },
      filterValue: (v) => Math.floor(Number.parseFloat(v.teacherScore) / 10),
    },
    {
      key: 'createdAt',
      title: $_('CREATED_AT'),
      value: (v) => {
        let d1 = new Date(Date.parse(v?.createdAt + 'Z'))
        return d1.getTime()
      },
      renderValue: (v) => {
        return new Date(Date.parse(v?.createdAt + 'Z')).toLocaleString()
      },
      filterValue: (v) => {
        let d1 = new Date(Date.parse(v?.createdAt + 'Z'))
        let ds = d1?.toDateString()
        let d2 = new Date(Date.parse(ds))
        return d2.toISOString()
      },
      filterOptions: (rows) => {
        let x = rows.map((r) => {
          let d1 = new Date(Date.parse(r?.createdAt + 'Z'))
          let ds = d1?.toDateString()
          let day = new Date(Date.parse(ds))
          const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }
          return {
            name: day?.toLocaleDateString(undefined, options),
            value: day.toISOString(),
          }
        })
        x = removeDuplicates(x, 'value')
        return Object.values(x)
      },
      sortable: true,
    },
  ]

  function printReport() {
    mixpanel.track('Teacher Print Button Clicked', {
      'Button Location': 'Student Detail Page',
    })
    // window.print()
    // printJS('', 'html')
    // const printJson = studentRecordingList
    // printJS({ type: 'json', printable: printJson, properties:[] })
    printJS({
      printable: 'student-detail-report',
      type: 'html',
      header: 'Sensay Student Report',
      ignoreElements: ['no-print'],
    })
  }

  function deleteStudentData() {
    // console.log('delete exercise')
    if (
      confirm(
        $_('STUDENT_DETAIL_DELETE_ALL_RECORDINGS_CONFIRM', {
          values: { studentName: studentDetails?.studentName },
        })
      )
    ) {
      // console.log('Thing was saved to the database.')
      deleteData('api/student/' + studentDetails?.studentName)
        .then((data) => {
          // console.log('All recordings for student removed successfully', data)

          clickChangePage('students')
        })
        .catch((error) => {
          // console.log('error Student delete', error)
        })
    } else {
      // console.log('Thing was not saved to the database.')
    }
  }
</script>

<main id="student-detail-report" class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">
        {$_('STUDENT_REPORT')}
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    <h4 class="text-dark mb-4">
      {#if studentDetails?.studentName}
        {studentDetails?.studentName}
      {/if}
    </h4>
  </div>

  {#if studentDetailsStatus == 'ok'}
    <div class="s-content-spacing">
      <div class="card shadow mb-4">
        <div class="card-header py-3 no-print" id="no-print">
          <button
            class="btn btn-primary s-button-primary s-mr-10px"
            on:click={printReport}
            ><i class="fa fa-print" style="padding-right: 8px;" />{$_(
              'PRINT'
            )}<br /></button
          >
          <div class="btn-group ml-4" role="group">
            <button
              class="btn btn-danger s-button-danger"
              on:click={deleteStudentData}
            >
              <i class="far fa-window-close" style="padding-right: 8px;" />{$_(
                'DELETE'
              )}
            </button>
          </div>
          {#if studentDetails?.studentAccountId}
            <a href="/student-analytics/{studentDetails?.studentAccountId}"
              ><button
                class="btn btn-primary s-button-primary"
                style="margin-left: 10px">{$_("ANALYTICS_SECTION.ANALYTICS")}</button
              ></a
            >
          {/if}
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-8">
                <div class="mb-1">
                  {$_('AVERAGE_SCORE')}
                  {studentDetails?.overallScoreAvg.toFixed(0)}
                </div>
                <div class="progress mb-4">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentDetails?.overallScoreAvg.toFixed(0)}%"
                    aria-valuenow={studentDetails?.overallScoreAvg.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <div class="mb-1 small">
                  {$_('ACCURACY_SCORE')}
                  {studentDetails?.accuracyScoreAvg.toFixed(0)}
                </div>
                <div class="progress progress-sm mb-2">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentDetails?.accuracyScoreAvg.toFixed(
                      0
                    )}%"
                    aria-valuenow={studentDetails?.accuracyScoreAvg.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>

                <div class="mb-1 small">
                  {$_('COMPLETENESS_SCORE')}
                  {studentDetails?.completenessScoreAvg.toFixed(0)}
                </div>
                <div class="progress progress-sm mb-2">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentDetails?.completenessScoreAvg.toFixed(
                      0
                    )}%"
                    aria-valuenow={studentDetails?.completenessScoreAvg.toFixed(
                      0
                    )}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>

                <div class="mb-1 small">
                  {$_('FLUENCY_SCORE')}
                  {studentDetails?.fluencyScoreAvg.toFixed(0)}
                </div>
                <div class="progress progress-sm mb-2">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentDetails?.fluencyScoreAvg.toFixed(0)}%"
                    aria-valuenow={studentDetails?.fluencyScoreAvg.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>

                <div class="mb-1 small">
                  {$_('PRONUNCIATION_SCORE')}
                  {studentDetails?.pronScoreAvg.toFixed(0)}
                </div>
                <div class="progress progress-sm mb-2">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: {studentDetails?.pronScoreAvg.toFixed(0)}%"
                    aria-valuenow={studentDetails?.pronScoreAvg.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>

              <div class="col-4">
                <div class="row mb-3">
                  <div class="col">
                    <div class="card border-left-secondary shadow h-100 py-2">
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div
                              class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                            >
                              {$_('NUMBER_OF_RECORDINGS')}
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                              {studentDetails?.publicRecordingCount}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i
                              class="fas fa-clipboard-list fa-2x text-gray-300"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="card border-left-info shadow h-100 py-2">
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div
                              class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                            >
                              {$_('TOTAL_WORD_COUNT')}
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                              {studentDetails?.totalWordCount}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-comments fa-2x text-gray-300" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  {/if}

  <div class="s-content-spacing">
    <h4>{$_("STUDENT_PROGRESS")}</h4>
    <div class="card shadow">
      <div class="container">
        <Line
          data={studentProgressData}
          options={{
            responsive: true,
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'day',
                  },
                  display: true,
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    <h4>{$_('TEACHER_COMMENT')}</h4>
    <div class="card shadow">
      <div class="card-body">
        {#if studentRecordingListStatus != 'ok'}
          <Loader />
        {:else}
          <div
            class="table-responsive table mt-2"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info"
          >
            <table class="table my-0 table-hover" id="dataTable">
              <thead>
                <tr>
                  <th>{$_('EXERCISE')}</th>
                  <th>{$_('TEACHER_SCORE')}</th>
                  <th>{$_('TEACHER_LABEL')}</th>
                  <th>{$_('TEACHER_COMMENT')}</th>
                  <th>{$_('UPDATED_AT')}</th>
                </tr>
              </thead>
              <tbody>
                {#each studentRecordingList.sort((x) => {
                  x.updatedAt
                }) as r, i}
                  {#if r?.teacherComment}
                    <tr>
                      <td>{r?.exerciseTitle}</td>
                      <td>
                        {#if r?.teacherScore}
                          {r?.teacherScore}
                        {/if}
                      </td>
                      <td>
                        {#if r?.teacherLabel}
                          {r?.teacherLabel}
                        {/if}
                      </td>
                      <td>
                        {#if r?.teacherComment}
                          {r?.teacherComment}
                        {/if}
                      </td>
                      <td>
                        {#if r?.updatedAt}
                          {new Date(
                            Date.parse(r?.updatedAt + 'Z')
                          ).toLocaleString()}
                        {/if}
                      </td>
                    </tr>
                  {/if}
                {/each}
              </tbody>
              <tfoot>
                <tr />
              </tfoot>
            </table>
          </div>
        {/if}
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    <h4>{$_('RECORDINGS')}</h4>
    <div class="card shadow mb-4">
      <div class="card-body">
        {#if studentRecordingListStatus != 'ok'}
          <Loader />
        {:else}
          <div
            class="table-responsive table mt-2"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info"
          >
            <SvelteTable
              {columns}
              rows={studentRecordingList}
              {filterSelections}
              classNameRow="recordingRow"
              sortBy="createdAt"
              sortOrder="-1"
              on:clickRow={(event, row) => {
                console.log(event, event?.detail?.row)
                viewRecordingDetail(event?.detail?.row)
              }}
            />
          </div>
          <div class="row">
            <div class="col-md-6 align-self-center">
              <p
                id="dataTable_info"
                class="dataTables_info"
                role="status"
                aria-live="polite"
              >
                {studentRecordingList?.length}
                {$_('RESULT_COUNT')}
              </p>
            </div>
            <div class="col-md-6">
              <nav
                class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
              />
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  :global(.recordingRow) {
    cursor: pointer;
  }

  :global(.recordingRow:hover) {
    background-color: whitesmoke;
  }

  .table-responsive {
    overflow: hidden;
  }
</style>
