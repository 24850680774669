<script>
  import LeftMenu from '../components/LeftMenu.svelte'
  import TopMenu from '../components/TopMenu.svelte'
  import Footer from '../components/Footer.svelte'
  import ContentMarketPlaceCallbackView from './ContentMarketPlace/ContentMarketPlaceCallbackView.svelte'

  export let contentPackId
</script>

<div id="wrapper">
  <LeftMenu activePage={'index'} />
  <div class="d-flex flex-column" id="content-wrapper">
    <div id="content">
      <TopMenu />
      <ContentMarketPlaceCallbackView {contentPackId} />
    </div>
    <Footer />
  </div>
</div>
