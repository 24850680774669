<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from 'components/Loader.svelte'
  import { onMount } from 'svelte'
  import { getData } from 'utils/ApiUtils.svelte'
  import Breadcrumb from 'components/Breadcrumb.svelte'
  import ShareExercise from '../../Exercise/ExerciseComponent/ShareExercise.svelte'
  import ExerciseTable from '../../Exercise/ExerciseComponent/ExerciseTable.svelte'
  import StudentLessonProgressTable from './StudentLessonProgressTable.svelte'
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    userConfig
  } from '../../../components/DataStore'
  import LessonDetailComponent from './LessonDetailComponent.svelte'
    import StudentLessonProgressTableOriginal from './StudentLessonProgressTableOriginal.svelte';
    import StudentLessonProgressTable2 from './StudentLessonProgressTable2.svelte';
    import StudentLessonProgressTableQr from './StudentLessonProgressTableQr.svelte';

  export let activeTab

  if (!activeTab) {
    activeTab = 'exercises'
  }
  onMount(async () => {})

  function changeTab(page) {
    try {
      switch (page) {
        case 'exercises':
          mixpanel.track('Teacher Lessons Page Exercises Tab Clicked')
          break
        case 'student-progress':
          mixpanel.track('Teacher Lessons Page Student Progress Tab Clicked')
          break
        default:
          break
      }
    } catch (error) {
      console.log('error mixpanel')
    }

    activeTab = page
    router.goto(
      `courses/${$currentCourseId}/lesson-detail/${$currentLessonId}/${page}`
    )
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <Breadcrumb />
    </div>
  </div>

  <div class="s-content-spacing">
    <LessonDetailComponent />
  </div>

  <div class="s-content-spacing" style="margin-bottom: 0 !important;">
    <ul class="nav nav-tabs">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li class="nav-item" on:click={(e) => changeTab('exercises')}>
        <span class="nav-link" class:active={activeTab === 'exercises'}
          >{$_('EXERCISE')}</span
        >
      </li>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- <li class="nav-item" on:click={(e) => changeTab('student-progress')}>
        <span class="nav-link" class:active={activeTab === 'student-progress'}
          >{$_('STUDENT_PROGRESS')}</span
        >
      </li> -->
      <!-- <li class="nav-item" on:click={(e) => changeTab('all-students-progress')}>
        <span class="nav-link" class:active={activeTab === 'all-students-progress'}
          >{$_('ALL_STUDENTS_PROGRESS')}</span
        >
      </li> -->
      <li class="nav-item" on:click={(e) => changeTab('student-account-progress')}>
        <span class="nav-link" class:active={activeTab === 'student-account-progress'}
          >{$_('STUDENT_ACCOUNT_PROGRESS')}</span
        >
      </li>
      {#if $userConfig?.serverFlags?.qrHidden !== true}
      <li class="nav-item" on:click={(e) => changeTab('qr-student-progress')}>
        <span class="nav-link" class:active={activeTab === 'qr-student-progress'}
          >{$_('QR_STUDENT_PROGRESS')}</span
        >
      </li>
      {/if}
    </ul>
  </div>

  {#key activeTab}
    {#if activeTab === 'exercises'}
      <div class="s-content-spacing" style="margin-top: 0 !important;">
        <ExerciseTable />
      </div>
    {:else if activeTab === 'student-progress'}
      <div class="s-content-spacing" style="margin-top: 0 !important;">
        <StudentLessonProgressTableOriginal courseId={$currentCourseId} />
      </div>
      {:else if activeTab === 'all-students-progress'}
      <div class="s-content-spacing" style="margin-top: 0 !important;">
        <StudentLessonProgressTable2 courseId={$currentCourseId} lessonId={$currentLessonId}/>
      </div>
      {:else if activeTab === 'student-account-progress'}
      <div class="s-content-spacing" style="margin-top: 0 !important;">
        <StudentLessonProgressTable courseId={$currentCourseId} lessonId={$currentLessonId}/>
      </div>
      {:else if activeTab === 'qr-student-progress'}
      <div class="s-content-spacing" style="margin-top: 0 !important;">
        <StudentLessonProgressTableQr courseId={$currentCourseId} lessonId={$currentLessonId}/>
      </div>
      {/if}
  {/key}
</main>

<style>
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid #00a094 !important;
    border-bottom-width: 0px !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .nav-item {
    margin-right: 1rem;
    cursor: pointer;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 0px;
  }
  .nav {
    border-bottom: 4px solid #00a094;
    color: black;
  }

  .nav-link {
    color: #575f59;
  }

  .nav-link.active {
    background-color: #00a094;
    color: white;
  }
</style>
