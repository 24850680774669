<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Modal from 'sv-bootstrap-modal'
  import { getData, patchData } from 'utils/ApiUtils.svelte'
  import { onMount } from 'svelte'
  import Loader from '../../../components/Loader.svelte'
  import { currentPage } from '../../../components/DataStore'

  export function openModal(eid, lid, cid) {
    exerciseId = Number.parseInt(eid)
    lessonId = Number.parseInt(lid)
    courseId = Number.parseInt(cid)
    shareModalShow = true
    loadData()
  }

  onMount(() => {
    // loadData()
  })

  let exerciseId
  let courseId
  let lessonId
  let exerciseData
  let courseList = []
  let lessonList = []
  let linkCopied = false
  let loadingStatus = 'loading'

  export let shareModalShow = false

  function loadData() {
    Promise.allSettled([
      getData('api/exercises/' + exerciseId)
        .then((data) => {
          exerciseData = data
        })
        .catch((error) => {
          console.log('error exercises', error)
          loadingStatus = 'error'
        }),
      getData('api/courses')
        .then((data) => {
          console.log('courses successful', data)
          courseList = data
          getData('api/courses/' + courseId + '/lessons/projection')
            .then((data) => {
              console.log('lessons successful', data)
              lessonList = data
              loadingStatus = 'ok'
            })
            .catch((error) => {
              loadingStatus = 'error'
              console.log('error lessons', error)
            })
        })
        .catch((error) => {
          console.log('error courses', error)
          loadingStatus = 'error'
        }),
    ])
  }

  function reloadLessons() {
    loadingStatus = 'loading'
    getData('api/courses/' + courseId + '/lessons/projection')
      .then((data) => {
        console.log('lessons successful', data)

        lessonList = data
        loadingStatus = 'ok'
      })
      .catch((error) => {
        loadingStatus = 'error'
        console.log('error lessons', error)
      })
  }

  function moveCourse() {
    let patch = {
      exerciseId: exerciseId,
      lessonId: lessonId,
      courseId: courseId,
    }
    loadingStatus = 'loading'

    patchData(`api/exercises/${exerciseId}/move`, patch)
      .then((data) => {
        console.log('login successful', data)
        clickChangePage(`courses-detail/${courseId}`)
      })
      .catch((error) => {
        console.log('error login', error)
        loadingStatus = 'error'
      })
  }

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    $currentPage = e
    router.goto('/' + e)
  }

  function close() {
    shareModalShow = false
  }
</script>

<Modal bind:open={shareModalShow} dialogClasses="modal-lg s-modal">
  <div class="modal-header">
    <h4 class="modal-title" style="position: absolute">
      {$_('MOVE_EXERCISE_TO_DIFFERENT_LESSON')}
    </h4>
    <div class="float-end" style="margin-left: auto">
      <button
        type="button"
        class="s-modal-close"
        on:click={() => (shareModalShow = false)}
      >
        &times;
      </button>
    </div>
  </div>
  <div class="modal-body container">
    <!-- {courseId} - {lessonId} - {exerciseId} -->
    {#if loadingStatus === 'loading'}
      <Loader />
    {:else}
      <div class="form-row">
        <div class="col offset-lg-0">
          <label for="course">{$_('COURSE')}</label>
          <select
            class="custom-select"
            id="course"
            bind:value={courseId}
            on:change={reloadLessons}
          >
            {#each courseList as c, i}
              <option value={c.id}>{c.name}</option>
            {:else}{$_('NO_DATA')}
            {/each}
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="col offset-lg-0">
          <label for="lesson">{$_('LESSON')}</label>
          <select class="custom-select" id="lesson" bind:value={lessonId}>
            {#each lessonList as l, i}
              <option value={l.lessonId}>{l.lessonName}</option>
            {:else}{$_('NO_DATA')}
            {/each}
          </select>
        </div>
      </div>
      <div class="row float-end">
        <div class="col">
          <button
            class="btn btn-secondary s-button-secondary s-mr-10px"
            on:click={close}>{$_('CANCEL')}</button
          >

          <button class="btn btn-primary s-button-primary" on:click={moveCourse}
            >{$_('MOVE')}</button
          >
        </div>
      </div>
    {/if}
  </div>
</Modal>

<style>
  .modal-header {
    align-items: center;
    justify-content: center;
  }
  .modal-title {
    color: #1cc88a;
  }
  .modal-header {
    align-items: center;
    justify-content: center;
  }

  :global(.s-modal) {
    max-width: 35rem;
  }

  /* .codeLink {
    padding: 1em;
    border: 5px;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    border-style: solid;
    border-radius: 4px;
    border-color: #1cc88a;
    text-align: center;
  } */

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* .codeLink {
      font-size: 1.2em;
    } */
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    /* .codeLink {
      font-size: 0.6em;
    } */
  }
</style>
