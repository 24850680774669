<script>
  import { _ } from 'svelte-i18n'
  import { createEventDispatcher } from 'svelte'

  export let lessonId

  const dispatch = createEventDispatcher()
  let linkCopied = false

  let url = 'https://student.oksensay.com/?key=' + lessonId

  function finishedSharing() {
    mixpanel.track('Teacher FRE Preview what students see Button Clicked')
    window.open(url, '_blank').focus()
    dispatch('nextPage', {})
  }

  function copyLink() {
    mixpanel.track('Teacher FRE Copy Lesson Link Button Clicked')
    navigator.clipboard.writeText(url)
    linkCopied = true
  }

  function closeModal(skip) {
    let event
    skip === 'skip'
      ? (event = 'Teacher FRE Skip for now Button Clicked')
      : (event = 'Teacher Teacher FRE Close Modal Clicked')
    mixpanel.track(event, { Step: 'Share with your students' })
    dispatch('closeModal', {})
  }
</script>

<div class="step-layout">
  <div class="steps-list-group">
    <span class="steps-list-heading">{$_('FRE_STEP_SHARE_HEADING')}</span>
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_CLASS')}
    </div>
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_LESSON')}
    </div>
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_EXERCISE')}
    </div>
    <div class="steps-list-item steps-list-highlight">
      <i class="far fa-circle mr-2" />{$_('FRE_STEP_SHARE')}
    </div>

    <div class="skip">
      <button class="button-skip" on:click={() => closeModal('skip')}>
        {$_('FRE_SKIP_BUTTON')}
      </button>
    </div>
  </div>

  <div class="exit">
    <button
      type="button"
      class="s-modal-close"
      on:click={() => closeModal('dismiss')}
    >
      &times;
    </button>
  </div>

  <div class="interaction-area">
    <div class="form-group fre-form-group">
      <div class="share-label">{$_('FRE_STEP_SHARE_PROMPT')}</div>
      <input type="text" class="form-control share-url" value={url} readonly />
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <svg
        on:click={copyLink}
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        fill="none"
        viewBox="0 0 15 15"
      >
        <path
          fill="#BCBCBC"
          d="M10 .625H2.5c-.688 0-1.25.563-1.25 1.25v8.75H2.5v-8.75H10V.625Zm-.625 2.5H5c-.688 0-1.244.563-1.244 1.25l-.006 8.75c0 .688.556 1.25 1.244 1.25h6.881c.688 0 1.25-.563 1.25-1.25v-6.25l-3.75-3.75ZM5 13.125v-8.75h3.75V7.5h3.125v5.625H5Z"
        />
      </svg>
    </div>

    {#if linkCopied}
      <div>{$_('FRE_LINK_COPIED_CLICK_BUTTON_TO_OPEN')}</div>
    {/if}

    <div class="fre-btn-group">
      <a
        on:click={finishedSharing}
        class="btn btn-primary s-button-primary"
        target="_blank"
        rel="noopener noreferrer"
        href="https://student.oksensay.com/?key={lessonId}"
        >{$_('FRE_STEP_SHARE_BUTTON')}</a
      >
    </div>
  </div>
</div>

<style>
  .step-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    grid-template-areas:
      'steps exit exit'
      'steps interact interact'
      'steps interact interact';
  }

  .steps-list-group {
    grid-area: steps;

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    width: 100%;

    padding-top: 5vh;

    background-color: #cacaca30;
  }

  .steps-list-heading {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6em;
    color: #000000;

    align-items: center;
    padding: 0 3vw 0 3vw;
    margin-bottom: 3vh;
  }

  .steps-list-item {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #00000060;

    align-items: center;
    padding: 0 3vw 0 3vw;
  }

  .steps-list-highlight {
    font-weight: 600;
    color: #00a094;
  }

  .steps-list-complete {
    text-decoration-line: line-through;
  }

  .interaction-area {
    grid-area: interact;

    display: flex;
    justify-self: center;
    width: 80%;
    flex-direction: column;
  }

  .fre-form-group {
    margin-top: 10vh;
    margin-bottom: 0;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #000000;
  }

  .share-url,
  .share-url:read-only {
    width: 85%;
    display: inline-block;
    color: #00a094;
    background-color: transparent;
    padding-right: 30px;
  }

  .fre-form-group > svg {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: -30px;
    cursor: pointer;
  }

  .fre-btn-group {
    margin-top: 3vh;
    align-self: center;
    margin-bottom: 5vh;
  }

  .exit {
    grid-area: exit;
    height: 2vh;
  }

  .skip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 3vh;
  }

  .button-skip {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    text-align: center;
    text-decoration-line: underline;

    color: rgba(17, 17, 17, 0.4);
    border: none;
    background-color: transparent;
  }

  @media only screen and (min-width: 992px) {
    .steps-list-heading {
      font-size: 2em;
    }

    .step-layout {
      grid-template-columns: repeat(3, 1fr);
    }
  }
</style>
