<script>
  import LeftMenu from "../components/LeftMenu.svelte";
  import TopMenu from "../components/TopMenu.svelte";
  import Footer from "../components/Footer.svelte";
  import { userDetails } from "../components/DataStore";
  import { leftMenuToggled } from "../components/DataStore";
  import ImportStudentsToOrg from "./ImportStudentsToOrg/ImportStudentsToOrg.svelte";
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="import-students-in-org" />
    </div>
    <div id="layoutSidenav_content">
      {#if $userDetails?.organizationId}
        <ImportStudentsToOrg organizationId={$userDetails?.organizationId} />
      {/if}
      <!-- <Footer /> -->
    </div>
  </div>
</div>
