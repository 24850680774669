<script>
  import LeftMenu from 'components/LeftMenu.svelte'
  import TopMenu from 'components/TopMenu.svelte'
  import Footer from 'components/Footer.svelte'
  import CourseDetailEditView from './CourseComponents/CourseDetailEditView.svelte'
  import { currentCourseId, leftMenuToggled } from '../../components/DataStore'

  export let courseId = undefined

  if (courseId) {
    $currentCourseId = courseId
    console.log('$currentCourseId', $currentCourseId)
  }
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="courses" />
    </div>
    <div id="layoutSidenav_content">
      <CourseDetailEditView />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
