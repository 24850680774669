<script>
  import { _, t } from 'svelte-i18n'
  import { router } from 'tinro'
  import { getData, postData } from '../../utils/ApiUtils.svelte'
  import Loader from '../../components/Loader.svelte'
  import { onMount } from 'svelte'
  import SvelteHeatmap from 'svelte-heatmap'

  export let courseId

  let studentList = []

  let studentRecordingListStatus = 'loading'

  let heatMap
  let heatMapData = {}

  onMount(async () => {
    getStudentList()
  })

  function getStudentList() {
    getData(`api/studentRecordingListForHeatmapForCoop${courseId? "?courseId="+courseId : ''}`)
      .then((data) => {
        console.log('studentList successful', data)

        studentList = data
        studentRecordingListStatus = 'ok'
        try {
          initHeatMap()
        } catch (error) {
          console.log('error initializing heatmap', error)
        }
      })
      .catch((error) => {
        studentRecordingListStatus = 'error'
        console.log('error lessons', error)
      })
  }

  function getStartDateXMonthAgo(monhts) {
    // Create a new date object for the current date
    let currentDate = new Date()

    // Get the current month and year
    let currentMonth = currentDate.getMonth()
    let currentYear = currentDate.getFullYear()

    // Subtract 12 months from the current date
    let targetMonth = (currentMonth - monhts) % 12 // Get the target month (handle negative values)
    // let targetYear = currentYear + ((currentMonth - monhts) / 12)
    let targetYear = currentYear;// + (currentMonth - monhts) / 12;

    // Set the date to the 1st of the target month and year
    let targetDate = new Date(targetYear, targetMonth, 1)

    // Print the result
    return targetDate
  }

  function initHeatMap() {
    let heatMapData = studentList
      .map((sl) => {
        return {
          date: new Date(Date.parse(sl.recordingDate)),
          value: sl.recordingCount,
        }
      })
      .filter((i) => {
        return i.date instanceof Date
      })
    console.log(heatMapData)

    // const startDate = new Date(Date.parse('2022-06-01T12:00:00.000Z'))
    const startDate = getStartDateXMonthAgo(12)
    // startDate.setMonth(startDate.getMonth() - 12);
    const endDate = new Date(Date.now())
    //const endDate = new Date(Date.parse("2022-08-02T00:00:00.000Z"));
    if (heatMap) {
      heatMap.$destroy()
    }
    heatMap = new SvelteHeatmap({
      props: {
        data: heatMapData,
        startDate: startDate,
        endDate: endDate,
        allowOverflow: false,
        view: 'monthly',
        monthGap: 20,
        monthLabelHeight: 10,
      },
      target: document.querySelector('#heatMap'),
    })
  }
</script>

<div class="card shadow mb-4">
  <div class="card-body">
    {#if studentRecordingListStatus === 'loading'}
      <Loader />
    {/if}
    <div id="heatMap" class="heatMap" />
  </div>
  <div />
</div>
