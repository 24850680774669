<script>
    import { _ } from "svelte-i18n";
    import { getData, patchData } from "../../utils/ApiUtils.svelte";
    import Loader from "../../components/Loader.svelte";
    import { onMount } from "svelte";
    import SvelteTable from "svelte-table";
    import { router } from "tinro";
    import {
        currentPage,
        userConfig,
        userDetails,
    } from "../../components/DataStore";
    import AutoComplete from "simple-svelte-autocomplete";
    import StudentRecordingsDropDown from "../../components/StudentRecordingsDropDown.svelte";
    import printJS from "print-js";
    import { formatNumberWith4Colors } from "utils/FormatUtils.svelte";
    import PremiumIcon from "../../components/visual/PremiumIcon.svelte";
    import TableHeaderItem from "../../components/TableHeaderItem.svelte";
    import { dynamicSort } from "utils/SortingUtils.svelte";
    import Tooltip from "../../components/Tooltip.svelte";

    export let organizationId;

    let studentList = [];
    let studentNameList = [];
    let filterSelections = "";
    let courseList = [];
    let courseListFilter = [];

    let statisticsData = {
        studentsAll: undefined,
        studentsInClass: undefined,
        studentsInRoster: undefined,
    };

    let includeQr = true;

    let studentListStatus = "loading";
    let courseListStatus = "loading";
    let organizationStatus = "loading";

    let sortColumn = "login";
    let sortDirection = 1;

    let selectActive = false;
    let studentLimit;

    function debounce(func, delay) {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    const debouncedSearch = debounce(getStudentList, 500);

    function handleSearch() {
        if (studentListStatus === 'ok'){
            debouncedSearch();
        }
    }

    userConfig.subscribe((v) => {
        if (v?.serverFlags?.qrHidden === true) {
            includeQr = false;
        }
    });

    onMount(async () => {
        getStudentList();
        getCourseList();
        getOrganization();
    });

    function getOrganization() {
        organizationStatus = "loading";
        getData(`api/organization/${organizationId}`, false, false)
            .then((data) => {
                console.log("organization successful", data);
                // organizationData = data;
                organizationStatus = "ok";

                studentLimit = JSON.parse(
                    data?.currentSubscriptionFlags,
                )?.numberOfStudent;
            })
            .catch((error) => {
                console.log("error organization", error);
            });
    }

    function viewStudentDetail(r, newTab = false) {
        console.log("viewStudentDetail", r.studentName);
        mixpanel.track("Teacher View Student Detail Clicked", {
            Location: "Student Page Overview",
        });
        clickChangePage("student-detail/" + r.studentName);
    }

    function clickChangePage(e) {
        console.log("clickChangePage", e);
        $currentPage = e;
        router.goto("/" + e);
    }

    function changeSort(e) {
        console.log(111, e.detail);
        sortColumn = e.detail.sortColumn;
        sortDirection = e.detail.sortDirection;

        // getStudentList();

        studentListStatus = "loading";

        studentList = studentList.sort(dynamicSort(sortColumn, sortDirection));

        studentListStatus = "ok";
    }

    function getStudentList() {
        studentListStatus = "loading";
        let courseIdList = courseListFilter;
        getData(`api/organization/${organizationId}/student-accounts-all`)
            .then((data) => {
                console.log("studentList successful", data, includeQr);

                studentList = data;

                if (filterSelections && filterSelections.length > 0) {
                    studentList = studentList.filter((s) => {
                        return (
                            s?.login?.includes(filterSelections) ||
                            s?.firstName?.includes(filterSelections) ||
                            s?.lastName?.includes(filterSelections)
                        );
                    });
                }

                studentList = studentList.sort(
                    dynamicSort(sortColumn, sortDirection),
                );

                statisticsData = {
                    studentsAll: 0,
                    studentsInClass: 0,
                    studentsInRoster: 0,
                };

                data.forEach((v) => {
                    statisticsData.studentsAll = statisticsData.studentsAll + 1;
                    if (v?.courseCount > 0) {
                        statisticsData.studentsInClass =
                            statisticsData.studentsInClass + 1;
                    }
                    if (v?.studentRosterCount > 0) {
                        statisticsData.studentsInRoster =
                            statisticsData.studentsInRoster + 1;
                    }
                });

                console.log("statisticsData", statisticsData);

                studentListStatus = "ok";
            })
            .catch((error) => {
                studentListStatus = "error";
                console.log("error lessons", error);
            });
    }

    function getCourseList() {
        courseListStatus = "loading";
        getData("api/courses")
            .then((data) => {
                console.log("studentList successful", data);

                courseList = data;

                courseListStatus = "ok";
                console.log("studentNameList", studentNameList);
            })
            .catch((error) => {
                courseListStatus = "error";
                console.log("error lessons", error);
            });
    }

    function removeStudentFromOrg(studentAccountId) {
        studentListStatus = "loading";
        patchData(
            `api/school-admin/organization/${organizationId}/student-accounts/${studentAccountId}/remove`,
            {},
        )
            .then((data) => {
                console.log("studentList successful", data);
                getStudentList();
            })
            .catch((error) => {
                courseListStatus = "error";
                console.log("error lessons", error);
            });
    }

    function removeAllStudentFromOrg(studentDataLine) {
        if (
            confirm(
                $_("ORGANIZATION_MANAGEMENT.REMOVE_STUDENT_ACCOUNT_FROM_ORG", {
                    values: { login: studentDataLine?.login },
                }),
            )
        ) {
            // Save it!
            studentListStatus = "loading";
            patchData(
                `api/school-admin/organization/${organizationId}/student-accounts/${studentDataLine?.id}/remove-all`,
                {},
                false,
            )
                .then((data) => {
                    getStudentList();
                })
                .catch((error) => {
                    courseListStatus = "error";
                    console.log("error lessons", error);
                });
        } else {
            // Do nothing!
            console.log("Thing was not saved to the database.");
        }
    }

    function importStudents() {
        studentListStatus = "loading";
        getStudentList();
    }

    let okCount = 0;
    let errorCount = 0;

    async function massDelete() {
        console.log("111", selectionSet);
        if (
            confirm(
                $_(
                    "ORGANIZATION_MANAGEMENT.REMOVE_STUDENT_ACCOUNT_FROM_ORG_MASS",
                    {
                        values: { count: selectionSet.size },
                    },
                ),
            )
        ) {
            // Save it!
            studentListStatus = "loading";
            okCount = 0;
            errorCount = 0;

            let parts = [...selectionSet];
            for (let i = 0; i < parts.length; i++) {
                let e = parts[i];
                await patchData(
                    `api/school-admin/organization/${organizationId}/student-accounts/${e}/remove-all`,
                    {},
                    false,
                )
                    .then((data) => {
                        okCount++;
                    })
                    .catch((error) => {
                        errorCount++;
                        courseListStatus = "error";
                        console.log("error remove-all ", error);
                    });
            }
            getStudentList();
            console.log("remove-all summary", okCount, errorCount);
        } else {
            // Do nothing!
            console.log("Thing was not saved to the database.");
        }
    }

    let selectionSet = new Set();

    function changeSelectCheckbox() {
        if (selectActive) {
            selectNone();
        } else {
            selectActive = true;
            document.getElementById("flexCheckDefault").indeterminate = true;
        }
    }

    function changeSelect(vId) {
        if (selectionSet.has(vId)) {
            selectionSet.delete(vId);
        } else {
            selectionSet.add(vId);
        }
        selectActive = true;

        if (selectionSet.size === studentList.length) {
            document.getElementById("flexCheckDefault").indeterminate = false;
        } else if (selectionSet.size === 0) {
            document.getElementById("flexCheckDefault").indeterminate = true;
        } else {
            document.getElementById("flexCheckDefault").indeterminate = true;
        }
    }

    function selectNone() {
        selectionSet.clear();
        selectActive = false;
        document.getElementById("flexCheckDefault").indeterminate = false;
    }

    function selectAll() {
        selectionSet = new Set(studentList.map((s) => s.id));
        selectActive = true;
        document.getElementById("flexCheckDefault").indeterminate = false;
    }

    function selectStudentWithoutRoster() {
        selectionSet.clear();
        selectionSet = new Set(
            studentList
                .filter((s) => s.studentRosterCount === 0)
                .map((s) => s.id),
        );
        selectActive = true;

        if (selectionSet.size === studentList.length) {
            document.getElementById("flexCheckDefault").indeterminate = false;
        } else if (selectionSet.size === 0) {
            document.getElementById("flexCheckDefault").indeterminate = true;
        } else {
            document.getElementById("flexCheckDefault").indeterminate = true;
        }
    }

    function selectStudentWithoutClass() {
        selectionSet.clear();
        selectionSet = new Set(
            studentList.filter((s) => s.courseCount === 0).map((s) => s.id),
        );
        selectActive = true;

        if (selectionSet.size === studentList.length) {
            document.getElementById("flexCheckDefault").indeterminate = false;
        } else if (selectionSet.size === 0) {
            document.getElementById("flexCheckDefault").indeterminate = true;
        } else {
            document.getElementById("flexCheckDefault").indeterminate = true;
        }
    }

    function selectStudentWithoutClassNorRoster() {
        selectionSet.clear();
        selectionSet = new Set(
            studentList
                .filter(
                    (s) => s.studentRosterCount === 0 && s.courseCount === 0,
                )
                .map((s) => s.id),
        );
        selectActive = true;

        if (selectionSet.size === studentList.length) {
            document.getElementById("flexCheckDefault").indeterminate = false;
        } else {
            document.getElementById("flexCheckDefault").indeterminate = true;
        }
    }
</script>

<main class="container-fluid px-4 s-container-fluid">
    <div class="s-content-spacing">
        <div class="s-content-header">
            <div class="s-content-header-title">
                {$_("ORGANIZATION_MANAGEMENT.STUDENTS_IN_ORG")}
            </div>
        </div>
    </div>

    <div class="s-content-spacing">
        <div class="card shadow">
            <!-- <div
            class="card-header py-3 no-print d-flex flex-row align-items-center justify-content-between"
          >
            <div>
              <button
                class="btn btn-primary s-button-primary"
                id="reloadExerciseListButton"
                on:click={refreshList}
                ><i class="fa fa-refresh" style="padding-right: 8px;" />{$_(
                  "REFRESH"
                )}<br /></button
              >
              <h3>{$_("ORGANIZATION.NAME")}</h3>
            </div>
          </div> -->
            <div class="card-body">
                {#if studentListStatus === "loading"}
                    <Loader />
                {:else if studentListStatus === "ok"}
                    <div class="row">
                        <div class="col-3">
                            {$_("ORGANIZATION_MANAGEMENT.STUDENTS_ALL")}
                        </div>
                        <div class="col-auto">
                            {statisticsData?.studentsAll || "0"}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            {$_("ORGANIZATION_MANAGEMENT.STUDENTS_IN_CLASS")}
                            <Tooltip
                                title={$_("ORGANIZATION.TEACHER_LIMIT_TOOLTIP")}
                            >
                                <i class="fa-solid fa-circle-info" />
                            </Tooltip>
                        </div>
                        <div class="col-auto">
                            {statisticsData?.studentsInClass || "0"}
                            {#if studentLimit}
                                / {studentLimit}{/if}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            {$_("ORGANIZATION_MANAGEMENT.STUDENTS_IN_ROSTER")}
                        </div>
                        <div class="col-auto">
                            {statisticsData?.studentsInRoster || "0"}
                        </div>
                    </div>
                {:else if studentListStatus === "ERROR_NO_ORGANIZATION"}
                    <div class="alert alert-danger" role="alert">
                        {$_("ORGANIZATION.ERROR_NO_ORGANIZATION")}
                    </div>
                {:else if studentListStatus === "ERROR_MORE_THAN_ONE_ORGANIZATION"}
                    <div class="alert alert-danger" role="alert">
                        {$_("ORGANIZATION.ERROR_MORE_THAN_ONE_ORGANIZATION")}
                    </div>
                {:else}
                    {$_("ERROR")} - {studentListStatus}
                {/if}
            </div>
        </div>
    </div>

    <div class="s-content-spacing">
        <div class="card shadow">
            <div
                class="card-header py-3 no-print d-flex flex-row align-items-center justify-content-between"
            >
                <div>
                    {#if $userDetails?.currentSubscriptionLevel === "SURREAL_ADMIN" || $userDetails?.currentSubscriptionLevel === "SCHOOL_ADMIN" || $userDetails?.currentSubscriptionLevel === "TRIAL_SCHOOL_ADMIN" || $userConfig?.serverFlags?.studentManagement === "RW"}
                        <a
                            role="button"
                            href="#/import-students-in-org"
                            class="btn btn-primary s-button-primary me-2"
                            ><i
                                class="fa fa-plus"
                                style="padding-right: 8px;"
                            />{$_(
                                "ORGANIZATION_MANAGEMENT.IMPORT_STUDENTS_TO_ORG",
                            )}</a
                        >
                    {/if}

                    {#if $userDetails?.currentSubscriptionLevel === "SURREAL_ADMIN" || $userDetails?.currentSubscriptionLevel === "SCHOOL_ADMIN" || $userDetails?.currentSubscriptionLevel === "TRIAL_SCHOOL_ADMIN" || $userConfig?.serverFlags?.studentManagement === "RW"}
                        {#if selectActive}
                            <a
                                role="button"
                                class="btn btn-danger s-button-danger me-2"
                                on:click={massDelete}
                                >⌦ {$_(
                                    "ORGANIZATION_MANAGEMENT.MASS_DELETE",
                                )}</a
                            >
                        {/if}
                    {/if}
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <label
                                for="studentNameFilterInput"
                                class="form-check-label"
                                >{$_("SEARCH_NAME")}</label
                            >
                            <input
                                id="studentNameFilterInput"
                                class="form-control s-form-input"
                                type="text"
                                style="min-width: 20em;"
                                bind:value={filterSelections}
                                on:keyup={handleSearch}
                            />
                        </div>
                    </div>
                    <div class="col align-self-end">
                        <div
                            class="btn btn-primary s-button-primary"
                            on:click={handleSearch}
                        >
                            {$_("SEARCH")}
                        </div>
                    </div>
                </div>

                {#if studentListStatus === "loading"}
                    <Loader />
                {:else if studentListStatus === "error"}
                    <div class="alert alert-danger" role="alert">
                        {$_("LESSON_LIST_LOAD_ERROR")}
                    </div>
                {:else}
                    <div
                        class="table-responsive table mt-2"
                        style="overflow-y: hidden"
                        id="dataTable"
                        role="grid"
                        aria-describedby="dataTable_info"
                    >
                        <table class="table my-0 table-hover" id="dataTable">
                            <thead>
                                <th>
                                    <div class="btn-group">
                                        <button
                                            type="button"
                                            class="btn btn-light"
                                        >
                                            <input
                                                on:click={changeSelectCheckbox}
                                                class="form-check-input"
                                                type="checkbox"
                                                bind:checked={selectActive}
                                                id="flexCheckDefault"
                                            />
                                        </button><button
                                            type="button"
                                            class="btn btn-light dropdown-toggle dropdown-toggle-split"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span class="visually-hidden"
                                                >Toggle Dropdown</span
                                            >
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a
                                                    on:click={selectNone}
                                                    class="dropdown-item"
                                                    >{$_("CLEAR")}</a
                                                >
                                            </li>
                                            <li>
                                                <hr class="dropdown-divider" />
                                            </li>
                                            <li>
                                                <a
                                                    class="dropdown-item"
                                                    on:click={selectAll}
                                                    >{$_("SELECT_ALL")}</a
                                                >
                                            </li>
                                            <li>
                                                <a
                                                    class="dropdown-item"
                                                    on:click={selectStudentWithoutClass}
                                                    >{$_(
                                                        "ORGANIZATION_MANAGEMENT.SELECT_NO_CLASS",
                                                    )}</a
                                                >
                                            </li>
                                            <li>
                                                <a
                                                    class="dropdown-item"
                                                    on:click={selectStudentWithoutRoster}
                                                    >{$_(
                                                        "ORGANIZATION_MANAGEMENT.SELECT_NO_ROSTER",
                                                    )}</a
                                                >
                                            </li>
                                            <li>
                                                <a
                                                    class="dropdown-item"
                                                    on:click={selectStudentWithoutClassNorRoster}
                                                    >{$_(
                                                        "ORGANIZATION_MANAGEMENT.SELECT_NO_CLASS_NOR_ROSTER",
                                                    )}</a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </th>
                                <th
                                    ><TableHeaderItem
                                        column="login"
                                        {sortColumn}
                                        {sortDirection}
                                        on:changeSort={changeSort}
                                        >{$_("LOGIN")}</TableHeaderItem
                                    ></th
                                >

                                <th
                                    ><TableHeaderItem
                                        column="firstName"
                                        {sortColumn}
                                        {sortDirection}
                                        on:changeSort={changeSort}
                                        >{$_("FIRST_NAME")}</TableHeaderItem
                                    ></th
                                >

                                <th
                                    ><TableHeaderItem
                                        column="lastName"
                                        {sortColumn}
                                        {sortDirection}
                                        on:changeSort={changeSort}
                                        >{$_("LAST_NAME")}</TableHeaderItem
                                    ></th
                                >

                                <th
                                    ><TableHeaderItem
                                        column="createdAt"
                                        {sortColumn}
                                        {sortDirection}
                                        on:changeSort={changeSort}
                                        >{$_("CREATED_AT")}</TableHeaderItem
                                    ></th
                                >
                                <th
                                    ><TableHeaderItem
                                        column="courseCount"
                                        {sortColumn}
                                        {sortDirection}
                                        on:changeSort={changeSort}
                                        >{$_(
                                            "ORGANIZATION_MANAGEMENT.COURSE_COUNT",
                                        )}</TableHeaderItem
                                    ></th
                                >
                                <th
                                    ><TableHeaderItem
                                        column="studentRosterCount"
                                        {sortColumn}
                                        {sortDirection}
                                        on:changeSort={changeSort}
                                        >{$_(
                                            "ORGANIZATION_MANAGEMENT.STUDENT_ROSTER_COUNT",
                                        )}</TableHeaderItem
                                    ></th
                                >
                                <th>{$_("ACTIONS")} </th>
                            </thead>
                            <tbody>
                                {#each studentList as v, i}
                                    <tr>
                                        <td>
                                            {#if selectActive}
                                                <input
                                                    class="form-check-input me-2"
                                                    type="checkbox"
                                                    checked={selectionSet.has(
                                                        v.id,
                                                    )}
                                                    on:click={() =>
                                                        changeSelect(v.id)}
                                                />
                                            {/if}
                                            {i + 1}
                                            <span class="ms-2">
                                                <a
                                                    href={`/#/student-account-detail/${v.id}`}
                                                    target="_blank"
                                                    ><svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 30 30"
                                                        width="1em"
                                                        height="1em"
                                                        ><path
                                                            d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"
                                                        /></svg
                                                    ></a
                                                ></span
                                            ></td
                                        >
                                        <td>
                                            <a
                                                href={`/#/student-account-detail/${v.id}`}
                                                ><strong>{v.login}</strong></a
                                            >
                                        </td>
                                        <td>{v.firstName || ""} </td>
                                        <td>{v.lastName || ""}</td>
                                        <td
                                            >{new Date(
                                                Date.parse(v?.createdAt + "Z"),
                                            ).toLocaleString()}</td
                                        >
                                        <td>
                                            <a
                                                href="#/student-account-detail/{v?.id}/SACourses"
                                                >{v?.courseCount || 0}</a
                                            ></td
                                        >
                                        <td
                                            ><a
                                                href="#/student-account-detail/{v?.id}/SARosters"
                                                >{v?.studentRosterCount || 0}</a
                                            ></td
                                        >
                                        <!-- <td>
                                            <button
                                                disabled={v?.courseCount > 0 ||
                                                    v?.studentRosterCount > 0}
                                                class="btn btn-danger"
                                                on:click|stopPropagation={() =>
                                                    removeStudentFromOrg(v?.id)}
                                                >{$_("DELETE")}</button
                                            >
                                        </td> -->
                                        <td>
                                            {#if $userDetails?.currentSubscriptionLevel === "SURREAL_ADMIN" || $userDetails?.currentSubscriptionLevel === "SCHOOL_ADMIN" || $userDetails?.currentSubscriptionLevel === "TRIAL_SCHOOL_ADMIN" || $userConfig?.serverFlags?.studentManagement === "RW"}
                                                <button
                                                    disabled={selectActive}
                                                    class="btn btn-danger"
                                                    title={$_("DELETE")}
                                                    on:click|stopPropagation={() =>
                                                        removeAllStudentFromOrg(
                                                            v,
                                                        )}>⌦</button
                                                >
                                            {/if}
                                        </td>
                                    </tr>
                                {:else}{$_("NO_DATA")}
                                {/each}
                            </tbody>
                        </table>
                    </div>

                    {#if filterSelections}
                        <div class="row">
                            <div class="col-md-6 align-self-center">
                                <p
                                    id="dataTable_info"
                                    class="dataTables_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                    {studentList?.length}
                                    {$_("RESULT_COUNT")}
                                </p>
                            </div>
                            <div class="col-md-6">
                                <nav
                                    class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
                                />
                            </div>
                        </div>
                    {/if}
                {/if}
            </div>
        </div>
    </div>
</main>

<style>
    :global(.recordingRow) {
        cursor: pointer;
    }
    :global(.recordingRow:hover) {
        background-color: whitesmoke;
    }
</style>
