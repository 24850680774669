<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from 'components/Loader.svelte'
  import Readability from 'components/Readability.svelte'
  import { onMount } from 'svelte'
  import { getData } from 'utils/ApiUtils.svelte'
  import { writable } from 'svelte/store'
  import ShareExercise from './ShareExercise.svelte'
  import { convertSecToMin } from 'utils/FormatUtils.svelte'
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    userDetails,
    userConfig,
    currentCourseRoleCheck
  } from '../../../components/DataStore'
  import ExerciseTypeLabel from './VisualComponentns/ExerciseTypeLabel.svelte'

  let audioTag
  let exerciseData = writable({})
  let questionScoring = writable({
    minWords: '',
    minDurationSec: '',
    containWords: '',
  })

  onMount(async () => {
    getExercise($currentCourseId, $currentLessonId, $currentExerciseId)
  })

  let exerciseDataStatus = 'loading'

  function editExerciseDetail() {
    // console.log('editExerciseDetail', $currentCourseId, $currentLessonId)
    clickChangePage(
      'courses/' +
        $currentCourseId +
        '/lessons/' +
        $currentLessonId +
        '/exercise-edit/' +
        $currentExerciseId
    )
  }

  function clickChangePage(e) {
    // console.log('clickChangePage', e)
    currentPage.update((x) => e)
    router.goto('/' + e)
  }

  function getExercise(courseId, lessonId, exerciseId) {
    getData(
      'api/courses/' +
        courseId +
        '/lessons/' +
        lessonId +
        '/exercises/' +
        exerciseId
    )
      .then((data) => {
        console.log('exercises successful', data)
        exerciseData.update((x) => data)
        currentCourseName.update((x) => $exerciseData?.courseName)
        currentLessonName.update((x) => $exerciseData?.lessonName)
        currentExerciseName.update((x) => $exerciseData?.title)
        exerciseDataStatus = 'ok'

        let x = JSON.parse($exerciseData?.data)
        $questionScoring = {
          minWords: x?.qs?.minWords,
          minDurationSec: x?.qs?.minDurationSec,
          containWords: x?.qs?.containWords,
        }

        if (
          x?.qs?.minWords ||
          x?.qs?.minDurationSec ||
          x?.qs?.containWords?.length > 0 ||
          $exerciseData?.answer
        ) {
          $questionScoring['enabled'] = true
        }

        if ($exerciseData?.ttsUrl) {
          getRecording($exerciseData?.ttsUrl)
        }
      })
      .catch((error) => {
        console.log('error exercises', error)
        exerciseDataStatus = 'error'
      })

      getData(`api/courses/${$currentCourseId}/user-roles/check`)
      .then((data) => {
        console.log('courses successful', data)
        $currentCourseRoleCheck = data
        // currentCourseName.update((x) => courseData?.name)
        // courseDetailStatus = 'ok'
      })
      .catch((error) => {
        console.log('error courses', error)
        courseDetailStatus = 'error'
      })

  }

  function isExerciseActive(exerciseData) {
    if (
      exerciseData?.lessonActiveDateStart ||
      exerciseData?.lessonActiveDateEnd
    ) {
      let startDate = Date.parse(exerciseData?.lessonActiveDateStart)
      let endDate = Date.parse(exerciseData?.lessonActiveDateEnd)
      let now = Date.now()

      console.log(333, startDate, endDate, now)
      if (startDate && startDate > now) {
        return false
      }
      if (endDate && endDate < now) {
        return false
      }
      return true
    }
    return null
  }

  async function getRecording(ttsUrl) {
    let filePath =
      'https://www.googleapis.com/storage/v1/b/shuoshuo/o/exerciseAudio%2F' +
      ttsUrl

    fetch(filePath)
      .then((dataResult) => {
        dataResult.json().then((dataJson) => {
          fetch(dataJson?.mediaLink).then((audioData) => {
            audioData.blob().then((audioBlob) => {
              console.log(audioBlob)
              audioTag.src = URL.createObjectURL(audioBlob)
            })
          })
        })
      })
      .catch((error) => {
        console.log('error courses', error)
      })
  }
</script>

{#if exerciseDataStatus === 'loading'}
  <Loader />
{:else}
  <div class="card shadow">
    <div class="card-body">
      <div class="card-body">
        <table class="table">
          <tbody>
            <tr
              ><th scope="row">{$_('TITLE')}</th><td>{$exerciseData?.title}</td
              ></tr
            >
            <tr
              ><th scope="row">{$_('EXERCISE_TYPE')}</th><td>
                <ExerciseTypeLabel type={$exerciseData?.type} />
              </td></tr
            >
            <!-- <tr><th scope="row">{$_("STATUS")}</th><td>{$exerciseData?.status}</td></tr> -->
            {#if $userConfig?.serverFlags?.disablePinSharing === false || !($userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.disablePinSharing === true)}
              <tr
                ><th scope="row"> {$_('EXERCISE_PIN')}</th><td
                  >{#if $exerciseData?.lessonActiveDateEnd || $exerciseData?.lessonActiveDateEnd}
                    {#if isExerciseActive($exerciseData)}
                      <span style="color:green">&#9679;</span>
                    {:else}
                      <span style="color:red">&#9679;</span>
                    {/if}
                  {/if}{$exerciseData?.key}
                  <ShareExercise
                    pin={$exerciseData?.key}
                    id={$exerciseData?.id}
                    isActive={isExerciseActive($exerciseData)}
                  />
                </td></tr
              >
            {/if}

            <tr
              ><th scope="row">{$_('NUMBER_OF_RECORDINGS_EXERCISE')}</th><td
                >{$exerciseData?.publicRecordingsCount}</td
              ></tr
            >
            <tr
              ><th scope="row">{$_('NUMBER_OF_WORDS_EXERCISE')}</th><td
                >{$exerciseData?.publicRecordingsWordCountSum}</td
              ></tr
            >
            <tr>
              <th scope="row">{$_('LATEST_RECORDING', { values: { n: 1 } })}</th
              >
              <td>
                {#if $exerciseData?.publicRecordingsLatest}
                  {new Date(
                    Date.parse($exerciseData?.publicRecordingsLatest + 'Z')
                  ).toLocaleString()}
                {:else}N/A
                {/if}
              </td>
            </tr>
          </tbody>
        </table>

        {#if $exerciseData.status != 'PUBLISHED'}
          <div class="float-end" role="group">
            <button
              class="btn btn-secondary s-button-secondary"
              disabled={"RW" !== $currentCourseRoleCheck?.permissions}
              on:click={editExerciseDetail}
            >
              <i class="fa fa-edit" style="padding-right: 8px;" />{$_(
                'EDIT_EXERCISE_INFO'
              )}</button
            >

            {#if $userConfig?.serverFlags?.disablePinSharing === false || !($userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.disablePinSharing === true)}

              <ShareExercise
                pin={$exerciseData?.key}
                asButton
                id={$exerciseData?.id}
                isActive={isExerciseActive($exerciseData)}
              />
            {/if}
          </div>
        {:else if $userConfig?.serverFlags?.disablePinSharing === false || !($userDetails?.userRole === "SURREAL" || $userConfig?.serverFlags?.disablePinSharing === true)}
          <div class="float-end" role="group">
            <ShareExercise
              pin={$exerciseData?.key}
              asButton
              id={$exerciseData?.id}
            />
          </div>
        {/if}
      </div>
    </div>
  </div>
  <div class="card shadow" style="margin-top: 10px;">
    <div class="card-body">
      <form>
        {#if $exerciseData.type === 'READ'}
          <div class="form-row">
            <div class="col">
              <label for="exerciseText">{$_('TEXT')}</label>
              <pre
                id="exerciseText"
                style="white-space: pre-wrap; font-size:120%">{$exerciseData?.text}
                    </pre>
            </div>

            {#if $userConfig?.serverFlags?.ssmlEditor === true}
              {#if $exerciseData?.ssml}
                <div class="col">
                  <label for="exerciseText">{$_('SSML')}</label>
                  <pre
                    id="exerciseText"
                    style="white-space: pre-wrap; font-size:80%">{$exerciseData?.ssml}
                    </pre>
                </div>
              {/if}
              {#if $exerciseData?.ttsWords}
                <div class="col">
                  <label for="exerciseText">{$_('TTS_WORDS')}</label>
                  <pre
                    id="exerciseText"
                    style="white-space: pre-wrap; font-size:70%">{$exerciseData?.ttsWords}
                    </pre>
                </div>
              {/if}
              {#if $exerciseData?.ttsUrl}
                <div class="col">
                  <label for="exerciseText">{$_('TTS_URL')}</label>
                  <audio controls bind:this={audioTag} />
                </div>
              {/if}
            {/if}
          </div>
          {#if $exerciseData?.language === 'en-us' || $exerciseData?.language === 'en-US'}
            <div class="form-row">
              <div class="col">
                <Readability textValue={$exerciseData?.text} />
              </div>
            </div>
          {/if}
        {:else if $exerciseData.type === 'VIDEO'}
          <div class="form-row">
            <div class="col">
              <label for="youtubeUrl">{$_('YOUTUBE_VIDEO')}</label>
              <a
                href={$exerciseData?.youtubeUrl}
                id="youtubeUrl"
                target="_blank"
                rel="noopener noreferrer"
                >{$exerciseData?.youtubeUrl}
              </a>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <label for="exerciseText">{$_('QUESTION')}</label>
              <pre
                id="exerciseText"
                style="white-space: pre-wrap; font-size:120%">{$exerciseData?.question}
                    </pre>
            </div>
          </div>
        {:else if $exerciseData.type === 'QUESTION'}
          <div class="form-row">
            <div class="col">
              <label for="exerciseText">{$_('QUESTION')}</label>
              <pre
                id="exerciseText"
                style="white-space: pre-wrap; font-size:120%">{$exerciseData?.question}
                    </pre>
            </div>
          </div>
        {:else if $exerciseData.type === 'SELF_PRACTICE'}
          <div class="form-row">
            <div class="col">
              <p>
                <i class="fas fa-info-circle" style="margin-right: 8px;" />{$_(
                  'STUDENT_PROVIDE_TEXT'
                )}
              </p>
            </div>
          </div>
        {:else if $exerciseData.type === 'IMAGE'}
          <div class="form-row">
            <div class="col">
              <label for="image">{$_('IMAGE')}</label>
              <div>
                <img
                  class="customImage"
                  src={$exerciseData.imageUrl}
                  alt="Question"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <label for="exerciseText">{$_('QUESTION')}</label>
              <pre
                id="exerciseText"
                style="white-space: pre-wrap; font-size:120%">{$exerciseData?.question}
                    </pre>
            </div>
          </div>
        {:else if $exerciseData.type === 'READ_IMAGE'}
          <div class="form-row">
            <div class="col">
              <label for="image">{$_('IMAGE')}</label>
              <div>
                <img
                  class="customImage"
                  src={$exerciseData.imageUrl}
                  alt="Question"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <label for="exerciseText">{$_('QUESTION')}</label>
              <pre
                id="exerciseText"
                style="white-space: pre-wrap; font-size:120%">{$exerciseData?.question}
                    </pre>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <label for="exerciseText">{$_('EXERCISE_.EXPECTED_ANSWER')}</label
              >
              <pre
                id="exerciseText"
                style="white-space: pre-wrap; font-size:120%">{$exerciseData?.answer}
                    </pre>
            </div>
          </div>
        {:else if $exerciseData.type === 'QUESTION_FIX_ANSWER'}
          <div class="form-row">
            <div class="col">
              <label for="exerciseText">{$_('QUESTION')}</label>
              <pre
                id="exerciseText"
                style="white-space: pre-wrap; font-size:120%">{$exerciseData?.question}
                    </pre>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <label for="exerciseText">{$_('EXERCISE_.EXPECTED_ANSWER')}</label
              >
              <pre
                id="exerciseText"
                style="white-space: pre-wrap; font-size:120%">{$exerciseData?.answer}
                    </pre>
            </div>
          </div>
        {/if}
        {#if $userDetails?.userRole === 'ADMIN' || $userConfig?.serverFlags?.questionScoring === true}
          {#if ['VIDEO', 'QUESTION', 'IMAGE'].includes($exerciseData.type)}
            <div class="form-row">
              <div class="col">
                <label for="questionScoringEnabled"
                  >{$_('SCORING.ADVANCED_SCORING')}</label
                >

                {#if $questionScoring.enabled}
                  <div>
                    {#if $questionScoring.minWords}
                      <div>
                        {$_('SCORING.MIN_WORDS')}: {$questionScoring.minWords}
                      </div>
                    {/if}

                    {#if $questionScoring.minDurationSec}
                      <div>
                        {$_('SCORING.MIN_DURATION')}: {$questionScoring.minDurationSec}
                      </div>
                    {/if}

                    {#if $questionScoring.containWords?.length > 0}
                      <div>
                        {$_('SCORING.CONTAIN_WORDS')}:
                        {#if Array.isArray($questionScoring.containWords)}
                          <ul>
                            {#each $questionScoring.containWords as w, i}
                              <li>{w}</li>
                            {/each}
                          </ul>
                        {/if}
                      </div>
                    {/if}
                    {#if $exerciseData.answer}
                      <div>
                        <span>{$_('SCORING.MATCH_TEXT')}:</span>
                        {$exerciseData.answer}
                      </div>
                    {/if}
                  </div>
                {:else}{$_('SCORING.NOT_ENABLED')}
                {/if}
              </div>
            </div>
          {/if}
        {/if}
      </form>
    </div>
  </div>
{/if}

<style>
  .customImage {
    max-width: 100%;
  }
</style>
