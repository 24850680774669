<script>
  import { _ } from "svelte-i18n";
  import { currentCourseRoleCheck } from "../../../components/DataStore";
  import { createEventDispatcher } from "svelte";

  export let elementId = "dropdownMenu";

  const dispatch = createEventDispatcher();

  function hideDropdown() {
    bootstrap.Dropdown.getInstance(`#${elementId}`).hide();
  }

  function refresh() {
    hideDropdown();
    dispatch("refresh", {});
  }

  function editLessonDetail() {
    hideDropdown();
    dispatch("editLessonDetail", {});
  }

  function copyLesson() {
    hideDropdown();
    dispatch("copyLesson", {});
  }

  function deleteLesson() {
    hideDropdown();
    dispatch("deleteLesson", {});
  }
</script>

<div class="btn-group float-end">
  <button
    id={elementId}
    type="button"
    class="dropdown-toggle no-border"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
  </button>
  <ul class="dropdown-menu">
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={refresh}
        ><i class="fa fa-refresh" style="padding-right: 8px;" />{$_(
          "REFRESH",
        )}</button
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={editLessonDetail}
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        ><i class="fa fa-edit" style="padding-right: 8px;" />{$_(
          "EDIT_LESSON_INFO",
        )}</button
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={copyLesson}
        ><i class="far fa-copy" style="padding-right: 8px;" />{$_(
          "COPY_TO_OTHER_COURSE",
        )}</button
      >
    </li>
    <li><hr class="dropdown-divider" /></li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        disabled={"RW" !== $currentCourseRoleCheck?.permissions}
        on:click|stopPropagation={deleteLesson}
        ><i class="far fa-window-close" style="padding-right: 8px;" />{$_(
          "DELETE",
        )}</button
      >
    </li>
  </ul>
</div>

<style>
  .no-border {
    border: 0px;
    background-color: white;
  }
</style>
