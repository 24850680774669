<script>
  import { _, init, getLocaleFromNavigator } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import { getData } from '../../utils/ApiUtils.svelte'
  import UserProfileDetail from './UserProfileDetail.svelte'
  import ChangePassword from './ChangePassword.svelte'
  import UserSubscriptionOnProfile from './UserSubscriptionOnProfile.svelte'
  import { userDetails, avatarImageSrc } from '../../components/DataStore'
  import LanguageSettings from './LanguageSettings.svelte'

  onMount(async () => {
    getProfile()
  })

  let profileStatus = 'loading'
  let userData = undefined

  function getProfile() {
    getData('api/user/me')
      .then((data) => {
        console.log('profile successful', data)
        // Save data to sessionStorage
        userData = data
        profileStatus = 'ok'
        userDetails.update((x) => userData)
      })
      .catch((error) => {
        console.log('error courses', error)
      })
  }

  console.log('getLocaleFromNavigator', getLocaleFromNavigator())
  let language = getLocaleFromNavigator()

  function changeLanguage(event) {
    console.log('changeLanguage', event)
    if (language && (language === 'en-US' || language === 'zh-TW')) {
      init({
        fallbackLocale: 'en',
        initialLocale: language,
      })
    } else {
      init({
        fallbackLocale: 'en',
        initialLocale: getLocaleFromNavigator(),
      })
    }
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">
        {$_('PROFILE')}
      </div>
    </div>
  </div>

  <div class="s-content-spacing">
    <div class="row mb-3">
      <div class="col-lg-4 pe-lg-5">
        <div class="mb-3">
          <div class="text-center">
            <img
              class="rounded-circle mb-3 mt-4"
              alt="avatar"
              src={$avatarImageSrc}
              width="200"
              height="200"
            />
            <div class="mb-3">
              <button
                class="btn btn-primary btn-block s-button-primary"
                style="width: 100%"
                on:click={() =>
                  window.open('https://www.gravatar.com/', '_blank')}
                >{$_('CONFIGURE_GRAVATAR')}</button
              >
            </div>
          </div>
        </div>

        <UserSubscriptionOnProfile />

        <div class="row">
          <div class="col">
            <LanguageSettings />
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col">
            <UserProfileDetail />
            <ChangePassword />
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<style>
</style>
