<script>
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { getData, postData } from "utils/ApiUtils.svelte";
    import {
        currentCourseId,
        currentLessonId,
        currentCourseName,
        currentLessonName,
        userDetails,
    } from "../../../components/DataStore";
    import { dynamicSort } from "utils/SortingUtils.svelte";
    import Loader from "../../../components/Loader.svelte";
    import { createEventDispatcher } from "svelte";

    export let courseId;
    const dispatch = createEventDispatcher();

    let dialogCopyToOtherTeacher;
    let copyCourseToSelectedUserFlag;
    let targetTeacherEmail;
    let targetTeacherList = [];
    let copyCourseToUserSuccessStatus;

    let status;

    async function initialize() {
        status = "loading";

        targetTeacherList = [];
        copyCourseToUserSuccessStatus = "";

        getData("api/organization/all-teachers")
            .then((data) => {
                console.log(321, data);
                targetTeacherList = data
                    .filter((t) => t?.state === "ACTIVE")
                    .filter((t) => t.teacherEmail !== $userDetails?.login);
                status = "ok";
            })
            .catch((error) => {
                console.log("error teachers", error);
                status = "error";
            });
    }

    export function openCopyToOtherTeacherDialog() {
        initialize();
        dialogCopyToOtherTeacher.showModal();
    }

    function copyCourseToSelectedUserConfirm() {
        let payload = {
            courseId: $currentCourseId,
            targetEmail: targetTeacherEmail,
        };

        copyCourseToUserSuccessStatus = "saving";

        postData("api/courses/" + $currentCourseId + "/copyToUsers", payload)
            .then((data) => {
                console.log("courses successful", data);
                copyCourseToUserSuccessStatus = "ok";
            })
            .catch((error) => {
                console.log("error courses", error);
                copyCourseToUserSuccessStatus = "error";
            });
    }

    function copyCourseToSelectedUserCancel() {
        dialogCopyToOtherTeacher.close();
    }
</script>

<dialog bind:this={dialogCopyToOtherTeacher} style="min-width:450px;">
    {#if status !== "ok"}
        <Loader />
    {:else if status !== "ok"}
        Error 
    {:else}
        <div class="row align-items-end mb-2">
            <div class="col">
                <label for="copyCourseToEmail">{$_("SELECT_TEACHER")}</label>
                <div class="form-group">
                    <select
                        class="form-select"
                        bind:value={targetTeacherEmail}
                        aria-label="multiple select example"
                    >
                        {#each targetTeacherList as t}
                            <option value={t.teacherEmail}
                                >{t.teacherFirstName}
                                {t.teacherLastName} ({t.teacherEmail})</option
                            >
                        {:else}<option
                                >{$_(
                                    "NO_OTHER_TEACHER_IN_ORGANIZATION",
                                )}</option
                            >
                        {/each}
                    </select>

                    {#if copyCourseToUserSuccessStatus === "error"}
                        <div class="alert alert-danger" role="alert">
                            {$_("COURSE_SAVING_ERROR")}
                        </div>
                    {:else if copyCourseToUserSuccessStatus === "saving"}
                        <div class="alert alert-info" role="alert">
                            {$_("PROCESSING")}
                        </div>
                    {:else if copyCourseToUserSuccessStatus === "ok"}
                        <div class="alert alert-success" role="alert">
                            {$_("COPIED")}
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    {/if}
    <div class="mt-4">
        <button
            disabled={targetTeacherList.length === 0 || !targetTeacherEmail}
            class="btn btn-success s-button-primary"
            on:click={copyCourseToSelectedUserConfirm}>{$_("COPY")}</button
        ><button
            class="btn btn-danger s-button-danger ms-4"
            on:click={copyCourseToSelectedUserCancel}>{$_("CANCEL")}</button
        >
    </div>
</dialog>
