<script>
    import { _ } from "svelte-i18n";
    import { router } from "tinro";
    import { getData, postData } from "../../utils/ApiUtils.svelte";
    import Loader from "../../components/Loader.svelte";
    import { onMount } from "svelte";
    import TextAreaAutoresize from "../../components/TextAreaAutoresize.svelte";

    onMount(async () => {
        loadData();
        getLocation();
    });

    let locationData;

    function getLocation() {
        fetch("https://get.geojs.io/v1/ip/geo.json")
            .then((response) => response.json())
            .then((data) => {
                console.log(data);

                locationData = data;

                if (data?.country_code === "TW") {
                    currency = "NTD";
                } else if (data?.country_code === "US") {
                    currency = "USD";
                } else if (data?.country_code === "CZ") {
                    currency = "EUR";
                } else {
                    currency = undefined;
                }

                recalculate();
            });
    }

    let numberOfStudents = "";
    let numberOfMonths = 12;

    let currency = "NTD";

    let price = 100;
    let total = 0;
    let comment = "";
    let billingAddress = "";
    let activeDateStartUi = undefined;
    let activeDateStart = undefined;

    let requestQuoteData = [];
    let requestQuoteStatus = "loading";

    function loadData() {
        //
        requestQuoteStatus = "loading";

        getData(`api/teacher/subscription/request-quote`, false, false)
            .then((data) => {
                console.log("student-roster successful", data);
                // Save data to sessionStorage
                requestQuoteData = data;

                requestQuoteStatus = "OK";
            })
            .catch((error) => {
                console.log("error student-roster", error);
                requestQuoteStatus = "ERROR";
            });
    }

    function recalculate() {
        if (currency === "NTD") {
            if (numberOfStudents <= 30) {
                if (numberOfMonths <= 1) {
                    price = 367;
                } else if (numberOfMonths <= 6) {
                    price = 210;
                } else if (numberOfMonths <= 12) {
                    price = 173;
                } else {
                    price = 131;
                }
            } else if (numberOfStudents <= 60) {
                if (numberOfMonths <= 1) {
                    price = 336;
                } else if (numberOfMonths <= 6) {
                    price = 189;
                } else if (numberOfMonths <= 12) {
                    price = 152;
                } else {
                    price = 105;
                }
            } else if (numberOfStudents <= 100) {
                if (numberOfMonths <= 1) {
                    price = 305;
                } else if (numberOfMonths <= 6) {
                    price = 163;
                } else if (numberOfMonths <= 12) {
                    price = 131;
                } else {
                    price = 95;
                }
            } else if (numberOfStudents <= 250) {
                if (numberOfMonths <= 1) {
                    price = 273;
                } else if (numberOfMonths <= 6) {
                    price = 137;
                } else if (numberOfMonths <= 12) {
                    price = 105;
                } else {
                    price = 84;
                }
            } else {
                price = undefined;
            }
        } else if (currency === "USD" || currency === "EUR") {
            if (numberOfStudents <= 30) {
                if (numberOfMonths <= 1) {
                    price = 12;
                } else if (numberOfMonths <= 6) {
                    price = 10;
                } else if (numberOfMonths <= 12) {
                    price = 10;
                } else {
                    price = 7;
                }
            } else if (numberOfStudents <= 60) {
                if (numberOfMonths <= 1) {
                    price = 12;
                } else if (numberOfMonths <= 6) {
                    price = 10;
                } else if (numberOfMonths <= 12) {
                    price = 10;
                } else {
                    price = 7;
                }
            } else if (numberOfStudents <= 100) {
                if (numberOfMonths <= 1) {
                    price = 10;
                } else if (numberOfMonths <= 6) {
                    price = 10;
                } else if (numberOfMonths <= 12) {
                    price = 10;
                } else {
                    price = 7;
                }
            } else if (numberOfStudents <= 250) {
                if (numberOfMonths <= 1) {
                    price = 10;
                } else if (numberOfMonths <= 6) {
                    price = 10;
                } else if (numberOfMonths <= 12) {
                    price = 8;
                } else {
                    price = 6;
                }
            } else {
                price = undefined;
            }
        } else {
            price = undefined;
        }

        if (price) {
            total = (numberOfStudents * numberOfMonths * price).toFixed(0);
        } else {
            total = undefined;
        }
    }

    let status = "";

    async function requestQuote() {
        let payload = {
            startDate: activeDateStart,
            durationMonth: numberOfMonths,
            accountCount: numberOfStudents,
            priceListId: 1,
            price: price,
            total: total,
            currency: currency,
            status: "NEW",
            comment: comment,
            billingAddress: billingAddress,
            countryCode: locationData?.country_code,
            jsonData: JSON.stringify(locationData),
        };

        await postData(`api/teacher/subscription/request-quote`, payload, false)
            .then((data) => {
                console.log("successful", data);
                status = "OK";

                loadData();
            })
            .catch((error) => {
                status = "ERROR";
            });
    }
</script>

<main class="container-fluid px-4 s-container-fluid">
    <div class="s-content-spacing">
        <div class="s-content-header">
            <div class="s-content-header-title">
                {$_("REQUEST_QUOTE")}
            </div>
        </div>
    </div>

    <div class="s-content-spacing">
        <div class="card shadow">
            <div class="card-body">
                {#if requestQuoteStatus !== "OK"}
                    <Loader></Loader>
                {:else}
                    <div class="row">
                        <div class="col">
                            {#if requestQuoteData?.length > 0}
                            <h1>{$_("REQUEST_QUOTE.PAST_QUOTES")}</h1>
                            <table class="table">
                                <thead
                                    ><th>{$_("START_DATE")}</th>
                                    <th>{$_("STUDENTS")}</th>
                                    <th>{$_("DURATION")}</th>
                                    <th>{$_("PRICE")}</th>
                                    <th>{$_("TOTAL")}</th>
                                    <th>{$_("STATUS")}</th></thead
                                >
                                <tbody>
                                    {#each requestQuoteData as rq, i}
                                        <tr>
                                            <td>{rq.startDate}</td>
                                            <td>{rq.accountCount}</td>
                                            <td>{rq.durationMonth}</td>
                                            <td>{rq.price} {rq.currency}</td>
                                            <td>{rq.total} {rq.currency}</td>
                                            <td>{rq.status}</td>
                                        </tr>
                                    {:else}
                                    {/each}
                                </tbody>
                            </table>
                            {:else}
                            {$_("REQUEST_QUOTE.NO_DATA")}
                            {/if}
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    </div>

    <div class="s-content-spacing">
        <div class="card shadow">
            <div class="card-body">
                <div class="row">
                    <div class="col-2">
                        {$_("REQUEST_QUOTE.SELECT_CURRENCY")}: <span style="font-size:large;">{currency}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                        <label for="customRange3" class="form-label"
                            >{$_("REQUEST_QUOTE.NUMBER_OF_STUDENTS")}</label
                        >

                        <input
                            type="text"
                            class="form-control"
                            bind:value={numberOfStudents}
                            on:change={recalculate}
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <label for="customRange3" class="form-label"
                            >{$_("REQUEST_QUOTE.NUMBER_OF_MONTHS")}</label
                        >

                        <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                type="button"
                                class:active={numberOfMonths === 1}
                                class="btn btn-outline-primary"
                                on:click={() => {
                                    numberOfMonths = 1;
                                    recalculate();
                                }}>1</button
                            >
                            <button
                                type="button"
                                class:active={numberOfMonths === 3}
                                class="btn btn-outline-primary"
                                on:click={() => {
                                    numberOfMonths = 3;
                                    recalculate();
                                }}>3</button
                            >
                            <button
                                type="button"
                                class:active={numberOfMonths === 6}
                                class="btn btn-outline-primary"
                                on:click={() => {
                                    numberOfMonths = 6;
                                    recalculate();
                                }}>6</button
                            >
                            <button
                                type="button"
                                class:active={numberOfMonths === 12}
                                class="btn btn-outline-primary"
                                on:click={() => {
                                    numberOfMonths = 12;
                                    recalculate();
                                }}>12</button
                            >
                            <button
                                type="button"
                                class:active={numberOfMonths === 24}
                                class="btn btn-outline-primary"
                                on:click={() => {
                                    numberOfMonths = 24;
                                    recalculate();
                                }}>24</button
                            >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        {$_("REQUEST_QUOTE.PRICE_PER_UNIT_PER_MONTH")}:
                    </div>
                    <div class="col-2">
                        <span style="font-size:large;">
                            {#if price}
                                {price}
                                {currency}{:else}{$_(
                                    "REQUEST_QUOTE.CUSTOM_PRICING",
                                )}{/if}</span
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        {$_("REQUEST_QUOTE.TOTAL")}:
                    </div>
                    <div class="col-2">
                        <span style="font-size:x-large;">
                            {#if total}
                                {total}
                                {currency}
                            {:else}{$_("REQUEST_QUOTE.CUSTOM_PRICING")}
                            {/if}
                        </span>
                    </div>
                </div>

                <div class="row">
                <div class="col form-group">
                    <label for="activeDateStartUi">{$_('START_DATE')}</label>
                    <input
                      id="activeDateStartUi"
                      type="date"
                      bind:value={activeDateStartUi}
                      on:change={(v) => {
                        activeDateStart = new Date(
                          Date.parse(v.target.value)
                        )?.toISOString()
                      }}
                    />
                  </div>
                </div>

                <div>
                    <label for="customRange3" class="form-label"
                        >{$_("REQUEST_QUOTE.BILLING_ADDRESS")}</label
                    >
                    <TextAreaAutoresize bind:value={billingAddress} />
                </div>

                <div>
                    <label for="customRange3" class="form-label"
                        >{$_("REQUEST_QUOTE.COMMENT")}</label
                    >
                    <TextAreaAutoresize bind:value={comment} />
                </div>

                <div class="row">
                    <div class="col">
                        {#if status !== "loading"}
                            <button
                                class="btn btn-primary s-button-primary"
                                on:click={requestQuote}
                                >{$_("REQUEST_QUOTE.REQUEST_QUOTE")}</button
                            >
                        {:else}
                            <Loader></Loader>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<style>
</style>
