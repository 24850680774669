<script>
  import ky from 'ky'
  import { onMount } from 'svelte'
  import { exerciseData, ssmlState } from '../ExerciseEdit/exerciseEditStore'
  import Uploader from './Uploader.svelte'

  let ttsToken

  let SpeechSDK
  let synthesizer
  let player
  let audioConfig
  let serviceRegion = 'eastasia'
  let authorizationToken

  let svgAnimation
  let svgAnimation2 = ''
  let svgComponent

  let fileBlob

  let words = []

  let timer
  let audioTag
  let audioFileName

  let timerValue = 0
  let timerValueEnd

  onMount(async () => {
    intialize()

    if ($exerciseData?.ttsWords) {
      words = JSON.parse($exerciseData?.ttsWords)
    }
  })

  function intialize() {
    if (!!window.SpeechSDK) {
      SpeechSDK = window.SpeechSDK

      player = undefined
      audioConfig = undefined
      synthesizer = undefined

      $ssmlState = 'starting'

      initPlayPage()
    } else {
      $ssmlState = 'error'
      console.log('error with SpeechSDK')
    }
  }

  function initPlayPage() {
    let data = {
      'Ocp-Apim-Subscription-Key': 'f5f51df19b84457fa04c08923439e070', //"67dbd5e5569546ffaf530da9b90b80d0",
    }
    postToken(
      'https://eastasia.api.cognitive.microsoft.com/sts/v1.0/issuetoken',
      data
    )
      .then((data) => {
        console.log('token', data)
        ttsToken = data
        $ssmlState = 'ready'
      })
      .catch((error) => {
        console.log('token', error)
        $ssmlState = 'error'
      })
  }

  async function postToken(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded',
        ...data,
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    console.log('postToken - status', response.status)
    //uploadStatus.innerHTML = response.status;
    return response.text() // parses JSON response into native JavaScript objects
  }

  function generate() {
    if (ttsToken != null) {
      authorizationToken = ttsToken
    } else {
      console.log('missing token')
      return
    }

    // if we got an authorization token, use the token. Otherwise use the provided subscription key
    let speechConfig
    if (authorizationToken) {
      speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
        authorizationToken,
        serviceRegion
      )
    }

    words = []

    speechConfig.speechSynthesisOutputFormat =
      SpeechSDK.SpeechSynthesisOutputFormat.Audio24Khz48KBitRateMonoMp3
    // player = new SpeechSDK.SpeakerAudioDestination()
    // audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player)

    synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, null)
    // synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig)

    $ssmlState = 'generating'

    synthesizer.wordBoundary = function (s, e) {
      window.console.log('wordBoundary', new Date().toISOString(), s, e)
      words.push({
        text: e.text,
        displayText: convertSymbols(e.text),
        start: e.audioOffset,
        duration: e.duration,
        end: e.audioOffset + Math.max(e.duration, 500000),
        boundaryType: e.boundaryType,
      })
      words = words
    }

    // synthesizer.visemeReceived = function (s, e) {
    //   window.console.log(
    //     '(Viseme), Audio offset: ' +
    //       e.audioOffset / 10000 +
    //       'ms. Viseme ID: ' +
    //       e.visemeId,
    //     e.animation
    //   )

    //   if (e?.animation) {
    //     svgAnimation = e.animation

    //     //   e.animation = e.animation.replaceAll('begin="0.5s"', 'begin="indefinite"')
    //     //   e.animation = e.animation.replaceAll('svg', 'svg class="viseme"')
    //     svgAnimation = svgAnimation.replaceAll(
    //       'width="1200px" height="1200px"',
    //       ''
    //     )
    //   }

    //   // `Animation` is an xml string for SVG or a json string for blend shapes
    // }

    synthesizer.synthesizing = function (s, e) {
      var str = `Synthesizing event: \
          \r\n\tAudioData: ${e.result.audioData.byteLength} bytes`
      console.log(new Date().toISOString(), str)
    }

    synthesizer.speakSsmlAsync(
      $exerciseData.ssml,
      function (result) {
        console.log(result)

        if (
          result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
        ) {
          fileBlob = new Blob([result.audioData], { type: 'audio/mp3' })

          console.log('synthesis finished', words)
        } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
          console.log('synthesis failed. Error detail: ' + result.errorDetails)
          $ssmlState = 'error'
        }
        synthesizer.close()
        synthesizer = undefined
        $ssmlState = 'generated'
        $exerciseData.ttsWords = JSON.stringify(words)
        mapTtsWords(words)
        if (audioTag) {
          audioTag.src = window.URL.createObjectURL(fileBlob)
        }
      },
      function (err) {
        $ssmlState = 'error'
        console.log('Synthesizer Error', err)

        synthesizer.close()
        synthesizer = undefined
        intialize()
      }
    )
  }

  function mapTtsWords(json) {
    //  let out = ''
    //  json.forEach(w => {
    //   if (w?.boundaryType === 'WordBoundary'){
    //     out += w.displayText
    //   }

    //  });

    //FIXME - join with space only if w.boundaryType === WordBoundary

    let result = []
    let prevType = null

    for (let i = 0; i < json.length; i++) {
      let currType = json[i].boundaryType
      let value = json[i].displayText

      if (prevType === null) {
        result.push(value) // First item, just append the value
      } else if (
        (prevType === 'WordBoundary' && currType === 'WordBoundary') ||
        (prevType === 'PunctuationBoundary' && currType === 'WordBoundary')
      ) {
        result.push(' ' + value) // A to A or B to A, add space before appending value
      } else {
        result.push(value) // A to B, no space before appending value
      }

      prevType = currType // Update previous type for next iteration
    }

    $exerciseData.text = result.join('')

    // $exerciseData.text = json.map((w) => w.displayText).join(' ')
  }

  export async function upload() {
    generateFileName()
    console.log(
      'upload',
      fileBlob,
      $exerciseData.ssml,
      $exerciseData.tssWords,
      audioFileName
    )
    await uploadMedia()
    //patchExercise($exerciseData.id)
  }

  function generateFileName() {
    audioFileName = crypto.randomUUID() + '.mp3'
    $exerciseData.ttsUrl = audioFileName
  }

  async function uploadMedia() {
    const searchParams = new URLSearchParams()
    searchParams.set('uploadType', 'media')
    searchParams.set('name', 'exerciseAudio/' + audioFileName)
    searchParams.set('key', 'AIzaSyBgFT_wbOWOauPZpCWoBiRVGmgFdfHvr6o')

    let urlValue = new URL(
      'https://storage.googleapis.com/upload/storage/v1/b/shuoshuo/o'
    )
    urlValue.search = new URLSearchParams(searchParams).toString()

    await ky
      .post(urlValue, {
        body: fileBlob,
        headers: { 'Content-Type': 'audio/mp3' },
        retry: {
          limit: 3,
          statusCodes: [400, 429, 502],
        },
        timeout: 120000,
      })
      .then((data) => {
        console.log('postFile', data)
      })
  }

  function convertSymbols(w) {
    if (w === '&amp;') {
      return '&'
    }
    if (w === 'dash') {
      return '-'
    }
    if (w === 'sees') {
      return "C'S"
    }
    return w
  }

  function formatTimer(v) {
    return (v / 10000000).toFixed(1)
  }
</script>

<div>
  <!-- <div
    contenteditable="plaintext-only"
    class="editor"
    placeholder="Enter text here..."
    bind:innerText={$exerciseData.ssml}
  /> -->
  <textarea class="editor" bind:value={$exerciseData.ssml}></textarea>
</div>
<div>
  <span>State:{$ssmlState}</span><button
    on:click={generate}
    class="btn btn-primary s-btn-primary">GENERATE</button
  >
</div>

{#if $ssmlState === 'generated'}
  <div>
    <div
      style="word-break: break-all; margin: 2em; border: 2px solid darkgrey; padding: 1em;"
    >
      {#each words as w, i}<span
          class={w.boundaryType}
          class:h-new={timerValue < w.start}
          class:h-done={timerValue >= w.end}
          class:h-current={timerValue <= w.end && w.start <= timerValue}
          >{w.displayText}</span
        >{:else}No Data{/each}
    </div>
    <div />
  </div>
{/if}

<audio bind:this={audioTag} controls />

<style>
  .editor {
    word-break: break-all;
    min-height: 200px;
    min-width: 600px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    text-align: left;
    font-family: monospace;
    white-space: pre-wrap;
  }

  .WordBoundary {
    margin-right: 0.5em;
  }
  .PunctuationBoundary {
    margin-left: -0.5em;
    margin-right: 0.5em;
  }
</style>
