<script>
  import LeftMenu from 'components/LeftMenu.svelte'
  import TopMenu from 'components/TopMenu.svelte'
  import Footer from 'components/Footer.svelte'
  import CourseCardGroup from './CourseComponents/CourseCardGroup.svelte'
  import { leftMenuToggled } from '../../components/DataStore'
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="courses" />
    </div>
    <div id="layoutSidenav_content">
      <CourseCardGroup />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
