<script>
  import { _, init, getLocaleFromNavigator } from 'svelte-i18n'
  import { patchData } from '../../utils/ApiUtils.svelte'
  import { userDetails } from '../../components/DataStore'

  import { onMount } from 'svelte'

  let userProfileStatus = ''
  let language
  let errorMessage = ''

  userDetails.subscribe((x) => {
    language = x?.languagePreference
    if (language !== 'en-US' && language !== 'zh-TW') {
      language = 'en-US'
    }
    console.log(444, language)
  })

  onMount(async () => {})

  function changeLanguage(event) {
    console.log('changeLanguage', event)
    if (language && (language === 'en-US' || language === 'zh-TW')) {
      init({
        fallbackLocale: 'en',
        initialLocale: language,
      })
    } else {
      init({
        fallbackLocale: 'en',
        initialLocale: getLocaleFromNavigator(),
      })
    }
  }

  function saveLanguageSettings() {
    userProfileStatus = 'loading'

    if (language === 'auto') {
      language = getLocaleFromNavigator()
    }

    let updateProfileJson = {
      languagePreference: language,
    }

    patchData('api/user/language', updateProfileJson)
      .then((data) => {
        console.log('login successful', data)
        userProfileStatus = 'updated'
      })
      .catch((error) => {
        console.log('error login', error)
        userProfileStatus = 'error'
        errorMessage = 'Error saving data'
      })
  }
</script>

<div class="s-content-header">
  <div class="s-content-header-title">{$_('UI_LANGUAGE')}</div>
</div>
<select
  id="languageSelect"
  class="s-select"
  bind:value={language}
  on:change={changeLanguage}
>
  <option value="en-US">{$_('en-US')}</option>
  <option value="zh-TW">{$_('zh-TW')}</option>
</select>

{#if userProfileStatus === 'updated'}
  <div class="alert alert-success with-top-margin" role="alert">
    {$_('PROFILE_UPDATED')}
  </div>
{:else if userProfileStatus === 'loading'}
  <div class="alert alert-info with-top-margin" role="alert">
    {$_('PROCESSING')}
  </div>
{:else if userProfileStatus === 'error'}
  <div class="alert alert-info with-top-margin" role="alert">
    Error {errorMessage}
  </div>
{/if}

<div class="form-group s-form-group">
  <button
    id="buttonSaveProfile"
    class="btn btn-primary s-button-primary"
    style="width: 100%"
    type="button"
    on:click={saveLanguageSettings}>{$_('SAVE_SETTINGS')}</button
  >
</div>

<style>
  .s-select {
    width: 100%;
  }
</style>
