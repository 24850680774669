<script>
    import LeftMenu from "../components/LeftMenu.svelte";
    import TopMenu from "../components/TopMenu.svelte";
    import Footer from "../components/Footer.svelte";
    import IndexOverview from "./IndexPage/IndexOverview.svelte";
    import { userDetails } from "../components/DataStore";
    import { leftMenuToggled } from "../components/DataStore";
    import RequestQuoteView from "./RequestQuote/RequestQuoteView.svelte";
  
    export let activeTab
  </script>
  
  <div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
    <TopMenu />
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <LeftMenu activePage="request-quote" />
      </div>
      <div id="layoutSidenav_content">
          <RequestQuoteView
            organizationId={$userDetails?.organizationId}
            activeTab={activeTab}
          />
      </div>
    </div>
  </div>
  