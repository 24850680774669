<script>
  import { _ } from 'svelte-i18n'
  import Modal from 'sv-bootstrap-modal'
  import { onMount } from 'svelte'
  import SensayStudentTryOut from './SensayStudentTryOut.svelte'
  import StepClass from './StepClass.svelte'
  import StepExercise from './StepExercise.svelte'
  import StepFinal from './StepFinal.svelte'
  import StepLesson from './StepLesson.svelte'
  import StepShareLesson from './StepShareLesson.svelte'
  import { writable } from 'svelte/store'
  import { userContext } from '../../components/DataStore'
  import { updateContext } from 'utils/ApiCalls.svelte'
  import { userDetails, userConfig } from '../../components/DataStore'
  import StepShareStudentAccounts from './StepShareStudentAccounts.svelte'

  export let isOpen = false
  export let courseId
  export let courseName
  export let lessonId
  export let lessonName
  export let exerciseId
  export const exerciseKey = writable(null)

  let frePage = ''

  onMount(() => {
    userContext.subscribe((v) => {
      console.log(111, $userContext)
      frePage = v?.fre?.frePage ? v?.fre?.frePage : 'try'
      courseId = v?.fre?.courseId
      courseName = v?.fre?.courseName
      lessonId = v?.fre?.lessonId
      lessonName = v?.fre?.lessonName
      exerciseId = v?.fre?.exerciseId
    })
  })

  function onOpened() {
    console.log(999)
    //frePage = "try";
    //updateContext({ fre: {frePage: "try" }});
  }

  function closeModalStudentDemo() {
    mixpanel.track('Teacher FRE Close Modal Clicked', { Step: 'Student Demo' })
    isOpen = false
    updateContext({ fre: { freStatus: 'CLOSED' } })
  }
</script>

<Modal
  backdrop="true"
  ignoreBackdrop="true"
  keyboard="false"
  bind:open={isOpen}
  on:onOpened={onOpened}
  dialogClasses="modal-xl fre-modal-container"
>
  {#if frePage === 'try'}
    <div class="fre-header">
      <div class="exit">
        <button
          type="button"
          class="s-modal-close"
          on:click={closeModalStudentDemo}
        >
          &times;
        </button>
      </div>
      <!-- <div class="logo">
        <img
          class="sensay-logo"
          src="../assets/img/logo.svg"
          alt="Sensay Logo"
        />
      </div> -->
      <div class="instructions">
        {$_('FRE_DEMO_MESSAGE', {
          values: {
            name: $userDetails?.firstName,
          },
        })}
      </div>
    </div>
  {/if}
  <div class="modal-body fre-modal">
    {#if frePage === 'try'}
      <SensayStudentTryOut
        on:nextPage={(event) => {
          console.log(333, event)
          frePage = 'class'
          updateContext({ fre: { frePage: 'class' } })
        }}
        on:closeModal={() => {
          isOpen = !isOpen
        }}
      />
    {:else if frePage === 'class'}
      <StepClass
        on:nextPage={(event) => {
          console.log(444, event)
          frePage = 'lesson'
          courseId = event?.detail?.courseId
          courseName = event?.detail?.courseName
          updateContext({
            fre: {
              frePage: 'lesson',
              courseId: courseId,
              courseName: courseName,
            },
          })
        }}
        on:closeModal={() => {
          isOpen = !isOpen
        }}
      />
    {:else if frePage === 'lesson'}
      <StepLesson
        {courseId}
        on:nextPage={(event) => {
          console.log(555, event)
          frePage = 'exercise'
          lessonId = event?.detail?.lessonId
          lessonName = event?.detail?.lessonName
          updateContext({
            fre: {
              frePage: 'exercise',
              lessonId: lessonId,
              lessonName: lessonName,
            },
          })
        }}
        on:closeModal={() => {
          isOpen = !isOpen
        }}
      />
    {:else if frePage === 'exercise'}
      <StepExercise
        {courseId}
        {lessonId}
        on:nextPage={(event) => {
          console.log(666, event)
          frePage = 'share'
          exerciseId = event.detail.exerciseId
          updateContext({ fre: { frePage: 'share', exerciseId: exerciseId } })
        }}
        on:closeModal={() => {
          isOpen = !isOpen
        }}
      />
    {:else if frePage === 'share'}
      {#if $userDetails?.userRole === 'SURREAL' || $userConfig?.serverFlags?.studentAccount === true}
        <StepShareStudentAccounts
          {lessonId}
          on:nextPage={() => {
            frePage = 'final'
            updateContext({ fre: { frePage: 'final' } })
          }}
          on:closeModal={() => {
            isOpen = !isOpen
          }}
        />{:else}
        <StepShareLesson
          {lessonId}
          on:nextPage={() => {
            frePage = 'final'
            updateContext({ fre: { frePage: 'final' } })
          }}
          on:closeModal={() => {
            isOpen = !isOpen
          }}
        />{/if}
    {:else if frePage === 'final'}
      <StepFinal
        on:nextPage={() => {
          isOpen = false
          updateContext({ fre: { frePage: 'final', freStatus: 'DONE' } })
        }}
        on:closeModal={() => {
          isOpen = !isOpen
          updateContext({ fre: { frePage: 'final', freStatus: 'DONE' } })
        }}
      />
    {:else}
      {frePage} - N/A
    {/if}
    <!-- <SensayStudentTryOut bind:loaded={loaded}/> -->
    <!-- <StepClass /> -->
    <!-- <StepLesson /> -->
    <!-- <StepExercise /> -->
    <!-- <StepShareLesson /> -->
  </div>
</Modal>

<style>
  .fre-modal {
    min-height: 400px;
    max-width: 800px;
    padding: 0;
    display: flex;
    border-radius: 10px;
  }

  .fre-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      '. . . exit'
      'instructions instructions instructions instructions';
  }

  /* .logo {
    display: flex;
    align-items: center;
    justify-content: center;

    grid-area: logo;
  } */

  .instructions {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4em;

    color: #000000;

    grid-area: instructions;
  }

  .exit {
    grid-area: exit;
    height: 3em;
  }

  /* .sensay-logo {
    height: 4em;
  } */

  :global(.fre-modal-container) {
    max-width: 800px;
  }

  :global(.modal-backdrop.show) {
    opacity: 0.8;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    /* .sensay-logo {
      height: 5em;
    } */

    .instructions {
      font-size: 2em;
    }
  }
</style>
