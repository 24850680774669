<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { getData, postData } from "utils/ApiUtils.svelte";
  import {
    currentCourseId,
    currentLessonId,
    currentCourseName,
    currentLessonName,
    userDetails,
  } from "../../../components/DataStore";
  import { dynamicSort } from "utils/SortingUtils.svelte";
  import Loader from "../../../components/Loader.svelte";
  import { createEventDispatcher } from "svelte";

  export let courseId;
  export let courseInviteCode;

  let courseDetailStatus = "new";
  let courseData;

  const dispatch = createEventDispatcher();

  let dialogCopyToOtherTeacher;

  export function openDialog() {
    getCourses(courseId);
    dialogCopyToOtherTeacher.showModal();
  }

  function getCourses(id) {
    getData("api/courses-2/" + id)
      .then((data) => {
        console.log("courses successful", data);
        courseData = data;
        courseDetailStatus = "ok";
      })
      .catch((error) => {
        console.log("error courses", error);
        courseDetailStatus = "error";
      });

    getData(`api/courses/${$currentCourseId}/user-roles/check`)
      .then((data) => {
        console.log("courses successful", data);
      })
      .catch((error) => {
        console.log("error courses", error);
        courseDetailStatus = "error";
      });
  }

  function cancelCopyToOtherTeacher() {
    dialogCopyToOtherTeacher.close();
  }

  function formatCode(code){
    return code.substr(0,4)+'-'+code.substr(4,8)
  }
</script>

<dialog bind:this={dialogCopyToOtherTeacher} style="min-width:250px; max-width:40em;">
  {#if courseDetailStatus !== "ok"}
    <Loader />
  {:else}
    <h3>{$_("COURSE.INVITE_STUDENTS_TO_CLASS_BY_CODE")}</h3>
    <br />

    <div>{$_("COURSE.INVITE_STUDENTS_TO_CLASS_BY_CODE_INSTRUCTIONS")}</div>

    <div class="mt-4">{$_("COURSE.INVITE_STUDENTS_TO_CLASS_BY_CODE_INSTRUCTIONS_COPY_CODE")}</div>
    <div class="codeLink">{formatCode(courseData?.key)}</div>
  {/if}

  <div class="mt-4" >
    <button
      class="btn btn-danger s-button-danger ms-4"
      on:click={cancelCopyToOtherTeacher}>{$_("CLOSE")}</button
    >
  </div>
</dialog>

<style>
  .codeLink {
    padding: 1em;
    border: 5px;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    border-style: solid;
    border-radius: 4px;
    border-color: #1cc88a;
    text-align: center;
    max-width: 10em;
  }
</style>