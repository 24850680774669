<script>
  import { _ } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import { getData, postData, deleteData } from '../../utils/ApiUtils.svelte'
  import Loader from '../../components/Loader.svelte'
  import { userDetails } from '../../components/DataStore'
  import { createEventDispatcher } from 'svelte'
  import { dynamicSort } from 'utils/SortingUtils.svelte'

  export let organizationId

  const dispatch = createEventDispatcher()

  onMount(async () => {
    if (organizationId) {
      getOrganizationStudents(organizationId)
    }
  })

  let studentsData = []
  let studentsStatus = 'new'

  function refreshList() {
    dispatch('update', {})
    getOrganizationStudents(organizationId)
  }

  function getOrganizationStudents(organizationId) {
    getData(
      `api/organization/${organizationId}/student-invitations`,
      false,
      false
    )
      .then((data) => {
        console.log('organization student statistics successful', data)
        // Save data to sessionStorage
        studentsData = data.sort(dynamicSort('invitationEmail', 1))
        studentsStatus = 'ok'

        dispatch('load', { size: data.length })
      })
      .catch((error) => {
        console.log('error organization students', error)
      })
  }

  function removeStudent(
    studentAccountEnrollmentId,
    courseId,
    invitationEmail
  ) {
    if (
      confirm(
        $_('REMOVE_STUDENT_INVITATIONS_DIALOG', {
          values: {
            studentName: invitationEmail,
          },
        })
      )
    ) {
      deleteData(
        'api/courses/' +
          courseId +
          '/student-accounts/' +
          studentAccountEnrollmentId
      )
        .then((data) => {
          studentsStatus = 'loading'
          refreshList()
        })
        .catch((error) => {
          console.log('error courses', error)
        })
    } else {
      console.log('Thing was not saved to the database.')
    }
  }
</script>

<div class="card shadow ">
  <div class="card-body">
    {#if studentsStatus != 'ok'}
      <Loader />
    {:else if studentsData?.length > 0}
      <div
        class="table table mt-2"
        id="dataTable"
        role="grid"
        aria-describedby="dataTable_info"
      >
        <table class="table my-0 table-hover" id="dataTable">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{$_('STUDENT_EMAIL')}</th>
              <th scope="col">{$_('TEACHER')}</th>
              <th scope="col">{$_('COURSE_NAME')}</th>
              <th scope="col">{$_('ACTIONS')}</th>
            </tr>
          </thead>
          <tbody>
            {#if studentsData?.length > 0}
              {#each studentsData as subRow, i}
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td>{subRow.invitationEmail}</td>
                  <td>{subRow.teacherName} - {subRow.teacherLogin}</td>
                  <td
                    >{#if subRow?.teacherLogin === $userDetails?.login}
                      <a
                        href={`#courses-detail/${subRow.courseId}/student-accounts`}
                        >{subRow.courseName}</a
                      >
                    {:else}{subRow.courseName}{/if}
                  </td>
                  <td class="action-buttons"
                    ><button
                      class="btn btn-danger s-button-danger"
                      on:click={(x) => {
                        removeStudent(
                          subRow?.csaeId,
                          subRow?.courseId,
                          subRow?.invitationEmail
                        )
                      }}
                      >&#8998; {$_('DELETE')}</button
                    ></td
                  >
                </tr>
              {/each}
            {/if}
          </tbody>
          <tfoot>
            <tr />
          </tfoot>
        </table>
      </div>
    {:else}{$_('ORGANIZATION.NO_PENDING_INVITATIONS')}
    {/if}
  </div>
</div>

<style>
  .action-buttons{
    display: flex;
    flex-direction: row-reverse;
  }
</style>