<script>
  import { _ } from 'svelte-i18n'
  import Modal from 'sv-bootstrap-modal'
  import { logout } from '../../utils/ApiUtils.svelte'

  export let isOpen = false

  mixpanel.track_links('#logout', 'Logout Clicked', {
    'Button Location': 'Surreal Locked Modal',
  })

  function logoutAction() {
    logout()
  }
</script>

<Modal
  bind:open={isOpen}
  ignoreBackdrop={true}
  keyboard={false}
  dialogClasses="modal-xl"
>
  <div class="modal-header">
    <h5 class="modal-title">{$_('ORGANIZATION.ACCOUNT_LOCKED')}</h5>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger" role="alert">
      {$_('ORGANIZATION.ACCOUNT_LOCKED_MESSAGE')}
    </div>

    <div class="mt-4 mb-2" style="text-align: center;">
      <button
        id="logout"
        class="btn btn-primary s-button-primary"
        on:click={logoutAction}>{$_('LOGOUT')}</button
      >
    </div>
  </div>
</Modal>

<style>
</style>
