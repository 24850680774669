<script>
    import { _ } from "svelte-i18n";
  import { postData } from "../../utils/ApiUtils.svelte";
    import IconChangePassword from "./IconChangePassword.svelte";

export let studentAccountId;

let isDialog = false

let dialogDiv;

let textValue

let dataStatus

function showDialog(){
    isDialog = true;
    dialogDiv.showModal();
}


function save(){

    let payloadData = {
        newPasswordPlain: textValue,
    };

    dataStatus = "saving";

    postData(
        `api/teacher/student-accounts/${studentAccountId}/new-password`,
      payloadData,
    )
      .then((data) => {
        console.log("courses successful", data);

        dataStatus = "saved";
        closeDialog();
      })
      .catch((error) => {
        console.log("error courses", error);
        dataStatus = "error";
      });
    
}


function closeDialog() {
    isDialog = false;
    dialogDiv.close();
  }
</script>


<dialog class="dialogBox" bind:this={dialogDiv}>
    <header><h3>{$_("CHANGE_PASSWORD")}</h3></header>
  
   
    <div>
        <label for="exampleFormControlInput1" class="form-label">{$_("NEW_PASSWORD")}</label>
        <input
        class="form-control"
        type="text"
        style="width:20em;"
        bind:value={textValue}
      />
  
    <footer>
      <div class="mt-2">
        <button class="btn btn-primary s-button-primary" on:click={save}>{$_("SAVE")}</button>
        <button class="btn btn-danger s-button-danger" on:click={closeDialog}>{$_("CANCEL")}</button>
      </div>
    </footer>
  </dialog>

<button class="btn btn-danger s-button-danger ms-2" on:click={showDialog}><IconChangePassword ></IconChangePassword>{$_("CHANGE_PASSWORD")}</button>


<style>
    .dialogBox::backdrop {
      opacity: 1;
      /* background-color: rgb(25, 25, 170); */
      backdrop-filter: blur(2px);
    }
    .dialogBox {
      box-shadow: 2px 4px #cccccc;
      border-radius: 8px;
      border: none;
    }
    .dialogBox header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  
    .dialogBox footer {
      /* background-color: gray; */
      border: none;
      color: white;
      /* padding: 12px 32px; */
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
    }
  </style>
  