<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
    currentRecordingStudentName,
  } from './DataStore'

  let courseId
  let lessonId
  let exerciseId
  let recordingId

  let lessonName
  let exerciseName
  let recordingStudentName

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    currentPage.update((x) => e)
    router.goto('/' + e)
  }

  function navigateHome() {
    console.log('navigateHome')
    currentCourseId.update((x) => undefined)
    currentCourseName.update((x) => undefined)
    currentLessonId.update((x) => undefined)
    currentLessonName.update((x) => undefined)
    currentExerciseId.update((x) => undefined)
    currentExerciseName.update((x) => undefined)
    currentRecordingId.update((x) => undefined)
    currentRecordingStudentName.update((x) => undefined)
    clickChangePage('courses')
  }

  function navigateCourse() {
    console.log('navigateCourse')
    currentLessonId.update((x) => undefined)
    currentLessonName.update((x) => undefined)
    currentExerciseId.update((x) => undefined)
    currentExerciseName.update((x) => undefined)
    currentRecordingId.update((x) => undefined)
    currentRecordingStudentName.update((x) => undefined)
    clickChangePage('courses-detail/' + $currentCourseId)
  }

  function navigateLesson() {
    console.log('navigateLesson')
    currentExerciseId.update((x) => undefined)
    currentExerciseName.update((x) => undefined)
    currentRecordingId.update((x) => undefined)
    currentRecordingStudentName.update((x) => undefined)
    clickChangePage(
      'courses/' + $currentCourseId + '/lesson-detail/' + $currentLessonId
    )
  }

  function navigateExercise() {
    console.log('navigateExercise')
    currentRecordingId.update((x) => undefined)
    currentRecordingStudentName.update((x) => undefined)

    clickChangePage(
      'courses/' +
        $currentCourseId +
        '/lessons/' +
        $currentLessonId +
        '/exercise-detail/' +
        $currentExerciseId
    )
  }

  function navigateRecording() {
    console.log('navigateRecording')
  }
</script>

<ol class="breadcrumb">
  <li class="breadcrumb-item">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <span class="btn-link" on:click={navigateHome}
      ><span>{$_('COURSES')}</span></span
    >
  </li>

  {#if $currentCourseId}
    <li class="breadcrumb-item">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class="btn-link" on:click={navigateCourse}
        ><span class="hidden">{$currentCourseId}</span><span
          >{$currentCourseName}</span
        ></span
      >
    </li>
  {/if}

  {#if $currentLessonId}
    <li class="breadcrumb-item">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class="btn-link" on:click={navigateLesson}
        ><span class="hidden">{$currentLessonId}</span><span
          >{$currentLessonName}</span
        ></span
      >
    </li>
  {/if}

  {#if $currentExerciseId}
    <li class="breadcrumb-item">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class="btn-link" on:click={navigateExercise}
        ><span class="hidden">{$currentExerciseId}</span><span
          >{$currentExerciseName}</span
        ></span
      >
    </li>
  {/if}

  {#if $currentRecordingId}
    <li class="breadcrumb-item">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class="btn-link" on:click={navigateRecording}
        ><span class="hidden">{$currentRecordingId}</span><span
          >{$currentRecordingStudentName}</span
        ></span
      >
    </li>
  {/if}
</ol>

<style>
  li {
    cursor: pointer;
  }

  .hidden {
    display: none;
  }
</style>
