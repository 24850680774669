<script>
  import { _, locale } from "svelte-i18n";
  import { router } from "tinro";
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
    currentExerciseId,
    currentExerciseName,
    currentRecordingId,
    currentRecordingStudentName,
    userDetails,
    userConfig,
    isOpenSendFeedbackModal,
    isOpenGettingStartedModal,
    leftMenuToggled,
  } from "./DataStore";
  import Modal from "sv-bootstrap-modal";
  import IconHome from "./icons/IconHome.svelte";
  import IconStudents from "./icons/IconStudents.svelte";
  import IconRecordings from "./icons/IconRecordings.svelte";
  import IconSettings from "./icons/IconSettings.svelte";

  export let activePage;

  let isOpen = false;
  const dispatch = createEventDispatcher();

  function clickChangePage(e) {
    console.log("clickChangePage", e);

    currentCourseId.update((x) => undefined);
    currentCourseName.update((x) => undefined);
    currentLessonId.update((x) => undefined);
    currentLessonName.update((x) => undefined);
    currentExerciseId.update((x) => undefined);
    currentExerciseName.update((x) => undefined);
    currentRecordingId.update((x) => undefined);
    currentRecordingStudentName.update((x) => undefined);

    currentPage.update((x) => e);
    try {
      switch (e) {
        case "courses":
          mixpanel.track("Teacher Classes Button Clicked", {
            "Button Location": "Sidebar",
          });
          break;
        case "students":
          mixpanel.track("Teacher Students Button Clicked", {
            "Button Location": "Sidebar",
          });
          break;
        case "students-recording":
          mixpanel.track("Teacher All Recordings Button Clicked", {
            "Button Location": "Sidebar",
          });
          break;
        case "profile":
          mixpanel.track("Teacher Account Settings Button Clicked", {
            "Button Location": "Sidebar",
          });
          break;
        case "index":
          mixpanel.track("Teacher Dashboard Button Clicked", {
            "Button Location": "Sidebar",
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("error mixpanel");
    }
    hideSidebar();
    dispatch("changePage", e);
    router.goto("/" + e);
  }

  onMount(async () => {
    userDetails.subscribe((x) => prepareMarketLink());

    mixpanel.track_links(
      "#marketplace-sidebar",
      "Teacher Content Marketplace Button Clicked",
      { "Button Location": "Sidebar" },
    );

    mixpanel.track_links("#faq-sidebar", "Teacher FAQ Button Clicked", {
      "Button Location": "Sidebar",
    });

    // if ($userDetails?.currentSubscriptionLevel === 'TRIAL_ACTIVE') {
    //   mixpanel.track_links(
    //     '#upgrade-sidebar',
    //     'Teacher Upgrade to Sensay Pro Button Clicked',
    //     {
    //       'Button Location': 'Sidebar',
    //     }
    //   )
    // }
  });

  let marketPlaceLink = "";
  function prepareMarketLink() {
    marketPlaceLink =
      "https://www.oksensay.com/sensay-packs?group=A&userId=" +
      $userDetails?.id +
      "&userName=" +
      encodeURIComponent($userDetails?.login);
  }

  function openFeedback() {
    mixpanel.track("Teacher Send Feedback to Sensay Clicked");
    hideSidebar();
    $isOpenSendFeedbackModal = true;
  }

  function openGettingStarted() {
    mixpanel.track("Teacher Getting Started Clicked", {
      "Button Location": "Sidebar",
    });
    hideSidebar();
    $isOpenGettingStartedModal = true;
  }

  function hideSidebar() {
    if ($leftMenuToggled) {
      $leftMenuToggled = false;
    }
  }
</script>

<nav
  class="sb-sidenav accordion sb-sidenav-light s-sidebar"
  id="sidenavAccordion"
>
  <div class="sb-sidenav-menu">
    <div class="nav">
      <!-- Upgrade to Sensay Pro -->

      {#if $userDetails?.userRole === "SURREAL_TRIAL"}
        <div class="s-section-header" />
        <div class="s-section-item s-section-item-upgrade">
          <a
            href={`https://www.oksensay.com/surreal-upgrade/?email=${encodeURIComponent(
              $userDetails?.login,
            )}`}
            id="upgrade-sidebar"
            target="_blank"
            style="text-decoration: none;"
          >
            <!-- <span class="s-section-item-icon s-section-item-icon-upgrade" /> -->
            <span class="s-section-item-text s-section-item-text-upgrade">
              {$_("PAYWALL.UPGRADE_TO_SENSAY_PRO")}
            </span>
          </a>
        </div>
      {:else if $userDetails?.currentSubscriptionLevel === "TRIAL_ACTIVE"}
        <div class="s-section-header" />
        <!-- <div class="s-section-item s-section-item-upgrade">
          <a
            href={$locale === "zh-TW"
              ? `https://www.oksensay.com/checkout-tw/?email=${encodeURIComponent(
                  $userDetails?.login
                )}`
              : `https://www.oksensay.com/checkout/?email=${encodeURIComponent(
                  $userDetails?.login
                )}`}
            id="upgrade-sidebar"
            target="_blank"
            style="text-decoration: none;"
          >
            <span class="s-section-item-icon s-section-item-icon-upgrade" />
            <span class="s-section-item-text s-section-item-text-upgrade">
              {$_("PAYWALL.UPGRADE_TO_SENSAY_PRO")}
            </span>
          </a>
        </div> -->

        <div class="s-section-item s-section-item-upgrade">
          <a
            id="upgrade"
            class="alert-link mp-purchase-subscription"
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSd0H8-oEzd7_hp1uvBqHAeG2uQeUpyq9EbMW2fzi5WkSkHRLg/viewform"
          >
            <!-- <span class="s-section-item-icon s-section-item-icon-upgrade" /> -->
            <span class="s-section-item-text s-section-item-text-upgrade">
              {$_("PAYWALL.REQUEST_QUOTE")}
            </span>
          </a>
        </div>
      {/if}

      <!-- 
        Quick Access 
      -->
      <div class="s-section-header">
        <span class="s-section-header-text">{$_("QUICK_ACCESS")}</span>
      </div>
      <!-- Dashboard -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="s-section-item"
        class:s-active-page={activePage === "index"}
        on:click={() => clickChangePage("index")}
      >
        <span class="s-section-item-icon">
          <IconHome isActive={activePage === "index"}></IconHome>
        </span>
        <span class="s-section-item-text">
          {$_("DASHBOARD")}
        </span>
      </div>

      {#if $userConfig?.serverFlags?.adminOnlyView !== true}
        <!-- My Students -->
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          class="s-section-item"
          class:s-active-page={activePage === "students"}
          on:click={() => clickChangePage("students")}
        >
          <span class="s-section-item-icon">
            <IconStudents isActive={activePage === "students"}></IconStudents>
          </span>
          <span class="s-section-item-text">
            {$_("STUDENTS")}
          </span>
        </div>

        <!-- All Recordings -->
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          class="s-section-item"
          class:s-active-page={activePage === "students-recording"}
          on:click={() => clickChangePage("students-recording")}
        >
          <span class="s-section-item-icon">
            <IconRecordings isActive={activePage === "students-recording"}
            ></IconRecordings>
          </span>
          <span class="s-section-item-text">
            {$_("ALL_RECORDINGS")}
          </span>
        </div>
      {/if}

      <!-- Account -->
      <div class="s-section-header">
        <span class="s-section-header-text">{$_("ACCOUNT")}</span>
      </div>

      <!-- Account Settings -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="s-section-item"
        class:s-active-page={activePage === "profile"}
        on:click={() => clickChangePage("profile")}
      >
        <span class="s-section-item-icon">
          <IconSettings isActive={activePage === "profile"}></IconSettings>
        </span>
        <span class="s-section-item-text">
          {$_("PROFILE")}
        </span>
      </div>

      {#if $userDetails?.currentSubscriptionLevel === "SURREAL_ADMIN" || $userDetails?.currentSubscriptionLevel === "SCHOOL_ADMIN" || $userDetails?.currentSubscriptionLevel === "TRIAL_SCHOOL_ADMIN"}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          class="s-section-item"
          class:s-active-page={activePage === "organization-management"}
          on:click={() => clickChangePage("organization-management")}
        >
          <span class="s-section-item-icon s-section-item-icon-courses" />
          <span class="s-section-item-text">
            {$_("ORGANIZATION_MANAGEMENT")}
          </span>
        </div>
      {/if}

      {#if $userDetails?.dsns || $userDetails?.spRefreshToken || $userConfig?.serverFlags?.adminStudentRoster === true || $userConfig?.serverFlags?.studentManagement === "RW" || $userConfig?.serverFlags?.studentManagement === "R"}
        <!-- Student Roster -->
        <div
          class="s-section-item"
          class:s-active-page={activePage === "student-roster"}
          on:click={() => clickChangePage("student-roster")}
        >
          <span class="s-section-item-icon s-section-item-icon-students" />
          <span class="s-section-item-text">
            {$_("STUDENT_ROSTER.LEFT_MENU")}
          </span>
        </div>
      {/if}

      {#if $userDetails?.dsns || $userDetails?.spRefreshToken || $userConfig?.serverFlags?.adminStudentRoster === true || $userConfig?.serverFlags?.studentManagement === "RW" || $userConfig?.serverFlags?.studentManagement === "R"}
        <!-- Student In Org -->
        {#if $userDetails?.organizationId}
          <div
            class="s-section-item"
            class:s-active-page={activePage === "students-in-org"}
            on:click={() => clickChangePage("students-in-org")}
          >
            <span class="s-section-item-icon s-section-item-icon-students" />
            <span class="s-section-item-text">
              {$_("ORGANIZATION_MANAGEMENT.STUDENTS_IN_ORG")}
            </span>
          </div>
        {/if}
      {/if}

      <!-- 
        Resources 
      -->
      <div class="s-section-header">
        <span class="s-section-header-text">{$_("RESOURCES")}</span>
      </div>
      {#if $userConfig?.serverFlags?.adminOnlyView !== true}
        <!-- Lesson Packs -->
        <div class="s-section-item">
          <a
            href={marketPlaceLink}
            on:click={hideSidebar}
            id="marketplace-sidebar"
            target="_blank"
            style="text-decoration: none;"
          >
            <span class="s-section-item-icon s-section-item-icon-marketplace" />
            <span class="s-section-item-text">
              {$_("COURSE_MARKETPLACE")}
            </span>
          </a>
        </div>
      {/if}

      <!-- Getting Started -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="s-section-item" on:click={() => openGettingStarted()}>
        <span class="s-section-item-icon s-section-item-icon-marketplace" />
        <span class="s-section-item-text">
          {$_("GETTING_STARTED")}
        </span>
      </div>

      <!-- 
        Feedback 
      -->
      <div class="s-section-header">
        <span class="s-section-header-text">{$_("SUPPORT")}</span>
      </div>
      <!-- Help Desk -->
      <div class="s-section-item">
        <a
          href={$locale === "zh-TW"
            ? "https://help.oksensay.com/language/zh/"
            : "https://help.oksensay.com/"}
          on:click={hideSidebar}
          id="faq-sidebar"
          target="_blank"
          style="text-decoration: none;"
        >
          <span class="s-section-item-icon s-section-item-icon-faq" />
          <span class="s-section-item-text">
            {$_("FAQ")}
          </span>
        </a>
      </div>

      <!-- <div
        class="s-section-item"
        class:s-active-page={activePage === "request-quote"}
        on:click={() => clickChangePage("request-quote")}
      >
        <span class="s-section-item-icon s-section-item-icon-faq" />
        <span class="s-section-item-text">
          {$_("REQUEST_QUOTE")}
        </span>
      </div> -->

      <!-- Contact Sensay -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="s-section-item" on:click={() => openFeedback()}>
        <span class="s-section-item-icon s-section-item-icon-feedback" />
        <span class="s-section-item-text">
          {#if $userDetails?.userRole !== "SURREAL"}
            {$_("CONTACT_SENSAY")}
          {:else}
            {$_("CUSTOMER_SUPPORT")}
          {/if}
        </span>
      </div>
    </div>
  </div>
</nav>

<Modal bind:open={isOpen}>
  <div class="modal-header">
    <h5 class="modal-title">Modal title</h5>
    <button type="button" class="close" on:click={() => (isOpen = false)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Woohoo, you're reading this text in a modal!</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary">Close</button>
    <button type="button" class="btn btn-primary">Save changes</button>
  </div>
</Modal>

<style>
  .s-sidebar {
    background: white;
    mix-blend-mode: normal;
    border-right: 0.5px solid #eff0f1;
    z-index: 999 !important;
    overflow-y: auto;
    height: 90vh;
    top: 10vh;
    position: sticky;
  }

  .s-section-header {
    margin: 3vh 0 0 3vh;
  }

  .s-section-header-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 1rem !important;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    padding-left: 2vh;

    color: #575f59;
  }

  .s-section-item {
    padding: 0.5vh 1.2vh;
    margin-left: 4vh;
    margin-right: 4vh;

    cursor: pointer;
  }

  .s-section-item-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;

    color: #575f59;
    opacity: 0.9;
  }

  .s-section-item-icon {
    display: inline-flex;
    align-self: center;
    top: 0.125rem;
    position: relative;

    width: 1rem;
    height: 1rem;

    background-repeat: no-repeat no-repeat;
    background-size: auto 100%;
  }

  .s-section-item-upgrade {
    display: flex;
    justify-content: center;

    background: #f9a000;
    border: 0.25px solid #e69300;
    border-radius: 8px;
  }

  .s-section-item-text-upgrade {
    font-weight: 700;
    font-size: 1rem;
    color: #ffffff;
    text-shadow: 0px 0px 4px rgba(249, 160, 0, 0.8);
  }

  .s-active-page {
    background-color: #e5f5f4;
    border-radius: 8px;
  }

  .s-active-page > .s-section-item-text {
    font-weight: 600;
    color: #00a094;
    background-color: #e5f5f4;
  }

  /* .s-section-item-icon-upgrade {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none' viewBox='0 0 21 20'%3E%3Cpath fill='%23fff' d='m10.5 5.941.808 1.909.392.925 1 .083 2.058.175-1.566 1.359-.759.658.225.983.467 2.008-1.767-1.066-.858-.534-.858.517-1.767 1.067.466-2.008.226-.984-.759-.658-1.566-1.358L8.3 8.841l1-.084.391-.925.809-1.892Zm0-4.274L8.158 7.19l-5.991.51 4.55 3.942L5.35 17.5l5.15-3.108 5.15 3.108-1.367-5.858 4.55-3.942-5.991-.509L10.5 1.667Z'/%3E%3C/svg%3E%0A");
  } */

  .s-section-item-icon-index {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23575F59' d='M15.833 7.75V3.333h-2.5V5.5L10 2.5 1.667 10h2.5v6.667h5v-5h1.666v5h5V10h2.5l-2.5-2.25ZM14.166 15H12.5v-5h-5v5H5.833V8.492L10 4.742l4.166 3.75V15Z'/%3E%3C/svg%3E%0A");
  }

  .s-active-page > .s-section-item-icon-index {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%2300A094' d='M15.833 7.75V3.333h-2.5V5.5L10 2.5 1.667 10h2.5v6.667h5v-5h1.666v5h5V10h2.5l-2.5-2.25ZM14.166 15H12.5v-5h-5v5H5.833V8.492L10 4.742l4.166 3.75V15Z'/%3E%3C/svg%3E%0A");
  }

  .s-section-item-icon-courses {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23575F59' d='M16.666 5.833h-3.333V4.167L11.666 2.5H8.333L6.666 4.167v1.666H3.333c-.917 0-1.667.75-1.667 1.667v4.167c0 .625.334 1.15.834 1.441v2.725A1.66 1.66 0 0 0 4.167 17.5h11.666a1.66 1.66 0 0 0 1.667-1.667V13.1a1.68 1.68 0 0 0 .833-1.433V7.5c0-.917-.75-1.667-1.666-1.667ZM8.333 4.167h3.333v1.666H8.333V4.167Zm-5 3.333h13.334v4.167H12.5v-2.5h-5v2.5H3.333V7.5Zm7.5 5H9.166v-1.667h1.667V12.5Zm5 3.333H4.167v-2.5H7.5v.834h5v-.834h3.333v2.5Z'/%3E%3C/svg%3E%0A");
  }

  .s-active-page > .s-section-item-icon-courses {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%2300A094' d='M16.666 5.833h-3.333V4.167L11.666 2.5H8.333L6.666 4.167v1.666H3.333c-.917 0-1.667.75-1.667 1.667v4.167c0 .625.334 1.15.834 1.441v2.725A1.66 1.66 0 0 0 4.167 17.5h11.666a1.66 1.66 0 0 0 1.667-1.667V13.1a1.68 1.68 0 0 0 .833-1.433V7.5c0-.917-.75-1.667-1.666-1.667ZM8.333 4.167h3.333v1.666H8.333V4.167Zm-5 3.333h13.334v4.167H12.5v-2.5h-5v2.5H3.333V7.5Zm7.5 5H9.166v-1.667h1.667V12.5Zm5 3.333H4.167v-2.5H7.5v.834h5v-.834h3.333v2.5Z'/%3E%3C/svg%3E%0A");
  }

  .s-section-item-icon-students {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23575F59' d='M7.5 11.458c-1.95 0-5.833.975-5.833 2.917v1.458h11.666v-1.458c0-1.942-3.883-2.917-5.833-2.917Zm-3.884 2.708c.7-.483 2.392-1.041 3.884-1.041s3.183.558 3.883 1.041H3.617ZM7.5 10a2.92 2.92 0 0 0 2.916-2.917A2.92 2.92 0 0 0 7.5 4.166a2.92 2.92 0 0 0-2.917 2.917A2.92 2.92 0 0 0 7.5 10Zm0-4.167c.691 0 1.25.558 1.25 1.25s-.559 1.25-1.25 1.25c-.692 0-1.25-.558-1.25-1.25s.558-1.25 1.25-1.25Zm5.867 5.675c.966.7 1.633 1.634 1.633 2.867v1.458h3.333v-1.458c0-1.684-2.916-2.642-4.966-2.867ZM12.5 10a2.92 2.92 0 0 0 2.916-2.917A2.92 2.92 0 0 0 12.5 4.166c-.45 0-.867.109-1.25.292.525.742.833 1.65.833 2.625 0 .975-.308 1.883-.833 2.625.383.184.8.292 1.25.292Z'/%3E%3C/svg%3E%0A");
  }

  .s-active-page > .s-section-item-icon-students {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%2300A094' d='M7.5 11.458c-1.95 0-5.833.975-5.833 2.917v1.458h11.666v-1.458c0-1.942-3.883-2.917-5.833-2.917Zm-3.884 2.708c.7-.483 2.392-1.041 3.884-1.041s3.183.558 3.883 1.041H3.617ZM7.5 10a2.92 2.92 0 0 0 2.916-2.917A2.92 2.92 0 0 0 7.5 4.166a2.92 2.92 0 0 0-2.917 2.917A2.92 2.92 0 0 0 7.5 10Zm0-4.167c.691 0 1.25.558 1.25 1.25s-.559 1.25-1.25 1.25c-.692 0-1.25-.558-1.25-1.25s.558-1.25 1.25-1.25Zm5.867 5.675c.966.7 1.633 1.634 1.633 2.867v1.458h3.333v-1.458c0-1.684-2.916-2.642-4.966-2.867ZM12.5 10a2.92 2.92 0 0 0 2.916-2.917A2.92 2.92 0 0 0 12.5 4.166c-.45 0-.867.109-1.25.292.525.742.833 1.65.833 2.625 0 .975-.308 1.883-.833 2.625.383.184.8.292 1.25.292Z'/%3E%3C/svg%3E%0A");
  }

  .s-section-item-icon-recordings {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23575F59' d='M10 12.5a2.49 2.49 0 0 0 2.492-2.5l.008-5c0-1.383-1.117-2.5-2.5-2.5A2.497 2.497 0 0 0 7.5 5v5c0 1.383 1.117 2.5 2.5 2.5ZM9 4.917c0-.55.45-1 1-1s1 .45 1 1l-.008 5.166c0 .55-.442 1-.992 1s-1-.45-1-1V4.917ZM14.416 10c0 2.5-2.116 4.25-4.416 4.25-2.3 0-4.417-1.75-4.417-4.25H4.166c0 2.842 2.267 5.192 5 5.6v2.733h1.667V15.6c2.733-.4 5-2.75 5-5.6h-1.417Z'/%3E%3C/svg%3E%0A");
  }

  .s-active-page > .s-section-item-icon-recordings {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%2300A094' d='M10 12.5a2.49 2.49 0 0 0 2.492-2.5l.008-5c0-1.383-1.117-2.5-2.5-2.5A2.497 2.497 0 0 0 7.5 5v5c0 1.383 1.117 2.5 2.5 2.5ZM9 4.917c0-.55.45-1 1-1s1 .45 1 1l-.008 5.166c0 .55-.442 1-.992 1s-1-.45-1-1V4.917ZM14.416 10c0 2.5-2.116 4.25-4.416 4.25-2.3 0-4.417-1.75-4.417-4.25H4.166c0 2.842 2.267 5.192 5 5.6v2.733h1.667V15.6c2.733-.4 5-2.75 5-5.6h-1.417Z'/%3E%3C/svg%3E%0A");
  }

  .s-section-item-icon-faq {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23575F59' d='M10.008 13.333a.825.825 0 0 1-.591-.242.792.792 0 0 1-.242-.591.822.822 0 0 1 .817-.834h.016c.234 0 .426.084.592.242.15.159.234.359.234.584a.839.839 0 0 1-.242.591.782.782 0 0 1-.584.25Zm-.733-3.05c0-.375.083-.7.242-.966.158-.276.442-.584.833-.934.234-.208.4-.391.508-.55a.913.913 0 0 0 .159-.533.84.84 0 0 0-.267-.617c-.175-.167-.416-.25-.708-.25-.309 0-.617.083-.8.25-.175.167-.334.375-.334.817H7.5c0-.842.383-1.442.809-1.842A2.507 2.507 0 0 1 10 5c.492 0 .925.1 1.309.292.383.191.658.458.875.8.216.341.316.716.316 1.125 0 .408-.083.75-.258 1.041-.175.292-.4.592-.742.909-.267.25-.441.466-.541.641-.1.175-.15.409-.15.675v.35H9.267v-.55h.008ZM15 8.5c0-3.025-2.208-5.167-5-5.167S5 5.475 5 8.5c0 1.95 1.625 4.533 5 7.616 3.375-3.083 5-5.666 5-7.616Zm-5-6.833c3.5 0 6.667 2.683 6.667 6.833 0 2.767-2.225 6.041-6.667 9.833-4.44-3.791-6.667-7.066-6.667-9.833C3.333 4.35 6.5 1.667 10 1.667Z'/%3E%3C/svg%3E%0A");
  }

  .s-section-item-icon-marketplace {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23575F59' d='M17.5 4.167c-.925-.292-1.941-.417-2.916-.417-1.625 0-3.375.333-4.584 1.25-1.208-.917-2.958-1.25-4.583-1.25S2.042 4.083.833 5v12.208c0 .209.209.417.417.417.083 0 .125-.042.208-.042 1.125-.541 2.75-.916 3.959-.916 1.625 0 3.375.333 4.583 1.25 1.125-.709 3.167-1.25 4.584-1.25 1.375 0 2.791.25 3.958.875a.38.38 0 0 0 .208.041.448.448 0 0 0 .417-.416V5c-.5-.375-1.042-.625-1.667-.833Zm0 11.25c-.916-.292-1.916-.417-2.916-.417-1.417 0-3.459.542-4.584 1.25V6.667c1.125-.709 3.167-1.25 4.584-1.25 1 0 2 .125 2.916.416v9.584Z'/%3E%3Cpath fill='%23575F59' d='M14.584 8.75c.733 0 1.441.075 2.083.217V7.7c-.659-.125-1.367-.2-2.084-.2-1.416 0-2.7.242-3.75.692v1.383c.942-.533 2.25-.825 3.75-.825Zm-3.75 1.659v1.383c.941-.534 2.25-.825 3.75-.825a9.79 9.79 0 0 1 2.083.216V9.918c-.659-.125-1.367-.2-2.084-.2-1.416 0-2.7.25-3.75.691Zm3.75 1.533c-1.417 0-2.7.242-3.75.692v1.383c.941-.533 2.25-.825 3.75-.825.733 0 1.441.075 2.083.217v-1.267c-.659-.133-1.367-.2-2.084-.2Z'/%3E%3C/svg%3E%0A");
  }

  .s-section-item-icon-settings {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23575F59' d='M16.192 10.816c.033-.266.058-.533.058-.816 0-.284-.025-.55-.058-.817l1.758-1.375a.42.42 0 0 0 .1-.533L16.383 4.39a.416.416 0 0 0-.508-.183L13.8 5.04a6.089 6.089 0 0 0-1.409-.816l-.316-2.208a.406.406 0 0 0-.409-.35H8.333a.406.406 0 0 0-.408.35l-.317 2.208a6.402 6.402 0 0 0-1.408.817l-2.075-.834a.472.472 0 0 0-.15-.025.413.413 0 0 0-.358.208L1.95 7.275a.41.41 0 0 0 .1.533l1.758 1.375A6.61 6.61 0 0 0 3.75 10c0 .275.025.55.058.816L2.05 12.191a.42.42 0 0 0-.1.534l1.667 2.883a.416.416 0 0 0 .508.183l2.075-.833c.433.333.9.608 1.408.817l.317 2.208c.025.2.2.35.408.35h3.333c.209 0 .384-.15.409-.35l.317-2.208a6.401 6.401 0 0 0 1.408-.817l2.075.833c.05.017.1.025.15.025a.413.413 0 0 0 .358-.208l1.667-2.883a.42.42 0 0 0-.1-.534l-1.758-1.375Zm-1.65-1.425c.033.259.041.434.041.609s-.017.358-.042.608l-.116.942.741.583.9.7-.583 1.008-1.058-.425-.867-.35-.75.567a4.89 4.89 0 0 1-1.041.609l-.884.358-.133.941-.167 1.125H9.417l-.159-1.125-.133-.941-.883-.358a4.729 4.729 0 0 1-1.025-.592l-.759-.584-.883.359-1.058.425-.584-1.008.9-.7.742-.584-.117-.941A6.703 6.703 0 0 1 5.417 10c0-.167.016-.358.041-.608l.117-.942-.742-.583-.9-.7.584-1.009 1.058.425.867.35.75-.566a4.89 4.89 0 0 1 1.041-.609l.884-.358.133-.942.167-1.125h1.158l.158 1.125.133.942.884.358c.358.15.691.342 1.025.592l.758.583.883-.358 1.059-.425.583 1.008-.892.708-.741.584.116.942ZM10 6.668a3.332 3.332 0 1 0 0 6.666 3.332 3.332 0 1 0 0-6.667Zm0 5c-.917 0-1.667-.75-1.667-1.667S9.083 8.333 10 8.333c.916 0 1.666.75 1.666 1.667 0 .916-.75 1.666-1.666 1.666Z'/%3E%3C/svg%3E%0A");
  }

  .s-active-page > .s-section-item-icon-settings {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%2300A094' d='M16.192 10.816c.033-.266.058-.533.058-.816 0-.284-.025-.55-.058-.817l1.758-1.375a.42.42 0 0 0 .1-.533L16.383 4.39a.416.416 0 0 0-.508-.183L13.8 5.04a6.089 6.089 0 0 0-1.409-.816l-.316-2.208a.406.406 0 0 0-.409-.35H8.333a.406.406 0 0 0-.408.35l-.317 2.208a6.402 6.402 0 0 0-1.408.817l-2.075-.834a.472.472 0 0 0-.15-.025.413.413 0 0 0-.358.208L1.95 7.275a.41.41 0 0 0 .1.533l1.758 1.375A6.61 6.61 0 0 0 3.75 10c0 .275.025.55.058.816L2.05 12.191a.42.42 0 0 0-.1.534l1.667 2.883a.416.416 0 0 0 .508.183l2.075-.833c.433.333.9.608 1.408.817l.317 2.208c.025.2.2.35.408.35h3.333c.209 0 .384-.15.409-.35l.317-2.208a6.401 6.401 0 0 0 1.408-.817l2.075.833c.05.017.1.025.15.025a.413.413 0 0 0 .358-.208l1.667-2.883a.42.42 0 0 0-.1-.534l-1.758-1.375Zm-1.65-1.425c.033.259.041.434.041.609s-.017.358-.042.608l-.116.942.741.583.9.7-.583 1.008-1.058-.425-.867-.35-.75.567a4.89 4.89 0 0 1-1.041.609l-.884.358-.133.941-.167 1.125H9.417l-.159-1.125-.133-.941-.883-.358a4.729 4.729 0 0 1-1.025-.592l-.759-.584-.883.359-1.058.425-.584-1.008.9-.7.742-.584-.117-.941A6.703 6.703 0 0 1 5.417 10c0-.167.016-.358.041-.608l.117-.942-.742-.583-.9-.7.584-1.009 1.058.425.867.35.75-.566a4.89 4.89 0 0 1 1.041-.609l.884-.358.133-.942.167-1.125h1.158l.158 1.125.133.942.884.358c.358.15.691.342 1.025.592l.758.583.883-.358 1.059-.425.583 1.008-.892.708-.741.584.116.942ZM10 6.668a3.332 3.332 0 1 0 0 6.666 3.332 3.332 0 1 0 0-6.667Zm0 5c-.917 0-1.667-.75-1.667-1.667S9.083 8.333 10 8.333c.916 0 1.666.75 1.666 1.667 0 .916-.75 1.666-1.666 1.666Z'/%3E%3C/svg%3E%0A");
  }

  .s-section-item-icon-feedback {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath fill='%23575F59' d='M18.333 5c0-.917-.75-1.667-1.666-1.667H3.332c-.917 0-1.667.75-1.667 1.667v10c0 .917.75 1.667 1.667 1.667h13.334c.916 0 1.666-.75 1.666-1.667V5Zm-1.666 0L10 9.167 3.333 5h13.334Zm0 10H3.332V6.667L10 10.834l6.666-4.167V15Z'/%3E%3C/svg%3E%0A");
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
</style>
