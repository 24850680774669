<script>
  import LeftMenu from '../../components/LeftMenu.svelte'
  import TopMenu from '../../components/TopMenu.svelte'
  import Footer from '../../components/Footer.svelte'
  import RecordingDetailView from './RecordingComponent/RecordingDetailView.svelte'

  import {
    currentRecordingId,
    leftMenuToggled,
  } from '../../components/DataStore'
  export let recordingId = undefined

  if (recordingId) {
    $currentRecordingId = recordingId
    console.log('$currentRecordingId', $currentRecordingId)
  }
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="students-recordings" />
    </div>
    <div id="layoutSidenav_content">
      <RecordingDetailView />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
