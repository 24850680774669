<script>
    export let isActive = false;
</script>

<svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    class:is-active={isActive}
>
    <defs>
        <style>
            .cls-1 {
                fill: #414141;
                stroke-width: 2;
                stroke: #414141;
            }

            .is-active .cls-1 {
                fill: #00a094;
                stroke-width: 4;
                stroke: #00a094;
            }
        </style>
    </defs>
    <path
        stroke="currentColor"
        fill="none"
        class="cls-1"
        d="M169.15,85.29L103.64,29.55c-2.12-1.8-5.2-1.8-7.33,0L30.59,85.53c-1.15,.98-1.85,2.34-1.97,3.85-.12,1.5,.35,2.97,1.33,4.11,.94,1.11,2.35,1.82,3.86,1.94,.59,.05,1.18,0,1.74-.12v66.97c0,3.12,2.54,5.65,5.65,5.65h117.1c3.12,0,5.65-2.54,5.65-5.65V95.04c.64,.18,1.31,.25,2,.19,1.5-.12,2.87-.82,3.85-1.97,2.01-2.38,1.73-5.95-.65-7.97Zm-55.54,29.06v42.03h-27.72v-42.03h27.72Zm5.66-11.3h-39.03c-3.12,0-5.65,2.54-5.65,5.65v47.93h-27.72V86.52l53.12-45.25,52.66,44.8v70.54h-27.72v-47.93c0-3.12-2.54-5.65-5.65-5.65Z"
    />
</svg>
