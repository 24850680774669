<script>
    export let isActive = false;
</script>

<svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    class:is-active={isActive}
>
    <defs>
        <style>
            .cls-1 {
                fill: #414141;
                stroke-width: 2;
                stroke: #414141;
            }
            .is-active .cls-1 {
                fill: #00a094;
                stroke-width: 4;
                stroke: #00a094;
            }
        </style>
    </defs>
    <path
        stroke="currentColor"
        fill="none"
        class="cls-1"
        d="M101,65.72c-17.25,0-31.29,14.03-31.29,31.28s14.03,31.28,31.29,31.28,31.29-14.03,31.29-31.28-14.03-31.28-31.29-31.28Zm0,51.78c-11.3,0-20.49-9.19-20.49-20.49s9.19-20.49,20.49-20.49,20.49,9.19,20.49,20.49-9.19,20.49-20.49,20.49Z"
    />
    <path
        stroke="currentColor"
        fill="none"
        class="cls-1"
        d="M166.76,111.06l-9.49-6.84c.31-2.41,.47-4.83,.47-7.21s-.16-4.8-.47-7.21l9.5-6.85c5.37-3.9,6.92-11.16,3.6-16.86l-7.89-13.7c-3.33-5.72-10.39-8-16.44-5.29l-10.65,4.81c-3.84-2.94-8.04-5.37-12.5-7.23l-1.18-11.65c-.69-6.59-6.19-11.55-12.81-11.55h-15.79c-6.62,0-12.12,4.97-12.81,11.57l-1.18,11.63c-4.45,1.86-8.65,4.29-12.5,7.23l-10.67-4.81c-6.04-2.71-13.1-.42-16.44,5.32l-7.86,13.65c-3.33,5.73-1.78,12.99,3.61,16.91l9.48,6.83c-.31,2.41-.47,4.83-.47,7.21s.16,4.8,.47,7.21l-9.5,6.85c-5.37,3.9-6.92,11.16-3.6,16.86l7.89,13.7c3.33,5.72,10.4,8,16.44,5.29l10.65-4.81c3.85,2.94,8.04,5.37,12.5,7.23l1.18,11.65c.69,6.59,6.19,11.56,12.81,11.56h15.79c6.62,0,12.12-4.97,12.81-11.57l1.18-11.63c4.45-1.86,8.65-4.29,12.49-7.23l10.67,4.81c6.04,2.71,13.1,.42,16.44-5.32l7.87-13.66c3.33-5.72,1.79-12.99-3.59-16.9Zm-16.29,26.02l-13.69-6.18c-1.91-.86-4.17-.53-5.75,.84-4.42,3.83-9.45,6.74-14.95,8.65-1.97,.69-3.39,2.47-3.6,4.55l-1.52,14.92c-.11,1.07-1,1.89-2.07,1.89h-15.79c-1.07,0-1.96-.81-2.07-1.87l-1.52-14.94c-.21-2.08-1.63-3.87-3.6-4.55-5.5-1.91-10.53-4.82-14.95-8.65-1-.86-2.26-1.31-3.54-1.31-.75,0-1.51,.16-2.21,.48l-13.67,6.17c-.99,.44-2.14,.07-2.68-.85l-7.89-13.7c-.53-.92-.28-2.08,.59-2.71l12.18-8.78c1.7-1.22,2.54-3.34,2.15-5.39-.55-2.86-.83-5.77-.83-8.63s.28-5.77,.83-8.64c.39-2.05-.45-4.17-2.15-5.39l-12.16-8.77c-.88-.64-1.14-1.82-.59-2.75l7.86-13.65c.55-.94,1.7-1.32,2.67-.89l13.7,6.18c1.91,.86,4.17,.53,5.75-.84,4.42-3.83,9.45-6.74,14.94-8.65,1.98-.68,3.39-2.47,3.6-4.55l1.51-14.92c.11-1.08,1-1.89,2.08-1.89h15.79c1.07,0,1.96,.8,2.07,1.87l1.52,14.94c.21,2.08,1.63,3.87,3.6,4.55,5.5,1.91,10.53,4.82,14.95,8.65,1.58,1.37,3.84,1.7,5.75,.84l13.68-6.17c.98-.44,2.14-.07,2.67,.85l7.89,13.7c.53,.92,.28,2.09-.58,2.71l-12.18,8.78c-1.69,1.22-2.54,3.34-2.15,5.39,.55,2.86,.83,5.77,.83,8.63s-.28,5.77-.83,8.64c-.39,2.05,.45,4.17,2.15,5.39l12.16,8.77c.88,.64,1.13,1.82,.59,2.75l-7.86,13.65c-.55,.94-1.7,1.32-2.68,.89h0Z"
    />
</svg>
