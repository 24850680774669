<script>
  import LeftMenu from "../components/LeftMenu.svelte";
  import TopMenu from "../components/TopMenu.svelte";
  import Footer from "../components/Footer.svelte";
  import StudentAccountDetailPageOverview from "./StudentAccountDetail/StudentAccountDetailPageOverview.svelte";
  import { leftMenuToggled } from "../components/DataStore";

  export let studentAccountId;

  export let activeTab;
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="students" />
    </div>
    <div id="layoutSidenav_content">
      <StudentAccountDetailPageOverview {activeTab} {studentAccountId} />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
