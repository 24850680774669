<script>
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { getData, postData } from "utils/ApiUtils.svelte";
    import { userDetails } from "../../../components/DataStore";
    import Loader from "../../../components/Loader.svelte";
    import { createEventDispatcher } from "svelte";

    export let studentRosterId;
    const dispatch = createEventDispatcher();

    let dialog;
    let status = "";

    async function initialize() {
        status = "loading";
        status = "ok";
        phase = "NEW";

        file = undefined;
        sheets = [];
        dataOut = [];
        columns = [];

        okCount = 0;
        errorCount = 0;

        selectedSheet = undefined
    }

    export function openDialog() {
        initialize();
        dialog.showModal();
    }

    let file;

    let sheets = [];
    let selectedSheet;
    let selectedExerciseType;

    let updateNames = false;

    let sheetData;

    let dataOut = [];

    let columns = [];

    let columnMapping = {
        login: null,
    };

    let state = "NEW";
    let phase = "NEW";

    let ExcelToJSON;

    onMount(() => {
        ExcelToJSON = function () {
            this.parseExcel = function (file) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    var data = e.target.result;
                    var workbook = XLSX.read(data, {
                        type: "binary",
                    });

                    sheets = workbook.SheetNames;

                    sheetData = workbook;
                    state = "OK";
                    phase = "NEW";

                    if (sheets?.length === 1) {
                        selectedSheet = sheets[0];

                        parse();
                    }

                    // workbook.SheetNames.forEach(function (sheetName) {
                    //     // Here is your object
                    //     var XL_row_object = XLSX.utils.sheet_to_row_object_array(
                    //         workbook.Sheets[sheetName],
                    //     );
                    //     var json_object = JSON.stringify(XL_row_object);
                    //     console.log(json_object);
                    // });
                };

                reader.onerror = function (ex) {
                    console.log(ex);
                    state = "ERROR";
                };

                reader.readAsBinaryString(file);
            };
        };
    });

    function load(evt) {
        state = "LOADING";
        console.log("load");
        var files = evt.target.files; // FileList object
        var xl2json = new ExcelToJSON();
        xl2json.parseExcel(files[0]);
    }

    function parse() {
        dataOut = XLSX.utils.sheet_to_row_object_array(
            sheetData.Sheets[selectedSheet],
        );

        columns = Object.keys(dataOut[0]);

        columnMapping = {
            login: findColumnMatch(columns, mappingDictionary.login),
        };

        console.log(111, columnMapping, dataOut);

        phase = "READ";
    }

    const mappingDictionary = {
        login: ["login", "student email", "studentemail", "email"],
    };

    const findColumnMatch = (columns, potentialMatches) => {
        return columns.find((column) =>
            potentialMatches.some((match) =>
                column.toLowerCase().includes(match),
            ),
        );
    };

    function cancelDialog() {
        dialog.close();
    }

    async function saveDialog() {
        status = "saving";

        for (let i = 0; i < dataOut.length; i++) {
            let e = dataOut[i][columnMapping.login];
            await addStudentByEmail(e);
        }

        status = "ok";
        phase = "DONE";

        dispatch("importDone", {});
    }

    let okCount = 0;
    let errorCount = 0;

    async function addStudentByEmail(email) {
        await postData(
            `api/student-roster/${studentRosterId}/student-account?studentAccountLogin=${encodeURIComponent(
                email,
            )}`,
            {},
            false,
        )
            .then((data) => {
                console.log("successful", data);
                okCount = okCount + 1;
            })
            .catch((error) => {
                errorCount = errorCount + 1;
            });
    }
</script>

<svelte:head>
    <script
        lang="javascript"
        src="https://cdn.sheetjs.com/xlsx-0.20.3/package/dist/xlsx.full.min.js"
    ></script>
</svelte:head>

<dialog bind:this={dialog} style="min-width:650px;">
    {#if status !== "ok"}
        <Loader />
    {:else}
        <h3>{$_("STUDENT_ROSTER.IMPORT_FILE")}</h3>

        <div>
            <label class="form-label" for="fileInput"
                >{$_("STUDENT_ROSTER.UPLOAD_XLSX_OR_CSV_FILE")}</label
            >
            <input
                class="form-control"
                id="fileInput"
                type="file"
                bind:value={file}
                on:change={load}
            />
        </div>

        {#if sheets?.length > 1}
            <div class="mt-2">
                <button
                    class="btn btn-primary s-button-primary"
                    on:click={parse}>{$_("STUDENT_ROSTER.READ_SHEET")}</button
                >
            </div>
        {/if}

        <div>
            {#if selectedSheet && dataOut}
                <!-- {JSON.stringify(dataOut)} -->
                <table class="table">
                    <thead>
                        <th
                            >Student Email<br />
                            <select bind:value={columnMapping.login}>
                                {#each columns as c}
                                    <option value={c}>{c}</option>
                                {/each}
                            </select>
                        </th>
                    </thead>
                    <tbody>
                        {#each dataOut as r, i}
                            <tr>
                                <td>{r[columnMapping.login]}</td>
                            </tr>
                        {/each}
                    </tbody>
                </table>
            {/if}
        </div>
    {/if}

    {#if phase === "DONE"}
        <div>
            {#if okCount || errorCount}
                {$_("IMPORT_STUDENT.SUCCESS_COUNT")}
                {okCount} <br />
                {#if errorCount > 0}
                    {$_("IMPORT_STUDENT.ERROR_COUNT")}
                    {errorCount}<br />
                {/if}
                {$_("IMPORT_STUDENT.TOTAL_COUNT")}
                {dataOut.length}<br />
                {#if okCount === dataOut.length}{$_(
                        "STUDENT_ROSTER.IMPORT_SUCCESS",
                    )}{:else if errorCount > 0}{$_(
                        "STUDENT_ROSTER.IMPORT_ERROR_IMPORT",
                    )}
                {:else}{$_("STUDENT_ROSTER.IMPORT_IN_PROGRESS")}
                {/if}{/if}
        </div>
    {/if}
    <div class="mt-4">
        {#if phase === "DONE"}
            <button
                class="btn btn-danger s-button-danger me-4"
                on:click={cancelDialog}>{$_("CLOSE")}</button
            >
        {:else}
            <button
                disabled={status !== "ok" || phase !== "READ"}
                class="btn btn-success s-button-primary me-4"
                on:click={saveDialog}>{$_("SAVE")}</button
            >

            <button
                class="btn btn-danger s-button-danger"
                on:click={cancelDialog}>{$_("CANCEL")}</button
            >
        {/if}
    </div>
</dialog>
