<script>
  import Loader from "components/Loader.svelte";
  import Breadcrumb from "components/Breadcrumb.svelte";
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { onMount } from "svelte";
  import {
    getData,
    postData,
    deleteData,
    putData,
  } from "utils/ApiUtils.svelte";
  import { currentPage, userDetails } from "../../components/DataStore";
  import { dynamicSortLocale } from "utils/SortingUtils.svelte";

  export let studentRosterId;

  let studentRosterStatus = "new";
  let studentRosterData = {};
  let targetTeacherList = [];

  onMount(async () => {
    if (Number.parseInt(studentRosterId)) {
      getStudentRoster(studentRosterId);
    } else {
      studentRosterStatus = "new";
      studentRosterData = { code: "", name: "", userId: $userDetails?.id };
    }
    getAllTeachers();
  });

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    $currentPage = e;
    router.goto("/" + e);
  }

  function getStudentRoster(studentRosterId) {
    studentRosterStatus = "loading";
    getData(`api/student-roster/${studentRosterId}`)
      .then((data) => {
        console.log("successful", data);

        studentRosterData = data;

        studentRosterStatus = "ok";
      })
      .catch((error) => {
        console.log("error courses", error);
        studentRosterStatus = "error";
      });
  }

  function getAllTeachers() {
    console.log(333, $userDetails);
    if ($userDetails?.organizationId) {
      getData(`api/organization/${$userDetails?.organizationId}/teachers`)
        .then((data) => {
          console.log(321, data);
          targetTeacherList = data.filter((t) => t?.state === "ACTIVE");

          targetTeacherList = targetTeacherList.sort(
            dynamicSortLocale("teacherEmail", 1),
          );
        })
        .catch((error) => {
          console.log("error teachers", error);
        });
    }
  }

  function handleSubmit() {
    if (
      Number.parseInt(studentRosterId) &&
      studentRosterId == studentRosterData.id
    ) {
      let payload = studentRosterData;
      payload["name"] = studentRosterData.name;
      payload["code"] = studentRosterData.code;
      payload["userId"] = studentRosterData.userId;

      putData(`api/student-roster/${studentRosterId}`, payload)
        .then((data) => {
          console.log("successful", data);

          clickChangePage(`student-roster/${studentRosterId}`);
        })
        .catch((error) => {
          console.log("error courses", error);
          studentRosterStatus = "error";
        });
    } else {
      let payload = {
        name: studentRosterData.name,
        code: studentRosterData.code,
        userId: studentRosterData.userId,
      };

      if (!payload?.code) {
        studentRosterStatus = "error";
        return;
      }

      postData(`api/student-roster`, payload)
        .then((data) => {
          console.log("successful", data);

          clickChangePage(`student-roster/${data.id}`);
        })
        .catch((error) => {
          console.log("error courses", error);
          studentRosterStatus = "error";
        });
    }
  }

  function deleteStudentRoster() {
    console.log("delete studentRoster");

    if (
      confirm(
        $_("STUDENT_ROSTER.DELETE_CONFIRM_DIALOG", {
          values: { studentRosterName: studentRosterData?.name },
        }),
      )
    ) {
      // Save it!
      console.log("Thing was saved to the database.");
      deleteData(`api/student-roster/${studentRosterId}`)
        .then((data) => {
          console.log("studentRoster successful", data);

          clickChangePage("student-roster");
        })
        .catch((error) => {
          console.log("studentRoster courses", error);
        });
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  }

  function cancelEdit() {
    clickChangePage("student-roster");
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  {#if studentRosterStatus === "loading"}
    <Loader />
  {:else}
    <div class="s-content-spacing">
      <div class="s-content-header">
        <a href="/student-roster">{$_("STUDENT_ROSTER.HEADER")}</a>
      </div>
    </div>

    <div class="s-content-spacing">
      <form>
        <div class="card shadow">
          <div class="card-body">
            <div>
              <div class="form-row">
                <div class="col-lg-12 offset-lg-0">
                  <label for="courseEditDescription" class="s-form-field-prompt"
                    >{$_("STUDENT_ROSTER.CODE")}</label
                  >
                  <input
                    id="lessonDescription"
                    class="form-control s-form-input"
                    style="margin-bottom: 0;"
                    type="text"
                    placeholder={$_("STUDENT_ROSTER.CODE")}
                    bind:value={studentRosterData.code}
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="col-lg-12 offset-lg-0">
                  <label for="">{$_("STUDENT_ROSTER.TEACHER")}</label>
                  <select
                    class="form-select"
                    bind:value={studentRosterData.userId}
                    aria-label="multiple select example"
                  >
                    {#each targetTeacherList as t}
                      <option value={t.teacherUserId}
                        >{t.teacherFirstName}
                        {t.teacherLastName} ({t.teacherEmail})</option
                      >
                    {:else}<option
                        >{$_("NO_OTHER_TEACHER_IN_ORGANIZATION")}</option
                      >
                    {/each}
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div class="col offset-lg-0">
                  <label for="lessonDescription" class="s-form-field-prompt"
                    >{$_("STUDENT_ROSTER.DESCRIPTION")}</label
                  >
                  <textarea
                    id="lessonDescription"
                    class="form-control s-form-input"
                    style="margin-bottom: 0;"
                    type="text"
                    placeholder={$_("STUDENT_ROSTER.DESCRIPTION")}
                    bind:value={studentRosterData.name}
                  />
                </div>
              </div>

              {#if studentRosterStatus === "loading"}
                <div class="alert alert-success with-top-margin" role="alert">
                  {$_("LESSON_SAVED")}
                </div>
              {/if}
            </div>
          </div>

          <div class="card-header py-3">
            <div class="clearfix">
              {#if studentRosterStatus != "new"}
                <div class="btn-group" role="group">
                  <button
                    class="btn btn-danger s-button-danger"
                    on:click|preventDefault={() => deleteStudentRoster()}
                  >
                    <i
                      class="far fa-window-close"
                      style="padding-right: 8px;"
                    />{$_("DELETE")}
                  </button>
                </div>
              {/if}
              <div class="btn-group float-end" role="group">
                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  disabled={studentRosterStatus === "saving"}
                  on:click|preventDefault={() => {
                    cancelEdit();
                  }}
                >
                  <i
                    class="far fa-window-close"
                    style="padding-right: 8px;"
                  />{$_("CANCEL")}
                </button>

                <button
                  class="btn btn-success s-button-success"
                  disabled={studentRosterStatus === "saving"}
                  on:click|preventDefault={() => {
                    handleSubmit(true);
                  }}
                >
                  <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                    "SAVE",
                  )}
                  <br />
                </button>
              </div>
            </div>

            {#if studentRosterStatus === "error"}
              <div class="alert alert-danger" role="alert">
                {$_("STUDENT_ROSTER.ERROR")}
              </div>
            {:else if studentRosterStatus === "saving"}
              <div class="alert alert-info" role="alert">
                {$_("PROCESSING")}
              </div>
            {:else if studentRosterStatus === "saved"}
              <div class="alert alert-success" role="alert">
                {$_("STUDENT_ROSTER.SAVED")}
              </div>
            {/if}
          </div>
        </div>
      </form>
    </div>
  {/if}
</main>

<style>
  .validation-text-error {
    color: red;
    font-size: 0.75em;
    height: 3vh;
  }

  .validation-input-box-error {
    border-color: red;
  }

  input:invalid + span:after {
    content: "✖";
    padding-left: 5px;
  }

  input:valid + span:after {
    content: "✓";
    padding-left: 5px;
  }
</style>
