<script context="module">
  export function dynamicSort(property, sortOrder) {
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;

      return result * sortOrder;
    };
  }

  export function dynamicSortLocale(property, sortOrder) {
    return function (a, b) {
      // Use localeCompare for case-insensitive comparison of strings
      if (typeof a[property] === "string" && typeof b[property] === "string") {
        // 'i' option for case-insensitivity
        var result = a[property]?.localeCompare(b[property], undefined, {
          sensitivity: "accent",
          caseFirst: "false",
        });
      } else {
        // Fallback to regular comparison for non-string types
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      }

      return result * sortOrder;
    };
  }

  export function dynamicSortLocaleTwo(property, secondary, sortOrder) {
    return function (a, b) {
      // Use localeCompare for case-insensitive comparison of strings
      if (typeof a[property] === "string" && typeof b[property] === "string") {
        // 'i' option for case-insensitivity
        var result = a[property]?.localeCompare(b[property], undefined, {
          sensitivity: "accent",
          caseFirst: "false",
        });
        if (result === 0) {
          result = a[secondary]?.localeCompare(b[secondary], undefined, {
            sensitivity: "accent",
            caseFirst: "false",
          });
        }
      } else {
        // Fallback to regular comparison for non-string types
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        if (result === 0) {
          result =
            a[secondary] < b[secondary]
              ? -1
              : a[secondary] > b[secondary]
                ? 1
                : 0;
        }
      }

      return result * sortOrder;
    };
  }

  export function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
</script>
