<script>
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { getData, postData } from "utils/ApiUtils.svelte";
    import {
        currentCourseId,
        currentLessonId,
        currentCourseName,
        currentLessonName,
        userDetails,
    } from "../../../components/DataStore";
    import { dynamicSort } from "utils/SortingUtils.svelte";
    import Loader from "../../../components/Loader.svelte";
    import { createEventDispatcher } from "svelte";

    export let courseId;
    const dispatch = createEventDispatcher();

    let dialogCopyToOtherTeacher;

    let targetTeacherList = [];
    let status = "";

    let teacherUserId;
    let newCourseId;

    let sameDistributorOrgList = [];
    let selectedOrgId;

    let courseData = [];
    let courseStatus = "new";

    let targetCourseName;
    let targetTeacherEmail;

    async function initialize() {
        status = "loading";
        teacherUserId = $userDetails?.id;
        targetCourseName = $currentCourseName;
        await Promise.allSettled([
            getData(`api/organization/same-distributor?distributor=${$userDetails?.organizationDistributor}`)
                .then((data) => {
                    console.log(321, data);
                    sameDistributorOrgList = data;
                })
                .catch((error) => {
                    console.log("error teachers", error);
                })

            // getData("api/organization/all-teachers")
            //     .then((data) => {
            //         console.log(321, data);
            //         targetTeacherList = data.filter(
            //             (t) => t?.state === "ACTIVE",
            //         );
            //     })
            //     .catch((error) => {
            //         console.log("error teachers", error);
            //     }),
        ]);

        status = "ok";
    }

    export function openCopyToOtherTeacherDialog() {
        initialize();
        dialogCopyToOtherTeacher.showModal();
    }

    function cancelCopyToOtherTeacher() {
        dialogCopyToOtherTeacher.close();
    }

    function saveCopyToOtherTeacher() {
        if (!targetTeacherEmail) {
            return;
        }

        status = "saving";
        let payload = {
            courseId: $currentCourseId,
            targetOrganizationId: selectedOrgId,
            targetEmail: targetTeacherEmail,
            targetCourseName: targetCourseName,
        };

        // copyLessonToOtherCourseSuccessStatus = "saving";
        postData(
            `api/courses/${$currentCourseId}/copyToOrganization`,
            payload,
        )
            .then((data) => {
                console.log("courses successful", data);
                // copyLessonToOtherCourseSuccessStatus = "ok";
                status = "ok";
                dispatch("done", { result: "ok" });
                dialogCopyToOtherTeacher.close();
            })
            .catch((error) => {
                console.log("error courses", error);
                // copyLessonToOtherCourseSuccessStatus = "error";
            });
    }
</script>

<dialog bind:this={dialogCopyToOtherTeacher} style="min-width:450px;">
    {#if status !== "ok"}
        <Loader />
    {:else}
        <h3>{$_("COURSE.COPY_TO_ANOTHER_ORG")}</h3>

        <label
            for="courseEditEmail"
            style="padding-right: 12px;padding-top: 6px;"
            >{$_("ORGANIZATION.TARGET_ORGANIZATION")}</label
        >

        <select
            class="form-control"
            id="courseEditEmail"
            bind:value={selectedOrgId}
            on:change={(z) => {
                teacherUserId = undefined;

                let org = sameDistributorOrgList.find(
                    (v) => v?.id === selectedOrgId,
                );
                console.log("org", org);
                targetTeacherEmail = org?.adminUserLogin;
            }}
        >
            <option></option>
            {#each sameDistributorOrgList as o}
                {#if o?.id}
                    <option value={o?.id}>{o?.organizationName}</option>
                {/if}
            {/each}
        </select>

        <label
            for="courseEditEmail"
            style="padding-right: 12px;padding-top: 6px;"
            >{$_("ORGANIZATION.TEACHER_EMAIL")}</label
        >

        <input
            class="form-control s-form-input"
            type="text"
            disabled
            bind:value={targetTeacherEmail}
        />

        <label
            for="courseEditEmail"
            style="padding-right: 12px;padding-top: 6px;"
            >{$_("COURSE_NAME")}</label
        >

        <input
            class="form-control s-form-input"
            type="text"
            bind:value={targetCourseName}
        />
    {/if}
    <div class="mt-4">
        <button
            disabled={status !== "ok" || !targetTeacherEmail}
            class="btn btn-success s-button-primary"
            on:click={saveCopyToOtherTeacher}>{$_("SAVE")}</button
        ><button
            class="btn btn-danger s-button-danger ms-4"
            on:click={cancelCopyToOtherTeacher}>{$_("CANCEL")}</button
        >
    </div>
</dialog>
