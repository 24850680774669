<script>
  export let title = ''
  let isHovered = false

  function mouseOver(event) {
    isHovered = true
  }
  function mouseMove(event) {}
  function mouseLeave() {
    isHovered = false
  }
  function focusChange() {
    isHovered = !isHovered
  }
</script>

<div style="position: relative; display: inline;">
  <!-- svelte-ignore a11y-mouse-events-have-key-events -->
  <div
    on:mouseover={mouseOver}
    on:mouseleave={mouseLeave}
    on:mousemove={mouseMove}
    style="display:inline;"
  >
    <slot />
  </div>

  {#if isHovered}
    <div style="top: 1em; left: 1em;" class="tooltip2">{title}</div>
  {/if}
</div>

<style>
  .tooltip2 {
    border: 1px solid #ddd;
    box-shadow: 1px 1px 1px #ddd;
    background: white;
    border-radius: 4px;
    padding: 4px;
    position: absolute;
    z-index: 5;
    min-width: 20em;
  }
</style>
