import { writable } from 'svelte/store';

export const currentPage = writable('login');
export const loginToken = writable(null);

export const currentCourseId = writable(null);
export const currentCourseName = writable(null);

export const currentCourseRoleCheck = writable({});

export const currentLessonId = writable(null);
export const currentLessonName = writable(null);

export const currentExerciseId = writable(null);
export const currentExerciseName = writable(null);

export const currentRecordingId = writable(null);
export const currentRecordingStudentName = writable(null);

export const userDetails = writable(null);
export const userContext = writable(null);

export const avatarImageSrc = writable(null);

export const searchTerm = writable(null);

export const userConfig = writable({ "featureSwitch": { "recordingDebug": false, "recordingVisualization": false } });

export const termsAndConditionOpen = writable(false);

export const leftMenuToggled = writable(false);

export const isOpenSendFeedbackModal = writable(false);
export const isOpenGettingStartedModal = writable(false);

export const trialModalOpen = writable(true);
