import { writable } from 'svelte/store';
 
export const ssmlState = writable('');

export const exerciseData = writable({
    type: 'READ',
    title: '',
    text: '',
    youtubeUrl: '',
    question: '',
    imageUrl: '',
    data: '{}',
  })