<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { getData, patchData, putData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { createEventDispatcher } from "svelte";
  import { userDetails } from "../../components/DataStore";
  import { validateEmail } from "../../utils/FormatUtils.svelte";
  import { dynamicSort, dynamicSortLocale } from "utils/SortingUtils.svelte";
  import TableHeaderItem from "../../components/TableHeaderItem.svelte";

  export let organizationId;

  const dispatch = createEventDispatcher();

  let teachersData = [];
  let teachersStatus = "new";

  let teacherClassData = [];
  let teacherClassStatus = "new";

  let newTeacherId;
  let newStudentEmail;
  let isValidEmail = true;

  let dialogChangeOwner;
  let dialogInviteStudent;

  let editData;
  let editRowData;

  let sortColumn = "login";
  let sortDirection = 1;
  let filterSelections = "";
  let filterCourseName = "";

  onMount(async () => {
    if (organizationId) {
      getOrganizationClassTeachers();
      getOrganizationTeachers();
    }
  });

  function getOrganizationClassTeachers() {
    teacherClassStatus = "loading";
    getData(
      `api/organization/${organizationId}/teacher-statistics`,
      false,
      false,
    )
      .then((data) => {
        console.log("organization teachers successful", data);

        data.forEach((subRow) => {
          subRow["courseJsonParsed"] = parseJsonData(subRow.courseJson)
        });
        

        teacherClassData = data.sort(dynamicSort(sortColumn, sortDirection));

        if (filterSelections && filterSelections.length > 0) {
          teacherClassData = teacherClassData.filter((s) => {
            return (
              s?.login?.includes(filterSelections) ||
              s?.teacherName?.includes(filterSelections)
            );
          });
        }

        if (filterCourseName && filterCourseName.length > 0) {
          teacherClassData = teacherClassData.filter((s) => {
            let cCount = s?.courseJsonParsed.filter((n)=>{
              return n?.name?.includes(filterCourseName)
            })
            if (cCount){
              s["courseJsonParsed"] = cCount
            }
            console.log('xx', cCount)
            return (
              cCount.length > 0
            );
          });
        }

        console.log("teacherClassData", teacherClassData);

        teacherClassStatus = "ok";
      })
      .catch((error) => {
        teacherClassStatus = "error";
        console.log("error organization teachers", error);
      });
  }

  function changeSort(e) {
    console.log(111, e.detail);
    sortColumn = e.detail.sortColumn;
    sortDirection = e.detail.sortDirection;

    teacherClassStatus = "loading";
    teacherClassData = teacherClassData.sort(dynamicSort(sortColumn, sortDirection));
    teacherClassStatus = "ok";
  }

  function getOrganizationTeachers() {
    getData(`api/organization/${organizationId}/teachers`, false, false)
      .then((data) => {
        console.log("organization teachers successful", data);
        // Save data to sessionStorage
        teachersData = data.sort(dynamicSort("teacherEmail", 1));
        teachersStatus = "ok";
      })
      .catch((error) => {
        console.log("error organization teachers", error);
      });
  }

  function parseJsonData(x) {
    try {
      const y = JSON.parse(x);
      return y;
    } catch (e) {
      console.log(444, e);
    }

    return [];
  }

  function openChangeOwnerDialog(rowData, classData) {
    editRowData = rowData;
    editData = classData;
    dialogChangeOwner.showModal();
  }

  function saveChangeOwnerDialog() {
    console.log(editData, newTeacherId);

    let courseId = editData.idCourse;

    let payload = {
      teacherId: newTeacherId,
      organizationId: organizationId,
    };

    patchData(`api/school-admin/courses/${courseId}/change-owner`, payload)
      .then((data) => {
        console.log("organization teachers successful", data);
        // Save data to sessionStorage
        teachersStatus = "ok";

        dispatch("update", {});
        getOrganizationClassTeachers(organizationId);
        dialogChangeOwner.close();
      })
      .catch((error) => {
        console.log("error organization teachers", error);
      });
  }

  function cancelDialog() {
    dialogChangeOwner.close();
    dialogInviteStudent.close();
  }

  function openInviteStudentDialog(rowData, classData) {
    editRowData = rowData;
    editData = classData;
    isValidEmail = true;
    dialogInviteStudent.showModal();
  }

  function saveAddStudentDialog() {
    console.log();

    isValidEmail = validateEmail(newStudentEmail);
    if (!isValidEmail) {
      return;
    }

    let payload = {
      courseId: editData?.idCourse,
      studentEmail: newStudentEmail,
    };

    putData(
      `api/school-admin/courses/${editData?.idCourse}/student-accounts`,
      payload,
    )
      .then((data) => {
        console.log("organization teachers successful", data);

        getOrganizationClassTeachers(organizationId);
        dispatch("update", {});
        dialogInviteStudent.close();
      })
      .catch((error) => {
        console.log("error organization teachers", error);
      });
  }
</script>

<div class="card shadow">
  <div class="card-header py-3 no-print">


    <div class="row">
      <div style="display: flex;gap: 2em;">
        <div class="form-check">
          <label for="studentNameFilterInput" class="form-check-label"
            >{$_("ORGANIZATION.TEACHER")}</label
          >
          <input
            id="studentNameFilterInput"
            class="form-control s-form-input"
            type="text"
            style="min-width: 20em;"
            bind:value={filterSelections}
            on:blur={getOrganizationClassTeachers}
          />
        </div>
        <div class="form-check">
          <label for="courseNameFilterInput" class="form-check-label"
            >{$_("COURSE_NAME")}</label
          >
          <input
            id="courseNameFilterInput"
            class="form-control s-form-input"
            type="text"
            style="min-width: 20em;"
            bind:value={filterCourseName}
            on:blur={getOrganizationClassTeachers}
          />
        </div>
      </div>
    </div>
  </div>
  <dialog bind:this={dialogChangeOwner} style="min-width:450px;">
    <h3>{$_("ORGANIZATION.CHANGE_TEACHER")}</h3>
    <div style="display:none;">
      {JSON.stringify(editRowData)}
      {JSON.stringify(editData)}
    </div>
    <div>{$_("COURSE_NAME")}: {editData?.name}</div>
    <label for="courseEditEmail" style="padding-right: 12px;padding-top: 6px;"
      >{$_("ORGANIZATION.TEACHER_EMAIL")}</label
    >
    <select class="form-control" id="courseEditEmail" bind:value={newTeacherId}>
      {#each teachersData as t}
        {#if t?.teacherId}
          <option value={t?.teacherId}
            >{t?.teacherEmail} - {t?.teacherFirstName
              ? t?.teacherFirstName
              : ""}
            {t?.teacherLastName ? t?.teacherLastName : ""}</option
          >
        {/if}
      {/each}
    </select>
    <div class="mt-4">
      <button
        class="btn btn-success s-button-primary"
        on:click={saveChangeOwnerDialog}>{$_("SAVE")}</button
      ><button
        class="btn btn-danger s-button-danger ms-4"
        on:click={cancelDialog}>{$_("CANCEL")}</button
      >
    </div>
  </dialog>

  <dialog bind:this={dialogInviteStudent} style="min-width:450px;">
    <h3>{$_("ORGANIZATION.ADD_STUDENT")}</h3>
    <div style="display:none;">
      {JSON.stringify(editRowData)}
      {JSON.stringify(editData)}
      ({editData?.idCourse})
    </div>
    <div>{$_("COURSE_NAME")}: {editData?.name}</div>
    <div class="form-row">
      <div class="col-lg-12 offset-lg-0">
        <label
          for="courseEditEmail"
          style="padding-right: 12px;padding-top: 6px;"
          >{$_("ORGANIZATION.STUDENT_EMAIL")}</label
        >
        <input
          id="courseEditEmail"
          class="form-control"
          class:is-invalid={!isValidEmail}
          type="text"
          placeholder={$_("ORGANIZATION.STUDENT_EMAIL")}
          bind:value={newStudentEmail}
        />
        <div class="invalid-feedback">
          {$_("EMAIL_ADDRESS_INVALID_FEEDBACK")}
        </div>
      </div>
    </div>
    <div class="mt-4">
      <button
        disabled={!isValidEmail}
        class="btn btn-success s-button-primary"
        on:click={saveAddStudentDialog}>{$_("SAVE")}</button
      ><button
        class="btn btn-danger s-button-danger ms-4"
        on:click={cancelDialog}>{$_("CANCEL")}</button
      >
    </div>
  </dialog>

  <div class="card-body">
    {#if teacherClassStatus != "ok"}
      <Loader />
    {:else if teacherClassData?.length > 0}
      <div
        class="table table mt-2"
        id="dataTable"
        role="grid"
        aria-describedby="dataTable_info"
      >
        <table class="table my-0 table-hover" id="dataTable">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">
                <TableHeaderItem
                column="login"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_("ORGANIZATION.TEACHER_EMAIL")}</TableHeaderItem
            >
                </th>
              <th scope="col">
                <TableHeaderItem
                column="teacherName"
                {sortColumn}
                {sortDirection}
                on:changeSort={changeSort}
                >{$_("TEACHER")}</TableHeaderItem
            >
                </th>
              <!-- <th scope="col">{$_('ORGANIZATION.COURSE_COUNT')}</th> -->
              <th scope="col">{$_("ORGANIZATION.COURSE_DETAIL")}</th>
            </tr>
          </thead>
          <tbody>
            {#if teacherClassData?.length > 0}
              {#each teacherClassData as subRow, i}
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td
                    ><a href="#/teacher-account/{subRow.id}">{subRow.login}</a
                    ></td
                  >
                  <td>{subRow.teacherName}</td>
                  <!-- <td>{subRow.courseCount}</td> -->
                  <td>
                    <table class="table my-0 table-hover" id="dataTable2">
                      <thead>
                        <tr
                          ><th>{$_("COURSE_NAME")}</th><th style="width:3em;"
                            >{$_("#SA")}</th
                          ><th style="width:3em;">{$_("#REC")}</th><th
                            style="width:18em;">{$_("ACTIONS")}</th
                          ></tr
                        >
                      </thead>
                      <tbody>
                        {#each subRow?.courseJsonParsed.sort(dynamicSortLocale("name", 1)) as c, y}
                          <tr>
                            <td>
                              <a
                                href={`#courses-detail/${c.idCourse}/student-accounts`}
                                >{c.name}</a
                              >
                            </td><td>{c?.saCount}</td><td>{c.recCount}</td><td
                              class="action-buttons"
                              ><button
                                class="btn btn-danger s-button-danger"
                                style="float:right"
                                on:click={() =>
                                  openChangeOwnerDialog(subRow, c)}
                                >{$_("ORGANIZATION.CHANGE_TEACHER")}</button
                              ><button
                                class="btn btn-primary s-button-primary"
                                on:click={() =>
                                  openInviteStudentDialog(subRow, c)}
                                >{$_("ORGANIZATION.ADD_STUDENT")}</button
                              ></td
                            >
                          </tr>
                        {:else}No Class
                        {/each}
                      </tbody>
                    </table>
                  </td>
                </tr>
              {/each}
            {/if}
          </tbody>
          <tfoot>
            <tr />
          </tfoot>
        </table>
      </div>
    {/if}
  </div>
</div>

<style>
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;
  }
</style>
