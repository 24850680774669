<script>
  import { _, locale } from "svelte-i18n";
  import { router } from "tinro";
  import { updateContext } from "utils/ApiCalls.svelte";
  import { userContext } from "../../components/DataStore";
  import { onMount } from "svelte";
  import { userDetails } from "../../components/DataStore";
  import FreFrame from "./FreFrame.svelte";
  import IntroVideoModal from "../IndexPage/IntroVideoModal.svelte";
  import FiveStepsEn from "./Infographics/FiveStepsEn.svelte";
  import FiveStepsPartnerEn from "./Infographics/FiveStepsPartnerEn.svelte";
  import FiveStepsPartnerZhTw from "./Infographics/FiveStepsPartnerZhTw.svelte";
  import FiveStepsZhTw from "./Infographics/FiveStepsZhTw.svelte";

  let isOpenFre = false;

  onMount(() => {
    userContext.subscribe((v) => {
      console.log(110, v);
      isOpenFre = v?.fre?.freStatus === "NEW";
    });
  });

  function clickFre() {
    mixpanel.track("Teacher Sensay Walkthrough Card Clicked");
    isOpenFre = true;
  }

  function dismiss() {
    mixpanel.track("Teacher Dismiss FRE Dashboard Section Button Clicked");
    // updateContext({ fre: { freStatus: 'CLOSED' } })
    updateContext({ fiveSteps: { status: "CLOSED" } });
  }

  let isOpenWhyWeStartedSensayVideo = false;
  function showWhyWeStartedSensayVideo() {
    mixpanel.track("Teacher Why We Started Sensay Video Clicked");
    isOpenWhyWeStartedSensayVideo = !isOpenWhyWeStartedSensayVideo;
  }

  let isOpenHowDoesSensayWorkVideo = false;
  function showHowDoesSensayWorkVideo() {
    mixpanel.track("Teacher Intro Video Clicked");
    isOpenHowDoesSensayWorkVideo = !isOpenHowDoesSensayWorkVideo;
  }

  function addNewClass() {
    mixpanel.track("Teacher Sensay Walkthrough Add New Class");
    router.goto("/courses-edit");
  }
</script>

<IntroVideoModal
  bind:isOpen={isOpenWhyWeStartedSensayVideo}
  label={$_("WHY_WE_STARTED_SENSAY")}
  videoSRC="https://www.youtube.com/embed/LsIP6Rv7sIE"
/>
<IntroVideoModal
  bind:isOpen={isOpenHowDoesSensayWorkVideo}
  label={$_("HOW_DOES_SENSAY_WORK")}
  videoSRC="https://www.youtube.com/embed/IW8s3fzIhSg"
/>

{#if $userContext != null && $userContext?.fre?.freStatus !== "CLOSED"}
  <div>
    <FreFrame bind:isOpen={isOpenFre} />
  </div>
{/if}

{#if $userContext != null && $userContext?.fiveSteps?.status !== "CLOSED"}
  <div class="fretask-header">
    <div class="fretask-header-greeting">
      {$_("FRE_TASK_SECTION_GREETING", {
        values: {
          name: $userDetails?.firstName,
        },
      })}
    </div>
    <div class="fretask-header-dismiss">
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div on:click|preventDefault={dismiss} class="dismissFre">
        X {$_("FRE_TASK_SECTION_DISMISS_TIPS")}
      </div>
    </div>
  </div>
  <div class="m-4">
    {#if $locale === "zh-TW"}
      {#if $userDetails?.dsns}
        <FiveStepsPartnerZhTw partner={"1Campus"} />
      {:else if $userDetails?.spRefreshToken}
        <FiveStepsPartnerZhTw partner={"School+"} />
      {:else}
        <FiveStepsZhTw />
      {/if}
    {:else if $userDetails?.dsns}
      <FiveStepsPartnerEn partner={"1Campus"} />
    {:else if $userDetails?.spRefreshToken}
      <FiveStepsPartnerEn partner={"School+"} />
    {:else}
      <FiveStepsEn />
    {/if}
  </div>
{/if}

<style>
  .fretask-header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    width: 100%;

    padding-bottom: 0.75vh;
    margin-bottom: 1.5vh;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  }

  .fretask-header-greeting {
    align-items: center;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-size: 1.5em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
  }

  .fretask-header-dismiss {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 0.88rem;

    display: flex;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
  }

  .dismissFre {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;

    align-items: center;
    color: #00a094;
    text-decoration: none;

    cursor: pointer;
  }

  /* .fretask-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    width: 100%;
  } 

  .fretask-card {
    width: 100%;
    height: 6.25rem;
    padding-left: 5vw;

    background: #eceef7;
    border-radius: 10px;
  }

  .fretask-card-video {
    background: #f6f6f6;
    border: none;
  }

  .fretask-card-video:hover {
    background: #e8f5f4;
  } */

  :global(.fretask-card-video:hover > div > svg > circle) {
    fill: #00a094;
    fill-opacity: 1;
  }

  :global(.fretask-card-video:hover > div > svg > path) {
    stroke: #fff;
  }

  :global(.fretask-card-video:hover > div > span) {
    color: #00a094;
  }

  /* .fre-card-title {
    font-size: 1.7rem;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    margin-left: 0.75rem;
    color: #fff;
  }

  .fre-card-title-video {
    color: #616161;
  }

  .fre-card-number {
    margin-right: 0.4rem;
  } */

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    /* .fretask-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }

    .fretask-card {
      padding-left: 5vw;
    } */
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    /* .fretask-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }

    .fretask-card {
      padding-left: 1vw;
    } */
  }
</style>
