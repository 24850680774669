<script>
    import { _ } from "svelte-i18n";
    import { router } from "tinro";
    import { getData, postData, deleteData } from "../../utils/ApiUtils.svelte";
    import Loader from "../../components/Loader.svelte";
    import { onMount } from "svelte";
    import Line from "svelte-chartjs/src/Line.svelte";
    import {
      currentPage,
      currentCourseId,
      currentCourseName,
      currentLessonId,
      currentLessonName,
      currentExerciseId,
      currentExerciseName,
      currentRecordingId,
    } from "../../components/DataStore";
    import SvelteTable from "svelte-table";
    import printJS from "print-js";
    import { formatNumberWith4Colors } from "utils/FormatUtils.svelte";
    import TeacherCourseTable from "./TeacherCourseTable.svelte";
  
    export let teacherUserId;
  
    let teacherDetails = {};
    let teacherStatistics = {};
  
    let teacherDetailsStatus = "loading";
    let teacherStatisticsStatus = "loading";
  
    onMount(async () => {
      getStudentDetails();
      getStudentStatistics();
    });
  
    function getStudentDetails() {
      getData("api/school-admin/teacher/" + teacherUserId)
        .then((data) => {
          // console.log('getStudentDetails successful', data)
  
          teacherDetails = data;
          teacherDetailsStatus = "ok";
          // getStudentRecordingList();
        })
        .catch((error) => {
          teacherDetailsStatus = "error";
          console.log("error teacher", error);
        });
    }

    function getStudentStatistics() {
      getData(`api/school-admin/teacher/${teacherUserId}/dashboard`)
        .then((data) => {
          // console.log('getStudentDetails successful', data)
  
          teacherStatistics = data;
          teacherStatisticsStatus = "ok";
          // getStudentRecordingList();
        })
        .catch((error) => {
            teacherStatisticsStatus = "error";
          console.log("error teacher", error);
        });
    }
    
  </script>
  
  <main id="student-detail-report" class="container-fluid px-4 s-container-fluid">
    <div class="s-content-spacing">
      <div class="s-content-header">
        <div class="s-content-header-title">
          {$_("TEACHER_REPORT")}
        </div>
      </div>
    </div>
  
    <div class="s-content-spacing">
      <h4 class="text-dark mb-4">
        {#if teacherDetails?.firstName || teacherDetails?.lastName}
          {teacherDetails?.firstName} {teacherDetails?.lastName}
        {/if}
        {#if teacherDetails?.login}({teacherDetails?.login}){/if}
      </h4>
    </div>
  
    {#if teacherStatisticsStatus == "NO_DATA"}
      <div class="s-content-spacing">
        <div class="card shadow mb-4">
          <div class="card-header py-3 no-print" id="no-print">
            <div class="btn-group ml-4" role="group">
            </div>
          </div>
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">{$_("NO_DATA")}
              </div>
              </div>
              </div>
  
        </div>
      </div>
    {:else if teacherStatisticsStatus == "ok"}
      <div class="s-content-spacing">
        <div class="card shadow mb-4">
          <div class="card-header py-3 no-print" id="no-print">
            <h2>{$_('TEACHER_REPORT.STATISTICS')}</h2>
          </div>
          <div class="card-body">
            <div class="container-fluid">
                {$_("TEACHER_REPORT.COURSE_TOTAL")}: {teacherStatistics?.courseTotal} <br />
                {$_("TEACHER_REPORT.LESSON_TOTAL")}: {teacherStatistics?.lessonTotal} <br />
                {$_("TEACHER_REPORT.EXERCISE_TOTAL")}: {teacherStatistics?.exerciseTotal} <br />
                {$_("TEACHER_REPORT.RECORDINGS_TOTAL")}: {teacherStatistics?.publicRecordingTotal} <br />
            </div>
          </div>
        </div>
      </div>
    {/if}

    <div class="s-content-spacing">
        <TeacherCourseTable {teacherUserId} />
        </div>
  
    
  </main>
  
  
  <style>
    :global(.recordingRow) {
      cursor: pointer;
    }
  
    :global(.recordingRow:hover) {
      background-color: whitesmoke;
    }
  
    
  </style>
  