<script>
  import { onMount } from 'svelte'
  import { _, locale } from 'svelte-i18n'
  import { isOpenGettingStartedModal } from '../../components/DataStore'
  import Modal from 'sv-bootstrap-modal'

  onMount(async () => {
    isOpenGettingStartedModal.subscribe((x) => {
      console.log(x)
    })
  })
</script>

<Modal bind:open={$isOpenGettingStartedModal} dialogClasses="modal-lg">
  <div class="modal-header">
    <h3 class="modal-title">{$_('GETTING_STARTED')}</h3>
    <button
      type="button"
      class="s-modal-close"
      on:click={() => ($isOpenGettingStartedModal = false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="videoWrapper">
      {#if $locale === 'zh-TW'}
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/videoseries?list=PLnIV4hsDRlHG5WQpBhwwJtIdB2zkCUqLC&autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      {:else}
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/videoseries?list=PLnIV4hsDRlHEP97UOOR_4FMj9DzLH611o&autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      {/if}
    </div>
  </div>
</Modal>

<style>
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
