<script>
  import ExerciseStudentRecordingTab from './ExerciseStudentRecordingTab.svelte'
  import ExerciseContentTab from './ExerciseContentTab.svelte'

  export let selectedTab
</script>

<div class="tab-content">
  <div
    class="tab-pane"
    class:active={selectedTab === 'tabRecording'}
    role="tabpanel"
    id="tabRecording"
  >
    <ExerciseStudentRecordingTab />
  </div>

  <div
    class="tab-pane"
    class:active={selectedTab === 'tabExerciseDetail'}
    role="tabpanel"
    id="tabExerciseDetail"
  >
    <ExerciseContentTab />
  </div>
</div>
