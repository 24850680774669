<script>
  import { _ } from 'svelte-i18n'
  export let type
</script>

{#if type === 'READ'}
<svg class="svg-inline--fa fa-file-lines" style="margin-right: 8px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-lines" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM272 416h-160C103.2 416 96 408.8 96 400C96 391.2 103.2 384 112 384h160c8.836 0 16 7.162 16 16C288 408.8 280.8 416 272 416zM272 352h-160C103.2 352 96 344.8 96 336C96 327.2 103.2 320 112 320h160c8.836 0 16 7.162 16 16C288 344.8 280.8 352 272 352zM288 272C288 280.8 280.8 288 272 288h-160C103.2 288 96 280.8 96 272C96 263.2 103.2 256 112 256h160C280.8 256 288 263.2 288 272z"></path></svg>
{$_('READING')}
{:else if type === 'VIDEO'}
  <i class="fa-brands fa-youtube" style="margin-right: 8px;" />{$_('VIDEO')}
{:else if type === 'QUESTION'}
  <i class="fa fa-question" style="margin-right: 8px;" />{$_(
    'QUESTION_AND_ANSWER'
  )}
{:else if type === 'SELF_PRACTICE'}
  <i class="fa fa-comment-alt" style="margin-right: 8px;" />{$_(
    'SELF_PRACTICE'
  )}
{:else if type === 'IMAGE'}
  <i class="fa fa-image" style="margin-right: 8px;" />{$_('IMAGE')}
{:else if type === 'READ_IMAGE'}
  <i class="fa fa-image" style="margin-right: 8px;" />{$_(
    'EXERCISE_.READ_IMAGE'
  )}
{:else if type === 'QUESTION_FIX_ANSWER'}
  <i class="fa fa-question" style="margin-right: 8px;" />{$_(
    'EXERCISE_.QUESTION_FIX_ANSWER'
  )}
{/if}

<!-- {#if type === 'READ'}
  <i style="margin-right: 8px;" />{$_('READING')}
{:else if type === 'VIDEO'}
  <i  style="margin-right: 8px;" />{$_('VIDEO')}
{:else if type === 'QUESTION'}
  <i  style="margin-right: 8px;" />{$_(
    'QUESTION_AND_ANSWER'
  )}
{:else if type === 'SELF_PRACTICE'}
  <i class="fa fa-comment-alt" style="margin-right: 8px;" />{$_(
    'SELF_PRACTICE'
  )}
{:else if type === 'IMAGE'}
  <i  style="margin-right: 8px;" />{$_('IMAGE')}
{:else if type === 'READ_IMAGE'}
  <i  style="margin-right: 8px;" />{$_(
    'EXERCISE_.READ_IMAGE'
  )}
{:else if type === 'QUESTION_FIX_ANSWER'}
  <i  style="margin-right: 8px;" />{$_(
    'EXERCISE_.QUESTION_FIX_ANSWER'
  )}
{/if} -->