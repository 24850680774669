<script context="module">
  export function convertSecToMin(timeInSec, defaultValue = "") {
    if (!timeInSec) {
      return defaultValue;
    }
    let mins = ~~((timeInSec % 3600) / 60);
    let secs = ~~timeInSec % 60;
    let formattedTime =
      (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "") + secs;
    //console.log(formattedTime)
    return formattedTime;
  }

  export function formatNumberWith4Colors(value, defaultValue = "") {
    if (!value) {
      return defaultValue;
    }
    const num = Number.parseFloat(value);
    if (num) {
      return num.toFixed(0);
    }
    return defaultValue;
  }

  export function average(arr) {
    return arr.reduce((p, c) => p + c, 0) / arr.length;
  }

  export function trimText(string, length = 20) {
    return string?.length > length
      ? string?.substring(0, length - 3) + "..."
      : string;
  }

  export function generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime(); //Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
  }

  export function CSVToArray(strData, strDelimiter) {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ",";

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
      // Delimiters.
      "(\\" +
        strDelimiter +
        "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        "\\r\\n]*))",
      "gi",
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      var strMatchedDelimiter = arrMatches[1];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([]);
      }

      var strMatchedValue;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3];
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
  }

  export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  //To check if an array is empty using javascript
  export function arrayIsEmpty(array) {
    //If it's not an array, return FALSE.
    if (!Array.isArray(array)) {
      return FALSE;
    }
    //If it is an array, check its length property
    if (array.length == 0) {
      //Return TRUE if the array is empty
      return true;
    }
    //Otherwise, return FALSE.
    return false;
  }

  export function cleanCharactersForSSML(text) {
    text = text.replaceAll("&", "&amp;");
    text = text.replaceAll("<", "&lt;");
    text = text.replaceAll(">", "&gt;");
    return text;
  }

  export function validateEmail(value) {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  }

  export function mapPronToIpa(ph) {
    const mapTable = {
      B: "b",
      CH: "t.ʃ",
      D: "d",
      DH: "ð",
      DX: "ɾ",
      F: "f",
      G: "g",
      H: "h",
      HH: "h",
      JH: "d.ʒ",
      K: "k",
      L: "l",
      M: "m",
      N: "n",
      NG: "ŋ",
      NX: "ɾ̃",
      P: "p",
      R: "ɻ",
      S: "s",
      SH: "ʃ",
      T: "t",
      TH: "θ",
      V: "v",
      W: "w",
      WH: "ʍ",
      J: "j",
      Z: "z",
      ZH: "ʒ",
      AA: "ɑ",
      AE: "æ",
      AH0: "ə",
      AH: "ʌ",
      AO: "ɔ",
      AOX: "ɔ.ə",
      AU: "ɑ.ʊ",
      AX: "ə",
      AXR: "ɚ",
      AI: "ɑ.ɪ",
      AW: "aʊ",
      AY: "ɑ.ɪ",
      EH: "ɛ",
      EHX: "ɛ.ə",
      ER: "ɜ",
      EL: "l̩",
      EM: "m̩",
      EN: "n̩",
      ERR: "ɝ",
      EI: "e.ɪ",
      EY: "e.ɪ",
      IH: "ɪ",
      I: "i",
      IY: "i",
      O: "o",
      OI: "ɔ.ɪ",
      OY: "ɔ.ɪ",
      OW: "əʊ",
      OWX: "o.ə",
      Q: "ɒ",
      UH: "ʊ",
      U: "u",
      UW: "uː",
      UX: "ʉ",
      UWX: "u.ə",
      Y: "j",
    };
    const p = ph.toUpperCase();
    return mapTable[p];
  }
</script>
