<script>
  import { _ } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import { createEventDispatcher } from 'svelte'
  import { userDetails } from '../../components/DataStore'

  const dispatch = createEventDispatcher()

  onMount(async () => {})

  function closeFinalStep() {
    mixpanel.track('Teacher FRE Get started with Sensay Button Clicked')
    dispatch('nextPage', {})
  }
</script>

<div class="step-layout">
  <div class="steps-list-group">
    <span class="steps-list-heading">{$_('FRE_STEP_FINAL_HEADING')}</span>
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_CLASS')}
    </div>
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_LESSON')}
    </div>
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_CREATE_EXERCISE')}
    </div>
    <div class="steps-list-item steps-list-complete">
      <i class="far fa-check-circle mr-2" />{$_('FRE_STEP_SHARE')}
    </div>
  </div>

  <div class="interaction-area">
    <div class="logo">
      <img class="sensay-logo" src="../assets/img/logo.svg" alt="Sensay Logo" />
    </div>
    <div class="congrats-text-heading">
      {$_('FRE_STEP_FINAL_PROMPT_HEADING', {
        values: {
          name: $userDetails?.firstName,
        },
      })}
    </div>
    <div class="congrats-text-desc">
      {$_('FRE_STEP_FINAL_PROMPT_TEXT')}
    </div>

    <div class="fre-btn-group">
      <button class="btn btn-primary s-button-primary" on:click={closeFinalStep}
        >{$_('FRE_STEP_FINISH_BUTTON')}</button
      >
    </div>
  </div>

  <div class="exit">
    <button
      type="button"
      class="s-modal-close"
      on:click={() => dispatch('closeModal', {})}
    >
      &times;
    </button>
  </div>
</div>

<style>
  .step-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    grid-template-areas:
      'steps exit exit'
      'steps interact interact'
      'steps interact interact';
  }

  .steps-list-group {
    grid-area: steps;

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    width: 100%;

    background-color: #cacaca30;
  }

  .steps-list-heading {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.6em;
    color: #000000;

    align-items: center;
    padding: 0 3vw 0 3vw;
    margin-bottom: 3vh;
  }

  .steps-list-item {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #00000060;

    align-items: center;
    padding: 0 3vw 0 3vw;
  }

  .steps-list-complete {
    text-decoration-line: line-through;
  }

  .interaction-area {
    grid-area: interact;

    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .congrats-text-heading {
    width: 80%;
    text-align: center;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5em;

    color: #000000;
  }

  .congrats-text-desc {
    width: 80%;
    text-align: center;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    color: rgba(17, 17, 17, 0.8);
  }

  .fre-btn-group {
    margin-top: 3vh;
  }

  .exit {
    grid-area: exit;
    height: 2vh;
  }

  @media only screen and (min-width: 992px) {
    .steps-list-heading {
      font-size: 2em;
    }

    .step-layout {
      grid-template-columns: repeat(3, 1fr);
    }
  }
</style>
