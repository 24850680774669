<script>
  import { _ } from "svelte-i18n";
  import { getData, postData } from "../../utils/ApiUtils.svelte";
  import Loader from "../../components/Loader.svelte";
  import { onMount } from "svelte";
  import SvelteTable from "svelte-table";
  import { router } from "tinro";
  import { currentPage, userConfig } from "../../components/DataStore";
  import AutoComplete from "simple-svelte-autocomplete";
  import StudentRecordingsDropDown from "../../components/StudentRecordingsDropDown.svelte";
  import printJS from "print-js";
  import { formatNumberWith4Colors } from "utils/FormatUtils.svelte";
  import PremiumIcon from "../../components/visual/PremiumIcon.svelte";
  import TableHeaderItem from "../../components/TableHeaderItem.svelte";
  import { dynamicSort } from "utils/SortingUtils.svelte";

  let studentList = [];
  let studentNameList = [];
  let filterSelections = { studentName: "" };
  let courseList = [];
  let courseListFilter = [];

  let includeQr = true;

  let studentListStatus = "loading";
  let courseListStatus = "loading";

  let sortColumn = "createdAt";
  let sortDirection = -1;

  userConfig.subscribe((v) => {
    if (v?.serverFlags?.qrHidden === true) {
      includeQr = false;
    }
  });

  onMount(async () => {
    getStudentList();
    getCourseList();
  });

  function viewStudentDetail(r, newTab = false) {
    console.log("viewStudentDetail", r.studentName);
    mixpanel.track("Teacher View Student Detail Clicked", {
      Location: "Student Page Overview",
    });
    clickChangePage("student-detail/" + r.studentName);
  }

  function clickChangePage(e) {
    console.log("clickChangePage", e);
    $currentPage = e;
    router.goto("/" + e);
  }

  function changeSort(e) {
    console.log(111, e.detail);
    sortColumn = e.detail.sortColumn;
    sortDirection = e.detail.sortDirection;

    // getStudentList();

    studentListStatus = "loading";

    studentList = studentList.sort(dynamicSort(sortColumn, sortDirection));

    studentListStatus = "ok";
  }

  function getStudentList() {
    let courseIdList = courseListFilter;
    getData(`api/studentList4?courseId=${courseIdList}`)
      .then((data) => {
        console.log("studentList successful", data, includeQr);

        if (includeQr === true) {
          studentList = data;
        } else {
          studentList = data.filter((d) => {
            return d.studentAccountId != null;
          });
        }

        studentNameList = studentList.map((s) => s.studentName);
        studentNameList = studentNameList.sort();

        if (filterSelections?.studentName) {
          studentList = studentList.filter((s) => {
            return s.studentName
              ?.toLowerCase()
              .includes(filterSelections.studentName.toLowerCase());
          });
        }

        studentList = studentList.sort(dynamicSort(sortColumn, sortDirection));
        studentListStatus = "ok";

        console.log("studentNameList", studentNameList);
      })
      .catch((error) => {
        studentListStatus = "error";
        console.log("error lessons", error);
      });
  }

  function getCourseList() {
    getData("api/courses-coop")
      .then((data) => {
        console.log("studentList successful", data);

        courseList = data;

        courseListStatus = "ok";
        console.log("studentNameList", studentNameList);
      })
      .catch((error) => {
        courseListStatus = "error";
        console.log("error lessons", error);
      });
  }

  function refreshList() {
    mixpanel.track("Teacher Refresh Button Clicked", {
      "Button Location": "Students Page",
    });
    studentListStatus = "loading";
    getStudentList();
  }

  function printList() {
    mixpanel.track("Teacher Print Button Clicked", {
      "Button Location": "Students Page",
    });
    printJS("dataTable", "html");
  }

  function downloadList() {
    const mimeType = "data:text/csv;charset=utf-8";

    let studentListOut = studentList.map((r) => {
      r.overallScoreAvg = Number.parseInt(r?.overallScoreAvg?.toFixed(0));
      delete r.accuracyScoreAvg;
      delete r.completenessScoreAvg;
      delete r.fluencyScoreAvg;
      delete r.pronScoreAvg;
      return r;
    });

    if (filterSelections.studentName) {
      studentListOut = studentListOut.filter((s) => {
        return s.studentName === filterSelections.studentName;
      });
    }

    let items = studentListOut.map(function (v) {
      let x = Object.assign({}, v);
      delete x.studentToken;
      return x;
    });

    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    //const header = Object.keys(items[0]);
    const header = [
      "studentName",
      "overallScoreAvg",
      "publicRecordingCount",
      "publicRecordingCountDistinct",
      "totalWordCount",
    ];
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(","),
      ),
    ].join("\r\n");

    const d = new Date();

    var dummy = document.createElement("a");
    dummy.href = URL.createObjectURL(
      new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
        type: mimeType,
      }),
    );
    dummy.download = "students" + "-" + d.toISOString() + ".csv";
    dummy.click();
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  <div class="s-content-spacing">
    <div class="s-content-header">
      <div class="s-content-header-title">{$_("STUDENTS")}</div>
    </div>
  </div>

  <div class="s-content-spacing">
    <div class="card shadow">
      <div
        class="card-header py-3 no-print d-flex flex-row align-items-center justify-content-between"
      >
        <div>
          <button
            class="btn btn-primary s-button-primary s-mr-10px"
            id="reloadExerciseListButton"
            on:click={refreshList}
            ><i class="fa fa-refresh" style="padding-right: 8px;" />{$_(
              "REFRESH",
            )}<br /></button
          >
          <button class="btn btn-primary s-button-primary" on:click={printList}
            ><i class="fa fa-print" style="padding-right: 8px;" />{$_(
              "PRINT",
            )}<br /></button
          >
          <!-- <button class="btn btn-primary" on:click={downloadList}
        ><i class="fa fa-download" style="padding-right: 8px;" />{$_(
          "EXPORT_CSV"
        )}<br /></button
      > -->
        </div>
        <StudentRecordingsDropDown
          on:refresh={refreshList}
          on:print={printList}
          on:exportAllToCsv={downloadList}
        />
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <label for="studentNameSearch" class="mr-3"
              >{$_("SEARCH_NAME")}</label
            >
            <!-- <select id="studentNameSearch" class="form-select" on:change={refreshList} bind:value={filterSelections.studentName}>
              <option value=""/>
              {#each studentNameList as s}
              <option value={s}>{s}</option>
              {/each}
            </select> -->
            <input
              type="text"
              class="form-control"
              style="min-width: 20em;"
              id="studentNameSearch"
              on:change={refreshList}
              bind:value={filterSelections.studentName}
            />
          </div>
          <div class="col mt-4 form-check">
            {#if $userConfig?.serverFlags?.qrHidden === true}
              <label
                id="includeQr"
                class="mr-3 form-check-label text-secondary"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  bind:checked={includeQr}
                  on:input={refreshList}
                  for="includeQr"
                  disabled
                />{$_("SHOW_QR_SHARE_STUDENTS")}
                <PremiumIcon></PremiumIcon></label
              >
            {:else}
              <label id="includeQr" class="mr-3 form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  bind:checked={includeQr}
                  on:input={refreshList}
                  for="includeQr"
                />{$_("SHOW_QR_SHARE_STUDENTS")}</label
              >
            {/if}
          </div>
          <div class="col">
            <label for="studentNameSearch" class="mr-3"
              >{$_("SELECT_COURSE")}</label
            >
            <select
              class="form-select"
              bind:value={courseListFilter}
              on:change={() => {
                refreshList();
              }}
              aria-label="multiple select example"
            >
              <option value="" />
              {#each courseList as c}
                <option value={c.id}>{c.name}</option>
              {/each}
            </select>
          </div>
        </div>
        {#if studentListStatus === "loading"}
          <Loader />
        {:else if studentListStatus === "error"}
          <div class="alert alert-danger" role="alert">
            {$_("LESSON_LIST_LOAD_ERROR")}
          </div>
        {:else}
          <div
            class="table-responsive table mt-2"
            style="overflow-y: hidden"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info"
          >
            <table class="table my-0 table-hover" id="dataTable">
              <thead>
                <th style="min-width:4em;">#</th>
                <th 
                  ><TableHeaderItem
                    column="studentName"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                    >{$_("STUDENT_NAME")}</TableHeaderItem
                  ></th
                >

                <th
                  ><TableHeaderItem
                    column="overallScoreAvg"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                    >{$_("AVERAGE_SCORE")}</TableHeaderItem
                  ></th
                >

                <th
                  ><TableHeaderItem
                    column="publicRecordingCount"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                    >{$_("RECORDING_COUNT")}</TableHeaderItem
                  ></th
                >

                <th
                  ><TableHeaderItem
                    column="totalWordCount"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                    >{$_("SPOKEN_WORD_COUNT")}</TableHeaderItem
                  ></th
                >

                <th
                  ><TableHeaderItem
                    column="lastRecording"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                    >{$_("LAST_RECORDING")}</TableHeaderItem
                  ></th
                >

                <th
                  ><TableHeaderItem
                    column="publicRecordingCountDistinct"
                    {sortColumn}
                    {sortDirection}
                    on:changeSort={changeSort}
                    >{$_("EXERCISE_COUNT")}</TableHeaderItem
                  ></th
                >
              </thead>
              <tbody>
                {#each studentList as v, i}
                  <tr>
                    <td
                      >{i + 1}
                      <span class="ms-2">
                        <a
                          href={v?.studentAccountId
                            ? `/#/student-account-detail/${v.studentAccountId}`
                            : `/#/student-detail/${v.studentName}`}
                          target="_blank"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 30 30"
                            width="1em"
                            height="1em"
                            ><path
                              d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"
                            /></svg
                          ></a
                        ></span
                      ></td
                    >
                    <td>
                      {#if v?.studentAccountId}
                        <a
                          href={`/#/student-account-detail/${v.studentAccountId}`}
                          ><strong>{v.studentName}</strong></a
                        >
                      {:else}
                        <a href={`/#/student-detail/${v.studentName}`}
                          >{v?.studentName}</a
                        >
                      {/if}
                    </td>
                    <td
                      >{formatNumberWith4Colors(
                        v?.overallScoreAvg?.toFixed(0),
                      ) || ""}</td
                    >
                    <td>{v?.publicRecordingCount || "0"}</td>
                    <td>{v?.totalWordCount || "0"}</td>
                    <td
                      >{new Date(
                        Date.parse(v?.lastRecording + "Z"),
                      ).toLocaleString()}</td
                    >
                    <td>{v.publicRecordingCountDistinct || "0"}</td>
                  </tr>
                {:else}{$_("NO_DATA")}
                {/each}
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-md-6 align-self-center">
              <p
                id="dataTable_info"
                class="dataTables_info"
                role="status"
                aria-live="polite"
              >
                {studentList?.length}
                {$_("RESULT_COUNT")}
              </p>
            </div>
            <div class="col-md-6">
              <nav
                class="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers"
              />
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  :global(.recordingRow) {
    cursor: pointer;
  }
  :global(.recordingRow:hover) {
    background-color: whitesmoke;
  }
</style>
