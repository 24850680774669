<script>
  import LeftMenu from '../../components/LeftMenu.svelte'
  import TopMenu from '../../components/TopMenu.svelte'
  import Footer from '../../components/Footer.svelte'
  import ExerciseEditView from './ExerciseComponent/ExerciseEditView.svelte'
  import {
    leftMenuToggled,
    currentCourseId,
    currentLessonId,
    currentExerciseId,
  } from '../../components/DataStore'
  export let courseId = undefined
  export let lessonId = undefined
  export let exerciseId = undefined

  if (courseId && lessonId) {
    $currentCourseId = courseId
    $currentLessonId = lessonId

    console.log('$currentCourseId', $currentCourseId)
    console.log('$currentLessonId', $currentLessonId)
  }
  if (exerciseId) {
    $currentExerciseId = exerciseId
    console.log('$currentExerciseId', $currentExerciseId)
  } else {
    $currentExerciseId = null
    console.log('$currentExerciseId missing', $currentExerciseId)
  }
</script>

<div class="sb-nav s-nav" class:sb-sidenav-toggled={$leftMenuToggled}>
  <TopMenu />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftMenu activePage="courses" />
    </div>
    <div id="layoutSidenav_content">
      <ExerciseEditView />
      <!-- <Footer /> -->
    </div>
  </div>
</div>
