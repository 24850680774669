<svg
  width="16px"
  height="16px"
  viewBox="0 0 20 20"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
>
  <path
    stroke="#000000"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M4 7l6 6 6-6"
  />
</svg>
