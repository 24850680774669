<script>
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { getData, postData } from "utils/ApiUtils.svelte";
    import {
        currentCourseId,
        currentLessonId,
        currentLessonName,
        userDetails,
    } from "../../../components/DataStore";
    import { dynamicSort } from "utils/SortingUtils.svelte";
    import Loader from "../../../components/Loader.svelte";
    import { createEventDispatcher } from "svelte";

    export let lessonId;
    const dispatch = createEventDispatcher();

    let dialogCopyToOtherTeacher;

    let targetTeacherList = [];
    let status = "";

    let teacherUserId;
    let newCourseId;

    let courseData = [];
    let courseStatus = "new";

    let targetLessonName;

    async function initialize() {
        status = "loading";
        teacherUserId = $userDetails?.id;
        targetLessonName = $currentLessonName;
        await Promise.allSettled([
            getData("api/organization/all-teachers")
                .then((data) => {
                    console.log(321, data);
                    targetTeacherList = data.filter(
                        (t) => t?.state === "ACTIVE",
                    );
                })
                .catch((error) => {
                    console.log("error teachers", error);
                }),
            getData(`api/courses-coop`, false, false)
                .then((data) => {
                    console.log("organization courses successful", data);
                    // Save data to sessionStorage
                    courseData = data;
                    courseData.sort(dynamicSort("name", 1));
                    courseStatus = "ok";
                })
                .catch((error) => {
                    courseStatus = "error";
                    console.log("error organization courses", error);
                }),
        ]);

        status = "ok";
    }

    export function openCopyToOtherTeacherDialog() {
        initialize();
        dialogCopyToOtherTeacher.showModal();
    }

    function cancelCopyToOtherTeacher() {
        dialogCopyToOtherTeacher.close();
    }

    function saveCopyToOtherTeacher() {
        if (!newCourseId) {
            return;
        }

        status = "saving";
        let payload = {
            courseId: $currentCourseId,
            lessonId: $currentLessonId,
            targetCourseId: newCourseId,
            targetTeacherUserId: teacherUserId,
            targetLessonName: targetLessonName,
        };

        // copyLessonToOtherCourseSuccessStatus = "saving";

        postData(
            `api/courses/${$currentCourseId}/lessons/${$currentLessonId}/copyToCourse`,
            payload,
        )
            .then((data) => {
                console.log("courses successful", data);
                // copyLessonToOtherCourseSuccessStatus = "ok";
                status = "ok";
                dispatch("done", { result: "ok" });
                dialogCopyToOtherTeacher.close();
            })
            .catch((error) => {
                console.log("error courses", error);
                // copyLessonToOtherCourseSuccessStatus = "error";
            });
    }
</script>

<dialog bind:this={dialogCopyToOtherTeacher} style="min-width:450px;">
    {#if status !== "ok"}
        <Loader />
    {:else}
        <h3>{$_("LESSON.COPY_TO_OTHER_TEACHER")}</h3>

        <label
            for="courseEditEmail"
            style="padding-right: 12px;padding-top: 6px;"
            >{$_("ORGANIZATION.TEACHER_EMAIL")}</label
        >

        <select
            class="form-control"
            id="courseEditEmail"
            bind:value={teacherUserId}
            on:change={() => {
                newCourseId = undefined;
            }}
        >
            {#each targetTeacherList as t}
                {#if t?.teacherUserId}
                    <option value={t?.teacherUserId}
                        >{t?.teacherEmail} - {t?.teacherFirstName
                            ? t?.teacherFirstName
                            : ""}
                        {t?.teacherLastName ? t?.teacherLastName : ""}</option
                    >
                {/if}
            {/each}
        </select>

        <!-- XX {teacherUserId}: -->
        {#key teacherUserId}
            <!-- {targetTeacherList.filter((t) => t.teacherUserId === teacherUserId)[0]
            ?.teacherEmail} -->

            <label
                for="courseEditEmail"
                style="padding-right: 12px;padding-top: 6px;"
                >{$_("COURSE")}</label
            >

            <select
                class="form-control"
                id="courseEditEmail"
                bind:value={newCourseId}
            >
                {#each courseData.filter((c) => c?.teacherUserId === targetTeacherList.filter((t) => t.teacherUserId === teacherUserId)[0]?.teacherUserId) as c}
                    {#if c?.teacherUserId === targetTeacherList.filter((t) => t.teacherUserId === teacherUserId)[0]?.teacherUserId}
                        <option value={c?.id}
                            >{c?.name} ({c?.id}-
                            {c?.teacherUserId})</option
                        >
                    {/if}
                {:else}<option disabled>{$_("LESSON.COPY_LESSON_NO_CLASS")}</option>
                {/each}
            </select>
        {/key}

        <label
            for="courseEditEmail"
            style="padding-right: 12px;padding-top: 6px;"
            >{$_("LESSON_NAME")}</label
        >

        <input
            class="form-control s-form-input"
            type="text"
            bind:value={targetLessonName}
        />
    {/if}
    <div class="mt-4">
        <button
            disabled={status !== "ok" || !newCourseId}
            class="btn btn-success s-button-primary"
            on:click={saveCopyToOtherTeacher}>{$_("SAVE")}</button
        ><button
            class="btn btn-danger s-button-danger ms-4"
            on:click={cancelCopyToOtherTeacher}>{$_("CANCEL")}</button
        >
    </div>
</dialog>
