<script>
  import Loader from 'components/Loader.svelte'
  // import Datepicker from "svelte-calendar";
  import TextAreaAutoresize from 'components/TextAreaAutoresize.svelte'
  import Breadcrumb from 'components/Breadcrumb.svelte'
  import * as yup from 'yup'
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import { onMount } from 'svelte'
  import { getData, postData, deleteData, putData } from 'utils/ApiUtils.svelte'
  import { writable } from 'svelte/store'
  import {
    currentPage,
    currentCourseId,
    currentCourseName,
    currentLessonId,
    currentLessonName,
  } from '../../../components/DataStore'

  // Form Validation
  const schema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(50, 'LESSON_VALIDATION_TOO_LONG')
      .required('LESSON_VALIDATION_ERROR_NO_NAME'),
    description: yup.string(),
    activeDateStart: yup.date().nullable(),
    activeDateEnd: yup.date().nullable(),
  })
  let errors = {}

  let lessonDetailStatus = 'loading'
  let lessonData = writable({
    name: '',
    description: '',
    color: '#BCD2F0',
    activeDateStartDate: new Date(),
    activeDateEndDate: new Date(),
  })

  let dueDateEnabled = false

  lessonData.subscribe((x) => {
    lessonDetailStatus = 'ok'
  })

  onMount(async () => {
    if ($currentCourseId && $currentLessonId) {
      getLesson($currentCourseId, $currentLessonId)
    } else {
      lessonDetailStatus = 'new'
    }
  })

  function clickChangePage(e) {
    console.log('clickChangePage', e)
    $currentPage = e
    router.goto('/' + e)
  }

  function parseIsoDate(isoString) {
    //const x = "2022-07-31T21:26:30.846Z";
    return new Date(Date.parse(isoString))
  }

  function cleanDateForInputDatetimeLocal(dt) {
    //const dt = new Date();
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr)

    return `${dt.getFullYear()}-${padL(dt.getMonth() + 1)}-${padL(
      dt.getDate()
    )}T${padL(dt.getHours())}:${padL(dt.getMinutes())}`
  }

  function getLesson(courseId, lessonId) {
    getData('api/courses/' + courseId + '/lessons/' + lessonId)
      .then((data) => {
        console.log('lesson successful', data)
        if (data.activeDateStart) {
          data.activeDateStartUi = cleanDateForInputDatetimeLocal(
            parseIsoDate(data.activeDateStart)
          )
        } else {
          data.activeDateStartUi = new Date()
        }
        if (data.activeDateEnd) {
          data.activeDateEndUi = cleanDateForInputDatetimeLocal(
            parseIsoDate(data.activeDateEnd)
          )
        } else {
          data.activeDateEndUi = new Date()
        }

        lessonData.update((x) => data)
        currentLessonName.update((x) => $lessonData?.name)
        currentCourseName.update((x) => $lessonData?.courseName)
        lessonDetailStatus = 'loaded'
        if ($lessonData?.activeDateEnd) {
          dueDateEnabled = true
        } else {
          dueDateEnabled = false
        }
      })
      .catch((error) => {
        console.log('error courses', error)
        lessonDetailStatus = 'error'
      })
  }

  async function handleSubmit(moveOut) {
    try {
      await schema.validate($lessonData, { abortEarly: false })
      // for debugging validation result
      // alert(JSON.stringify($lessonData, null, 2));
      errors = {}
      saveLesson(moveOut)
    } catch (err) {
      console.log(333, err)
      errors = err.inner.reduce((acc, err) => {
        return { ...acc, [err.path]: err.message }
      }, {})
    }
  }

  function saveLesson(moveOut = false) {
    console.log(444)
    lessonDetailStatus = 'saving'
    let payloadData = {
      name: $lessonData.name,
      description: $lessonData.description,
      activeDateStart: $lessonData.activeDateStart,
      activeDateEnd: $lessonData.activeDateEnd,
    }

    if (!dueDateEnabled) {
      payloadData.activeDateStart = undefined
      payloadData.activeDateEnd = undefined
    }

    console.log('save lesson', payloadData)

    if (!$lessonData?.id) {
      postData('api/courses/' + $currentCourseId + '/lessons', payloadData)
        .then((data) => {
          console.log('courses successful', data)

          lessonData.update((x) => data)
          currentLessonId.update((x) => data?.id)

          lessonDetailStatus = 'saved'

          mixpanel.track('Teacher Save Lesson Button Clicked', {
            New: true,
            'Lesson ID': $currentLessonId,
            'Lesson Description': $lessonData.description,
          })

          if (moveOut) {
            clickChangePage(
              'courses/' +
                $currentCourseId +
                '/lesson-detail/' +
                $currentLessonId
            )
          }
        })
        .catch((error) => {
          console.log('error courses', error)
          lessonDetailStatus = 'error'
        })
    } else {
      mixpanel.track('Teacher Save Lesson Button Clicked', {
        New: false,
        'Lesson ID': $lessonData?.id,
        'Lesson Description': $lessonData.description,
      })
      putData(
        'api/courses/' + $lessonData?.courseId + '/lessons/' + $lessonData?.id,
        payloadData
      )
        .then((data) => {
          console.log('courses successful', data)

          lessonData.update((x) => data)
          lessonDetailStatus = 'saved'

          if (moveOut) {
            clickChangePage(
              'courses/' +
                $currentCourseId +
                '/lesson-detail/' +
                $currentLessonId
            )
          }
        })
        .catch((error) => {
          console.log('error courses', error)
          lessonDetailStatus = 'error'
        })
    }
  }

  function cancelLessonEdit() {
    if (lessonDetailStatus === 'saved' || lessonDetailStatus === 'loaded') {
      clickChangePage(
        'courses/' + $currentCourseId + '/lesson-detail/' + $currentLessonId
      )
    } else {
      if (confirm($_('LESSON_CANCEL_EDIT'))) {
        if ($lessonData?.id) {
          clickChangePage(
            'courses/' + $currentCourseId + '/lesson-detail/' + $currentLessonId
          )
        } else {
          clickChangePage('courses-detail/' + $currentCourseId)
        }
      } else {
        // Do nothing!
        // console.log('Cancel')
      }
    }
  }

  function deleteLesson() {
    console.log('delete lesson')
    mixpanel.track('Teacher Delete Button Clicked', {
      'Button Location': 'Lesson Edit Page',
    })

    if (
      confirm(
        $_('LESSON_DELETE_CONFIRM', {
          values: { lessonName: $lessonData?.name },
        })
      )
    ) {
      // Save it!
      console.log('Thing was saved to the database.')
      deleteData(
        'api/courses/' + $currentCourseId + '/lessons/' + $currentLessonId
      )
        .then((data) => {
          console.log('lesson deleted successfully', data)

          clickChangePage('courses-detail/' + $currentCourseId)
        })
        .catch((error) => {
          console.log('error lesson', error)
        })
    } else {
      console.log('Thing was not saved to the database.')
    }
  }

  function handleCtrlEnter(event) {
    console.log('Save')
    saveLesson(true)
  }

  function skipEnterOnInput(event) {
    console.log(event)
    if (event.key === 'Enter' && !event.ctrlKey) {
      event.preventDefault()
    } else if (event.key === 'Enter' && event.ctrlKey) {
      console.log('Save')
      saveLesson(true)
    }
  }
</script>

<main class="container-fluid px-4 s-container-fluid">
  {#if lessonDetailStatus === 'loading'}
    <Loader />
  {:else}
    <div class="s-content-spacing">
      <div class="s-content-header">
        <Breadcrumb />
      </div>
    </div>

    <div class="s-content-spacing">
      <form>
        <div class="card shadow">
          <div class="card-body">
            <div>
              <div class="form-row">
                <div class="col offset-lg-0">
                  <label for="lessonDescription" class="s-form-field-prompt"
                    >{$_('LESSON_NAME')}</label
                  >
                  <input
                    id="lessonDescription"
                    class="form-control s-form-input"
                    class:validation-input-box-error={errors.name}
                    style="margin-bottom: 0;"
                    type="text"
                    placeholder={$_('LESSON_NAME')}
                    bind:value={$lessonData.name}
                    on:keydown={skipEnterOnInput}
                  />
                  <div class="validation-text-error">
                    {#if errors.name}
                      {$_(errors.name)}
                    {/if}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-lg-12 offset-lg-0">
                  <label for="courseEditDescription" class="s-form-field-prompt"
                    >{$_('LESSON_DESCRIPTION')}</label
                  >
                  <TextAreaAutoresize
                    bind:value={$lessonData.description}
                    on:ctrlEnter={handleCtrlEnter}
                  />
                </div>
              </div>
              <div class="form-row" style="margin-top:1em;">
                <div class="col">
                  <label for="dueDate" class="s-form-field-prompt"
                    ><input
                      type="checkbox"
                      id="dueDate"
                      name="dueDate"
                      bind:checked={dueDateEnabled}
                    />
                    {$_('LESSON_DEADLINE_FLAG')}</label
                  >
                </div>
              </div>
              {#if dueDateEnabled}
                <div class="row">
                  <div class="col form-group">
                    <label for="activeDateStartUi">{$_('START_DATE')}</label>
                    <input
                      id="activeDateStartUi"
                      type="datetime-local"
                      bind:value={$lessonData.activeDateStartUi}
                      on:change={(v) => {
                        $lessonData.activeDateStart = new Date(
                          Date.parse(v.target.value)
                        )?.toISOString()
                      }}
                    />
                    <span class="validity" />
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="activeDateEndUi">{$_('DEADLINE')}</label>
                    <input
                      id="activeDateEndUi"
                      type="datetime-local"
                      bind:value={$lessonData.activeDateEndUi}
                      on:change={(v) => {
                        $lessonData.activeDateEnd = new Date(
                          Date.parse(v.target.value)
                        )?.toISOString()
                      }}
                    />
                    <span class="validity" />
                  </div>
                </div>
              {/if}

              {#if lessonDetailStatus === 'loading'}
                <div class="alert alert-success with-top-margin" role="alert">
                  {$_('LESSON_SAVED')}
                </div>
              {/if}
            </div>
          </div>

          <div class="card-header py-3">
            <div class="clearfix">
              {#if lessonDetailStatus != 'new'}
                <div class="btn-group" role="group">
                  <button
                    class="btn btn-danger s-button-danger"
                    on:click={deleteLesson}
                  >
                    <i
                      class="far fa-window-close"
                      style="padding-right: 8px;"
                    />{$_('DELETE')}
                  </button>
                </div>
              {/if}

              <div class="btn-group float-end" role="group">
                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  on:click={cancelLessonEdit}
                >
                  <i
                    class="far fa-window-close"
                    style="padding-right: 8px;"
                  />{$_('CANCEL')}
                </button>

                <button
                  class="btn btn-secondary s-button-secondary s-mr-10px"
                  type="button"
                  style="display: none"
                  on:click={() => {
                    handleSubmit(false)
                  }}
                >
                  <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                    'SAVE_DRAFT'
                  )}
                  <br />
                </button>

                <button
                  class="btn btn-success s-button-success"
                  type="submit"
                  disabled={lessonDetailStatus === 'saving'}
                  on:click|preventDefault={() => {
                    handleSubmit(true)
                  }}
                >
                  <i class="fa fa-save" style="padding-right: 8px;" />{$_(
                    'SAVE'
                  )}
                  <br />
                </button>
              </div>
            </div>

            {#if lessonDetailStatus === 'error'}
              <div class="alert alert-danger" role="alert">
                {$_('LESSON_SAVING_ERROR')}
              </div>
            {:else if lessonDetailStatus === 'saving'}
              <div class="alert alert-info" role="alert">
                {$_('PROCESSING')}
              </div>
            {:else if lessonDetailStatus === 'saved'}
              <div class="alert alert-success" role="alert">{$_('SAVED')}</div>
            {/if}
          </div>
        </div>
      </form>
    </div>
  {/if}
</main>

<style>
  .validation-text-error {
    color: red;
    font-size: 0.75em;
    height: 3vh;
  }

  .validation-input-box-error {
    border-color: red;
  }

  input:invalid + span:after {
    content: '✖';
    padding-left: 5px;
  }

  input:valid + span:after {
    content: '✓';
    padding-left: 5px;
  }
</style>
