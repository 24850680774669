<script>
  import { _ } from 'svelte-i18n'
  import { postData } from '../../utils/ApiUtils.svelte'
  import PasswordInput from '../../components/visual/PasswordInput.svelte'

  let changePasswordStatus = 'loading'
  let errorMessage = ''

  let changePasswordOldPassword
  let changePasswordNewPassword
  let changePasswordConfirmPassword

  let isValidPassword = undefined
  let passwordField = undefined

  async function changePasswordSubmit() {
    try {
      await passwordField.validatePassword()
      if (!isValidPassword) {
        return
      }

      if (changePasswordOldPassword === changePasswordNewPassword) {
        changePasswordStatus = 'error'
        errorMessage = $_('PASSWORD_CHANGE_ERROR_MUST_BE_DIFFERENT')
        return
      } else if (changePasswordNewPassword != changePasswordConfirmPassword) {
        changePasswordStatus = 'error'
        errorMessage = $_('PASSWORD_CHANGE_ERROR_MUST_MATCH')
        return
      }

      console.log(
        'changePassword',
        changePasswordOldPassword,
        changePasswordNewPassword,
        changePasswordConfirmPassword
      )

      let changePasswordJson = {
        oldPasswordPlain: changePasswordOldPassword,
        newPasswordPlain: changePasswordNewPassword,
      }

      changePasswordStatus = 'processing'

      postData('api/user/new-password', changePasswordJson)
        .then((data) => {
          console.log('login successful', data)
          changePasswordStatus = 'changed'
          changePasswordOldPassword = ''
          changePasswordNewPassword = ''
          changePasswordConfirmPassword = ''
        })
        .catch((error) => {
          console.log('error login', error)
          changePasswordStatus = 'error'
          errorMessage = $_('PASSWORD_CHANGE_ERROR')
        })
    } catch (err) {
      console.log('change password error:', err)
    }
  }
</script>

<div class="">
  <div class="s-content-header">
    <div class="s-content-header-title">{$_('CHANGE_PASSWORD')}</div>
  </div>

  <div class="">
    <form>
      <div class="form-row">
        <div class="col mb-2">
          <div class="form-group s-form-group">
            <label for="changePassword" class="s-form-field-prompt"
              >{$_('CURRENT_PASSWORD')}</label
            >
            <PasswordInput
              showValidation={false}
              bind:password={changePasswordOldPassword}
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group s-form-group">
            <label for="changePassword" class="s-form-field-prompt"
              >{$_('NEW_PASSWORD')}</label
            >
            <PasswordInput
              bind:password={changePasswordNewPassword}
              bind:isValidPassword
              bind:this={passwordField}
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group s-form-group">
            <label for="changePasswordConfirm" class="s-form-field-prompt"
              >{$_('CONFIRM_NEW_PASSWORD')}</label
            >
            <PasswordInput bind:password={changePasswordConfirmPassword} />
          </div>
        </div>
      </div>

      {#if changePasswordStatus === 'error'}
        <div class="alert alert-danger with-top-margin" role="alert">
          {errorMessage}
        </div>
      {/if}
      {#if changePasswordStatus === 'processing'}
        <div class="alert alert-primary with-top-margin" role="alert">
          {$_('PROCESSING')}
        </div>
      {/if}
      {#if changePasswordStatus === 'changed'}
        <div class="alert alert-success with-top-margin" role="alert">
          {$_('PASSWORD_CHANGED')}
        </div>
      {/if}

      <div class="form-group s-form-group">
        <button
          class="btn btn-primary s-button-primary"
          style="width: 100%"
          type="button"
          id="changePasswordButton"
          disabled={changePasswordStatus === 'processing'}
          on:click={changePasswordSubmit}>{$_('CHANGE_PASSWORD')}</button
        >
      </div>
    </form>
  </div>
</div>
