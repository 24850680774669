<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";

  export let elementId = "dropdownMenu";

  const dispatch = createEventDispatcher();

  function hideDropdown() {
    bootstrap.Dropdown.getInstance(`#${elementId}`).hide();
  }

  function refresh() {
    hideDropdown();
    dispatch("refresh", {});
  }

  function exportToCsv() {
    hideDropdown();
    dispatch("exportToCsv", {});
  }
</script>

<div class="btn-group">
  <button
    id={elementId}
    type="button"
    class="dropdown-toggle no-border"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
  </button>
  <ul class="dropdown-menu">
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={refresh}
        ><i class="fa fa-refresh" style="padding-right: 8px;" />{$_(
          "REFRESH"
        )}</button
      >
    </li>
    <li>
      <button
        class="dropdown-item"
        type="button"
        on:click|stopPropagation={exportToCsv}
        ><i class="fa fa-download" style="padding-right: 8px;" />{$_(
          "EXPORT_ALL_TO_CSV"
        )}</button
      >
    </li>
  </ul>
</div>

<style>
  .no-border {
    border: 0px;
  }

  .dropdown-menu {
    /* background-color: var(--bs-card-cap-color); */
  }
</style>
