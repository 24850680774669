<script>
  import { onMount } from 'svelte'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let value = ''
  export let error 

  let element

  onMount(async () => {
    autoGrow()
  })

  function autoGrow() {
    element.style.height = '7em'
    element.style.height = element.scrollHeight + 'px'
  }

  function handleCtrlEnter(event) {
    if ((event.keyCode == 10 || event.keyCode == 13) && event.ctrlKey) {
      console.log('handleCtrlEnter')
      dispatch('ctrlEnter')
    }
  }

  function onChangeCleanUnicodeText(event) {
    //Here add more required trasnformations
    event.target.value = event?.target?.value.replace(/\u2019/g, "'")
    event.target.value = event?.target?.value.replace(/\2026/g, '.')
    value = event.target.value
  }
</script>

<div class="text-area-container">
  <textarea
    bind:this={element}
    on:input={autoGrow}
    on:change={onChangeCleanUnicodeText}
    on:keydown={handleCtrlEnter}
    bind:value
    class:validation-input-box-error={error}
    {...$$restProps}
  />
</div>

<style>
  .text-area-container {
    position: relative;
  }

  textarea {
    resize: none;
    overflow: auto;
    min-height: 7em;
    max-height: 15em;
    width: 100%;
  }
</style>
